import React, { useEffect, useState } from 'react';
import * as PaymentService from '@services/payment';
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import valid from "card-validator";
import 'react-credit-cards/es/styles-compiled.css';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import Swal from 'sweetalert2';
import { appTimeZone } from '@app/appConstants';
import moment from 'moment-timezone';
require("dotenv").config();

const Payment = (props) => {

    const history = useHistory();
    let { id } = useParams();
    const [rolePrice, setRolePrice] = useState('');
    const [planId, setPlanId] = useState('');
    const [selectPlanId, setSelectPlanId] = useState('');
    const [selectPlanText, setSelectPlanText] = useState('');

    const handleSubmit = async (values) => {
        document.getElementById("load").style.display = "block";
        try {
            values.userId = window.atob(id)
            values.planId = selectPlanId

            if (values.expiry) {
                let selectedDate = values.expiry.split("-");
                values.exp_month = selectedDate[1]
                values.exp_year = selectedDate[0]
                // values.expiry = undefined
                // values.name = undefined
            }
            const paymentData = await PaymentService.subscriptionPlan(values);
            if (paymentData) {
                if (props.isLoggedIn) {
                    const user = await Auth.currentAuthenticatedUser();
                    if (user && user.attributes) {
                        const { userData } = await AuthService.GetProfile(window.atob(id));
                        const { roleData } = await AuthService.GetRoleById(user.attributes['custom:user_type']);
                        const session = await Auth.currentSession();
                        props.onUserLogin(session.idToken.jwtToken);
                        await Auth.updateUserAttributes(user, {
                            'custom:userrole': roleData.slug,
                            'custom:isSubscribed': (userData.isSubscribed) ? '1' : '0',
                            'custom:expiryDate': userData.expiryDate,
                        });
                    }
                }
                (props.isLoggedIn) ? history.push('/payment-response/login') : history.push('/payment-response/register');
                // toast.success('Congrats. Payment process has been successfully completed.');
                if (props.isLoggedIn) {
                    const user = await Auth.currentAuthenticatedUser();
                    if (user && user.attributes) {
                        props.onUserDetail(user.attributes);
                    }
                }
            }

        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     'Failed'
            // );
            // Swal.fire({
            //     title: 'Message',
            //     text: (error.response && error.response.data && error.response.data.message) || 'Failed',
            //     showCloseButton: true,
            //     customClass: {
            //         title: 'popup-title',
            //         closeButton: 'popup-close-btn',
            //         confirmButton: 'primary-confirm-popup-btn',
            //         htmlContainer: 'popup-popup-body'
            //     }
            // })
        }
        document.getElementById('preloader').style.display = "none";
    }

    const formik = useFormik({
        initialValues: {
            cvc: "",
            expiry: "",
            name: "",
            number: "",
            coupon: ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            cvc: Yup.string().required('Enter your card cvv').matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Please enter correct cvv"),
            expiry: Yup.string().required('Enter your card expiry date'),
            name: Yup.string().required('Enter your name'),
            // number: Yup.string().required('Please enter card number yourself').matches(
            //     /^(?:5[1-5][0-9]{14})$/,
            //     "Please enter a valid card no"
            //   ),
            number: Yup.string().required('Enter your card number')
                // .min(16, 'Card number should not be less than 16 digits')
                // .max(16, 'Card number should not be more than 16 digits')
            // .test(
            //     "test-number",
            //     "Credit Card number is invalid",
            //     value => valid.number(value).isValid
            // )
        }),
        onSubmit: async (values) => {
            if (!selectPlanId) {
                formik.setFieldError('planType', 'Please select your subscription plan');
            } else {
                let couponMsg = '';
                if (formik.values.coupon) {
                    try {
                        const { couponData } = await PaymentService.getCouponCodeByCode(formik.values.coupon);
                        // console.log(couponData,"couponData")
                        const coupnDuration = (couponData) ? couponData.duration : '';
                        const coupnDurationType = (couponData) ? couponData.durationType : '';
                        // const expDate = (couponData)?couponData.durationType:'';
                        var expDate = new Date(moment().tz(appTimeZone));
                        expDate.setDate(new Date(moment().tz(appTimeZone)).getDate() + coupnDuration);
                        var year = expDate.getFullYear();
                        var mon = expDate.getMonth() + 1;
                        var date = expDate.getDate();
                        var trialExp = mon + "/" + date + "/" + year;
                        if (couponData.couponType && couponData.couponType == 'percentage') {
                            couponMsg = (couponData) ? ` You get ${(couponData.discount) ? couponData.discount : ''}% discount on selected plan for 12 months.` : '';
                        } else {
                            couponMsg = (couponData) ? ` Your free ${coupnDuration} ${coupnDurationType} trial period expires on ${trialExp}.` : '';
                        }

                    } catch (error) {
                        // toast.error(
                        //     (error.response &&
                        //         error.response.data &&
                        //         error.response.data.message) ||
                        //     'Failed'
                        // );
                        // Swal.fire({
                        //     title: 'Message',
                        //     text: (error.response && error.response.data && error.response.data.message) || 'Failed',
                        //     showCloseButton: true,
                        //     customClass: {
                        //         title: 'popup-title',
                        //         closeButton: 'popup-close-btn',
                        //         confirmButton: 'primary-confirm-popup-btn',
                        //         htmlContainer: 'popup-popup-body'
                        //     }
                        // })
                    }
                }

                if (formik.values.coupon && !couponMsg) {
                    Swal.fire({
                        title: 'Message',
                        text: 'Invalid coupon code.',
                        showCloseButton: true,
                        customClass: {
                            title: 'popup-title',
                            closeButton: 'popup-close-btn',
                            confirmButton: 'primary-confirm-popup-btn',
                            htmlContainer: 'popup-popup-body'
                        }
                    })

                } else {
                    Swal.fire({
                        title: 'Confirm Box',
                        text: 'You have subscribed for ' + selectPlanText + '.' + couponMsg + ' Click OK to confirm',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        showCloseButton: true,
                        customClass: {
                            title: 'popup-title',
                            closeButton: 'popup-close-btn',
                            confirmButton: 'confirm-confirm-popup-btn',
                            htmlContainer: 'popup-popup-body',
                            cancelButton: 'cancel-popup-btn'
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            handleSubmit(values);
                            document.getElementById('preloader').style.display = "block";
                        }
                    })
                }

            }
        }
    });


    const getRoleById = async (userId) => {
        try {
            const { roleData } = await PaymentService.getRolePriceById(window.atob(userId));
            setRolePrice((roleData.role) ? roleData.role.amount : '');
            setPlanId((roleData.role) ? roleData.role.planId : '');
            // setSelectPlanId((roleData.role)?roleData.role.planId:'')
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     'Failed'
            // );
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getRoleById(id);
        document.getElementById('preloader').style.display = "none";
    }, []);

    const setPlanRadioVal = (valId, msg) => {
        setSelectPlanText(msg)
        setSelectPlanId(valId)
        formik.setFieldError('planType', '');
    }

    return (


        <>
            {/* <section >
                <div className="auto-container">
                    <h1 className="h1-text text-black">Subscribe plan</h1>
                    <p>Please enter your card details to buy subscription for 1 year@${rolePrice}.</p>
                </div>
            </section> */}
            <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                <div className="auto-container">
                    <div className="row clearfix row-container-margin-width ">
                        <div className="col-md-12 bg-white margin-top-5 form-edit-col-main"
                            style={{ height: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="col-md-6 edit-profile-image">
                                <img className="edit-profile-image" style={{ width: '92%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Plan.png"} />

                                {/* <Cards
                                    cvc={formik.values.cvc}
                                    expiry={formik.values.expiry.replace('-', '/')}
                                    name={formik.values.name}
                                    number={formik.values.number}
                                /> */}
                            </div>
                            <div className="col-md-6 form-edit-col">
                                <div className="col-md-12 unset-padding padding-edit-profile">
                                    <div className="Inside">
                                        <h1 className="text-black h1-edit-profile">Select Subscription Plan</h1>
                                    </div>
                                    <div>
                                        <div class="radio" style={{ fontSize: "12px", textAlign: "left", marginLeft: "4%", marginTop: "5px", marginBottom: "0px", color: "black" }}>
                                            <label><input type="radio" name="planType" onClick={e => setPlanRadioVal(planId, '$' + `${rolePrice}` + ' per year (billed annually)')} id="Radios1" value={planId} />${rolePrice} per year (billed annually)</label>
                                            <label><input type="radio" name="planType" onClick={e => setPlanRadioVal(process.env.REACT_APP_SECOND_PLAN, '$500 per year (billed annually) plus $25 processing fee per transaction')} id="Radios1" value={process.env.REACT_APP_SECOND_PLAN} />$500 per year (billed annually) plus $25 processing fee per transaction</label>
                                            <label><input type="radio" name="planType" onClick={e => setPlanRadioVal(process.env.REACT_APP_THIRD_PLAN, '$50 per month (billed monthly) plus $25 processing fee per transaction')} id="Radios1" value={process.env.REACT_APP_THIRD_PLAN} />$50 per month (billed monthly) plus $25 processing fee per transaction</label>
                                        </div>
                                        {formik.errors.planType ? (
                                            <div className='col-md-12' style={{ fontSize: "12px", textAlign: "left", marginLeft: "1%", marginTop: "5px", marginBottom: "0px", color: "black" }}><div className="text text-danger" align="left">{formik.errors.planType}</div></div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12 form-edit-col" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="col-md-11 unset-padding padding-edit-profile">
                                            <div className="Inside">
                                                <h1 className="text-black h1-edit-profile">Card Detail</h1>
                                            </div>
                                            <div className="form-row ">
                                                <div className="form-group col-md-12" style={{ paddingTop: "8px", marginBottom: "5px" }}>
                                                    <h1 className="label-color text-left col-md-3" style={{ fontSize: "12px", paddingTop: "8px" }}>We Accept</h1>
                                                    <img className="col-md-6" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/unnamed.png"} />
                                                </div>
                                                <hr style={{
                                                    border: "none",
                                                    borderTop: "1px dashed rgb(204, 204, 204)",
                                                    backgroundColor: "#fff",
                                                    height: "1px",
                                                    width: "100%"
                                                }} />
                                            </div>

                                            <div className="default-form contact-form form-margin text-left ">

                                                <form align="left" onSubmit={formik.handleSubmit} autoComplete="off">
                                                    <div className="form-row ">
                                                        <div className="form-group col-md-12" style={{ marginBottom: "5px" }}>
                                                            <label className="label-color">Name</label>
                                                            <input type="text" name="name" className="form-control" autoComplete="off" placeholder="Your Name" {...formik.getFieldProps('name')} />
                                                            {formik.touched.name && formik.errors.name ? (
                                                                <div className="text text-danger">{formik.errors.name}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-group col-md-12" style={{ marginBottom: "5px" }}>
                                                            <label className="label-color">Card Number
                                                            </label>

                                                            <input type="text" name="number" className="form-control" autoComplete="cc-csc" placeholder="Card Number" {...formik.getFieldProps('number')} />
                                                            {formik.touched.number && formik.errors.number ? (
                                                                <div className="text text-danger">{formik.errors.number}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-group col-md-7" style={{ marginBottom: "5px" }}>
                                                            <label className="label-color">Expiry Date</label>
                                                            <input type="month" name="expiry" className="form-control" autoComplete="off" placeholder="Expire Date" format="MM-YYYY" {...formik.getFieldProps('expiry')} />
                                                            {formik.touched.expiry && formik.errors.expiry ? (
                                                                <div className="text text-danger">{formik.errors.expiry}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-group col-md-5" style={{ marginBottom: "5px" }}>
                                                            <label className="label-color">CVV</label>
                                                            <input type="text" maxLength={4} className="form-control" name="cvc" autoComplete="off" placeholder="CVV" {...formik.getFieldProps('cvc')} />
                                                            {formik.touched.cvc && formik.errors.cvc ? (
                                                                <div className="text text-danger">{formik.errors.cvc}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label className="label-color">Coupon Code</label>
                                                            <input type="text" name="coupon" className="form-control" autoComplete="coupon" placeholder="Coupon Code" {...formik.getFieldProps('coupon')} />
                                                            {formik.touched.coupon && formik.errors.coupon ? (
                                                                <div className="text text-danger">{formik.errors.coupon}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="form-group  col-md-12 text-center">
                                                        <button type="submit" className="btn btn-primary">Pay Now</button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);