import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import "./Custom.css"
import * as ActionTypes from '@store/actions';

const FAQ = ({ onUserLogin, isLoggedIn }) => {


    return (
        <>
            <section className="default-section faq-bg">
                <div className="auto-container">
                    <div className="sec-title centered">
                        <h2>Why Us?</h2>
                    </div>
                    <div className="services-outer wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="clearfix">
                            <div className="default-service-block col-md-6 col-sm-6 col-xs-12 ">
                                <div className="inner-box additiona-pd inner-box-ht bx-shadow text-grey-whyus">
                                    <h3 align="left">A Smoother Process for Transactors & Servicers</h3>
                                    <div className="text " align="left">
                                        <h5>
                                            MyTrustMe gives DCM originators more time for critical aspects of their transactions, and gives corporate trust providers broader exposure to prospects they may never have known about otherwise.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="default-service-block col-md-6 col-sm-6 col-xs-12">
                                <div className="inner-box additiona-pd inner-box-ht bx-shadow text-grey-whyus">
                                    <h3 align="left">With MyTrustMe, You Get…</h3>
                                    <div className="text" align="left">
                                        <h5 className="faq-secondbox">
                                            <ul >
                                                <li>Enhanced speed of execution and response</li>
                                                <li>Reduced costs for users (and their customers)</li>
                                                <li>A central repository for DCM transactions, available anytime, anywhere</li>
                                            </ul>
                                        </h5></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);