import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';

const HowWeWork = ({ onUserLogin, isLoggedIn }) => {


    return (
        <>
            <section className="default-section pb-0">
                <div className="auto-container">
                    <div className="sec-title centered">
                        <h2>What We Do</h2>
                    </div>

                    <div className="services-outer wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">

                        <div className="row clearfix pt-3">
                            <div className="col-md-6">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="default-service-block col-md-12 col-sm-12 col-xs-12">
                                        <div className="text"><h5>It’s simple: We automate a process that has long been manual. Our secure, cloud-based solution allows transactors to request information from multiple providers and lets providers respond quickly. By aggregating and analyzing information from many different sources, we give our clients faster access to the data they need.</h5></div>
                                    </div>
                                </div>
                                <div className="service-block-three col-md-4 col-sm-6 col-xs-12">
                                    <div className="inner-box">
                                        <div className="icon-box"><div><img className="" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/newreq.png"} alt="" /></div></div>
                                        <h3 style={{fontSize: '14px'}}>New Request</h3>
                                    </div>
                                </div>
                                <div className="service-block-three col-md-4 col-sm-6 col-xs-12">
                                    <div className="inner-box">
                                        <div className="icon-box"><img className="" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/proposalhome.png"} alt="" /></div>
                                        <h3 style={{fontSize: '14px'}}>Received Proposals</h3>
                                    </div>
                                </div>
                                <div className="service-block-three col-md-4 col-sm-6 col-xs-12">
                                    <div className="inner-box">
                                        <div className="icon-box"><img className="" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/repo.png"} alt="" /></div>
                                        <h3 style={{fontSize: '14px'}}>Document Repository</h3>

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                            <iframe width="100%" height="260" src="https://www.youtube.com/embed/e-e4THDIBmQ?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen='allowFullScreen'></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(HowWeWork);