import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const DPAAgreement = () => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Data Processing Addendum Agreement</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Data Processing Addendum Agreement</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 text-left">
                            <div>
                                <p><strong>DATA PROCESSING ADDENDUM TO THE MASTER SUBSCRIPTION AGREEMENT</strong></p>

                                <p>This Data Processing Addendum to the Master Subscription Agreement, including its Schedules and Appendices, (“<strong>MSA DPA</strong>”) between MyTrustMe.com LLC and Customer to which it is attached, to reflect the parties’ agreement with regard to the Processing of Personal Data submitted to the Covered Services.</p>

                                <p>Customer enters into this Addendum on behalf of itself and, to the extent required under Data Protection Laws and Regulations, in the name and on behalf of its Authorized Affiliates. For the purposes of this Addendum only, and except where indicated otherwise, the term "Customer" shall include Customer and Authorized Affiliates. All capitalized terms not defined herein shall have the meaning set forth in the Master Subscription Agreement. In the course of providing the Covered Services to Customer pursuant to the Master Subscription Agreement, MyTrustMe.com LLC may process Personal Data on behalf of Customer and the Parties agree to comply with the following provisions with respect to any Personal Data, each acting reasonably and in good faith.</p>

                                <p><strong>DATA PROCESSING TERMS</strong></p>

                                <p><strong>1. DEFINITIONS </strong></p>

                                <p>“<strong>Affiliate</strong>” means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity. “Control,” for purposes of this definition, means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity. </p>

                                <p>“<strong>Authorized Affiliate</strong>” means any of Customer's Affiliate(s) which (a) is subject to the data protection laws and regulations of the European Union, the European Economic Area and/or their member states, Switzerland and/or the United Kingdom, and (b) is permitted to use the Covered Services pursuant to the Master Subscription Agreement between Customer and MyTrustMe.com LLC, but is not a "Customer" as defined under the Master Subscription Agreement. </p>

                                <p><strong>“Controller” </strong>means the entity which determines the purposes and means of the Processing of Personal Data. </p>

                                <p><strong>“Data Protection Laws and Regulations” </strong>means all laws and regulations, including laws and regulations of the European Union, the European Economic Area and their member states, Switzerland and the United Kingdom, applicable to the Processing of Personal Data under the Master Subscription Agreement. </p>

                                <p><strong>“Data Subject” </strong>means the identified or identifiable person to whom Personal Data relates. </p>

                                <p>"<strong>Europe</strong>" means the European Union, the European Economic Area, Switzerland and the United Kingdom. </p>

                                <p>“<strong>GDPR</strong>” means the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation). </p>

                                <p><strong>“Personal Data” </strong>means any information relating to (i) an identified or identifiable natural person and, (ii) an identified or identifiable legal entity (where such information is protected similarly as personal data or personally identifiable information under Data Protection Laws and Regulations), where for each (i) or (ii), such data is Customer Data. </p>

                                <p>“<strong>Pilot Term</strong>” means what is defined as “Term” in accordance with Section 9 of the Pilot Agreement. </p>
                                <p><strong>“Processing” or “Process” </strong>means any operation or set of operations which is performed upon Personal Data, whether or not by automatic means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction. </p>

                                <p><strong>“Processor” </strong>means the entity which Processes Personal Data on behalf of the Controller. </p>

                                <p>“<strong>Public Authority</strong>” means a government agency or law enforcement authority, including judicial authorities. </p>

                                <p>“<strong>Security, Privacy and Architecture Documentation</strong>” means the Security, Privacy and Architecture Documentation available here: <Link to={"/company/legal/agreements"}>https://www.mytrustme.com/company/legal/agreements</Link></p>

                                <p><strong>“MYTRUSTME.COM LLC” </strong>means MyTrustMe.com LLC and its Affiliates engaged in the Processing of Personal Data.</p>

                                <p><strong>“Standard Contractual Clauses” </strong>means the Standard Contractual Clauses for the transfer of Personal Data to third countries pursuant to Regulation (EU) 2016/679 of the European Parliament and the Council approved by European C Commission Implementing Decision (EU) 2021/914 of 4 June 2021, as currently set out at <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj" style={{ color: '#0563c1', textDecoration: 'underline' }}>https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj</a>.</p>

                                <p><strong>“Sub-processor” </strong>means any Processor engaged by MyTrustMe.com LLC or a member of the MyTrustMe.com LLC.</p>

                                <p><strong>2. PROCESSING OF PERSONAL DATA<br />
                                    2.1 Roles of the Parties. </strong>The parties acknowledge and agree that with regard to the Processing of Personal Data, Customer is a Controller or a Processor, MyTrustMe.com LLC is a Processor and that MyTrustMe.com LLC or members of the MyTrustMe.com LLC Group will engage Sub-processors pursuant to the requirements set forth in section 5 “Sub-processors” below.</p>

                                <p><strong>2.2 Customer’s Processing of Personal Data. </strong>Customer shall, in its use of the Covered Services, Process Personal Data in accordance with the requirements of Data Protection Laws and Regulations, including any applicable requirement to provide notice to Data Subjects of the use of MyTrustMe.com LLC as Processor (including, where the Customer is a Processor, by ensuring that the ultimate Controller does so). For the avoidance of doubt, Customer’s instructions for the Processing of Personal Data shall comply with Data Protection Laws and Regulations. Customer shall have sole responsibility for the accuracy, quality, and legality of Personal Data and the means by which Customer acquired Personal Data. Customer specifically acknowledges and agrees that its use of the Covered Services will not violate the rights of any Data Subject, including those that have opted-out from sales or other disclosures of Personal Data, to the extent applicable under the Data Protection Laws and Regulations. </p>

                                <p><strong>2.3 Sensitive Data. </strong>The following types of sensitive Personal Data (including images, sounds or other information containing or revealing such sensitive data) may not be submitted to the Covered Services: government-issued identification numbers; financial information (such as credit or debit card numbers, any related security codes or passwords, and bank account numbers); racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership, biometric data for the purpose of uniquely identifying a natural person, information concerning health, sex life or sexual orientation; information related to an individual’s physical or mental health; and information related to the provision or payment of health care. </p>

                                <p><strong>2.4 MYTRUSTME.COM LLC’s Processing of Personal Data. </strong>MyTrustMe.com LLC shall treat Personal Data as confidential and shall only Process Personal Data on behalf of and in accordance with Customer’s documented instructions for the following purposes: (i) Processing initiated by Users in their use of the Covered Services; (ii) Processing to comply with other documented reasonable instructions provided by Customer (e.g., via email); and (iii) Processing to train and improve the Covered Services and any other of MyTrustMe.com LLC’s current and future features, products and/or services.</p>

                                <p><strong>2.5 Details of the Processing. </strong>The subject-matter of Processing of Personal Data by MyTrustMe.com LLC is the performance of the Covered Services. The duration of the Processing, the nature and purpose of the Processing, the types of Personal Data and categories of Data Subjects Processed under this addendum are further specified in Schedule 2 (Description of the Processing/Transfer) to this addendum. </p>


                                <p><strong>3. RIGHTS OF DATA SUBJECTS </strong></p>

                                <p>MyTrustMe.com LLC shall, to the extent legally permitted, promptly notify Customer of any complaint, dispute or request it has received from a Data Subject such as a Data Subject's right of access, right to rectification, restriction of Processing, erasure (“right to be forgotten”), data portability, object to the Processing, or its right not to be subject to an automated individual decision making, each such request being a “Data Subject Request”. MyTrustMe.com LLC shall not respond to a Data Subject Request itself, except that Customer authorizes MyTrustMe.com LLC to redirect the Data Subject Request as necessary to allow Customer to respond directly. Taking into account the nature of the Processing, MyTrustMe.com LLC shall assist Customer by appropriate technical and organizational measures, insofar as this is possible, for the fulfilment of Customer’s obligation to respond to a Data Subject Request under Data Protection Laws and Regulations. In addition, to the extent Customer, in its use of the Covered Services, does not have the ability to address a Data Subject Request, MyTrustMe.com LLC shall upon Customer’s request provide commercially reasonable efforts to assist Customer in responding to such Data Subject Request, to the extent MyTrustMe.com LLC is legally permitted to do so and the response to such Data Subject Request is required under Data Protection Laws and Regulations. To the extent legally permitted, Customer shall be responsible for any costs arising from MyTrustMe.com LLC’s provision of such assistance.</p>

                                <p><strong>4. MYTRUSTME.COM LLC PERSONNEL<br />
                                    4.1 Confidentiality. </strong>MyTrustMe.com LLC shall ensure that its personnel engaged in the Processing of Personal Data are informed of the confidential nature of the Personal Data, have received appropriate training on their responsibilities and have executed written confidentiality agreements. MyTrustMe.com LLC shall ensure that such confidentiality obligations survive the termination of the personnel engagement.</p>

                                <p><strong>4.2 Reliability. </strong>MyTrustMe.com LLC shall take commercially reasonable steps to ensure the reliability of any MyTrustMe.com LLC personnel engaged in the Processing of Personal Data.</p>

                                <p><strong>4.3 Limitation of Access. </strong>MyTrustMe.com LLC shall ensure that MyTrustMe.com LLC’s access to Personal Data is limited to those personnel performing Covered Services in accordance with the Agreement. </p>

                                <p><strong>4.4 Data Protection Officer. </strong>Members of the MyTrustMe.com LLC Group have appointed a data protection officer. The appointed person may be reached at <span className='custom-link-style' onClick={() => window.location = 'mailto:info@mytrustme.com'}>info@mytrustme.com</span>. </p>

                                <p><strong>5. SUB-PROCESSORS<br />
                                    5.1 Appointment of Sub-processors. </strong>Customer acknowledges and agrees that (a) MyTrustMe.com LLC’s Affiliates may be retained as Sub-processors; and (b) MyTrustMe.com LLC and MyTrustMe.com LLC’s Affiliates respectively may engage third-party Sub- processors in connection with the provision of the Covered Services. MyTrustMe.com LLC or a MyTrustMe.com LLC Affiliate has entered into a written agreement with each Sub-processor containing, in substance, data protection obligations no less protective than those in this Pilot DPA and Pilot Agreement with respect to the protection of Personal Data to the extent applicable to the nature of the Processing provided by such Sub-processor. </p>

                                <p><strong>5.2 List of Current Sub-processors and Notification of New Sub-processors. </strong>MyTrustMe.com LLC shall make available to Customer the current list of Sub-processors engaged in Processing Personal Data for the performance of each applicable Covered Service, including a description of their processing activities and countries of location, as listed under the Infrastructure and Sub-processor Documentation which can be found on MyTrustMe.com LLC’s <Link to={'/en/trust-and-compliance-documentation'} target="_blank">Trust and Compliance webpage</Link> (also accessible via <Link to={"/company/legal/agreements"}>https://www.mytrustme.com/company/legal/agreements/</Link> under the “Trust and Compliance Documentation” link). Customer hereby consents to these Sub-processors, their locations and processing activities as it pertains to their Personal Data. The Infrastructure and Sub-processor Documentation contains a mechanism to subscribe to notifications of new Sub-processors for each applicable Service, and if Customer subscribes, MyTrustMe.com LLC shall provide notification to Customer of a new Sub-processor(s) before authorizing any new Sub-processor(s) to Process Personal Data in connection with the provision of the applicable Covered Services. </p>
                                <p><strong>5.3 Objection Right for New Sub-processors. </strong>Customer may object to MyTrustMe.com LLC’s use of a new Sub-processor by notifying MyTrustMe.com LLC promptly in writing within ten (10) business days of receipt of MyTrustMe.com LLC’s notice made in accordance with section 5.2. If Customer objects to a new Sub-processor for the Covered Services, as permitted in the preceding sentence, Customer’s sole remedy is to terminate the addendum and cease use of the Covered Services. </p>

                                <p><strong>5.4 Liability. </strong>MyTrustMe.com LLC shall be liable for the acts and omissions of its Sub-processors to the same extent MyTrustMe.com LLC would be liable if performing the services of each Sub-processor directly under the terms of this addendum, unless otherwise set forth in the addendum. </p>

                                <p><strong>6. SECURITY<br />
                                    6.1. Controls for the Protection of Customer Data. </strong>MyTrustMe.com LLC shall maintain appropriate technical and organizational measures for protection of the security (including protection against unauthorized or unlawful Processing and against accidental or unlawful destruction, loss or alteration or damage, unauthorized disclosure of, or access to, Customer Data), confidentiality and integrity of Customer Data. Measures for the Covered Services are set forth in the Security, Privacy and Architecture Documentation as updated from time to time.</p>

                                <p><strong>6.2. Audit</strong>. MyTrustMe.com LLC shall maintain an audit program to help ensure compliance with the obligations set out in this DPA and shall make available to Customer information to demonstrate compliance with the obligations set out in this DPA as set forth in this section 6.2.</p>

                                <p><strong>6.3. Third-Party Certifications and Audits</strong>. With respect to the Covered Services, to the extent required by Data Protection Laws and Regulations and not covered by the applicable GA Service(s) Documentation, upon Customer’s written request at a reasonable interval of once during the Pilot Term, MyTrustMe.com LLC shall make available to Customer information necessary to demonstrate compliance with the obligations set forth in this DPA in the form of the information set forth in the Security, Privacy and Architecture Documentation. Only to the extent that:</p>

                                <p><strong>6.3.1. </strong>the provision of information or documents as referred to in this section does not satisfy the Customer’s obligations under Data Protection Laws; and </p>

                                <p><strong>6.3.2. </strong>MyTrustMe.com LLC is required to make available to Customer an on-site audit right by Data Protection Laws and Regulations, then Customer may, where it conducts an on-site audit of the applicable GA Service under the Customer’s Main Services Agreement, additionally request further information relating to a Covered Service. </p>

                                <p><strong>6. 4. Data Protection Impact Assessment. </strong>To the extent required by Data Protection Laws and Regulations, upon Customer’s request, MyTrustMe.com LLC shall provide Customer with reasonable cooperation and assistance needed to fulfill Customer’s obligation under Data Protection Laws and Regulations to carry out a data protection impact assessment related to Customer’s use of the Covered Services, to the extent Customer does not otherwise have access to the relevant information and to the extent such information is available to MyTrustMe.com LLC. </p>

                                <p><strong>7. CUSTOMER DATA INCIDENT MANAGEMENT AND NOTIFICATION </strong></p>

                                <p>MyTrustMe.com LLC maintains security incident management policies and procedures specified in the Security, Privacy and Architecture Documentation for the applicable GA Service associated with the Covered Services and shall notify Customer without undue delay after becoming aware of the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Customer Data, including Personal Data, transmitted, stored or otherwise Processed by MyTrustMe.com LLC or its Sub-processors of which MyTrustMe.com LLC becomes aware (a “<strong>Customer Data Incident</strong>”). MyTrustMe.com LLC shall make reasonable efforts to identify the cause of such Customer Data Incident and take such steps as MyTrustMe.com LLC deems necessary and reasonable to remediate the cause of such a Customer Data Incident to the extent the remediation is within MyTrustMe.com LLC’s reasonable control. The obligations herein shall not apply to incidents that are caused by Customer or Customer’s Users. </p>

                                <p><strong>8. GOVERNMENT ACCESS REQUESTS<br />
                                    8. 1. MYTRUSTME.COM LLC requirements. </strong>In its role as a Processor, MyTrustMe.com LLC shall maintain appropriate measures to protect Personal Data in accordance with the requirements of Data Protection Laws and Regulations, including by implementing appropriate technical and organizational safeguards to protect Personal Data against any interference that goes beyond what is necessary in a democratic society to safeguard national security, defense and public security. If MyTrustMe.com LLC receives a legally binding request to access Personal Data from a Public Authority, MyTrustMe.com LLC shall, unless otherwise legally prohibited, promptly notify Customer. To the extent MyTrustMe.com LLC is prohibited by law from providing such notification, MyTrustMe.com LLC shall use commercially reasonable efforts to obtain a waiver of the prohibition notify Customer if MyTrustMe.com LLC becomes aware of any direct access by a Public Authority to Personal Data and provide information available to MyTrustMe.com LLC in this respect, to the extent permitted by law. For the avoidance of doubt, this DPA shall not require MyTrustMe.com LLC to pursue actions or inactions that could result in civil or criminal penalty for MyTrustMe.com LLC such as contempt of court. </p>

                                <p><strong>8. 2. Sub-processors requirements</strong>. MyTrustMe.com LLC shall ensure that Sub-processors involved in the Processing of Personal data are subject to the relevant commitments regarding Government Access Requests in the Standard Contractual Clauses. </p>

                                <p><strong>9. RETURN AND DELETION OF CUSTOMER DATA </strong></p>

                                <p>When Customer’s right to use a Non-GA Service ends, if Customer continues using a Covered Service as a GA Service, MyTrustMe.com LLC’s return and deletion of Personal Data processed by the Covered Services shall be in accordance with the procedures and timeframes specified in the Security, Privacy and Architecture Documentation for the related GA Service. If Customer does not continue to use the Covered Service as a GA Service, Customer may export or request return of its data for 30 days after Customer’s right to use the Non-GA Service ends, after which Customer Data shall be subject to deletion. </p>

                                <p><strong>10. AUTHORIZED AFFILIATES<br />
                                    10. 1. Contractual Relationship</strong>. The parties acknowledge and agree that, by executing the Agreement, the Customer enters into this DPA on behalf of itself and, as applicable, in the name and on behalf of its Authorized Affiliates, thereby establishing a separate DPA between MyTrustMe.com LLC and each such Authorized Affiliate subject to the provisions of the Agreement and this section 10 and section 11. Each Authorized Affiliate agrees to be bound by the obligations under this DPA and, to the extent applicable, the Agreement. For the avoidance of doubt, an Authorized Affiliate is not and does not become a party to the Agreement, and is a party only to the DPA. All access to and use of the Covered Services and Content by Authorized Affiliates must comply with the terms and conditions of the Agreement and any violation of the terms and conditions of the Agreement by an Authorized Affiliate shall be deemed a violation by Customer.</p>
                                <p><strong>10. 2. Communication</strong>. The Customer that is the contracting party to the Agreement shall remain responsible for coordinating all communication with MyTrustMe.com LLC under this DPA and be entitled to make and receive any communication in relation to this DPA on behalf of its Authorized Affiliates. </p>
                                <p><strong>10. 3. Rights of Authorized Affiliates</strong>. Where an Authorized Affiliate becomes a party to the DPA with MyTrustMe.com LLC, it shall to the extent required under Data Protection Laws and Regulations be entitled to exercise the rights and seek remedies under this DPA, except where Data Protection Laws and Regulations require the Authorized Affiliate to exercise a right or seek any remedy under this DPA against MyTrustMe.com LLC directly by itself, in which case the parties agree that (i) solely the Customer that is the contracting party to the Pilot Agreement shall exercise any such right or seek any such remedy on behalf of the Authorized Affiliate, and (ii) the Customer that is the contracting party to the Agreement shall exercise any such rights under this Pilot DPA not separately for each Authorized Affiliate individually but in a combined manner for itself and all of its Authorized Affiliates together (as set forth, for example, in section 10.3.2, below). </p>

                                <p><strong>11. LIMITATION OF LIABILITY </strong></p>

                                <p>MYTRUSTME.COM LLC’s and all of its Affiliates’ liability under this DPA shall be subject to the “No Damages” section of the Agreement, to the maximum extent permitted under Data Protection Laws and Regulations. If the “No Damages” section is deemed impermissible, MyTrustMe.com LLC’s and all of its Affiliates’ liability under this DPA shall be capped at $10,000. </p>
                                <p><strong>12. EUROPE SPECIFIC PROVISIONS </strong></p>
                                <p><strong>12.1 Definitions. </strong>For the purposes of this section 12 and Schedule 1 these terms shall be defined as follows: </p>
                                <p>"<strong>EU C-to-P Transfer Clauses</strong>" means Standard Contractual Clauses sections I, II, III and IV (as applicable) to the extent they reference Module Two (Controller-to-Processor). </p>
                                <p>"<strong>EU P-to-P Transfer Clauses</strong>" means Standard Contractual Clauses sections I, II III and IV (as applicable) to the extent they reference Module Three (Processor-to-Processor). </p>

                                <p><strong>12.2 GDPR. </strong>MyTrustMe.com LLC will Process Personal Data in accordance with the GDPR requirements directly applicable to MyTrustMe.com LLC provision of its Services. </p>

                                <p><strong>12.3 Customer Instructions. </strong>SDFC shall inform Customer immediately (i) if, in its opinion, an instruction from Customer constitutes a breach of the GDPR and/or (ii) if MyTrustMe.com LLC is unable to follow Customer’s instructions for the Processing of Personal Data. </p>

                                <p><strong>12.4 Transfer Mechanisms for European Data Transfers. </strong>If, in the performance of the Covered Services, Personal Data that is subject to the GDPR or any other law relating to the protection or privacy of individuals that applies in Europe is transferred out of Europe to countries which do not ensure an adequate level of data protection within the meaning of the Data Protection Laws and Regulations of Europe, the transfer mechanisms listed below shall apply to such transfers and can be directly enforced by the Parties to the extent such transfers are subject to the Data Protection Laws of Europe: </p>

                                <p><strong>12.4.1 The EU C-to-P Transfer Clauses</strong>. Where Customer and/or its Authorized Affiliate is a Controller and a data exporter of Personal Data and MyTrustMe.com LLC is a Processor and data importer in respect of that Personal Data, then the Parties shall comply with the EU C-to-P Transfer Clauses, subject to the additional terms in section 2 of Schedule 1; and/or </p>

                                <p><strong>12.4.2 The EU P-to-P Transfer Clauses</strong>. Where Customer and/or its Authorized Affiliate is a Processor acting on behalf of a Controller and a data exporter of Personal Data and MyTrustMe.com LLC is a Processor and data importer in respect of that Personal Data, the Parties shall comply with the terms of the EU P-to-P Transfer Clauses, subject to the additional terms in sections 2 and 3 of Schedule 1.</p>

                                <p><strong>12.4.3 Impact of local laws. </strong>As of the Effective Date, MyTrustMe.com LLC has no reason to believe that the laws and practices in any third country of destination applicable to its Processing of the Personal Data as set forth in the Infrastructure and Sub-processors Documentation, including any requirements to disclose Personal Data or measures authorising access by a Public Authority, prevent MyTrustMe.com LLC from fulfilling its obligations under this DPA. If MyTrustMe.com LLC reasonably believes that any existing or future enacted or enforceable laws and practices of the third country of destination applicable to its Processing of the Personal Data ("Local Laws") prevent it from fulfilling its obligations under this DPA, it shall promptly notify Customer and Customer’s sole remedy is to terminate the Agreement and cease use of the Covered Services. </p>

                                <p><strong>13. PARTIES TO THIS DPA </strong></p>

                                <p>The section “HOW THIS DPA APPLIES” specifies which MyTrustMe.com LLC entity is party to this DPA. Where the Standard Contractual Clauses apply, Salesforce, Inc. is the signatory to the Standard Contractual Clauses. Where the MyTrustMe.com LLC entity that is a party to this DPA is not Salesforce, Inc. that MyTrustMe.com LLC entity is carrying out the obligations of the data importer on behalf of Salesforce, Inc. Notwithstanding the signatures of any other Salesforce entity, such other Salesforce entities are not a party to this DPA or the Standard Contractual Clauses.</p>

                                <p><strong>List of Schedules </strong></p>

                                <p>Schedule 1: Transfer Mechanisms for European Data Transfers Schedule 2: Description of the Processing/Transfer </p>

                                <p><strong>SCHEDULE 1 - TRANSFER MECHANISMS FOR EUROPEAN DATA TRANSFERS </strong></p>

                                <p><strong>1. Standard Contractual Clauses Operative Provisions And Additional Terms </strong></p>

                                <p>For the purposes of the EU C-to-P Transfer Clauses and the EU P-to-P Transfer Clauses, Customer is the data exporter and MyTrustMe.com LLC is the data importer and the Parties agree to the following. If and to the extent an Authorized Affiliate relies on the EU C-to-P Transfer Clauses or the EU P-to-P Transfer Clauses for the transfer of Personal Data, any references to ‘Customer’ in this Schedule include such Authorized Affiliate. Where this section 1 does not explicitly mention EU C-to-P Transfer Clauses or EU P-to-P Transfer Clauses, it applies to both of them. </p>

                                <p><strong>1.1 Reference to the Standard Contractual Clauses. </strong>The relevant provisions contained in the StandardContractual Clauses are incorporated by reference and are an integral part of this DPA. The information required for the purposes of the Appendix to the Standard Contractual Clauses is set out in Schedule 2. </p>

                                <p><strong>1.2. Docking clause. </strong>The option under clause 7 shall not apply. </p>

                                <p><strong>1.3. Instructions. </strong>This DPA and the Agreement are Customer’s complete and final documented instructions at the time of signature of the Agreement to MyTrustMe.com LLC for the Processing of Personal Data. For the purposes of clause 8.1(a)the instructions by Customer to Process Personal Data are set out in section 2.3 of the DPA and include onward transfers to a third party located outside Europe for the purpose of the performance of the Services. </p>

                                <p><strong>1.4. Security of Processing. </strong>For the purposes of clause 8.6(a), Customer is solely responsible for making an independent determination as to whether the technical and organizational measures set forth in the Security, Privacy and Architecture Documentation meet Customer’s requirements and agrees that (taking into account the state of the art, the costs of implementation, and the nature, scope, context and purposes of the Processing of its Personal Data as well as the risks to individuals) the security measures and policies implemented and maintained by MyTrustMe.com LLC provide a level of security appropriate to the risk with respect to its Personal Data. For the purposes of clause 8.6(c), personal data breaches will be handled in accordance with section 7 (Customer Data Incident Management and Notification) of this DPA. </p>

                                <p><strong>1.5. General authorisation for use of Sub-processors. </strong>Option 2 under clause 9 shall apply. For the purposes of clause 9(a), MyTrustMe.com LLC has Customer’s general authorization to engage Sub-processors in accordance with section 5 of this DPA. MyTrustMe.com LLC shall make available to Customer the current list of Sub-processors in accordance with Section 5.2 of this DPA. Where MyTrustMe.com LLC enters into the EU P-to-P Transfer Clauses with a Sub-processor in connection with the provision of the Services, Customer hereby grants MyTrustMe.com LLC and MyTrustMe.com LLC’s Affiliates authority to provide a general authorization on Controller’s behalf for the engagement of sub-processors by Sub-processors engaged in the provision of the Services, as well as decision making and approval authority for the addition or replacement of any such Sub-processors. </p>

                                <p><strong>1.6. Notification of New Sub-processors and Objection Right for New Sub-processors. </strong>Pursuant to clause 9(a) of the Standard Contractual Clauses, Customer acknowledges and expressly agrees that MyTrustMe.com LLC may engage new Sub-processors as described in sections 5.2 and 5.3 of the DPA. MyTrustMe.com LLC shall inform Customer of any changes to Sub-processors following the procedure provided for in section 5.2 of the DPA. </p>

                                <p><strong>1.7. Audits of the SCCs</strong>. The parties agree that the audits described in clause 8.9(f)of the Standard Contractual Clauses shall be carried out in accordance with section 6.2 of the DPA. </p>

                                <p><strong>1.8. Complaints - Redress. </strong>For the purposes of clause 11, and subject to section 3 of the DPA, MyTrustMe.com LLC shall inform data subjects on its website of a contact point authorized to handle complaints. MyTrustMe.com LLC shall inform Customer if it receives a complaint by, or a dispute from, a Data Subject with respect to Personal Data. MyTrustMe.com LLC shall not otherwise have any obligation to handle the request. The option under clause 11 shall not apply. </p>

                                <p><strong>1.9. Liability. </strong>MyTrustMe.com LLC 's liability under clause 12(b) shall be limited to any damage caused by its Processing where MyTrustMe.com LLC has not complied with its obligations under the GDPR specifically directed to Processors, or where it has acted outside of or contrary to lawful instructions of Customer, as specified in Article 82 GDPR. </p>

                                <p><strong>1.10. Certification of Deletion. </strong>The parties agree that the certification of deletion of Personal Data that is described in clause 8.5 and 16(d) of the Standard Contractual Clauses shall be provided by MyTrustMe.com LLC to Customer only upon Customer’s written request. </p>

                                <p><strong>1.11. Supervision. </strong>Clause 13 shall apply as follows: </p>
                                <p><strong>1.11.1. </strong>Where Customer is established in an EU Member State, the supervisory authority with responsibility for ensuring compliance by Customer with Regulation (EU) 2016/679 as regards the data transfer shall act as competent supervisory authority. </p>

                                <p><strong>1.11.2. </strong>Where Customer is not established in an EU Member State, but falls within the territorial scope of application of Regulation (EU) 2016/679 in accordance with its Article 3(2) and has appointed a representative pursuant to Article 27(1) of Regulation (EU) 2016/679, the supervisory authority of the Member State in which the representative within the meaning of Article 27(1) of Regulation (EU) 2016/679 is established shall act as competent supervisory authority. </p>

                                <p><strong>1.11.3. </strong>Where Customer is not established in an EU Member State, but falls within the territorial scope of application of Regulation (EU) 2016/679 in accordance with its Article 3(2) without however having to appoint a representative pursuant to Article 27(2) of Regulation (EU) 2016/679, Commission nationale de l'informatique et des libertés (CNIL) - 3 Place de Fontenoy, 75007 Paris, France shall act as competent supervisory authority. </p>

                                <p><strong>1.11.4. </strong>Where Customer is established in the United Kingdom or falls within the territorial scope of application of UK Data Protection Laws and Regulations, the Information Commissioner's Office shall act as competent supervisory authority. </p>

                                <p><strong>1.11.5. </strong>Where Customer is established in Switzerland or falls within the territorial scope of application of Swiss Data Protection Laws and Regulations, the Swiss Federal Data Protection and Information Commissioner shall act as competent supervisory authority insofar as the relevant data transfer is governed by Swiss Data Protection Laws and Regulations. </p>

                                <p><strong>1.12. Notification of Government Access Requests. </strong>For the purposes of clause 15(1)(a), MyTrustMe.com LLC shall notify Customer (only) and not the Data Subject(s) in case of government access requests. Customer shall be solely responsible for promptly notifying the Data Subject as necessary. </p>

                                <p><strong>1.13. Governing Law. </strong>The governing law for the purposes of clause 17 shall be the law that is designated in the Governing Law section of the Agreement. If the Agreement is not governed by an EU Member State law, the Standard Contractual Clauses will be governed by either (i) the laws of France; or (ii) where the Agreement is governed by the laws of the United Kingdom, the laws of the United Kingdom. </p>

                                <p><strong>1.14. Choice of forum and jurisdiction. </strong>The courts under clause 18 shall be those designated in the Venue section of the Agreement. If the Agreement does not designate an EU Member State court as having exclusive jurisdiction to resolve any dispute or lawsuit arising out of or in connection with this Agreement, the parties agree that the courts of either (i) France; or (ii) where the Agreement designates the United Kingdom as having exclusive jurisdiction, the United Kingdom, shall have exclusive jurisdiction to resolve any dispute arising from the Standard Contractual Clauses. For Data Subjects habitually resident in Switzerland, the courts of Switzerland are an alternative place of jurisdiction in respect of disputes. </p>
                                <p><strong>1.15. Appendix. </strong>The Appendix shall be completed as follows: </p>
                                <p><strong>1.15.1. </strong>The contents of section 1 of Schedule 2 shall form Annex I.A to the Standard Contractual Clauses </p>
                                <p><strong>1.15.2. </strong>The contents of sections 2 to 9 of Schedule 2 shall form Annex I.B to the Standard Contractual Clauses </p>
                                <p><strong>1.15.3. </strong>The contents of section 10 of Schedule 2 shall form Annex I.C to the Standard Contractual Clauses </p>
                                <p><strong>1.15.4. </strong>The contents of section 11 of Schedule 2 to this Exhibit shall form Annex II to the Standard Contractual Clauses. </p>

                                <p><strong>1.16. Data Exports from the United Kingdom and Switzerland under the Standard Contractual Clauses. I</strong>n case of any transfers of Personal Data from the United Kingdom and/or transfers of Personal Data from Switzerland subject exclusively to the Data Protection Laws and Regulations of Switzerland (“Swiss Data Protection Laws”), (i) general and specific references in the Standard Contractual Clauses to GDPR or EU or Member State Law shall have the same meaning as the equivalent reference in the Data Protection Laws and Regulations of the United Kingdom (“UK Data Protection Laws”) or Swiss Data Protection Laws, as applicable; and (ii) any other obligation in the Standard Contractual Clauses determined by the Member State in which the data exporter or Data Subject is established shall refer to an obligation under UK Data Protection Laws or Swiss Data Protection laws, as applicable. In respect of data transfers governed by Swiss Data Protection Laws, the Standard Contractual Clauses also apply to the transfer of information relating to an identified or identifiable legal entity where such information is protected similarly as Personal Data under Swiss Data Protection Laws until such laws are amended to no longer apply to a legal entity. </p>
                                <p><strong>1.17. Conflict. </strong>The Standard Contractual Clauses are subject to this DPA and the additional safeguards set out hereunder. The rights and obligations afforded by the Standard Contractual Clauses will be exercised in accordance with this DPA, unless stated otherwise. In the event of any conflict or inconsistency between the body of this Pilot DPA and the Standard </p>

                                <p>Contractual Clauses, with respect to Personal Data that relates to European Data Subjects, the Standard Contractual Clauses shall prevail. </p>

                                <p><strong>2. ADDITIONAL TERMS FOR THE EU P-TO-P TRANSFER CLAUSES </strong></p>

                                <p><strong>2.1. </strong>Instructions and notifications. For the purposes of clause 8.1(a), Customer hereby informs MyTrustMe.com LLC that it acts as Processor under the instructions of the relevant Controller in respect of Personal Data. Customer warrants that its Processing instructions as set out in the Agreement and the DPA, including its authorizations to MyTrustMe.com LLC for the appointment of Sub-processors in accordance with the DPA, have been authorized by the relevant Controller. Customer shall be solely responsible for forwarding any notifications received from MyTrustMe.com LLC to the relevant Controller where appropriate. </p>
                                <p><strong>2.2. Security of Processing. </strong>For the purposes of clause 8.6(c) and (d), MyTrustMe.com LLC shall provide notification of a personal data breach concerning Personal Data Processed by MyTrustMe.com LLC to Customer. </p>
                                <p><strong>2.3. Documentation and Compliance. </strong>For the purposes of clause 8.9, all enquiries from the relevant Controller shall be provided to MyTrustMe.com LLC by Customer. MyTrustMe.com LLC receives an enquiry directly from a Controller, it shall forward the enquiry to Customer and Customer shall be solely responsible for responding to any such enquiry from the relevant Controller where appropriate. </p>
                                <p><strong>2.4. Data Subject Rights. </strong>For the purposes of clause 10 and subject to section 3 of the DPA, MyTrustMe.com LLC shall notify Customer about any request it has received directly from a Data Subject without obligation to handle it, but shall not notify the relevant Controller. Customer shall be solely responsible for cooperating with the relevant Controller in fulfilling the relevant obligations to respond to any such request. </p>

                                <p><strong>SCHEDULE 2 – DESCRIPTION OF THE PROCESSING/TRANSFER </strong></p>
                                <p><strong>1. LIST OF PARTIES </strong></p>

                                <p>Data exporter(s): <em>Identity and contact details of the data exporter(s) and, where applicable, of its/their data protection officer and/or representative in the European Union </em></p>
                                <p>Name: Customer and its Authorized Affiliates Address: </p>
                                <p>Contact person’s name, position and contact details: </p>
                                <p>Activities relevant to the data transferred under these clauses: Performance of the Services pursuant to the Agreement and as further described in the Documentation. </p>
                                <p>Signature and date: </p>
                                <p>Role: For the purposes of the EU C-to-P Transfer Clauses Customer and/or its Authorized Affiliate is a Controller. For the purposes of the EU P-to-P Transfer Clauses Customer and/or its Authorized Affiliate is a Processor. </p>
                                <p>Data importer(s): <em>Identity and contact details of the data importer(s), including any contact person with responsibility for data protection </em></p>

                                <p>Name: MyTrustMe.com LLC</p>
                                <p>Address: 18305 Biscayne Blvd., Suite 210, Aventura, FL 33160 </p>
                                <p>Contact person’s name, position and contact details: Legal Department, <span className='custom-link-style' onClick={() => window.location = 'mailto:info@mytrustme.com'}>info@mytrustme.com</span> </p>
                                <p>Activities relevant to the data transferred under these clauses: Performance of the Services pursuant to the Pilot Agreement and as further described in the Documentation. </p>
                                <p>Signature and date: </p>
                                <p>Role: Processor</p>

                                <p><strong>2. CATEGORIES OF DATA SUBJECT WHOSE PERSONAL DATA IS TRANSFERRED </strong></p>

                                <p>Customer may submit Personal Data to the Covered Services, the extent of which is determined and controlled by Customer in its sole discretion, and which may include, but is not limited to Personal Data relating to the following categories of data subjects: </p>
                                <p>• Prospects, customers, business partners and vendors of Customer (who are natural persons) </p>
                                <p>• Employees or contact persons of Customer’s prospects, customers, business partners and vendors </p>
                                <p>• Employees, agents, advisors, freelancers of Customer (who are natural persons) </p>
                                <p>• Customer’s Users authorized by Customer to use the Covered Services </p>

                                <p><strong>3. CATEGORIES OF PERSONAL DATA TRANSFERRED </strong></p>

                                <p>Customer may submit Personal Data to the Covered Services, the extent of which is determined and controlled by Customer in its sole discretion, and which may include, but is not limited to the following categories of Personal Data: </p>
                                <p>• First and last name </p>
                                <p>• Title </p>
                                <p>• Position </p>
                                <p>• Employer </p>
                                <p>• Contact information (company, email, phone, physical business address) </p>
                                <p>• ID data </p>
                                <p>• Professional life data </p>
                                <p>• Personal life data </p>
                                <p>• Connection data </p>
                                <p>• Localization data </p>

                                <p><strong>4. SENSITIVE DATA TRANSFERRED (IF APPLICABLE) </strong></p>

                                <p><em>Sensitive data transferred (if applicable) and applied restrictions or safeguards that fully take into consideration the nature of the data and the risks involved, such as for instance strict purpose limitation, access restrictions (including access only for staff having followed specialized training), keeping a record of access to the data, restrictions for onwards transfers or security measures: </em></p>
                                <p>Pursuant to section 2.3 of the DPA, Customer shall not submit sensitive data to the Covered Services. </p>

                                <p><strong>5. FREQUENCY OF THE TRANSFER </strong></p>

                                <p><em>The frequency of the transfer (e.g. whether the data is transferred on a one-off or continuous basis): </em></p>
                                <p>Continuous basis depending on the use of the Covered Services by Customer. </p>

                                <p><strong>6. NATURE OF THE PROCESSING </strong></p>

                                <p>The nature of the processing is the performance of the SCC Services pursuant to the Agreement. </p>

                                <p><strong>7. PURPOSE OF THE PROCESSING </strong></p>

                                <p>MYTRUSTME.COM LLC will Process Personal Data as necessary to perform the Covered Services pursuant to the Agreement, as further specified in the Documentation, and as further instructed by Customer in its use of the Covered Services. </p>

                                <p><strong>8. DURATION OF THE PROCESSING </strong></p>

                                <p><em>The period for which the personal data will be retained, or, if that is not possible, the criteria used to determine that period: </em></p>
                                <p>MyTrustMe.com LLC will Process Personal Data for the duration of the Agreement, unless otherwise agreed upon in writing. </p>

                                <p><strong>9. SUB-PROCESSOR TRANSFERS </strong></p>

                                <p><em>For transfers to (sub-) processors, also specify subject matter, nature and duration of the processing: </em></p>
                                <p>As per 7 above, the Sub-processor will Process Personal Data as necessary to perform the Services pursuant to the Agreement. Subject to section 9 of the DPA, the Sub-processor will Process Personal Data for the duration of the Agreement, unless otherwise agreed in writing. </p>
                                <p>Identities of the Sub-processors used for the provision of the Covered Services and their country of location are listed in the Infrastructure and Sub-processor Documentation which can be found on MyTrustMe.com LLC’s Trust and Compliance webpage (also accessible via <Link to={"/company/legal/agreements"} >https://www.mytrustme.com/company/legal/agreements</Link> under the “Trust and Compliance Documentation” link). </p>

                                <p><strong>10. COMPETENT SUPERVISORY AUTHORITIES </strong></p>

                                <p><em>Identify the competent supervisory authority/ies in accordance with clause 13: </em></p>
                                <p>• Where the data exporter is established in an EU Member State: The supervisory authority with responsibility for ensuring compliance by the data exporter with Regulation (EU) 2016/679 as regards the data transfer shall act as competent supervisory authority. </p>
                                <p>• Where the data exporter is not established in an EU Member State, but falls within the territorial scope of application of Regulation (EU) 2016/679 in accordance with its Article 3(2) and has appointed a representative pursuant to Article 27(1) of Regulation (EU) 2016/679: The supervisory authority of the Member State in which the representative within the meaning of Article 27(1) of Regulation (EU) 2016/679 is established shall act as the competent supervisory authority.</p>
                                <p>• Where the data exporter is not established in an EU Member State, but falls within the territorial scope of application of Regulation (EU) 2016/679 in accordance with its Article 3(2) without however having to appoint a representative pursuant to Article 27(2) of Regulation (EU) 2016/679: Commission nationale de l'informatique et des libertés (CNIL) - 3 Place de Fontenoy, 75007 Paris, France shall act as the competent supervisory authority. </p>
                                <p>• Where the data exporter is established in the United Kingdom or falls within the territorial scope of application of UK Data Protection Laws and Regulations, the Information Commissioner's Office Shall Act as the competent supervisory authority. </p>
                                <p>• Where the data exporter is established in Switzerland or falls within the territorial scope of application of Swiss Data Protection Laws and Regulations, the Swiss Federal Data Protection and Information Commissioner shall act as competent supervisory authority insofar as the relevant data transfer is governed by Swiss Data Protection Laws and Regulations. </p>

                                <p><strong>11. TECHNICAL AND ORGANISATIONAL MEASURES </strong></p>
                                <p>• Data importer will maintain administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of Personal Data uploaded to the Covered Services. </p>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </>
    );
};

export default DPAAgreement;