import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import './aboutus.css';
import * as ActionTypes from '@store/actions';
import { GetAboutUs } from '../../services/aboutUs';
const AboutUs = ({ props }) => {
    const [data, setData] = useState({})
    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        // loader()
        GetAboutUsData()
    }, []);

    const GetAboutUsData = async () => {
        try {
            document.getElementById('preloader').style.display = "block";
            const result = await GetAboutUs()
            document.getElementById('preloader').style.display = "none";
            setData((result) ? result : '');
        } catch (error) {
            document.getElementById('preloader').style.display = "none";

        }
    }

    const onSavePdf = () => {
        const link = document.createElement('a');
        // link.href = process.env.REACT_APP_IMAGE_TAG + "/assets/MyTrustMe-Pitchbook-June-2022.pdf";
        link.href = (data.file) ? process.env.REACT_APP_AWS_OBJECT_URL + data.file : process.env.REACT_APP_IMAGE_TAG + "/assets/MyTrustMe-Pitchbook-June-2022.pdf"
        link.setAttribute('download', `${data.name}.pdf`,);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <>

            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>About Us</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">About Us</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="about-us bg-blue">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="content-column col-md-12 col-sm-12 col-xs-12">
                            <div className="inner">
                                <div className="sec-title col-md-7" align="left">
                                    <h2>Who are we?</h2>
                                    <div className="desc-text"><h5><b>Roni Gannon, CEO –</b> Over 20 years of experience as a Business Development professional at companies such as Equitrac, Bowne, Wolters Kluwer, Deutsche Bank, Radius Bank, and Citizens Bank. Roni’s primary area of expertise is in the Corporate Trust industry, where he worked on some of the most historical and complex transactions and structures for many Fortune 500 and AmLaw 100 firms.</h5></div>
                                    <div className="desc-text"><h5><b>MyTrustMe<sup>TM</sup></b> is an automated process solution for Capital Market transactors to minimize time spent on gathering quotes for Indenture Trustee services. Issuers now have more time to spend on compliance or fee generating transactions while spending less time on tertiary services for which there are no direct benefits, and indeed, some risk, for the originating banker to obtain.</h5></div>
                                </div>
                                <div className="col-md-5" align="right">
                                    <img className="about-div-image" src={process.env.REACT_APP_IMAGE_TAG + "/assets/about/aboutdiv.jpg"} />
                                </div>
                                <div className='col-md-12' align="left">
                                <div className="desc-text"><h5><b>MyTrustMe<sup>TM</sup></b> automates a manual process, gathers data, acts as a repository, analyzes data, and compiles a comprehensive, easy-to-read response. This response and executing a selection is available wherever and whenever you are.</h5></div><br />
                                    {(data.file) ? <div className="desc-text"><h4 style={{ cursor: 'pointer', color: '#3a71c8' }} onClick={() => onSavePdf()} download={`${data.name}.pdf`} title={`Download ${data.name}`} ><b>MyTrustMe Pitchbook</b></h4></div> : null}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);