import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const Section1 = ({ data }) => {
    return (
        <>
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4 col-sm-12">
                    <label className="label-text">Municipality/Agency/Institution</label>
                    <p className="paragraph-text">{(data.institution) ? data.institution : '----'}</p>
                </div>
                <div className="col-md-4 col-sm-4 form-group-margin details-row-left">
                    <label className="label-text">First Name</label>
                    <p className="paragraph-text">{(data.firstName) ? data.firstName : '----'}</p>
                </div>
                <div className="col-md-4 col-sm-4 form-group-margin details-row-left">
                    <label className="label-text">Last Name</label>
                    <p className="paragraph-text">{(data.lastName) ? data.lastName : '----'}</p>
                </div>
            </div>

            <div className="form-row col-md-12" >
                <div className="form-group form-group-margin details-row-left col-md-4 col-sm-12">
                    <label className="label-text">E-mail</label>
                    <p className="paragraph-text" style={{ textTransform: 'none' }}><span>{(data.email) ? data.email : '----'}</span></p>
                </div>
                <div className="col-md-4 col-sm-4 form-group-margin details-row-left">
                    <label className="label-text">Tel No</label>
                    <p className="paragraph-text"><span>{(data.workTel && data.workTel.length > 3) ? formatPhoneNumberIntl(data.workTel) : '----'}</span></p>
                </div>
                <div className="col-md-4 col-sm-4 form-group-margin details-row-left">
                    <label className="label-text">Cell No</label>
                    <p className="paragraph-text"><span>{(data.cellNo && data.cellNo.length > 3) ? formatPhoneNumberIntl(data.cellNo) : '----'}</span></p>
                </div>
            </div>


            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4 col-sm-12">
                    <label className="label-text">Additional Contact First Name</label>
                    <p className="paragraph-text">{(data.additionalFirstName) ? data.additionalFirstName : '----'}</p>
                </div>
                <div className="col-md-4 col-sm-4 form-group-margin details-row-left">
                    <label className="label-text">Additional Contact Last Name</label>
                    <p className="paragraph-text">{(data.additionalLastName) ? data.additionalLastName : '----'}</p>
                </div>
                <div className="col-md-4 col-sm-4 form-group-margin details-row-left">
                    <label className="label-text">Additional Contact E-mail</label>
                    <p className="paragraph-text" style={{ textTransform: 'none' }}><span>{(data.additionalEmail) ? data.additionalEmail : '----'}</span></p>
                </div>
            </div>

            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4 col-sm-12">
                    <label className="label-text">Additional Work Tel.</label>
                    <p className="paragraph-text"><span>{(data.additionalWorkTel && data.additionalWorkTel.length > 3) ? formatPhoneNumberIntl(data.additionalWorkTel) : '----'}</span></p>
                </div>
                <div className="col-md-4 col-sm-4 form-group-margin details-row-left">
                    <label className="label-text">Additional Cell No.</label>
                    <p className="paragraph-text"><span>{(data.additionalCellNo && data.additionalCellNo.length > 3) ? formatPhoneNumberIntl(data.additionalCellNo) : '----'}</span></p>
                </div>

            </div>
        </>
    );
};


export default Section1;