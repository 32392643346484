import React, { useState, useEffect } from 'react';


const Section4 = ({ data }) => {
    return (
        <>
            <div className="form-row col-md-12 padding-0 bg-white">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">TRUSTEE LEGAL FEES</h1>
                    </div>
                </div>
            </div>
            <div className="col-md-12 form-group-margin">
                <div className="col-md-6">
                    <label className="label-text">Legal Fee Type</label>
                    <p className="paragraph-text">{(data.legalType) ? data.legalType : '----'}</p>
                </div>
                <div className="col-md-6">
                    <label className="label-text">Legal Fee Amount</label>
                    <p className="paragraph-text">{(data.legalCurrencyType && data.legalAmount) ? data.legalCurrencyType : ''} {(data.legalAmount) ? data.legalAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '----'}</p>
                </div>
            </div>

        </>
    );
};


export default Section4;