import React, { useState, useEffect } from 'react';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as ArsrService from '@services/arsr';
import * as Yup from 'yup';
import * as ArsService from '@services/ars';
import { useParams, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

const Corporate = ({ currentUser }) => {
    const [isSubmit, setIsSubmit] = useState(false);
    let { uniqueNo, formtype } = useParams();
    const history = useHistory();
    const [services, setServices] = useState([]);
    const [allArs, setAllArs] = useState([]);
    const [finishStatus, setfinishStatus] = useState(false);
    // File Uploads
    const fileUploading = async (request, response, reqType) => {
        const checkSheetFile = (request.document) ? request.document : "";

        let fileData = new FormData();
        fileData.append('document', request.document);
        const checkAdditionalDoc = (request.additionalDocuments) ? request.additionalDocuments : [];
        for (const key of Object.keys(request.additionalDocuments)) {
            fileData.append('additionalDocuments', request.additionalDocuments[key])
        }
        fileData.append("id", response._id);
        fileData.append("uniqueNo", response.uniqueNo);
        fileData.append("userId", response.userId);
        try {
            const data = await ArsrService.uploadDocuments(fileData);
            toast.success("Your request has been " + reqType);
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    /**
     * New Ars response Api
     */
    const addNewArsr = async (request) => {
        setIsSubmit(true)
        try {
            const { data } = await ArsrService.NewArsr(request);
            if (data && data.saveArsrData) {
                fileUploading(request, data.saveArsrData, 'submitted');
            } else {
                toast.success('Your requested has been submitted.');
            }
            setIsSubmit(false)
            history.push('/previous-response/' + formtype);
        } catch (error) {
            setIsSubmit(false)
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    const formik = useFormik({
        initialValues: {
            userId: currentUser['custom:id'],
            arsId: (allArs) ? allArs._id : '',
            institution: '',
            formType: 'complete',
            reqFormType: formtype,
            firstName: '',
            lastName: '',
            email: '',
            workTel: '',
            cellNo: '',
            additionalFirstName: '',
            additionalLastName: '',
            additionalEmail: '',
            additionalWorkTel: '',
            additionalCellNo: '',
            transactionSummary: '',
            document: '',
            additionalDocuments: '',
            typeOfServices: [],
            corporateService: (allArs) ? allArs.transactionTrustService : '',
            corporateFees: [],
        },
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        validationSchema: Yup.object({
            institution: Yup.string()
                .required('* Required'),
            firstName: Yup.string()
                .required('* Required'),
            lastName: Yup.string()
                .required('* Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('* Required'),
            workTel: Yup.string()
                .required('* Required')
                .min(12, 'Minimum 10 digits'),
            cellNo: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalWorkTel: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalCellNo: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalEmail: Yup.string()
                .email('Invalid additional email address'),
            // typeOfServices: Yup.array()
            //     .required('Type of Service is required'),
        }),
        onSubmit: (values) => {
            setIsSubmit(true)
            values.formType = 'complete'
            addNewArsr(values);
        }
    });

    // useEffect(() => {
    //     const errors = formik.errors
    //     if(errors){
    //         for(var i in errors){
    //             document.getElementsByName(i)[0].focus()
    //             break;
    //         }
    //     }
    // },[formik.errors])
    const focusValidations = (errors) => {
        for (var i in errors) {
            document.getElementsByName(i)[0].focus()
            break;
        }
    }

    useEffect(() => {
        formik.setErrors({
            "institution": "",
            "firstName": "",
            "lastName": "",
            "email": "",
            "workTel": "",
            "additionalEmail": ""
        })
    }, [])


    const onDraft = async () => {
        setIsSubmit(true)
        formik.values.formType = 'draft'
        let draftData = formik.values;
        try {
            const { data } = await ArsrService.NewArsr(formik.values);
            if (data && data.saveArsrData) {
                fileUploading(draftData, data.saveArsrData, 'drafted')
            } else {
                toast.success('Your requested has been drafted.');
            }
            setIsSubmit(false)
            history.push('/saved-responses/' + formtype);
        } catch (error) {
            setIsSubmit(false)
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    useEffect(() => {
        getArsDetails();
    }, [currentUser])


    const onBackButtonEvent = (e) => {
        setIsSubmit(true)
        e.preventDefault();
        if (!finishStatus) {
            Swal.fire({
                title: 'Are you sure ?',
                text: "You want to exit without saving ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setfinishStatus(true)
                    setIsSubmit(false)
                    history.push('/new-ars-list/' + formtype);
                } else {
                    setIsSubmit(false)
                    window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'new-ars-list/' + formtype);
                    setfinishStatus(false)
                }
            });
            // if (window.confirm("Are you sure you want to exit without saving?")) {
            //     setfinishStatus(true)
            //     history.push('/new-ars-list');
            // } else {
            //     // window.history.pushState(null, null, window.location.pathname);

            //     // history.goForward()
            //     setfinishStatus(false)
            // }
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        // window.history.pushState(null, null, window.location.pathname);
        window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'new-ars-list/' + formtype);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);


    const getArsDetails = async () => {
        try {
            const { arsDetail } = await ArsService.GetARSDetails(uniqueNo);
            console.log(arsDetail, "ars")
            await setServices(arsDetail.additionalServices);
            await setAllArs(arsDetail);
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    const setCustomsFormVal = (key, value) => {
        formik.setFieldValue(key, value)
        if (value) {
            setTimeout(() => {
                formik.setFieldError(key, '')
            }, 1000)
        }
    }


    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundPosition: 'top center' }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="form-column col-md-12 col-sm-12 col-xs-12">
                            <h1 className="h1-text text-black">{(formtype == 'ars') ? "Arbitrage Rebate Services" : "Verification Agent"} Form</h1>
                        </div>
                        <div className="form-column col-md-12 col-sm-12 col-xs-12 text-align-right">
                            <Link to={`/previous-response/${allArs.reqType}/import/${allArs.uniqueNo}`} class="btn btn-primary btn-style-submit res-btn import-btn-style" title="Import details from previously submitted RFP's">
                                Import
                            </Link>
                        </div>
                        <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5">
                            <div className="col-md-12 main-form-outside bg-white">
                                <div className="main-title-head">
                                    <h1 className="h1-font-size-title">Your information</h1>
                                </div>
                                <form onSubmit={formik.handleSubmit} align="left">
                                    <Step1 formik={formik} reqType={formtype} setCustomsFormVal={setCustomsFormVal} />
                                    <Step2 formik={formik} reqType={formtype} arsDetails={allArs} services={services} setCustomsFormVal={setCustomsFormVal} />
                                    <Step3 formik={formik} reqType={formtype} setCustomsFormVal={setCustomsFormVal} />
                                    <div className="form-row" align="right">
                                        <div className="col-md-12 btn-margin-top-bottom">
                                            <button type="button" disabled={isSubmit} onClick={(e) => onBackButtonEvent(e)} className="btn btn-primary btn-style-submit btn-back-style"><i className="fa fa-arrow-left"></i> Back</button>
                                            <button type="button" disabled={isSubmit} onClick={e => onDraft()} className="btn btn-success btn-style-submit rfp-button">Save</button>
                                            <button type="submit" disabled={isSubmit} onClick={e =>
                                                focusValidations(formik.errors)
                                            } className="btn btn-success btn-style-submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
        <div className="auto-container">
            <div className="col-md-12" style={{zIndex: '999999'}}>
            <div className="col-md-12 p-0" align="left"><button onClick={() => history.goBack()} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</button></div>
            </div>
            <h1 className="text-black">Corporate Trust Services Form</h1>
        </div>
    </section>
    <section className="contact-section">
    	<div className="auto-container">
        	<div className="row clearfix">
                <div className="form-column col-md-12 col-sm-12 col-xs-12">
                <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Your information</h2></div></div>
                    <div className="default-form contact-form">
                        <form onSubmit={formik.handleSubmit} align="left">
                            <Step1 formik={formik} />
                            <Step2 formik={formik} issuanceAmount={allArs.issuanceAmount} rfpDetails={allArs} services={services}/>
                            <Step3 formik={formik} />
                            {(allArs.legalFee == 'yes')?<Step4 formik={formik} />:''}
                            <div className="form-row" align="right">
                                <div className="col-md-12">
                                <button type="button" onClick={e=>onDraft()}  className="btn btn-primary rfp-button">Save Progress</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>            
                </div>
            </div>
        </div>
    </section> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});


export default connect(mapStateToProps)(Corporate);
