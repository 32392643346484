import React, { useState, useEffect } from 'react';


const Section8 = ({ data, formtype }) => {
    const trusteeListDesign = data.trusteeList?.length > 0
        && data.trusteeList.map((item, i) => {
            return (
                <li>
                    {item.name}
                </li>
            )
        });
    return (
        <>
            <div className="form-row">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">{(formtype == 'va') ? "Verification Agent Selection" : "Arbitrage Rebate Services Provider Selection"}</h1>
                    </div>
                </div>
            </div>
            <div className="form-row col-md-12 form-group-margin details-row-left">
                <div className="col-md-6">
                    <label className="label-text">Please select how many provider quotes you require</label>
                    <p className="paragraph-text">{(data.noOfTrustee) ? data.noOfTrustee : '----'}</p>
                </div>
                <div className="col-md-6">
                    <label className="label-text">Please select preferred service providers.</label>
                    <p className="paragraph-text"> <ul >{(trusteeListDesign) ? trusteeListDesign : '----'}</ul></p>
                </div>
            </div>
        </>
    );
};


export default Section8;