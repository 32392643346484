import React from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { connect } from 'react-redux';
import { appTimeZone } from '@app/appConstants';
import moment from 'moment-timezone';

const PrivateRoute = ({ children, currenttUser, ...rest }) => {
    const history = useHistory();
    Auth.currentSession().catch(function (err) {
        history.push('/');
    });
    const todayDate = new Date(moment().tz(appTimeZone)).toISOString();
    return (
        <>
            {
                (currenttUser["custom:id"]) ?
                    <Route
                        {...rest}
                        render={({ location }) =>
                            currenttUser && parseInt(currenttUser['custom:isSubscribed']) && currenttUser['custom:expiryDate'] > todayDate ? (
                                children
                            ) : (
                                <Redirect
                                    to={{
                                        // pathname: '/',
                                        pathname: '/subscription/' + window.btoa(currenttUser["custom:id"]),
                                        state: { from: location }
                                    }}
                                />
                            )
                        }
                    /> :
                    <Route
                        {...rest}
                        render={({ location }) =>
                            children
                        }
                    />
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currenttUser: state.auth.currentUser
});


export default connect(mapStateToProps, null)(PrivateRoute);
