import React from 'react';
import dateFormat from "dateformat";


const Section9 = ({ data }) => {
    return (
        <>
            <div className="form-row">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Timeframe</h1>
                    </div>
                </div>
            </div>
            <div className=" form-row col-md-12 details-row-left">
                <div className=" col-md-12">
                    <label className="label-text">Final date for proposal submissions</label>
                    <p className="paragraph-text">
                        {
                            (data.finaldate) ? dateFormat(data.finaldate, "UTC:mm/d/yyyy") : '----'
                        }
                    </p>
                </div>
            </div>
        </>
    );
};
// 



export default Section9;