import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as AuthService from '@services/auth';
import { useFormik } from 'formik';
import { Auth } from "aws-amplify";
import * as Yup from 'yup';
import './trustee-style.css';

const ChangePassword = ({ currentUser }) => {
    const history = useHistory();

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);


    const changeUserPassword = async (passwordValue) => {
        try {
            const { userData } = await AuthService.ChangePasswords(passwordValue);
            if (userData) {
                const OldPass = passwordValue.oldPassword ?? '';
                const NewPass = passwordValue.newPassword ?? '';
                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, OldPass, NewPass);
                toast.success("Your password is successfully changed.");
                document.getElementById('preloader').style.display = "none";
                if (currentUser && currentUser['custom:userrole'] == 'corporate_trust_provider') {
                    history.push("/corporate");
                } else {
                    history.push("/trustee");
                }
            }
        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // toast.error((error.response &&
            //     error.response.data &&
            //     error.response.data.message) || (error.message) || 'Failed');
        }
    }


    const formik = useFormik({
        initialValues: {
            id: currentUser['custom:id'],
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Please enter a old password'),
            newPassword: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Please enter a new password'),
            confirmPassword: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Please enter a confirm password')
                .when("newPassword", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("newPassword")],
                        "Both new password and confirm password need to be the same"
                    )
                }),
        }),
        onSubmit: (values) => {
            changeUserPassword(values);
            document.getElementById('preloader').style.display = "block";
        }
    });


    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                <div className="auto-container">
                    <div className="row clearfix row-container-margin-width">
                        <h1 className="h1-text text-black">Change Password</h1>
                        <div className="col-md-12 col-xs-12 bg-white margin-top-5 form-edit-col-main">
                            <div className="col-md-6 edit-profile-image">
                                <img className="edit-profile-image" style={{ width: '92%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/Changes-Password.png"} />
                            </div>
                            <div className="col-md-6 col-xs-12 form-edit-col">
                                <div className="col-md-12  col-xs-12 unset-padding padding-edit-profile">
                                    <div className="Inside">
                                        <h1 className="text-black h1-edit-profile">Change Password</h1>
                                    </div>
                                    <form className="default-form contact-form form-margin text-left change-password-form-padding" onSubmit={formik.handleSubmit}>

                                        <div className="form-group col-md-12">
                                            <label className="label-color">Old Password <span className="text text-danger">*</span></label>
                                            <input type="password" className="font-weight-600 input-border-radius" name="oldPassword" {...formik.getFieldProps('oldPassword')} />
                                            {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                                <div className="text text-danger">{formik.errors.oldPassword}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label className="label-color">New Password <span className="text text-danger">*</span></label>
                                            <input type="password" className="font-weight-600 input-border-radius" name="newPassword" {...formik.getFieldProps('newPassword')} />
                                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                                <div className="text text-danger">{formik.errors.newPassword}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label className="label-color">Confirm Password <span className="text text-danger">*</span></label>
                                            <input type="password" className="font-weight-600 input-border-radius" name="confirmPassword" {...formik.getFieldProps('confirmPassword')} />
                                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                <div className="text text-danger">{formik.errors.confirmPassword}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-12 text-center">
                                            <button type="submit" className="btn btn-primary" style={{ width: '127px' }}>Submit</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section>
                <div className="auto-container">
                    <h1 className="h1-text text-black">CHANGE PASSWORD</h1>
                </div>
            </section>
            <section className="contact-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="form-column col-md-12 col-sm-12 col-xs-12">
                       

                            <div className="default-form contact-form">
                                <form align="left" onSubmit={formik.handleSubmit}>
                                    <div className="form-row" align="center">
                                    <div className="form-group col-md-5" >
                                           
                                           <img src={process.env.REACT_APP_IMAGE_TAG+"/assets/Changes-Password.png"} />
                               
                                       </div>
                                        <div className="form-group col-md-7">
                                            <div className=" inside-form col-md-12 ">
                                                <div className="form-border">
                                                <div className="segment_header">
                                                    <h2  >Change Password</h2>
                                                </div>

                                                <div className="text-box">
                                                <div className="insidetext">
                                                <label>Old Password <span className="text text-danger">*</span></label>
                                                    <input type="password" className="form-control input-radius" placeholder="Old Password" name="oldPassword" {...formik.getFieldProps('oldPassword')}/>
                                                    {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                                        <div className="text text-danger">{formik.errors.oldPassword}</div>
                                                    ) : null}
                                                </div>
                                                <div className="insidetext">
                                                    <label>Password <span className="text text-danger">*</span></label>
                                                    <input type="password" className="form-control input-radius" placeholder="New Password" name="newPassword" {...formik.getFieldProps('newPassword')} />
                                                    {formik.touched.newPassword && formik.errors.newPassword ? (
                                                        <div className="text text-danger">{formik.errors.newPassword}</div>
                                                    ) : null}
                                                </div>
                                                <div className="insidetext">
                                                    <label>Confirm Password <span className="text text-danger">*</span></label>
                                                    <input type="password" className="form-control input-radius" placeholder="Confirm Password" name="confirmPassword" {...formik.getFieldProps('confirmPassword')} />
                                                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                        <div className="text text-danger">{formik.errors.confirmPassword}</div>
                                                    ) : null}
                                                </div>
                                                <br />
                                                <button type="submit" className="btn btn-primary" align="center">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>


                                    <div className="form-row" align="left">

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});


export default connect(mapStateToProps)(ChangePassword);
