import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Help = () => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Help Page</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Help Page</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 text-center">
                            <p>While we believe MyTrustMe.com is an easy-to-use platform, sometimes customers need some additional assistance.</p>
                            <p>In addition to our How-To videos hosted on our website, we also offer free telephone support.</p>
                            <p>Please call (646) 223-0931 or e-mail <span className='custom-link-style' onClick={() => window.location = 'mailto:info@mytrustme.com'}>info@mytrustme.com</span> with any questions or assistance you may need.</p>
                        </div>
                    </div>

                </div>
            </section>

        </>
    );
};

export default Help;