import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as ArsrService from '@services/arsr';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import '../quotes/quotes.css';
import dateFormat from "dateformat";
import { getUsersByArsr } from '../../../services/arsr';
import AssignUserModal from '../../../components/global/modal/AssignUserModal';
import { assignUserToForm } from '../../../services/auth';
import { datatableDataLimit } from '@app/appConstants';

const NewArsList = ({ currentUser }) => {
  const { formtype } = useParams();
  const [savedData, setSavedData] = useState([]);
  const [arsrUserModalOpen, setArsrUserModalOpen] = useState(false);
  const [arsrUserData, setArsrUserData] = useState([]);
  const [selectedRowArs, setSelectedRowArs] = useState([]);
  const [page, setPage] = useState(1);
  const [dataTotalCount, setDataTotalCount] = useState(0);

  const getSavedList = async (requestData) => {
    try {
      requestData.limit = datatableDataLimit;
      requestData.pageNo = page;
      requestData.reqType = formtype;
      const data = await ArsrService.GetCompleteARS(requestData);
      let draftList = (data.data.arsData) ? data.data.arsData : [];
      setSavedData(draftList);
      setDataTotalCount((data.data.totalPages) ? data.data.totalPages : 0);
      document.getElementById('preloader').style.display = "none";
    } catch (error) {
      document.getElementById('preloader').style.display = "none";
      //  toast.error(error);
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    document.getElementById('preloader').style.display = "block";
    if (currentUser['custom:id'] && page) {
      // console.log("ether",currentUser['custom:id'])
      const query = {
        userId: currentUser['custom:id'],
        type: "complete"
      };
      getSavedList(query);
      // document.getElementById('preloader').style.display = "none";
    }
  }, [currentUser['custom:id'], page]);



  const showArsUserList = async (row) => {
    document.getElementById('preloader').style.display = "block";
    const {
      arsrData
    } = await getUsersByArsr({ arsrUserid: currentUser['custom:id'], arsId: row._id })
    setArsrUserData(arsrData);
    setArsrUserModalOpen(true)
    setSelectedRowArs(row);
    document.getElementById('preloader').style.display = "none";
  }


  const columns = [
    {
      name: 'Transaction Id',
      sortable: true,
      width: "15%",
      cell: (row) =>
        (row.isView) ?
          <Link to={'/ars/view-rfp/' + row.uniqueNo + "/" + row.reqType}>{row.uniqueNo ?? '...'}</Link>
          : row.uniqueNo
    },
    {
      name: 'Submitted Date',
      sortable: true,
      cell: (row) => (row.createdAt) ? dateFormat(row.createdAt, "UTC:mmm d, yyyy") : ""
    },
    {
      name: 'Issuer Name',
      selector: row => row.issuerName ?? '...',
      sortable: true,
    },
    {
      name: 'Requester Name',
      selector: row => ((row.firstName) ? row.firstName : '') + ' ' + ((row.lastName) ? row.lastName : ''),
      sortable: true,
    },
    {
      name: 'Transaction/Project Name',
      selector: row => row.projectName ?? '...',
      sortable: true,
    },
    {
      name: 'Action',
      sortable: true,
      cell: (row) =>
        <>
          {
            (row.isEdit) &&
            <>
              <button onClick={e => showArsUserList(row)} className="btn btn-success btn-sm" style={{
                marginRight: "1em"
              }}><i className="fa fa-user-plus" title="Assign User" /></button>

              {
                (row.status) ?
                  (row.status == 'pending') ?
                    <Link to={'/ars-response/' + row.uniqueNo + "/" + row.reqType} className="btn btn-info btn-sm"><i className="fa fa-reply" title="Respond" /></Link> :
                    <a className="btn btn-sm gray-bg"><i className="fa fa-reply" title="Expired/No Response" /></a> :
                  <Link to={'/ars-response/' + row.uniqueNo + "/" + row.reqType} className="btn btn-info btn-sm"><i className="fa fa-reply" title="Respond" /></Link>
              }
            </>
          }
          {
            (row.isView && !row.isEdit) &&
            <>
              <button disabled className="btn btn-success btn-sm" style={{
                marginRight: "1em"
              }}><i className="fa fa-user-plus" title="Assign User" /></button>
              <button className="btn btn-info btn-sm" disabled><i className="fa fa-reply" title="Respond" /></button>
            </>
          }
          {
            (!row.isView && !row.isEdit) &&
            <>
              <button disabled className="btn btn-success btn-sm" style={{
                marginRight: "1em"
              }}><i className="fa fa-user-plus" title="Assign User" /></button>
              <button className="btn btn-info btn-sm" disabled><i className="fa fa-reply" title="Respond" /></button>
            </>
          }

        </>
    },
  ];


  const customStyles = {
    rows: {
      style: {
        minHeight: '72px',// override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '16px',
        minHeight: '50px',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f5f6fa',
        marginTop: '20px',
      },
    },
    cells: {
      style: {
        padding: '10px', // override the cell padding for data cells
        paddingRight: '8px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      },
    },
  };

  const arsrUserColumns = [
    {
      name: 'Name',
      cell: (row) => (row.firstName + " " + row.lastName)
    },
    {
      name: 'Email',
      cell: (row) => <p style={{
        textTransform: 'none'
      }}>
        {(row.email) ? row.email : "N/A"}
      </p>
    },
    {
      name: 'Permissions',
      cell: (row) => <>
        <label>
          <input type="checkbox" name="remember" onClick={
            () => handlePermissions(row, 'view')
          } defaultChecked={(row.assignIsView) ? row.assignIsView : false} className="remember-box" value="" id={'permission-view-' + row._id} />
          <span> View</span>
        </label>

        <label style={{
          marginLeft: "1em"
        }}>
          <input type="checkbox" name="remember" onClick={
            () => handlePermissions(row, 'edit')
          } defaultChecked={(row.assignIsEdit) ? row.assignIsEdit : false} className="remember-box" value="" id={'permission-edit-' + row._id} />
          <span> Edit</span>
        </label>
      </>
    },
  ];


  const handlePermissions = async (row, type) => {
    const view = document.getElementById('permission-view-' + row._id).checked
    const edit = document.getElementById('permission-edit-' + row._id).checked

    let updateCriteria = {
      userId: row._id,
      formType: "arsr",
      isEdit: edit,
      isView: view,
      formId: selectedRowArs._id
    }

    if (type == "edit") {
      document.getElementById('permission-view-' + row._id).checked = true
      document.getElementById('permission-edit-' + row._id).checked = (!edit) ? false : true
      updateCriteria.isView = true
      updateCriteria.isEdit = (!edit) ? false : true
    } else if (type == "view") {
      document.getElementById('permission-view-' + row._id).checked = (!view) ? false : true
      document.getElementById('permission-edit-' + row._id).checked = false
      updateCriteria.isView = (!view) ? false : true
      updateCriteria.isEdit = false
    }

    await assignUserToForm(updateCriteria)
    // setTimeout(() => {
    //   setArsrUserModalOpen(false)
    // },1000)
  }
  return (
    <>
      <section>
        <div className="auto-container">
          <h1 className="h1-text text-black">New RFP Listing</h1>
        </div>
      </section>
      <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top' }}>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="form-column col-md-12 col-sm-12 col-xs-12">
              {
                (arsrUserModalOpen) &&
                <AssignUserModal
                  title={"Assign Permission To Users"}
                  columns={arsrUserColumns}
                  data={arsrUserData}
                  handleConfirmClose={() => setArsrUserModalOpen(!arsrUserModalOpen)}
                />
              }
              <DataTable
                columns={columns}
                data={savedData}
                pagination
                customStyles={customStyles}
                dense
                paginationServer
                paginationTotalRows={dataTotalCount}
                paginationPerPage={datatableDataLimit}
                paginationComponentOptions={{
                  noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
              />
            </div>
            <div className="col-md-12 clr-both">
              <div className="col-md-12 p-0" align="left"><Link to={'/trustee'} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</Link></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser
});


export default connect(mapStateToProps)(NewArsList);