import React,{useState,useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from 'react-redux';;

const NotFound = (props) => {

    const loader = () =>{
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
      }
    
      useEffect(() => {
        window.scrollTo(0, 0)
        loader()
      }, []);

    return(
        <>
            <section className="page-title" style={{backgroundImage: "url("+process.env.REACT_APP_IMAGE_TAG+"/assets/images/background/bg-page-title-1.jpg)"}}>
                <div className="auto-container">
                    <h1>404 Page</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">404 Page</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="error-section">
                <div className="auto-container">
                    <h3><span className="theme_color">404!</span><br /> Sorry! Can't Find That Page! </h3>
                    <div className="text">Can't find what you need? Take a moment and do a search <br /> below or start from our <Link to={'/'}>Homepage</Link></div>
                    
                </div>
            </section>
            </>
    )
}

export default connect(null, null)(withRouter(NotFound));