import API from '@app/utils/axios';

export const subscriptionPlan = async (requestData) => {
    const {data} = await API.post('payment/create-customer-card-subscription',requestData);
    
    return data;
};


export const getRolePriceById = async (requestData) => {
    const {data} = await API.post('user/get-role-by-userid',{id: requestData});
    return data.data;
};


export const getCurrentCardById = async (requestData) => {
    const {data} = await API.post('payment/get-card-detail',{userId: requestData});
    return data.data;
};


export const updateCardById = async (requestData) => {
    const {data} = await API.post('payment/update-card-detail',requestData);
    return data.data;
};


export const cancelSubscriptionById = async (requestData) => {
    const {data} = await API.post('payment/cancel-subscription',{userId: requestData});
    return data.data;
};

export const getCouponCodeByCode = async (requestData) => {
    const {data} = await API.post('coupon/get-coupon-by-code',{code: requestData});
    return data.data;
}

export const getSubscriptionData = async (requestData) => {
    const {data} = await API.post('payment/get-subscription-list',{userId: requestData});
    return data.data;
}

export const subscriptionUpdatePlan = async (requestData) => {
    const {data} = await API.post('payment/update-subscription',requestData);
    console.log(data,'datra')
    return data;
};