import React,{useState,useEffect} from 'react';
import '../subscription/subs.css';

const TermConditionModal = (props) => {
    const confirmData = props.subsData; 
    return (
        <>
        <div className="modal fade in d-block bd-example-modal-md term-condition" id="myModal" role="dialog">
            <div className="modal-dialog modal-md">
            <div className="modal-content br-0" align="left">
                <div className="modal-header modal-header-primary br-0" style={{background: 'white', color: 'black', padding: '10px 30px'}} align="left">
                <button type="button" onClick={e => props.handleConfirmClose()} className="close mdl-close-cfm" data-dismiss="modal">&times;</button>
                <h4 className="modal-title w-90" style={{fontWeight: '700'}}>Change Subscription Plan</h4>
                </div>
                <div className="modal-body" style={{padding:'40px;', overflow: 'auto', maxHeight: '450px', padding: '0 15px'}}>
                        <div className='row' style={{padding: '30px 15px', background: '#eaeaea'}}>
                             <div className='col-md-12 dotted-spaced'>
                                <h5><b>CURRENT PLAN:</b></h5>
                                <h5 style={{color: 'black', fontWeight: '600', marginBottom: '15px'}}>{(confirmData)?(confirmData.manageDetail)?confirmData.manageDetail.planName:'':''}</h5>
                             </div>
                             <div className='col-md-12' style={{marginTop: '15px'}}>
                                <h5><b>NEW PLAN:</b></h5>
                                <h5 style={{color: 'black', fontWeight: '600'}}>{(confirmData)?confirmData.selectPlanText:''}</h5>
                             </div>
                        </div>
                  <div>
                  <div className="checkboxes col-md-12 font-13 text-align-left" style={{padding:"5px 15px"}}>
                    <h5>{`Your new plan starts on ${(confirmData)?(confirmData.manageDetail.nextBillingDate)?confirmData.manageDetail.nextBillingDate:'----':'----'}. You'll pay ${(confirmData)?confirmData.selectPlanText:''} starting ${(confirmData)?(confirmData.manageDetail.nextBillingDate)?confirmData.manageDetail.nextBillingDate:'----':'----'}.`}</h5>
                    </div>
                  </div>
                </div>
                <div class="modal-footer" style={{padding: '15px 30px'}}>
                    <button type="button" onClick={e => props.handleConfirmCheck('confirm')} class="btn btn-primary">Confirm Change</button>
                    <button type="button" onClick={e => props.handleConfirmClose()} class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>
    );
};


export default TermConditionModal;