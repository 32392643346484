import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as AuthService from '@services/auth';
import { useFormik } from 'formik';
import { Auth } from "aws-amplify";
import * as Yup from 'yup';
import * as ActionTypes from '@store/actions';
import "react-phone-input-2/lib/style.css";
import PhoneInput from 'react-phone-input-2';
import './trustee-style.css';

const Profile = ({ currentUser, onUserDetail }) => {
    const [profile, setProfile] = useState({});
    const [updatePhone, setUpdatedPhone] = useState();
    const getProfile = async () => {
        if (currentUser['custom:id']) {
            try {
                const { userData } = await AuthService.GetProfile(currentUser['custom:id']);
                setProfile(userData);
                document.getElementById('preloader').style.display = "none";
            } catch (error) {
                document.getElementById('preloader').style.display = "none";
                // toast.error(
                //     (error.message) ||
                //     'Failed'
                // );
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getProfile();
        document.getElementById('preloader').style.display = "block";
    }, [currentUser['custom:id']]);


    const userUpdate = async (updateDetail) => {
        try {
            const { userData } = await AuthService.UpdateProfile(updateDetail);
            if (userData) {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(user, {
                    name: userData.firstName,
                    family_name: userData.lastName,
                    phone_number: (userData.phoneNo) ? userData.phoneNo : '',
                });
                Auth.currentAuthenticatedUser()
                    .then(data => {
                        onUserDetail(data.attributes);
                    })
                    .catch(err => {
                        //   console.log('err', err)
                    });
                toast.success("Your profile is successfully updated.");
                getProfile();
                document.getElementById('preloader').style.display = "none";
            }
        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // toast.error(
            //     (error.message) ||
            //     'Failed'
            // );
        }
    }


    const formik = useFormik({
        initialValues: {
            id: currentUser['custom:id'],
            firstName: profile.firstName ?? '',
            lastName: profile.lastName ?? '',
            email: profile.email ?? '',
            phoneNo: (profile.phoneNo) ? profile.phoneNo : '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            firstName: Yup.string()
                .test('alphabets', 'First Name must only contain alphabets', (value) => {
                    return /^[a-zA-Z\s]*$/.test(value);
                })
                .required('First Name is required'),
            lastName: Yup.string()
                .test('alphabets', 'Last Name must only contain alphabets', (value) => {
                    return /^[a-zA-Z\s]*$/.test(value);
                })
                .required('Last Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email address is required'),
            phoneNo: Yup.string().min(12, 'Minimum 10 digits')
        }),
        onSubmit: (values) => {
            const updatingData = {
                firstName: values.firstName,
                id: values.id,
                lastName: values.lastName,
                phoneNo: values.phoneNo,
            }
            userUpdate(updatingData);
            document.getElementById('preloader').style.display = "block";
        }
    });

    const setCustomsFormVal = (key,value) => {
        formik.setFieldValue(key, value)
        if(value){
            setTimeout(()=>{
                formik.setFieldError(key, '')
            },1000)
        }
        if(value && key == 'phoneNo' && value.length < 12){
            setTimeout(async()=>{
                await formik.setFieldError(key, "Minimum 10 digits")
                await formik.setFieldTouched(key, true)
            },1000)
        }else{
            setTimeout(async()=>{
                await formik.setFieldError(key, "")
                await formik.setFieldTouched(key, false)
            },1000)
        }
    }


    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                <div className="auto-container">
                    <div className="row clearfix row-container-margin-width">
                        <h1 className="h1-text text-black">Edit profile</h1>
                        <div className="col-md-12 col-xs-12 bg-white margin-top-3 form-edit-col-main">
                            <div className="col-md-5 ">
                                <img className="edit-profile-image" src={process.env.REACT_APP_IMAGE_TAG + "/assets/edit-profile.png"} />
                            </div>
                            <div className="col-md-7  col-xs-12  form-edit-col">
                                <div className="col-md-12 col-xs-12 unset-padding padding-edit-profile">
                                    <div className="">
                                        <h1 className="text-black h1-edit-profile">Edit Profile</h1>
                                    </div>
                                    <form className="default-form contact-form form-margin text-left" onSubmit={formik.handleSubmit}>
                                        <div className='col-md-12'>
                                            <div className="form-group col-md-6">
                                                <label className="label-color">First Name <span className="text text-danger">*</span></label>
                                                <input className="font-weight-600" type="text" name="firstName" placeholder="First Name *" {...formik.getFieldProps('firstName')} />
                                                {formik.touched.firstName && formik.errors.firstName ? (
                                                    <div className="text text-danger" align="left">{formik.errors.firstName}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="label-color">Last Name <span className="text text-danger">*</span></label>
                                                <input className="font-weight-600" type="text" name="lastName" placeholder="Last Name *" {...formik.getFieldProps('lastName')} />
                                                {formik.touched.lastName && formik.errors.lastName ? (
                                                    <div className="text text-danger" align="left">{formik.errors.lastName}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className="form-group col-md-6">
                                                <label className="label-color">E-mail <span className="text text-danger">*</span></label>
                                                <input type="email" className="font-weight-600" name="email" readOnly placeholder="Email *" {...formik.getFieldProps('email')} />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className="text text-danger" align="left">{formik.errors.email}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="label-color">Phone No. </label>
                                                <PhoneInput
                                                    className="phone-no-input font-weight-600"
                                                    country={'us'}
                                                    countryCodeEditable={false}
                                                    value={(formik.values.phoneNo.length > 2)?formik.values.phoneNo:'+1'}
                                                    onChange={phoneNo => setCustomsFormVal('phoneNo',(phoneNo.length >= 2)?"+"+phoneNo:'')}
                                                />
                                                {formik.touched.phoneNo && formik.errors.phoneNo ? (
                                                    <div className="text text-danger" align="left">{formik.errors.phoneNo}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 text-center">
                                            <button type="submit" className="btn btn-primary blue-bg-custom" style={{ width: '150px' }}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
