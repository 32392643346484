import React, { useState, useEffect } from 'react';

const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const ServiceModal = (props) => {
    let title = "";
    let description = "";
    if (props.items == 1) {
        title = "Debt & Capital Markets Bankers";
        description = "<b>MyTrustMe<sup>TM</sup></b> automates the manual process for Capital Markets transactions, minimizing time spent on gathering quotes for Indenture Trustee services. Bankers now have more time to spend on their fee generating transactions while spending less time on tertiary services for which there are no direct benefits, and indeed, some risk, for the originating banker to obtain.";
    } else if (props.items == 2) {
        title = "Corporate Trust Bankers";
        description = "<b>MyTrustMe<sup>TM</sup></b> is a revenue producing online platform which provides Corporate Trust Bankers with a dashboard for responding to new Indenture Trustee services quotes.<br /><br />Whether it’s for an existing client, or a new prospect, providing a quote has never been easier.<br /><br /><b>MyTrustMe<sup>TM</sup></b> will enable Corporate Trust bankers to accurately quote for services, provide responses in a timely manner, and potentially increase revenue per transaction.<br /><br />Corporate Trustees will be exposed to potentially new clients which they otherwise would not have had access to.";
    } else if (props.items == 3) {
        title = "Municipal/Corporate Issuers";
        description = "<b>MyTrustMe<sup>TM</sup></b> is an automated process solution for debt issuers, whether Municipal or Corporate, to obtain quotes, providing a centralized, transparent, and more economical solution.";
    } else if (props.items == 4) {
        title = "Financial Advisors & Lawyers";
        description = "<b>MyTrustMe<sup>TM</sup></b> will reduce the time it takes for Financial Advisors and Lawyers to obtain and analyze quotes.";
    } else if (props.items == 5) {
        title = "Arbitrage Rebate Services";
        description = "<b>MyTrustMe.com's</b> unique platform will aggregate and transmit your transaction information to industry professionals who will respond within the timeframe you dictate.  In addition, <b>MyTrustMe.com</b> will provide a detailed and comprehensive analysis of all the responses in an easy-to-read, downloadable format.";
    } else if (props.items == 6) {
        title = "Verification Agents";
        description = "Now you can select from a list of Refunding Verification Agent Servicers for Arbitrage Rebate transactions.  In a refunding or other defeasance, a Verification Agent is engaged to prepare a report by a certified public accountant or other independent third party that verifies the yield of the investments held in escrow in connection with an advance refunding bond issue and demonstrates that the cash flow from investments purchased with the proceeds of the refunding bonds and other funds held in escrow are sufficient to pay the principal of and interest on the refunded bonds that are being defeased.  <b>MyTrustMe.com</b> can obtain quotes and secure a provider for this vital aspect of your transaction.";
    }

    return (
        <>
            <div className="modal fade in d-block bd-example-modal-lg" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" align="left">
                        <div className="modal-header modal-header-primary" align="center">
                            <button type="button" onClick={e => props.handleClose()} className="close mdl-close" data-dismiss="modal">&times;</button>
                            <h2 className="modal-title w-90">{title}</h2>
                        </div>
                        <div className="modal-body" style={{ padding: '40px' }}>
                            <p className="modal-text">{renderHTML(description ?? "")}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};


export default ServiceModal;