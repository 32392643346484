import React,{useState,useEffect,useRef } from 'react';
import {Link, useHistory} from 'react-router-dom';
import store from '@store/index';
import {toast} from 'react-toastify';
import * as ActionTypes from '@store/actions';
import {connect} from 'react-redux';
import { Auth } from "aws-amplify";
import LoginModal from '../../../pages/main/LoginModal';
import RegisterModal from '../../../pages/main/RegisterModal';
import ForgetModel from '../../../pages/forgot-password/ForgetModal';
import VerifyOtpModal from '../../../pages/main/VerifyOtpModal';
import * as AuthService from '@services/auth';
import Swal from 'sweetalert2';
import * as PaymentService from '@services/payment';

const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const MainMenu = (props) => {
    const [loginui,setLoginui] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [forgetModal, setForgetModal] = useState(false);
    const [msg,setCodeMessage] = useState();
    const [username,setUsername] = useState('');
    const [userId,setUserId] = useState('');
    const [userDetail,setUserDetail] = useState();
    const history = useHistory();
    const ref = useRef();

    useOutsideClick(ref, () => {
        setLoginui(false);
    });
    const logoutUser = async () => {
        try {
            setLoginui(false)
            await Auth.signOut();
            store.dispatch({type: ActionTypes.LOGOUT_USER});
            history.push("/");
            // toast.success("You are Logout Successfully");
            document.getElementById('preloader').style.display = "none";
          }catch(error) {
            toast.error(error.message);
          }
    }
    const updateSubscribe = async (data) =>{
        const {userData} = await AuthService.GetProfile(data.attributes['custom:id']);
        setUserDetail(userData);
        Auth.updateUserAttributes(data, {
            'custom:isSubscribed': (userData.isSubscribed)?'1':'0',
            'custom:expiryDate': userData.expiryDate,
            });
        props.onUserDetail(data.attributes);
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(data => {
            updateSubscribe(data);
        }).catch(function(err){
            
        });
    }, []);

    const handleRegisterModal = (status) => {
        if(status){
            setLoginModal(false)
        }
        setRegisterModal(status)
    }

    const handleLoginModal = (status) => {
        if(status){
            setRegisterModal(false)
            setForgetModal(false)
        }
        setLoginModal(status)
    }
    
    const handleVerifyModal = (status) => {
        if(status){
            setRegisterModal(false)
            setLoginModal(false)
        }
        setOtpModal(status)
    }

    const handleForgetModal = (status) => {
        if(status){
            setLoginModal(false)
        }
        setForgetModal(status)
    }


    return(
    <>
    <div className="preloader" id="preloader"></div>
    <header className="main-header header-style-one">
    	{/* <div className="header-top-one">
        	<div className="auto-container">
            	<div className="clearfix">
                    
                    <div className="top-left top-links">
                    	<ul className="clearfix">
                        	<li><Link to={'/'}>Welcome to <b>MyTrustMe <sup>TM</sup></b></Link></li>
                        </ul>
                    </div>
                    
                    <div className="top-right">
                    	<ul className="social-links clearfix">
                        	<li><Link to={'/'}><span className="fa fa-facebook-f"></span></Link></li>
                            <li><Link to={'/'}><span className="fa fa-twitter"></span></Link></li>
                            <li><Link to={'/'}><span className="fa fa-google-plus"></span></Link></li>
                            <li><Link to={'/'}><span className="fa fa-linkedin"></span></Link></li>
                            <li><Link to={'/'}><span className="fa fa-instagram"></span></Link></li>
                        </ul>
                    </div>
                    
                </div>
                
            </div>
        </div> */}
        
        
        <div className="header-lower">
            <div className="main-box">
                <div className="auto-container mw-0">
                    <div className="outer-container clearfix">
                        <div className="logo-box">
                            <div className="logo">
                            <Link to={'/'} title="MyTrustMeee" style={{backgroundImage: 'none'}}>
                            <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/home/logo1.png"} alt="" />
                            </Link>
                            </div>
                        </div>
                        
                        <div className="nav-outer clearfix">
                            <nav className="main-menu">
                                <div className="navbar-header">  	
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    </button>
                                </div>
                                
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li><Link to={'/'}>Home</Link>
                                        </li>
                                        <li><Link to={'/about-us'}>About Us</Link>
                                        </li>
                                        <li><Link to={'/how-it-work'}>How it Works</Link>
                                        </li>
                                        <li><Link to={'/resources'}>Resources</Link>
                                        </li>
                                        <li><Link to={'/additional-service'}>Additional Services</Link>
                                        </li>
                                        {(!props.isLoggedIn)?<li><Link to={'/pricing'}>Pricing</Link>
                                        </li>:''}
                                        <li><Link to={'/contact-us'}>Contact Us</Link></li>
                                        {(!props.isLoggedIn)?
                                        <>
                                        <li><a onClick={
                                            () => setLoginModal(true)
                                        } style={{
                                            padding: '7px 21px',
                                            background: '#306ac5',
                                            color: 'white'
                                        }}>Log in</a></li>
                                        <li style={{
                                            marginLeft: '10px'
                                        }}><a onClick={
                                            () => setRegisterModal(true)
                                        } style={{
                                            padding: '7px 21px',
                                            background: '#545454',
                                            color: 'white',
                                        }}>Register</a></li>
                                        </>
                                        :''
                                        }
                                        
                                        {(props.isLoggedIn && props.currentUser['custom:userrole'] != 'corporate_trust_provider')?
                                         <li><Link to={'/trustee'}>My Requests</Link></li>
                                         :
                                        ''
                                        } 
                                        {(props.isLoggedIn && props.currentUser['custom:userrole'] == 'corporate_trust_provider')? 
                                        <li><Link to={'/corporate'}>My Requests</Link></li>
                                         :
                                        ''
                                        } 
                                        {(props.isLoggedIn)?
                                        <li className={(loginui)?"dropdown":''} ref={ref}>
                                            <a onClick={()=>(loginui == false)?setLoginui(true):setLoginui(false)}><i className="fa fa-user"></i><b className="caret"></b></a>
                                            {(loginui)
                                            ?
                                            <ul className="dropdown-menu open-navbar pull-right l-auto">
                                                <li><Link onClick={
                                                    () => setLoginui(false)
                                                } to={'/profile'}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  Update Profile</Link></li>
                                                <li><Link onClick={
                                                    () => setLoginui(false)
                                                } to={'/change-password'}><i className="fa fa-unlock" aria-hidden="true"></i>  Change Password</Link></li>
                                                <li><Link onClick={
                                                    () => setLoginui(false)
                                                } to={'/update-card/'+window.btoa(props.currentUser['custom:id'])}><i className="fa fa-credit-card" aria-hidden="true"></i>  Card Info</Link></li>
                                                {(userDetail)?(props.currentUser['custom:isSubscribed'] == '1')?<li><Link onClick={
                                                    () => setLoginui(false)
                                                } to={'/manage-subscription'}><i className="fa fa-server" aria-hidden="true"></i>  Manage Subscription</Link></li>:'':''}
                                                <li><a onClick={e => logoutUser()}><i className="fa fa-sign-out" aria-hidden="true"></i>  Log out</a></li>
                                            </ul>
                                            :
                                            ''
                                            }

                                        </li>
                                         :
                                        ''
                                        } 
                                    </ul>
                                </div>
                            </nav>
                            
                        </div>
                        
                    </div>    
                </div>
            </div>
        </div>
        
        <div className="sticky-header">
            <div className="auto-container clearfix mw-0">
                <div className="logo pull-left">
                    <Link to={'/'} title="MyTrustMee" style={{backgroundImage: 'none'}}>
                    <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/home/logo1.png"} alt="" />
                    </Link>
                </div>
                
                <div className="right-col pull-right">
                    <nav className="main-menu">
                        <div className="navbar-header">  	
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            </button>
                        </div>
                        
                        <div className="navbar-collapse collapse clearfix">
                            <ul className="navigation clearfix">
                            <ul className="navigation clearfix">
                            <li><Link to={'/'}>Home</Link>
                                        </li>
                                        <li><Link to={'/about-us'}>About Us</Link>
                                        </li>
                                        <li><Link to={'/how-it-work'}>How it Works</Link>
                                        </li>
                                        <li><Link to={'/resources'}>Resources</Link>
                                        </li>
                                        <li><Link to={'/additional-service'}>Additional Services</Link>
                                        </li>
                                        {(!props.isLoggedIn)?<li><Link to={'/pricing'}>Pricing</Link>
                                        </li>:''}
                                        <li><Link to={'/contact-us'}>Contact Us</Link></li>
                                        {(!props.isLoggedIn)?
                                        <>
                                        <li><a onClick={
                                            () => setLoginModal(true)
                                        }>Log in</a></li>
                                        <li><a onClick={
                                            () => setRegisterModal(true)
                                        }>Register</a></li>
                                        </>
                                        :''
                                        }
                                        {(props.isLoggedIn && props.currentUser['custom:userrole'] != 'corporate_trust_provider')?
                                         <li><Link to={'/trustee'}>My Requests</Link></li>
                                         :
                                        ''
                                        } 
                                        {(props.isLoggedIn && props.currentUser['custom:userrole'] == 'corporate_trust_provider')? 
                                        <li><Link to={'/corporate'}>My Requests</Link></li>
                                         :
                                        ''
                                        }
                                    </ul>
                                
                            </ul>
                        </div>
                    </nav>
                </div>
                
            </div>
        </div>
        {
            (loginModal) ?
                <LoginModal handleLoginModal={(status) => handleLoginModal(status)} handleRegisterModal={handleRegisterModal} handleForgetModal={handleForgetModal} usernameSet={
                    (username) => setUsername(username)
                } useridSet={(id)=>setUserId(id)}  handleVerifyModal={handleVerifyModal} customMessage={(msg) => setCodeMessage(msg)}/> : null 
         }
         {
            (otpModal) ?
                <VerifyOtpModal userId={userId} username={username} customMessage={msg} handleVerifyModal={(status) => handleVerifyModal(status)}/> : null 
         }
         {
             (registerModal) ?
                 <RegisterModal handleRegisterModal={handleRegisterModal} handleLoginModal={handleLoginModal} usernameSet={
                     (username) => setUsername(username)
                 } useridSet={(id)=>setUserId(id)} handleVerifyModal={handleVerifyModal} customMessage={(msg) => setCodeMessage(msg)}/> : null 
          }
          {
             (forgetModal) ?
                 <ForgetModel handleForgetModal={handleForgetModal} handleLoginModal={(status) => handleLoginModal(status)}/> : null 
          }
    </header>
    </>
    )
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
  });
  
  const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);