import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as AuthService from '@services/auth';
import * as AppConstant from '@app/appConstants';
import * as CurrencyJson from '@app/utils/currency';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment-timezone';
import DatePicker from 'react-date-picker';
import dateFormat from "dateformat";
var _ = require('lodash');



const Step2 = (props) => {
    let formik = props.formik;
    const [issuance, setIssuance] = useState([]);
    const [issuanceNo, setInssuanceNo] = useState([]);
    const [excVal, setExcVal] = useState(false);
    const [updateIssType, setUpdateIssType] = useState(false);
    const [updateCorpTrust, setUpdateCorpTrust] = useState(false);
    // console.log(issuanceNo);
    const getIssuanceType = async () => {
        try {
            const data = await AuthService.GetIssuanceType({ type: 'isCorporate' });
            let typeList = (data.data.issuanceType) ? data.data.issuanceType : [];
            setIssuance(typeList);
        } catch (error) {
            //  toast.error(error);
        }
    }


    useEffect(() => {
        let element = document.getElementsByName("issuanceAmount");
        for (let index = 0; index < element.length; index++) {
            const elements = element[index];
            if (typeof (elements) != 'undefined' && elements != null) {
                elements.value = "";
            }
        }
        if (formik.values.tranchesNumber == 'single') {
            setInssuanceNo([{
                type: 'single',
                issuanceCurrencyType: '$',
                issuanceAmount: ''
            }]);
        } else if (formik.values.tranchesNumber == 'dual') {
            setInssuanceNo([{
                type: 'single',
                issuanceCurrencyType: '$',
                issuanceAmount: ''
            }, {
                type: 'dual',
                issuanceCurrencyType: '$',
                issuanceAmount: ''
            }]);
        } else if (formik.values.tranchesNumber == 'multiple') {
            setInssuanceNo([{
                type: 'single',
                issuanceCurrencyType: '$',
                issuanceAmount: ''
            }, {
                type: 'dual',
                issuanceCurrencyType: '$',
                issuanceAmount: ''
            }, {
                type: 'multiple',
                issuanceCurrencyType: '$',
                issuanceAmount: ''
            }]);
        }
    }, [formik.values.tranchesNumber]);


    useEffect(() => {
        getIssuanceType()
        if (props.formType && formik.values.issuanceAmount.length != 0) {
            setInssuanceNo(formik.values.issuanceAmount)

        } else {
            if (formik.values.tranchesNumber) {
                // formik.values.tranchesNumber = formik.values.tranchesNumber;
                props.setCustomsFormVal('tranchesNumber', formik.values.tranchesNumber);
            } else {
                setInssuanceNo([{
                    type: 'single',
                    issuanceCurrencyType: '$',
                    issuanceAmount: 0
                }]);
            }
        }
    }, []);


    useEffect(() => {
        props.setCustomsFormVal('issuanceAmount', issuanceNo);
    }, [issuanceNo])


    useEffect(() => {
        if (updateIssType && formik.values.issuanceType == AppConstant.globalConst.ISSUANCETYPE_ID) {
            // console.log(updateIssType)
            // formik.values.exchangeIssuer = '';
            formik.setFieldValue('exchangeIssuer', '')
            // formik.values.cusipExchange = [];
            formik.setFieldValue('cusipExchange', [])
            // formik.values.offerServices = '';
            formik.setFieldValue('offerServices', '')
            // formik.values.expirationServices = '';
            formik.setFieldValue('expirationServices', '')
        }
    }, [formik.values.issuanceType]);

    useEffect(() => {
        if (formik.values.exchangeIssuer == "yes" && excVal) {
            // formik.values.cusipExchange = [];
            formik.setFieldValue('cusipExchange', [])
            // formik.values.offerServices = '';
            formik.setFieldValue('offerServices', '')
            // formik.values.expirationServices = '';
            formik.setFieldValue('expirationServices', '')
        }
    }, [formik.values.exchangeIssuer]);

    useEffect(() => {
        if (formik.values.corporateTrustService == AppConstant.globalConst.CORPCORPATETRUSTSERVICE && updateCorpTrust) {
            // formik.values.cusipExchange = [];
            formik.setFieldValue('issuanceRated', '')
            // formik.values.offerServices = '';
            formik.setFieldValue('ratedDescription', '')
            // formik.values.expirationServices = '';
            formik.setFieldValue('firstTimeIssuer', '')
            formik.setFieldValue('sovereignIssuer', '')
            formik.setFieldValue('exchangeIssuer', '')
            formik.setFieldValue('publiclyTradedCompany', '')
            formik.setFieldValue('tiaQualified', '')
        } else {
            setUpdateCorpTrust(false);
        }
    }, [formik.values.corporateTrustService]);

    const initValueissuanceTenure = (e) => {
        if ('/^\d+$/', /^\d+$/.test(e.target.value) || !e.target.value) {
            // formik.values.issuanceTenure = e.target.value
            formik.setFieldValue('issuanceTenure', e.target.value, 'required')
        } else {
            e.target.value = "";
            // formik.values.issuanceTenure = ''
            props.setCustomsFormVal('issuanceTenure', '', 'required');
            // toast.error("Value must be a number");
        }
    }

    const issuanceDesign = issuance.length > 0
        && issuance.map((item, i) => {
            return (
                <option value={(item) ? item._id : ''}>{(item) ? item.name : ''}</option>
            );
        });

    const onChangeIssuance = (type, e) => {
        let valueCheck = _.find(issuanceNo, (o) => { return _.isMatch(o.type, type) });
        if (valueCheck) {
            if (e.target.name == 'issuanceCurrencyType') {
                valueCheck.issuanceCurrencyType = e.target.value
            } else if (e.target.name == 'issuanceAmount') {
                if ('/^\d+$/', /^\d+$/.test(e.target.value.replaceAll(',', '')) || !e.target.value) {
                    valueCheck.issuanceAmount = e.target.value.replaceAll(',', '')
                    e.target.value = valueCheck.issuanceAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                } else {
                    e.target.value = "";
                    // toast.error("Amount of Issuance must be a number");
                }
            }
        } else {
            issuanceNo.push({
                type: type,
                [e.target.name]: e.target.value
            })
        }
        setInssuanceNo(issuanceNo)
        // formik.values.issuanceAmount = issuanceNo
        props.setCustomsFormVal('issuanceAmount', issuanceNo);
    }

    const CurrencyDesign = CurrencyJson.default.length > 0
        && CurrencyJson.default.map((item, i) => {
            return (
                <option value={item.symbol}>{item.code}</option>
            );
        });


    let issuanceAmtDesign = issuanceNo.length > 0
        && issuanceNo.map((item, i) => {
            return <>
                <div className="form-group form-group-margin col-md-4 col-sm-12">
                    <label className="label-text">{AppConstant.globalConst.CORPESCROWISSUANCEID == formik.values.issuanceType?"Amount of Deposit":"Amount of Issuance"} </label>
                    <div className="form-row">
                        <div className="col-md-4 p-0">
                            <select className="form-control" name="issuanceCurrencyType" {...formik.getFieldProps('issuanceCurrencyType')} onChange={e => onChangeIssuance(item.type, e)} defaultValue={item.issuanceCurrencyType} >
                                {CurrencyDesign}
                            </select>
                        </div>
                        <div className="col-md-8 p-0">
                            <input type="text" id='gadget_url' className="form-control" name="issuanceAmount" placeholder="ex. $100mm, €100mm, £100mm" onChange={e => onChangeIssuance(item.type, e)} defaultValue={(item.issuanceAmount != 0 || item.issuanceAmount != '') ? item.issuanceAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : ''} />
                        </div>
                    </div>
                    {formik.touched.issuanceAmount && formik.errors.issuanceAmount ? (
                        <div className="text text-danger" align="left">{formik.errors.issuanceAmount}</div>
                    ) : null}
                </div>
            </>;
        });

    const exchnageIssr = (e) => {
        props.setCustomsFormVal('exchangeIssuer', e.target.value, 'required');
        setExcVal(true)
    }

    const handleIssuanceType = (e) => {
        props.setCustomsFormVal('issuanceType', e.target.value, 'required');
        setUpdateIssType(true)
    }

    const handleCorpTrustSer = (e) => {
        props.setCustomsFormVal('corporateTrustService', e.target.value, 'required');
        setUpdateCorpTrust(true)
    }

    const handleIntDate = (key, val, type = '') => {
        var dateObj = new Date(val);
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        var date = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear();
        var shortDate = year + '-' + month + '-' + date;
        // props.setCustomsFormVal(key,(val)?moment(val, 'DDMMMYYYY').format('YYYY-MM-DD'):'',type);
        props.setCustomsFormVal(key, (val) ? shortDate : '', type);
    }

    return (
        <>

            <div className="form-row">
                <div className="col-md-12 padding-0 margin-head-info">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">{AppConstant.globalConst.CORPESCROWISSUANCEID == formik.values.issuanceType?"Escrow Information":"Issuer information"}</h1>
                    </div>
                </div>
            </div>
            <div className="form-row bg-white">
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text" >Name of Issuer
                        <p className="span-hash">(If you do not wish to reveal client name at this time, type "Confidential") <span className="text text-danger">*</span></p>
                    </label>
                    <input type="text" name="issuerName" className="form-control" {...formik.getFieldProps('issuerName')} />
                    {formik.touched.issuerName && formik.errors.issuerName ? (
                        <div className="text text-danger" align="left">{formik.errors.issuerName}</div>
                    ) : null}
                    {props.customErr ? (
                        <div className="text text-danger" align="left">{props.customErr}</div>
                    ) : null}
                </div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p"><p className="span-hash color-white">.</p>Transaction/Project Name</label>
                    <input type="text" name="projectName" className="form-control" {...formik.getFieldProps('projectName')} placeholder="ex. GE 20XX Issuance, Project Alpha, etc." />
                    {formik.touched.projectName && formik.errors.projectName ? (
                        <div className="text text-danger" align="left">{formik.errors.projectName}</div>
                    ) : null}
                </div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p"><p className="span-hash color-white">.</p>Corporate Trust Service <span className="text text-danger">*</span></label>

                    <select className="form-control" name="corporateTrustService" value={(formik.values.corporateTrustService) ? formik.values.corporateTrustService : ''} onChange={e => handleCorpTrustSer(e)}>
                        <option readOnly defaultValue="" value=''>Select Corporate Trust Service</option>
                        <option value="Agent">Agent</option>
                        <option value="Escrow Agent">Escrow Agent</option>
                        <option value="Fiscal Agent">Fiscal Agent</option>
                        <option value="Successor Trustee">Successor Trustee</option>
                        <option value="Paying Agent">Paying Agent</option>
                        <option value="Trustee">Trustee</option>
                        <option value="Tender Agent / Exchange Agent">Tender Agent / Exchange Agent</option>
                        <option value="None">None</option>
                    </select>
                    {formik.touched.corporateTrustService && formik.errors.corporateTrustService ? (
                        <div className="text text-danger" align="left">{formik.errors.corporateTrustService}</div>
                    ) : null}
                </div>
                <div className="clear-both"></div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text">Type of Issuance <span className="text text-danger">*</span></label>
                    <select className="form-control" id="sel1" value={(formik.values.issuanceType) ? formik.values.issuanceType : ''} onChange={e => handleIssuanceType(e)} name="issuanceType" >
                        <option readOnly defaultValue="" value=''>Select Issuance Type</option>
                        {issuanceDesign}
                    </select>
                    {formik.touched.issuanceType && formik.errors.issuanceType ? (
                        <div className="text text-danger" align="left">{formik.errors.issuanceType}</div>
                    ) : null}
                </div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text">{(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? 'Tenure of Issuance (Year)' : 'How many months will the funds be held in Escrow?'} <span className="text text-danger">*</span></label>
                    <input type="text" className="form-control" name="issuanceTenure" defaultValue={formik.values.issuanceTenure} placeholder="" onChange={
                        e => initValueissuanceTenure(e)
                    } />
                    {formik.touched.issuanceTenure && formik.errors.issuanceTenure ? (
                        <div className="text text-danger" align="left">{formik.errors.issuanceTenure}</div>
                    ) : null}
                </div>

                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text">{(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? 'Intended Date of Issuance' : 'Intended Date of Escrow Signing'} <span className="text text-danger">*</span></label>
                    {/* <input type="date"  name="issuanceIntendedDate" className="form-control date-input" onKeyDown={(e) => e.preventDefault()} {...formik.getFieldProps('issuanceIntendedDate')}/> */}
                    <DatePicker className="form-control date-input" name="issuanceIntendedDate"
                        minDate={new Date(moment().tz(AppConstant.appTimeZone))}
                        format={'MM/dd/y'}
                        //  required={true}
                        onChange={(date) => handleIntDate('issuanceIntendedDate', date, 'required')}
                        onKeyDown={(e) => e.preventDefault()}
                        value={(formik.values.issuanceIntendedDate) ? AppConstant.selectDateFormat(formik.values.issuanceIntendedDate) //new Date("'"+formik.values.issuanceIntendedDate+"'")
                            : ''}
                    />
                    {formik.touched.issuanceIntendedDate && formik.errors.issuanceIntendedDate ? (
                        <div className="text text-danger" align="left">{formik.errors.issuanceIntendedDate}</div>
                    ) : null}
                </div>
                <div className="clear-both"></div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text">{(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? 'Number of Tranches' : 'Number of Accounts'} <span className="text text-danger">*</span></label>
                    <select className="form-control" id="sel" name="tranchesNumber" {...formik.getFieldProps('tranchesNumber')}>
                        <option readOnly defaultValue="" value=''>{(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? 'Select Tranches Number' : 'Select Number of Accounts'}</option>
                        <option value="single">{(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? 'Single' : 'One'}</option>
                        <option value="dual">{(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? 'Dual' : 'Two'}</option>
                        <option value="multiple">{(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? 'Multiple' : 'Three'}</option>
                    </select>
                    {formik.touched.tranchesNumber && formik.errors.tranchesNumber ? (
                        <div className="text text-danger" align="left">{formik.errors.tranchesNumber}</div>
                    ) : null}
                </div>
                {issuanceAmtDesign}
                <div className="clear-both"></div>
            </div>

            <div className="col-md-12 padding-0 margin-head-info radio-form-bg" style={{
                marginBottom: 0
            }}>
                {(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ? <>
                    <div className="form-row">
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text">Will this Issuance be rated?  <span className="text text-danger">*</span></label>
                            <div className="radio">
                                <label className="label-text-black radio-inline"><input type="radio" name="issuanceRated" onChange={formik.handleChange} checked={formik.values.issuanceRated === "yes"} value="yes" />Yes</label>

                                <label className="label-text-black radio-inline"><input type="radio" name="issuanceRated" onChange={formik.handleChange} checked={formik.values.issuanceRated === "no"} value="no" />No</label>
                            </div>
                            {formik.touched.issuanceRated && formik.errors.issuanceRated ? (
                                <div className="text text-danger" align="left">{formik.errors.issuanceRated}</div>
                            ) : null}
                        </div>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text">If Issuance will be rated, what will the rating be?</label>
                            <input type="text" name="ratedDescription" className="form-control" {...formik.getFieldProps('ratedDescription')} />
                            {formik.touched.ratedDescription && formik.errors.ratedDescription ? (
                                <div className="text text-danger" align="left">{formik.errors.ratedDescription}</div>
                            ) : null}
                        </div>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text">Is this a first-time Issuer?  <span className="text text-danger">*</span></label>
                            <div className="radio">
                                <label className="label-text-black radio-inline"><input type="radio" name="firstTimeIssuer" onChange={formik.handleChange} checked={formik.values.firstTimeIssuer === "yes"} value="yes" />Yes</label>
                                <label className="label-text-black radio-inline"><input type="radio" name="firstTimeIssuer" onChange={formik.handleChange} checked={formik.values.firstTimeIssuer === "no"} value="no" />No</label>
                            </div>
                            {formik.touched.firstTimeIssuer && formik.errors.firstTimeIssuer ? (
                                <div className="text text-danger" align="left">{formik.errors.firstTimeIssuer}</div>
                            ) : null}
                        </div>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text">Is this a Sovereign Issuer?  <span className="text text-danger">*</span></label>
                            <div className="radio">
                                <label className="label-text-black radio-inline"><input type="radio" name="sovereignIssuer" onChange={formik.handleChange} checked={formik.values.sovereignIssuer === "yes"} value="yes" />Yes</label>
                                <label className="label-text-black radio-inline"><input type="radio" name="sovereignIssuer" onChange={formik.handleChange} checked={formik.values.sovereignIssuer === "no"} value="no" />No</label>
                            </div>
                            {formik.touched.sovereignIssuer && formik.errors.sovereignIssuer ? (
                                <div className="text text-danger" align="left">{formik.errors.sovereignIssuer}</div>
                            ) : null}
                        </div>
                        {(formik.values.issuanceType != AppConstant.globalConst.ISSUANCETYPE_ID) ?
                            <div className="form-group form-group-margin col-md-4">
                                <label className="label-text">Is this Issuance part of an Exchange?  <span className="text text-danger">*</span>
                                    <p className="span-hash">(If yes, please complete Section Exchange Transaction below)</p>
                                </label>

                                <div className="radio">
                                    <label className="label-text-black radio-inline"><input type="radio" name="exchangeIssuer" onChange={e => exchnageIssr(e)} checked={formik.values.exchangeIssuer === "yes"} value="yes" />Yes</label>
                                    <label className="label-text-black radio-inline"><input type="radio" name="exchangeIssuer" onChange={e => exchnageIssr(e)} checked={formik.values.exchangeIssuer === "no"} value="no" />No</label>
                                </div>
                                {formik.touched.exchangeIssuer && formik.errors.exchangeIssuer ? (
                                    <div className="text text-danger" align="left">{formik.errors.exchangeIssuer}</div>
                                ) : null}
                            </div>
                            : ''}
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text">Is the Issuer a Publicly Traded Company?  <span className="text text-danger">*</span></label>
                            <div className="radio">
                                <label className="label-text-black radio-inline"><input type="radio" name="publiclyTradedCompany" onChange={formik.handleChange} checked={formik.values.publiclyTradedCompany === "yes"} value="yes" />Yes</label>
                                <label className="label-text-black radio-inline"><input type="radio" name="publiclyTradedCompany" onChange={formik.handleChange} checked={formik.values.publiclyTradedCompany === "no"} value="no" />No</label>
                            </div>
                            {formik.touched.publiclyTradedCompany && formik.errors.publiclyTradedCompany ? (
                                <div className="text text-danger" align="left">{formik.errors.publiclyTradedCompany}</div>
                            ) : null}
                        </div>
                    </div>
                </> : ''}
            </div>
            <div className="form-group form-group-margin col-md-12 padding-0 radio-form-bg" style={{
                marginTop: 0,
                paddingLeft: 0
            }}>
                <div className="form-row">
                    <div className="form-group form-group-margin col-md-4">
                        <label className="label-text">Governing Law</label>
                        <input type="text" name="governingLaw" className="form-control" {...formik.getFieldProps('governingLaw')} placeholder="eg. NY, Delaware, UK, etc." />
                        {formik.touched.governingLaw && formik.errors.governingLaw ? (
                            <div className="text text-danger" align="left">{formik.errors.governingLaw}</div>
                        ) : null}
                    </div>
                    {(formik.values.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE) ?
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text">Is this TIA qualified?</label>
                            <div className="radio">
                                <label className="label-text-black radio-inline"><input type="radio" name="tiaQualified" onChange={formik.handleChange} checked={formik.values.tiaQualified === "yes"} value="yes" />Yes</label>
                                <label className="label-text-black radio-inline"><input type="radio" name="tiaQualified" onChange={formik.handleChange} checked={formik.values.tiaQualified === "no"} value="no" />No</label>
                            </div>
                            {formik.touched.tiaQualified && formik.errors.tiaQualified ? (
                                <div className="text text-danger" align="left">{formik.errors.tiaQualified}</div>
                            ) : null}
                        </div>
                        : ''}
                </div>
            </div>

            <div className="form-row">
                <div className="form-group form-group-margin col-md-12">
                    <label className="label-text">Transaction Summary </label>
                    {/* <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable'],
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                            ]
                        }
                    }}
                    data={(formik.values.transactionSummary)?formik.values.transactionSummary:''}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor ) => {
                        const data = editor.getData();
                        // formik.values.transactionSummary = (data)?data:'';
                        props.setCustomsFormVal('transactionSummary',(data)?data:'');
                        // console.log( { event, editor, data } );
                    }}
                /> */}
                    <textarea name='transactionSummary' rows={6} className="form-control" {...formik.getFieldProps('transactionSummary')} >
                        {(formik.values.transactionSummary) ? formik.values.transactionSummary : ''}
                    </textarea>
                </div>
            </div>
        </>
    );
};


export default Step2;