import React,{useState,useEffect} from 'react';
import * as AppConstant from '@app/appConstants';

const Section8 = ({data}) => {
    const trusteeListDesign = data.trusteeList.length > 0
      && data.trusteeList.map((item, i) => {
          return(
           <li>
               {item.name}
           </li>   
          )
      });
    return (
        <>
        <div className="form-row">
            <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                <div className="main-title-head text-center">
                    <h1 className="h1-font-size-title">{(data.issuanceType._id == AppConstant.globalConst.CORPESCROWISSUANCEID)?"Escrow Agents":"Trustees"}</h1>
                </div>
            </div>
        </div>
        <div className="form-row col-md-12 form-group-margin details-row-left">
            <div className="col-md-6">
                <label className="label-text">{(data.issuanceType._id == AppConstant.globalConst.CORPESCROWISSUANCEID)?"Please select how many Escrow Agent quotes you require":"Please select how many Trustee quotes you require"}</label>
                <p className="paragraph-text">{(data.noOfTrustee)?data.noOfTrustee:'----'}</p>
            </div>
            <div className="col-md-6">
                <label className="label-text">{(data.issuanceType._id == AppConstant.globalConst.CORPESCROWISSUANCEID)?"Please select preferred Escrow Agents providers":"Please select preferred Trustee providers"}</label>
               <p className="paragraph-text"> <ul >{(trusteeListDesign)?trusteeListDesign:'----'}</ul></p>
            </div>
            </div>
        </>
    );
};


export default Section8;