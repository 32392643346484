import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as CrfpService from '@services/crfp';
import * as RfpService from '@services/rfp';
import * as AuthService from '@services/auth';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import dateFormat from "dateformat";
import Swal from 'sweetalert2';
import AssignUserModal from '../../../components/global/modal/AssignUserModal';

const QuoteHistory = ({ currentUser }) => {
  const { filtertype, rfpunique } = useParams();
  const [cfrOption, selectCfrOption] = useState('');
  const [savedData, setSavedData] = useState([]);
  const [profile, setProfile] = useState();
  const [userData, setUserData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const history = useHistory()

  const getProfile = async () => {
    if (currentUser['custom:id']) {
      try {
        const { userData } = await AuthService.GetProfile(currentUser['custom:id']);
        setProfile(userData);
        document.getElementById('preloader').style.display = "none";
      } catch (error) {
        document.getElementById('preloader').style.display = "none";
        // toast.error((error.message) ||'Failed');
      }
    }
  }
  const getSavedList = async (userId) => {
    if (userId) {
      const requestData = {
        userId: userId,
        type: "complete"
      };
      try {
        const data = await CrfpService.GetSavedLists(requestData);
        let draftList = (data.data.cfrData) ? data.data.cfrData : [];
        setSavedData(draftList);
      } catch (error) {
        // toast.error(error);
      }
    }
    document.getElementById('preloader').style.display = "none";
  }
  const getFilterSavedList = async (userId) => {
    if (userId) {
      const { rfpDetail } = await RfpService.GetRFPDetails(rfpunique);
      const requestData = {
        userId: userId,
        issuerName: rfpDetail.issuerName,
        issuanceType: rfpDetail.issuanceType._id,
        reqType: rfpDetail.reqType,
        corporateTrustService: rfpDetail.corporateTrustService,
        tranchesNumber: rfpDetail.tranchesNumber,
      };
      try {
        const data = await CrfpService.GetFilterCfrLists(requestData);
        let draftList = (data.data.cfrData) ? data.data.cfrData : [];
        setSavedData(draftList);
      } catch (error) {
        // toast.error(error);
      }
    }
    document.getElementById('preloader').style.display = "none";
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getProfile();
    if (filtertype == "import") {
      getFilterSavedList(currentUser['custom:id']);
    } else {
      getSavedList(currentUser['custom:id']);
    }
    document.getElementById('preloader').style.display = "block";
  }, [currentUser['custom:id']]);
  const confirmResponse = async (crfId, userID) => {
    try {
      const { message } = await CrfpService.changeConfirmStatus({ cfrId: crfId });
      toast.success("Your response has been confirmed.");
      getSavedList(userID);
    } catch (error) {
      // toast.error(error);
    }
    document.getElementById('preloader').style.display = "none";
  }
  const confirmRfp = (crfId, userID) => {
    let message = "You want to accept this response ?";
    // if(process.env.REACT_APP_SECOND_PLAN == profile.planId || process.env.REACT_APP_THIRD_PLAN == profile.planId){
    //     message = "The amount $25 will be deducted from your account.";
    // }
    Swal.fire({
      title: 'Are you sure ?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        confirmResponse(crfId, userID);
        document.getElementById('preloader').style.display = "block";
      }
    });
  }
  const handleSelectOption = () => {
    if (cfrOption) {
      toast.success('Imported successfully')
      history.push('/edit-cfr/' + cfrOption + "/import/" + rfpunique)
    }
  }
  const showUserList = async (row) => {
    document.getElementById('preloader').style.display = "block";
    const { cfrData } = await CrfpService.getUsersByCfp({ crfUserid: currentUser['custom:id'], rfpId: row.rfpId._id })
    setUserData(cfrData);
    setSelectedRow(row.rfpId);
    setUserModalOpen(true)
    document.getElementById('preloader').style.display = "none";
  }
  const handlePermissions = async (row, type) => {
    const view = document.getElementById('permission-view-' + row._id).checked
    const edit = document.getElementById('permission-edit-' + row._id).checked
    let updateCriteria = {
      userId: row._id,
      formType: "cfr",
      isEdit: edit,
      isView: view,
      formId: selectedRow._id
    }
    if (type == "edit") {
      document.getElementById('permission-view-' + row._id).checked = true
      document.getElementById('permission-edit-' + row._id).checked = (!edit) ? false : true
      updateCriteria.isView = true
      updateCriteria.isEdit = (!edit) ? false : true
    } else if (type == "view") {
      document.getElementById('permission-view-' + row._id).checked = (!view) ? false : true
      document.getElementById('permission-edit-' + row._id).checked = false
      updateCriteria.isView = (!view) ? false : true
      updateCriteria.isEdit = false
    }
    await AuthService.assignUserToForm(updateCriteria)
  }
  const columns = [
    {
      name: 'Transaction Id',
      sortable: true,
      width: "15%",
      cell: (row) => <>
        {(filtertype == "import" && row.isEdit) && <input type={"radio"} name='type' className="import-radio-btn" onClick={() => selectCfrOption(row.uniqueNo)} />}
        {row.isView && <Link to={(row.rfpId.reqType == 'municipality') ? '/view-municipal-rfp/' + row.rfpId.uniqueNo : '/view-rfp/' + row.rfpId.uniqueNo}>{row.uniqueNo ?? '...'}</Link>}
        {!row.isView && !row.isEdit && row.uniqueNo}
      </>
    },
    {
      name: 'Submitted Date',
      sortable: true,
      cell: (row) => (row.rfpId) ? dateFormat(row.rfpId.createdAt, "UTC:mmm d, yyyy") : ""
    },
    {
      name: 'Issuer Name',
      selector: row => (row.rfpId) ? row.rfpId.issuerName : '...',
      sortable: true,
    },
    {
      name: 'Owner',
      selector: row => ((row.rfpId) ? row.rfpId.firstName : '') + ' ' + ((row.rfpId) ? row.rfpId.lastName : ''),
      sortable: true,
    },
    {
      name: 'Transaction/Project Name',
      selector: row => (row.rfpId) ? row.rfpId.projectName : '...',
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (!row.status) ? <p>Awaiting Decision</p> : <p style={{ textTransform: 'capitalize' }}>{row.status.replace('_', ' ')}</p>,
      sortable: true,
      width: '16rem'
    },
    {
      name: 'RFP Type',
      selector: row => (row.rfpId) ? (row.rfpId.reqType == 'regular') ? 'Corp' : (row.rfpId.reqType == 'municipality') ? 'Muni' : '...' : '...',
      sortable: true,
      width: "8rem",
    },
    {
      name: 'Action',
      sortable: true,
      cell: (row) => <>
        {
          row.isEdit ?
            <button onClick={e => showUserList(row)} className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            : <button disabled className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
        }
        {
          row.isEdit && (row.status == 'confirm_acceptance') && <>
            <div style={{ paddingLeft: '5px' }}>
              <button onClick={e => confirmRfp(row._id, (row.userId) ? row.userId._id : '')} className="btn btn-success btn-sm" title="Confirm">
                <i className="fa fa-check" />
              </button>
            </div>
          </>
        }
        {
          row.isView && <>
            <div style={{ paddingLeft: '5px' }}>
              <Link to={'/cfr-detail/' + row.uniqueNo} className="btn btn-primary btn-sm" title="View Response">
                <i style={{ color: 'white' }} className="fa fa-eye" />
              </Link>
            </div>
          </>
        }
        {
          !row.isView && !row.isEdit && <>
            {(row.status == 'confirm_acceptance') ?
              <div style={{ paddingLeft: '5px' }}>
                <button disabled className="btn btn-success btn-sm" title="Confirm">
                  <i className="fa fa-check" />
                </button>
              </div>
              : ''}
            <div style={{ paddingLeft: '5px' }}>
              <button disabled className="btn btn-primary btn-sm" title="View Response">
                <i style={{ color: 'white' }} className="fa fa-eye" />
              </button>
            </div>
          </>
        }
      </>
    }
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '16px',
        minHeight: '50px'
      }
    },
    //heading row style
    headRow: {
      style: {
        backgroundColor: '#f5f6fa',
        marginTop: '20px'
      }
    },
    cells: {
      style: {
        padding: '10px', // override the cell padding for data cells
        paddingRight: '8px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      }
    }
  };
  const userColumns = [
    {
      name: 'Name',
      cell: (row) => (row.firstName + " " + row.lastName)
    },
    {
      name: 'Email',
      cell: (row) => <p style={{ textTransform: 'none' }}>{(row.email) ? row.email : "N/A"}</p>
    },
    {
      name: 'Permissions',
      cell: (row) => <>
        <label>
          <input type="checkbox" name="remember" onClick={() => handlePermissions(row, 'view')} defaultChecked={(row.assignIsView) ? row.assignIsView : false} className="remember-box" value="" id={'permission-view-' + row._id} />
          <span> View</span>
        </label>
        <label style={{ marginLeft: "1em" }}>
          <input type="checkbox" name="remember" onClick={() => handlePermissions(row, 'edit')} defaultChecked={(row.assignIsEdit) ? row.assignIsEdit : false} className="remember-box" value="" id={'permission-edit-' + row._id} />
          <span> Edit</span>
        </label>
      </>
    }
  ];
  return (
    <>
      <section>
        <div className="auto-container">
          <h1 className="h1-text text-black">Previous Quote List</h1>
        </div>
      </section>
      <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top' }}>
        <div className="auto-container">
          {
            (filtertype == "import") ? <>
              <div className="form-column col-md-12 col-sm-12 col-xs-12 text-align-left">
                <button type="button" class="btn btn-primary btn-style-submit res-btn import-btn-style" onClick={() => handleSelectOption()}>Import</button>
                <p className='text text-secondary'> Dates and attachments will not imported</p>
              </div>
            </> : null
          }
          <div className="row clearfix">
            <div className="form-column col-md-12 col-sm-12 col-xs-12">
              {
                (userModalOpen) &&
                <AssignUserModal
                  title={"Assign Permission To Users"}
                  columns={userColumns}
                  data={userData}
                  handleConfirmClose={() => setUserModalOpen(!userModalOpen)}
                />
              }
              <DataTable
                columns={columns}
                data={savedData}
                pagination
                customStyles={customStyles}
                responsive={true}
              />
            </div>
            <div className="col-md-12 clr-both">
              <div className="col-md-12 p-0" align="left">
                <Link to={'/corporate'} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser
});
export default connect(mapStateToProps)(QuoteHistory);