import React, { useEffect, useState } from 'react';
import * as PaymentService from '@services/payment';
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
const moment = require('moment-timezone');
require("dotenv").config();

const ManageSubscription = (props) => {
    
    const [manageDetail,setManageDetail] = useState();
    const [currentUsers,setCurrentUsers] = useState();
    const history = useHistory();
    const getCardById = async (userId) => {
        try {
            const cardDtl = await PaymentService.getSubscriptionData(userId);
            console.log(cardDtl,"detail")
            setManageDetail((cardDtl)?cardDtl:'');
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     'Failed'
            // );
        }
    }


    const getCurrentUsers = async () => {
        try {   
            const {userData} = await AuthService.GetProfile(props.currentUser['custom:id']);
            setCurrentUsers(userData);
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     'Failed'
            // );
        }
    }


    const cancelSubscription = async (values) => {
        try {
            const cancelSubs = await PaymentService.cancelSubscriptionById(values);
            if (cancelSubs) {
                if(cancelSubs.instantCancel){
                   const user = await Auth.currentAuthenticatedUser();
                   props.onUserDetail(user.attributes);
                   // update cognito
                   const {userData} = await AuthService.GetProfile(user.attributes['custom:id']);
                   const {roleData} = await AuthService.GetRoleById(user.attributes['custom:user_type']);
                   await Auth.updateUserAttributes(user, {
                       'custom:userrole': roleData.slug,
                       'custom:isSubscribed': '0',
                       'custom:expiryDate': (userData.expiryDate)?userData.expiryDate:'',
                   });
                   history.push('/subscription/'+window.btoa(values));
                }else{
                   history.push('/payment-response/cancelsubs');
                }
                toast.success('Your subscription has been cancelled.');
            }
            getCurrentUsers();
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     'Failed'
            // );
        }
    }


    const handleCancelSubscription = (UID) => {
        Swal.fire({
            title: 'Are you sure ?',
            text: "You want to cancel subscription ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          }).then(async (result) => {
            if (result.isConfirmed) {
                await cancelSubscription(UID);
            }else{
            }
          });
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.currentUser['custom:id']){
        console.log(props.currentUser['custom:id'],"uId")
        getCardById(props.currentUser['custom:id']);
        getCurrentUsers();
        }
        document.getElementById('preloader').style.display = "none";
    }, [props.currentUser['custom:id']]);


    const customStyles = {
        rows: {
          style: {
            minHeight: '72px', // override the row height
          }
        },
        headCells: {
          style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            fontSize: '16px',
            minHeight: '50px'
          },
        },
        //heading row style
    headRow: {
      style: {
        backgroundColor: '#f5f6fa',
        marginTop:'20px',
      }
    },
    
        cells: {
          style: {
            padding: '10px', // override the cell padding for data cells
            paddingRight: '8px',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
          },
        },
      };


    const columns = [
        {
            name: 'Date',
            sortable: true,
            cell: (row) => (row.created)?moment.unix(row.created).format("MM/DD/YYYY"):"",
            width: '15rem'
        },
        {
            name: 'Service Period',
            selector: row => (row.lines)?(row.lines.data)?`${(row.lines.data[0])?moment.unix(row.lines.data[0].period.start).format("MM/DD/YYYY"):'...'} - ${(row.lines.data[0])?moment.unix(row.lines.data[0].period.end).format("MM/DD/YYYY"):'...'}`:'...':'...' ,
            sortable: true,
        },
        {
            name: 'Total',
            selector: row => (row.total)?'$'+(row.total / 100):'$0',
            sortable: true,
        },
        {
          name: 'Action',
          selector: (row) => <><div className='col-md-6' style={{paddingLeft: '0'}}><button onClick={() => window.open((row.hosted_invoice_url)?row.hosted_invoice_url:'', "_blank")} className="btn btn-primary btn-sm" title="View Invoice"><i className="fa fa-file"/></button></div><div className='col-md-6' style={{paddingLeft: '0'}}><button onClick={() => window.open((row.invoice_pdf)?row.invoice_pdf:'', "_blank")} target="_blank" download className="btn btn-success btn-sm" title="Download Invoice"><i className="fa fa-download"/></button></div></>,
          sortable: true,
          width: '15rem'
        }
    ];


    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                <div className="auto-container">
                    <div className="row clearfix row-container-margin-width" style={{background: 'white'}}>
                                <div className="col-md-12 unset-padding padding-edit-profile" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/manage-sub.png)", backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'left bottom' }}>
                                    <div className="Inside">
                                        <h1 className="text-black h1-edit-profile">Manage Subscription</h1>
                                    </div>
                                    <div className='row clearfix row-container-margin-width'>
                                    <div className='col-md-12'>
                                    <div className="form-row">
                                        <div className="form-group col-md-12" style={{paddingTop: "8px", marginBottom: '0px', paddingLeft: '0px', paddingRight: '0px'}}>
                                            <div className="label-color text-left col-md-9" style={{paddingRight: '0', paddingLeft: '0'}}>
                                                <h4 style={{fontWeight: '800', color: 'black'}}>Active Plan</h4>
                                                <h1 className="label-color text-left" style={{ fontSize:"50px !important"}}>
                                                    <b>{(manageDetail)?(manageDetail.planName)?manageDetail.planName:'----':'----'}</b>
                                                </h1>
                                                {(manageDetail)?(manageDetail.nextPlan)?<>
                                                            <h1 className="label-color text-left" style={{ fontSize:"50px !important"}}>
                                                                <b>{manageDetail.nextPlan}</b>
                                                            </h1></>:'':''}
                                                {/* <Link to={'/update-subscription'}><h5 className='mt-4' style={{fontWeight: '800', color: 'red',cursor:'pointer'}}>Change Plan</h5></Link> */}
                                            </div>


                                            <div className="label-color text-align-end col-md-3" style={{paddingRight: '0', paddingLeft: '0'}}>
                                                <h4 style={{fontWeight: '800', color: 'black'}}>Next Billing Date</h4>
                                                <h1 className="label-color text-align-end" style={{ fontSize:"50px !important"}}>
                                                    <b>{(manageDetail)?(manageDetail.nextBillingDate)?manageDetail.nextBillingDate:'----':'----'}</b>
                                                </h1>
                                            {/* {(currentUsers && currentUsers.isCancelled)?'':<h5 onClick={()=>handleCancelSubscription(props.currentUser['custom:id'])} className='mt-4' style={{fontWeight: '800', color: 'red',cursor:'pointer'}}>Cancel Subscription</h5>} */}
                                            </div>
                                            <div className="label-color text-left col-md-9" style={{paddingRight: '0', paddingLeft: '0'}}>
                                                <Link to={'/update-subscription'}><h5 className='mt-4' style={{fontWeight: '800', color: 'red',cursor:'pointer'}}>Change Plan</h5></Link>

                                            </div>
                                            <div className="label-color text-align-end col-md-3" style={{paddingRight: '0', paddingLeft: '0'}}>
                                            {(currentUsers && currentUsers.isCancelled)?'':<h5 onClick={()=>handleCancelSubscription(props.currentUser['custom:id'])} className='mt-4' style={{fontWeight: '800', color: 'red',cursor:'pointer'}}>Cancel Subscription</h5>}

                                            </div>
                                        </div>
                                        <hr style={{
                                            border:"none",
                                            borderTop:"1px dashed rgb(204, 204, 204)",
                                            backgroundColor:"#fff",
                                            height:"1px",
                                            width:"100%"
                                        }}/>
                                    </div>
                                    <div className="Inside">
                                        <h4 className="text-black text-left" style={{fontWeight: '800', color: 'black'}}>Billing History</h4>
                                    </div>
                                    <div className="default-form contact-form form-margin text-left">
                                            <div className="form-row ">
                                                <div className="form-group col-md-12" style={{padding: '0'}}>
                                                <DataTable
                                                    columns={columns} 
                                                    data={(manageDetail)?manageDetail.invoicesList:[]}
                                                    pagination
                                                    paginationComponentOptions={{noRowsPerPage: true}} 
                                                    customStyles={customStyles}
                                                    responsive={true}
                                                />
                                                </div>
                                            </div>

                                    </div>
                                    </div>
                                    </div>
                                </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscription);