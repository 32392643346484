import React, { useState, useEffect } from 'react';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step7 from './step7';
import Step8 from './step8';
import Step9 from './step9';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as RfpService from '@services/rfp';
import * as AppConstant from '@app/appConstants';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import '../../user-detail/trustee-style.css'
import Swal from 'sweetalert2';
import { values } from 'lodash';



const RFP = (props) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [getSubmit, setGetSubmit] = useState(false);
    const [section5, setSection5] = useState(false);
    const history = useHistory();
    const [userID, setUserId] = useState('');
    const [userOrg, setUserOrg] = useState('');
    const [finishStatus, setfinishStatus] = useState(false);
    const [additionalEmailMsg, setAdditionalEmailMsg] = useState('');
    const [customErr, setCustomErr] = useState('');

    // File Uploads
    const fileUploading = async (request, response, reqType) => {
        const checkSheetFile = (request.termSheet) ? request.termSheet : "";

        let fileData = new FormData();
        fileData.append('termSheet', request.termSheet);
        const checkAdditionalDoc = (request.additionalDocuments) ? request.additionalDocuments : [];
        for (const key of Object.keys(request.additionalDocuments)) {
            fileData.append('additionalDocuments', request.additionalDocuments[key])
        }
        fileData.append("id", response._id);
        fileData.append("uniqueNo", response.uniqueNo);
        fileData.append("userId", response.userId);

        try {
            const data = await RfpService.uploadDocuments(fileData);
            toast.success("Your request has been " + reqType);
            formik.resetForm();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.responseType && error.response.data.responseType == 'INVALID_USER_ACCESS') {
                setAdditionalEmailMsg(error.response.data.message);
            }
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    /**
     * New Rfp Api
     */
    const addNewRfp = async (request) => {
        try {
            request.userId = userID;
            formik.values.userOrganisation = userOrg;
            const { data } = await RfpService.NewRfp(request);
            if (data && data.saveRfpData) {
                fileUploading(request, data.saveRfpData, 'submitted')
            } else {
                toast.success('Your requested has been submitted.');
                formik.resetForm();
            }

            setIsSubmit(false)
            history.push('/previous-list');
        } catch (error) {

            setIsSubmit(false)
            if (error.response && error.response.data && error.response.data.responseType && error.response.data.responseType == 'INVALID_USER_ACCESS') {
                setAdditionalEmailMsg(error.response.data.message);
            }
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    useEffect(() => {
        setUserId(props.currentUser['custom:id']);
        setUserOrg(props.currentUser['custom:organization']);
    }, [props.currentUser['custom:id']])


    const onBackButtonEvent = (e) => {
        e.preventDefault();
        setIsSubmit(true)
        if (!finishStatus) {
            // if (window.confirm("Are you sure you want to exit without saving?")) {
            //     setfinishStatus(true)
            //     history.push('/trustee');
            // } else {
            //     window.history.pushState(null, null, window.location.pathname);
            //     setfinishStatus(false)
            // }

            Swal.fire({
                title: 'Are you sure ?',
                text: "You want to exit without saving ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setfinishStatus(true)
                    setIsSubmit(false)
                    history.push('/trustee');
                } else {
                    window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'trustee');
                    setIsSubmit(false)
                    setfinishStatus(false)
                }
            });
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'trustee');
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);




    const formik = useFormik({
        initialValues: {
            userId: '',
            userOrganisation: '',
            institution: '',
            formType: 'complete',
            firstName: '',
            lastName: '',
            email: '',
            workTel: '',
            cellNo: '',
            additionalFirstName: '',
            additionalLastName: '',
            additionalEmail: '',
            additionalWorkTel: '',
            additionalCellNo: '',
            issuerName: '',
            projectName: '',
            corporateTrustService: '',
            issuanceType: '',
            issuanceTenure: '',
            issuanceAmount: [],
            tranchesNumber: '',
            issuanceIntendedDate: '',
            firstTimeIssuer: '',
            sovereignIssuer: '',
            exchangeIssuer: '',
            publiclyTradedCompany: '',
            issuanceRated: '',
            ratedDescription: '',
            transactionSummary: '',
            termSheet: '',
            additionalDocuments: '',
            additionalServices: '',
            additionalServicesDescription: '',
            cusipExchange: [],
            offerServices: '',
            expirationServices: '',
            asop: '',
            agentFirstName: '',
            agentLastName: '',
            agentStreetAddress: '',
            agentAddressLine: '',
            agentCity: '',
            agentState: '',
            agentZip: '',
            agentPhoneNo: '',
            agentCountry: '',
            agentEmail: '',
            legalFee: '',
            legalType: '',
            additionalLegalFee: '',
            noOfTrustee: '',
            trusteeList: '',
            submissionDate: '',
            governingLaw: '',
            tiaQualified: ''
        },
        validateOnChange: true,
        validateOnBlur: false,
        validationSchema: Yup.object({
            institution: Yup.string()
                .required('* Required'),
            firstName: Yup.string()
                .required('* Required'),
            lastName: Yup.string()
                .required('* Required'),
            email: Yup.string()
                .email('Invalid email address')
                // .matches(new RegExp('[a-z0-9]+@[a-z]+\.edu\.[a-z]{2,3}'), "Email is not valid")
                .required('* Required'),
            workTel: Yup.string()
                .required('* Required')
                .min(12, 'Minimum 10 digits'),
            cellNo: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalWorkTel: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalCellNo: Yup.string()
                .min(12, 'Minimum 10 digits'),
            issuerName: Yup.string()
                .required('* Required'),
            additionalEmail: Yup.string()
                .email('Invalid email address'),
            // agentEmail : Yup.string()
            //     .email('Invalid email address'),
            corporateTrustService: Yup.string()
                .required('* Required'),
            issuanceType: Yup.string()
                .required('* Required'),
            issuanceTenure: Yup.string()
                .required('* Required'),
            issuanceAmount: Yup.array()
                .required('* Required'),
            tranchesNumber: Yup.string()
                .required('* Required'),
            issuanceIntendedDate: Yup.string()
                .required('* Required'),
            firstTimeIssuer: Yup.string()
                .when('corporateTrustService', {
                    is: (corporateTrustService) => corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE,
                    then: Yup.string()
                        .required('* Required')
                }),
            sovereignIssuer: Yup.string()
                .when('corporateTrustService', {
                    is: (corporateTrustService) => corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE,
                    then: Yup.string()
                        .required('* Required')
                }),
            exchangeIssuer: Yup.string()
                .when(['issuanceType', 'corporateTrustService'], {
                    is: (issuanceType, corporateTrustService) => issuanceType != AppConstant.globalConst.ISSUANCETYPE_ID && corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE,
                    then: Yup.string()
                        .required('* Required')
                }),
            publiclyTradedCompany: Yup.string()
                .when('corporateTrustService', {
                    is: (corporateTrustService) => corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE,
                    then: Yup.string()
                        .required('* Required')
                }),
            issuanceRated: Yup.string()
                .when('corporateTrustService', {
                    is: (corporateTrustService) => corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE,
                    then: Yup.string()
                        .required('* Required')
                }),
            additionalServices: Yup.string()
                .required('* Required'),
            legalFee: Yup.string()
                .required('* Required'),
            legalType: Yup.string()
                .when('legalFee', {
                    is: 'yes',
                    then: Yup.string()
                        .required('* Required')
                }),
            noOfTrustee: Yup.string()
                .required('* Required'),
            trusteeList: Yup.string()
                .required('* Required')
                .test('oneOfRequired', 'Providers should match the selected number of trustee quotes.',
                    function () {
                        const providerCount = formik.values.trusteeList.toString().split(",").length;
                        if (formik.values.noOfTrustee != providerCount) {
                            return false
                        } else {
                            return true
                        }
                    }
                ),
            submissionDate: Yup.string()
                .required('* Required'),
        }),
        onSubmit: (values) => {
            setIsSubmit(true)
            setCustomErr("");
            values.asop = (values.asop) ? values.asop[0] : ''
            values.formType = 'complete'
            addNewRfp(values);
        }
    });

    useEffect(() => {
        formik.setErrors({
            "institution": "",
            "firstName": "",
            "lastName": "",
            "email": "",
            "workTel": "",
            "issuerName": "",
            "corporateTrustService": "",
            "issuanceType": "",
            "issuanceTenure": "",
            "tranchesNumber": "",
            "issuanceIntendedDate": "",
            "firstTimeIssuer": "",
            "sovereignIssuer": "",
            "exchangeIssuer": "",
            "publiclyTradedCompany": "",
            "issuanceRated": "",
            "additionalServices": "",
            "legalFee": "",
            "noOfTrustee": "",
            "trusteeList": "",
            "submissionDate": ""
        })

    }, [])



    const focusValidations = (errors) => {
        for (var i in errors) {
            if (i == 'additionalServices') {
                document.getElementsByClassName('additionalCls')[0].focus()
            } else if (i == 'trusteeList') {
                document.getElementsByClassName('trustListCls')[0].focus()
            } else {
                document.getElementsByName(i)[0].focus()
            }
            break;
        }
    }

    const onDraft = async () => {

        setIsSubmit(true)
        formik.values.formType = 'draft'
        formik.values.asop = (formik.values.asop) ? formik.values.asop[0] : ''
        let draftData = formik.values;
        if (!draftData.issuerName) {

            setIsSubmit(false)
            // formik.errors.issuerName = "Issuer Name is required";
            for (var i in formik.errors) {
                await formik.setFieldError(i, '')
                await formik.setFieldTouched(i, false)
            };
            await formik.setFieldError('issuerName', "* Required")
            await formik.setFieldTouched('issuerName', true)
            setTimeout(() => {
                document.getElementsByName('issuerName')[0].focus()
                // focusValidations(formik.errors)
            }, 1000)

            //    toast.error("Issuer Name is required");
        } else {
            try {
                formik.values.userId = userID;
                formik.values.userOrganisation = userOrg;
                const { data } = await RfpService.NewRfp(formik.values);
                if (data && data.saveRfpData) {
                    fileUploading(draftData, data.saveRfpData, 'drafted')
                } else {
                    toast.success('Your requested has been drafted.');
                }
                setIsSubmit(false)
                history.push('/saved-list');
            } catch (error) {

                setIsSubmit(false)
                if (error.response && error.response.data && error.response.data.responseType && error.response.data.responseType == 'INVALID_USER_ACCESS') {
                    setAdditionalEmailMsg(error.response.data.message);
                }
                // toast.error(
                //     (error.response &&
                //         error.response.data &&
                //         error.response.data.message) ||
                //         'Something went wrong'
                // );
            }
        }
    }

    const setCustomsFormVal = (key, value, type = '') => {
        formik.setFieldValue(key, value)
        if (value) {
            setTimeout(() => {
                formik.setFieldError(key, '')
            }, 1000)
        }
        if (!value && type == 'required') {
            setTimeout(async () => {
                await formik.setFieldError(key, "* Required")
                await formik.setFieldTouched(key, true)
            }, 1000)
        }
    }

    // console.log("formik",formik)

    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundPosition: 'top center' }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="form-column col-md-12 col-sm-12 col-xs-12">
                            <h1 className="h1-text text-black">INDENTURE TRUSTEE / ESCROW AGENT RFP FORM</h1>
                        </div>
                        <div className="form-column col-md-12 col-sm-12 col-xs-12 text-align-right">
                            <Link to={"/previous-list/import/rfp"} class="btn btn-primary btn-style-submit res-btn import-btn-style" title="Import details from previously submitted RFP's">
                                Import
                            </Link>
                        </div>
                        <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5">
                            <div className="col-md-12 main-form-outside bg-white">
                                <div className="main-title-head">
                                    <h1 className="h1-font-size-title">Your information</h1>
                                </div>
                                <form onSubmit={formik.handleSubmit} align="left">
                                    <Step1 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} additionalEmailMsg={additionalEmailMsg} />
                                    <Step2 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} customErr={customErr} />
                                    <Step3 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />
                                    <Step4 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />
                                    {(formik.values.issuanceType == AppConstant.globalConst.ISSUANCETYPE_ID || formik.values.exchangeIssuer == 'no') ? '' : <Step5 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />}
                                    <Step7 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />
                                    <Step8 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />
                                    <Step9 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />
                                    <div className="form-row" align="right">
                                        <div className="col-md-12 clr-both p-12 btn-margin-top-bottom">
                                            <button type="button" onClick={(e) => onBackButtonEvent(e)} disabled={isSubmit} className="btn btn-primary btn-style-submit btn-back-style res-btn"><i className="fa fa-arrow-left"></i> Back</button>
                                            <button type="button" onClick={e => onDraft()} disabled={isSubmit} className="btn btn-success btn-style-submit rfp-button res-btn m-0">Save</button>
                                            <button type="submit" onClick={e =>
                                                focusValidations(formik.errors)
                                            } disabled={isSubmit} className="btn btn-success btn-style-submit res-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section>
        <div className="auto-container">
        <div className="col-md-12" style={{zIndex: '999999'}}>
        <div className="col-md-12 p-0" align="left"><button onClick={() => history.goBack()} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</button></div>
        </div>
        </div>
    </section>
    <section className="contact-section" style={{
        'padding': '45px 0px 70px'
    }}>
    	<div className="auto-container">
        	<div className="row clearfix">
                <div className="form-column col-md-12 col-sm-12 col-xs-12">
                <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Your information</h2></div></div>
                    <div className="default-form contact-form">
                        <form onSubmit={formik.handleSubmit} align="left">
                            <Step1 formik={formik} loggedUserDetail={props.currentUser}/>
                            <Step2 formik={formik} loggedUserDetail={props.currentUser}/>
                            <Step3 formik={formik} loggedUserDetail={props.currentUser}/>
                            <Step4 formik={formik} loggedUserDetail={props.currentUser}/>
                            {(formik.values.issuanceType == AppConstant.globalConst.ISSUANCETYPE_ID || formik.values.exchangeIssuer == 'no') ? '' : <Step5 formik={formik} loggedUserDetail={props.currentUser}/>}
                            <Step7 formik={formik} loggedUserDetail={props.currentUser}/>
                            <Step8 formik={formik} loggedUserDetail={props.currentUser}/>
                            <Step9 formik={formik} loggedUserDetail={props.currentUser}/>
                            <div className="form-row" align="right">
                                <div className="col-md-12">
                                <button type="button" onClick={e=>onDraft()} className="btn btn-primary rfp-button">Save Progress</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>            
                </div>
            </div>
        </div>
    </section> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});


export default connect(mapStateToProps)(RFP);

