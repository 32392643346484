import React from "react";
import Footer from '@components/global/footer/Footer';
import MainMenu from '@components/global/header/MainMenu';
import * as ActionTypes from '@store/actions';
import {connect} from 'react-redux';

const Layout = (props) => (
  <>
        {
          (props.mainMenu == "show") ?
          <MainMenu />:""
        }
        {props.children}
        {
          (props.footer == "show") ?
          <Footer />:""
        }
  </>
);


const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogin: (token) => dispatch({
      type: ActionTypes.LOGIN_USER, token
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);