import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import './additional.css';
import * as ActionTypes from '@store/actions';

const AdditionalService = ({ props }) => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Additional Services</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Additional Services</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue" style={{ paddingBottom: "20px" }}>
                <div className="auto-container">
                    <div style={{ padding: "20px" }}>
                        <p>MyTrustMe.com was created to focus on Debt Capital Markets transactions, but we recognize that many DCM transactions require additional services or are part of larger Project Finance transactions. We can service any Corporate Trust & Agency transaction, including;</p>
                    </div>
                    <div className="row clearfix">
                        <div className="col-md-4">
                            <div className="box first-bx">
                                <div className="card two_col text-left">
                                    <div className="card-body">
                                        <h3 className="p-b-1">Find an Escrow Agent</h3>
                                        <p>
                                            An escrow agent is a neutral third party who protects your money while a transaction is finalized or a disagreement is resolved.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="box first-bx">
                                <div className="card two_col text-left">
                                    <div className="card-body">
                                        <h3 className="p-b-1">Find an Agent for Service of Process</h3>
                                        <p>
                                            An agent for service of process receives lawsuits and other documents on behalf of your business. Depending on your state, the agent might also be called a “registered agent” or a “statutory agent.”
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box first-bx">
                                <div className="card two_col text-left">
                                    <div className="card-body">
                                        <h3 className="p-b-1">Project Finance Administration Services  </h3>
                                        <p>
                                            Request for Proposal from Project Finance administration specialists including trustee and/or agent, monitoring of the terms of financing agreements, hold and safekeep collateral for the benefit of secured creditors, monitor transaction reporting and covenant compliance requirements, communicate with investors, maintain project accounts and administer cash flows.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue" style={{ paddingBottom: "20px" }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-xl-offset-2 col-lg-offset-2 col-md-offset-0 col-sm-offset-0 col-xs-offset-0">
                            <div className="box first-bx">
                                <div className="card two_col text-left">
                                    <div className="card-body">
                                        <h3 className="p-b-1">Arbitrage Rebate Services</h3>
                                        <p>
                                            Arbitrage is any interest or value earned over the declared yield of a tax-exempt bond issue as defined by Internal Revenue Code Section 103 and Section 148. MyTrustMe.com can connect you with experienced professionals that can determine the amount, if any, that must be rebated (paid) to the Federal Government. Even if your issue does not earn arbitrage, the calculation is required at least every five years over the life of the issue.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="box first-bx">
                                <div className="card two_col text-left">
                                    <div className="card-body">
                                        <h3 className="p-b-1">Verification Agent</h3>
                                        <p>
                                            A verification agent is a company that checks the accuracy of a debt capital borrower's origination data and reporting. The scale and complexity of a verification agent's responsibility vary significantly by debt capital transaction and asset-level data availability. MyTrustMe.com allows transactors to select a Verification Agent while streamlining the process and saving you time and money.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalService);