import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as AuthService from '@services/auth';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';

const Step4 = (props) => {
    let formik = props.formik;
    const [additional, setAdditional] = useState([]);
    const [additionalSelect, setAdditionalSelect] = useState();
    const [inputList, setInputList] = useState([{ additionalServices: "" }]);

    const getAdditionalService = async () => {
        try {
            const data = await AuthService.GetAdditionalService(1);
            let typeList = (data.data.additionalService) ? data.data.additionalService : [];
            setAdditional(typeList);
        } catch (error) {
            //  toast.error(error);
        }
    }

    useEffect(() => {
        getAdditionalService();
        setInputList((formik.values.additionalServices.length != 0) ? formik.values.additionalServices : [{ additionalServices: "" }]);
    }, []);
    useEffect(() => {
        setInputList((formik.values.additionalServices.length != 0) ? formik.values.additionalServices : [{ additionalServices: "" }]);
    }, [formik.values.additionalServices])
    const handleAdditionalOnchange = val => {
        const updateAdditional = val;
        setAdditionalSelect(updateAdditional);
        //   if(props.formType == "edit"){
        //     props.additionalServices(val)
        //   }
        //   props.formik.values.additionalServices = (updateAdditional)?updateAdditional:'';
        props.setCustomsFormVal('additionalServices', (updateAdditional) ? updateAdditional : '', 'required');
    }

    const additionalList = [];
    const additionalDesign = additional.length > 0
        && additional.map((item, i) => {
            return (
                additionalList.push({ label: (item) ? item.name : '', value: (item) ? item._id : '' })
            );
        });

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        // props.formik.values.cusipExchange = list ?? []
        props.setCustomsFormVal('additionalServices', list ?? []);
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        // props.formik.values.cusipExchange = list ?? []
        props.setCustomsFormVal('additionalServices', list ?? []);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { additionalServices: "" }]);
    };

    const cusipDesign = inputList.map((x, i) => {
        return (
            <>
                <div className="col-md-12">
                    {/* <div className="form-group form-group-margin col-md-3">
                      <select className="form-control"  name="cusipType" value={x.cusipType} onChange={e => handleInputChange(e, i)}>
                            <option readOnly defaultValue="">CUSIP/ISIN</option>
                            <option value="cusip">CUSIP</option>
                            <option value="isin">ISIN</option>
                        </select>
                      </div> */}
                    <div className="form-group form-group-margin col-md-6  p-0">
                        {/* <input type="text" className="form-control" name="cusipExchange" /> */}
                        <input type="text" name="additionalServices" className="form-control" placeholder="Please specify other additional service here" value={(x.additionalServices) ? x.additionalServices : ''} onChange={e => handleInputChange(e, i)} />

                    </div>
                    <div className="col-md-4">
                        {inputList.length !== 1 && <button type="button"
                            className="btn btn-danger " style={{ width: '50px', padding: '10px 12px', marginRight: '15px', marginTop: '10px' }}
                            onClick={() => handleRemoveClick(i)}><i className='fa fa-minus'></i></button>}
                        {inputList.length - 1 === i && <button type="button" style={{ width: '50px', padding: '10px 12px', marginTop: '10px' }} className="btn btn-success btn-class-add-remove" onClick={handleAddClick}><i className='fa fa-plus'></i></button>}
                    </div>
                </div>
            </>
        );
    })

    return (
        <>
            <div className="form-row">
                <div className="col-md-12 padding-0 margin-head-info">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Additional Services</h1>
                    </div>
                </div>
            </div>
            {/* <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Additional Services</h2></div></div> */}
            <div className="form-row">
                <div className="form-group form-group-margin">
                    <div className=" col-md-6">
                        <label className="label-text">Additional service</label>
                    </div>

                    {cusipDesign}
                    {formik.touched.additionalServices && formik.errors.additionalServices ? (
                        <div className="text text-danger" align="left">{formik.errors.additionalServices}</div>
                    ) : null}
                </div>

            </div>
        </>
    );
};


export default Step4;