import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import * as CurrencyJson from '@app/utils/currency' ;
import {toast} from 'react-toastify';

const Step4 = ({formik,setCustomsFormVal}) => {

    const initValue = (e) => {
        if('/^\d+$/',/^\d+$/.test(e.target.value.replaceAll(',',''))){
            // formik.values.legalAmount = e.target.value.replaceAll(',','')
            const legalFeeAmt = e.target.value.replaceAll(',','')
            setCustomsFormVal('legalAmount',legalFeeAmt)
            e.target.value =  legalFeeAmt.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")    
        }else{
            e.target.value = "";
            // formik.values.legalAmount = ''
            setCustomsFormVal('legalAmount','')
            // toast.error("Value must be a number");
        }
        // e.target.value =  e.target.value.replaceAll(',','').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        // formik.values.legalAmount = e.target.value.replaceAll(',','');
    }

    const CurrencyDesign = CurrencyJson.default.length > 0
    && CurrencyJson.default.map((item, i) => {
        return(
            <option value={item.symbol}>{item.code}</option>
        );
    });

    const currType = (e) => {
          if(e.target.value){
            // formik.values.legalCurrencyType = e.target.value;
            setCustomsFormVal('legalCurrencyType',e.target.value);
          }else{
            // formik.values.legalCurrencyType = '$';
            setCustomsFormVal('legalCurrencyType','$')
          }
    }

    return (
        <>
        {/* <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Legal Fees</h2></div></div> */}
        <div className="form-row">
            <div className="col-md-12 padding-0 margin-head-info">
                <div className="main-title-head text-center">
                    <h1 className="h1-font-size-title">Legal Fees</h1>
                </div>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6" >
            <label className="label-text">Legal Fee Type</label>
            <input type="text" style={{textTransform: 'capitalize'}}  disabled className="form-control" value={formik.values.legalType} />
            {/* <p style={{textTransform: 'capitalize'}}>{formik.values.legalType}</p> */}
            </div>
            <div className="form-group col-md-6 col-sm-6">
            <label className="label-text">Legal Fee Amount </label>
            <div className="form-row">
                <div className="col-md-4 p-0">
                    <select className="form-control border-radius-input-left" name="legalCurrencyType"  onChange={e=>currType(e)} defaultValue={formik.values.legalCurrencyType}>
                        {CurrencyDesign}
                    </select>
                </div>
                <div className="col-md-8 p-0">
                    <input type="text" min="0" step="any" name="legalAmount" className="form-control border-radius-input-right" onChange={
                        e => initValue(e)
                    } defaultValue={((formik.values.legalAmount != 0) ? formik.values.legalAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"): '')}/>
                </div>
            </div>
            {formik.touched.legalCurrencyType && formik.errors.legalCurrencyType ? (
                    <div className="text text-danger" align="left">{formik.errors.legalCurrencyType}</div>
                ) : null}
            {formik.touched.legalAmount && formik.errors.legalAmount ? (
                    <div className="text text-danger" align="left">{formik.errors.legalAmount}</div>
                ) : null}
            </div>


        </div>
        </>
    );
};


export default Step4;