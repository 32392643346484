import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as AuthService from '@services/auth';
import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';

const Step4 = (props) => {
    let formik = props.formik;
    const [additional, setAdditional] = useState([]);
    const [additionalSelect, setAdditionalSelect] = useState();

    const getAdditionalService = async () =>{
        try {
             const data = await AuthService.GetAdditionalService(1);
             let typeList = (data.data.additionalService)?data.data.additionalService:[];
             setAdditional(typeList);
         } catch (error) {
            //  toast.error(error);
         }
      }
    
      useEffect(() => {
        getAdditionalService();
      }, []);

      const  handleAdditionalOnchange  =  val  => {
          const updateAdditional = val;
          setAdditionalSelect(updateAdditional);
        //   if(props.formType == "edit"){
        //     props.additionalServices(val)
        //   }
        //   props.formik.values.additionalServices = (updateAdditional)?updateAdditional:'';
        props.setCustomsFormVal('additionalServices',(updateAdditional)?updateAdditional:'','required');
      }

      const additionalList  = [];
      const additionalDesign = additional.length > 0
      && additional.map((item, i) => {
          return(
              additionalList.push({ label:  (item)?item.name:'', value:  (item)?item._id:''  })
          );
      });

    return (
        <>
        <div className="form-row">
            <div className="col-md-12 padding-0 margin-head-info">
                <div className="main-title-head text-center">
                    <h1 className="h1-font-size-title">Additional Services</h1>
                </div>
            </div>
        </div>
        {/* <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Additional Services</h2></div></div> */}
        <div className="form-row">
            <div className="form-group form-group-margin col-md-6">
            <label className="label-text">Additional services (select multiple) <span className="text text-danger">*</span></label>
            {(additionalList && additionalList.length>0)?
            <MultiSelect
                onChange={(val) => handleAdditionalOnchange(val)}
                options={additionalList}
                name="additionalServices"
                className="multi-drop additionalCls"
                defaultValue={props.formik.values.additionalServices}
                placeholder={(props.formik.values.additionalServices.length == 0)? "Select..." : ""}
            />
            :''}
            {formik.touched.additionalServices && formik.errors.additionalServices ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalServices}</div>
                ) : null}
            </div>
            
            <div className="form-group form-group-margin col-md-6">
            <label className="label-text">Additional service</label>
            <input type="text" name="additionalServicesDescription" {...formik.getFieldProps('additionalServicesDescription')} className="form-control" placeholder="Please specify other additional service here"/>
            {formik.touched.additionalServicesDescription && formik.errors.additionalServicesDescription ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalServicesDescription}</div>
                ) : null}
            </div>
        </div>
        </>
    );
};


export default Step4;