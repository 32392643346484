import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var _ = require('lodash');

const Step2 = ({ formik, services, formType, arsDetails, filterType, setCustomsFormVal, reqType }) => {
    const [custom, setCustom] = useState([]);
    const [corporateVal, setCorporateVal] = useState([]);
    // const [rfpService, setRfpService] = useState([]);
    const [isCustom, setIsCustom] = useState(false);
    console.log(arsDetails)
    useEffect(() => {
        setTimeout(() => {
            if (formik.values.typeOfServices.length != 0) {
                setCustom(formik.values.typeOfServices)
            } else {
                const emptySer = [];
                if (services && services.length > 0) {
                    for (let index = 0; index < services.length; index++) {
                        emptySer.push({
                            id: index,
                            name: services[index].additionalServices,
                            currency: '$',
                            acceptanceFee: "0",
                            annualFee: "0",
                        });
                    }
                }
                // formik.values.typeOfServices = emptySer
                setCustomsFormVal('typeOfServices', emptySer);
                setCustom(emptySer);
            }

            if (formik.values.corporateFees.length != 0) {
                setCorporateVal(formik.values.corporateFees);
            } else {
                const emptyCorp = [];
                if (reqType == 'ars') {
                    if (arsDetails) {
                        console.log(arsDetails.typeOfServices, "detail")
                        let tOS = arsDetails.typeOfServices;
                        for (let index = 0; index < tOS.length; index++) {
                            emptyCorp.push({
                                id: tOS[index]._id,
                                name: tOS[index].name,
                                type: 'single',
                                currency: '$',
                                corporateAcceptanceFee: '0',
                                corporateAnnualFee: '0',
                            });
                        }
                    }
                } else {
                    if (formik.values.corporateService) {
                        emptyCorp.push({
                            id: 1,
                            name: formik.values.corporateService,
                            type: 'single',
                            currency: '$',
                            corporateAcceptanceFee: '0',
                            corporateAnnualFee: '0',
                        });
                    }
                }
                // formik.values.corporateFees = emptyCorp
                setCustomsFormVal('corporateFees', emptyCorp);
                setCorporateVal(emptyCorp);
            }
        }, 2000)
        // console.log(formik.values.corporateService, "formik.values.corporateService")
    }, [formik.values.corporateService, services])

    const serviceValue = (id, e) => {
        var valueCheck = custom && custom.find(function (element) {
            return element.id == id;
        });
        if (valueCheck) {
            const name = e.target.name;
            if (name == 'issuanceCurrencyType') {
                valueCheck.issuanceCurrencyType = e.target.value
            } else if (name == 'acceptanceFee' || name == "annualFee") {
                if ('/^\d+$/', /^\d+$/.test(e.target.value.replaceAll(',', ''))) {
                    valueCheck[name] = e.target.value.replaceAll(',', '')
                    e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                } else {
                    e.target.value = "";
                    valueCheck[name] = "0";
                    // toast.error("Value must be a number");
                }
                // valueCheck[name] = e.target.value.replaceAll(',','')
                // e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            }
        } else {
            custom.push({
                id: id,
                currency: '$',
                [e.target.name]: e.target.value
            })
        }
        setCustom(custom)
        setIsCustom(true)
        // console.log(custom)
        // formik.values.typeOfServices = custom
        setCustomsFormVal('typeOfServices', custom);
    }
    useEffect(() => {
        setIsCustom(false)
    }, [isCustom])

    // useEffect(() => {
    //     if (arsDetails && arsDetails.additionalServices) {
    //         let followingIds = arsDetails.additionalServices.length > 0 && arsDetails.additionalServices.map(service => service._id);
    //         setRfpService(followingIds)
    //     }
    // }, [arsDetails.additionalServices])

    let serviceDesign = "";

    if (!formType || formik.values.typeOfServices.length == 0) {
        serviceDesign = services.length > 0
            && services.map((item, i) => {
                return <>
                    <div className="form-group col-md-12">
                        <div className="col-md-3">
                            <label className="label-text">{item.additionalServices ?? ''}</label>
                        </div>

                        <div className="col-md-8 p-0">
                            <div className="col-md-6">
                                <div className="form-row">
                                    <div className="col-md-3 p-0">
                                        <input type="text" className="form-control" readOnly disabled value="$" />
                                    </div>
                                    <div className="col-md-9 p-0">
                                        <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="acceptanceFee" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-row">
                                    <div className="col-md-3 p-0">
                                        <input type="text" className="form-control" readOnly disabled value="$" />
                                    </div>
                                    <div className="col-md-9 p-0">
                                        <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="annualFee" />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </>;
            });
    } else if (filterType == 'import') {
        serviceDesign = services.length > 0
            && services.map((item, i) => {
                var data = custom.filter(element => element.id == item._id)
                if (data && data[0]) {
                    return <>
                        <div className="form-group col-md-12">
                            <div className="col-md-3">
                                <label className="label-text">{item.additionalServices ?? ''}</label>
                            </div>

                            <div className="col-md-8 p-0">
                                <div className="col-md-6">
                                    <div className="form-row">
                                        <div className="col-md-3 p-0">
                                            <input type="text" className="form-control" readOnly disabled value="$" />
                                        </div>
                                        <div className="col-md-9 p-0">
                                            <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="acceptanceFee" defaultValue={(data[0].acceptanceFee == '0') ? '' : data[0].acceptanceFee} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-row">
                                        <div className="col-md-3 p-0">
                                            <input type="text" className="form-control" readOnly disabled value="$" />
                                        </div>
                                        <div className="col-md-9 p-0">
                                            <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="annualFee" defaultValue={(data[0].annualFee == '0') ? '' : data[0].annualFee} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </>;
                } else {
                    return <>
                        <div className="form-group col-md-12">
                            <div className="col-md-3">
                                <label className="label-text">{item.additionalServices ?? ''}</label>
                            </div>

                            <div className="col-md-8 p-0">
                                <div className="col-md-6">
                                    <div className="form-row">
                                        <div className="col-md-3 p-0">
                                            <input type="text" className="form-control" readOnly disabled value="$" />
                                        </div>
                                        <div className="col-md-9 p-0">
                                            <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="acceptanceFee" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-row">
                                        <div className="col-md-3 p-0">
                                            <input type="text" className="form-control" readOnly disabled value="$" />
                                        </div>
                                        <div className="col-md-9 p-0">
                                            <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="annualFee" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </>;
                }
            });
    } else {
        serviceDesign = custom.length > 0
            && custom.map((item, i) => {
                return <>
                    <div className="form-group col-md-12">
                        <div className="col-md-3">
                            <label className="label-text">{services[i].additionalServices ? services[i].additionalServices : ''}</label>
                        </div>
                        <div className="col-md-8 p-0">
                            <div className="col-md-6">
                                <div className="form-row">
                                    <div className="col-md-3 p-0">
                                        <input type="text" className="form-control" readOnly disabled value="$" />
                                    </div>
                                    <div className="col-md-9 p-0">
                                        <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="acceptanceFee" defaultValue={(item.acceptanceFee == '0') ? '' : item.acceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-row">
                                    <div className="col-md-3 p-0">
                                        <input type="text" className="form-control" readOnly disabled value="$" />
                                    </div>
                                    <div className="col-md-9 p-0">
                                        <input type="text" className="form-control" onChange={e => serviceValue(i, e)} name="annualFee" defaultValue={(item.annualFee == '0') ? '' : item.annualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
            });
    }


    const corporateValue = (itm, e) => {
        // let valueCheck = _.find(corporateVal, (o) => { return _.isMatch(o.type, itm) });
        var valueCheck = corporateVal && corporateVal.find(function (element) {
            return element.id == itm.id;
        });
        if (valueCheck) {
            const name = e.target.name;
            if (name == 'issuanceCurrencyType') {
                valueCheck.issuanceCurrencyType = e.target.value
            } else if (name == 'corporateAcceptanceFee' || name == "corporateAnnualFee") {
                // console.log(e.target.value,"vall")
                if ('/^\d+$/', /^\d+$/.test(e.target.value.replaceAll(',', ''))) {
                    valueCheck[name] = e.target.value.replaceAll(',', '')
                    e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                } else {
                    e.target.value = "";
                    valueCheck[name] = "0";
                    // toast.error("Value must be a number");
                }

                // valueCheck[name] = e.target.value.replaceAll(',','')
                // e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            }
        } else {
            corporateVal.push({
                id: itm.id,
                name: itm.name,
                type: itm.type,
                currency: itm.currency,
                [e.target.name]: e.target.value
            })
        }
        setCorporateVal(corporateVal);
        // console.log(corporateVal, "corpval");
        // formik.values.corporateFees = corporateVal;
        setCustomsFormVal('corporateFees', corporateVal);
    }

    let corporateDesign = corporateVal && corporateVal.length > 0
        && corporateVal.map((item, i) => {
            return <>
                <div className="col-md-3" style={{ textTransform: 'capitalize' }}>
                    {
                        <label className="label-text">{item.name}</label>
                    }
                </div>
                <div className="col-md-8 p-0">
                    <div className="col-md-6" style={{
                        marginBottom: '20px'
                    }}>
                        <div className="form-row">
                            <div className="col-md-3 p-0 mb-2">
                                <input type="text" className="form-control" readOnly disabled value={item.currency} />
                            </div>
                            <div className="col-md-9 p-0 mb-2">
                                <input type="text" className="form-control" name="corporateAcceptanceFee" onChange={e => corporateValue(item, e)} defaultValue={(item.corporateAcceptanceFee == 0) ? '' : item.corporateAcceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} />
                            </div>
                        </div>

                        {formik.touched.corporateAcceptanceFee && formik.errors.corporateAcceptanceFee ? (
                            <div className="text text-danger" align="left">{formik.errors.corporateAcceptanceFee}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <div className="form-row">
                            <div className="col-md-3 p-0">
                                <input type="text" className="form-control" readOnly disabled value={item.currency} />
                            </div>
                            <div className="col-md-9 p-0">
                                <input type="text" className="form-control" name="corporateAnnualFee" onChange={e => corporateValue(item, e)} defaultValue={(item.corporateAnnualFee == 0) ? '' : item.corporateAnnualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} />
                            </div>
                        </div>
                        {formik.touched.corporateAnnualFee && formik.errors.corporateAnnualFee ? (
                            <div className="text text-danger" align="left">{formik.errors.corporateAnnualFee}</div>
                        ) : null}
                    </div>
                </div>
            </>;
        });

    return (
        <>
            <div className="form-row">
                <div className="col-md-12 padding-0 margin-head-info" style={{ marginBottom: '0px' }}>
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Services</h1>
                    </div>
                </div>
            </div>
            {/* <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Services</h2></div></div> */}
            <div className="col-md-12 rfp-info" style={{ background: '#fbfbfb', marginTop: '0px' }} align="center">
                <div className="col-md-2">
                    <label className="label-text label-service">Institution </label>
                    <div className="ars-detail-aln"><label className="text text-primary text-cap">{(arsDetails) ? arsDetails.institution : '----'} </label></div>
                </div>
                <div className="col-md-2">
                    <label className="label-text label-service">Name of Issuer </label>
                    <div className="ars-detail-aln"><label className="text text-primary text-cap">{(arsDetails) ? arsDetails.issuerName : '----'}  {(arsDetails) ? (arsDetails.corporateBorrower) ? '(' + arsDetails.corporateBorrower + ')' : '' : ''}</label></div>
                </div>
                <div className="col-md-2">
                    <label className="label-text label-service">Issuance Type </label>
                    <div className="ars-detail-aln"><label className="text text-primary text-cap">{(arsDetails) ? (arsDetails.issuanceType) ? arsDetails.issuanceType.name : '----' : '----'}  </label></div>
                </div>
                <div className="col-md-2">
                    <label className="label-text label-service">Are funds held in a commingled or segregated account ? </label>
                    <div className='ars-sl-aln'><label className="text text-primary text-cap">{(arsDetails) ? arsDetails.accountType : '----'}  </label></div>
                </div>
                <div className="col-md-2">
                    <label className="label-text label-service">Do you utilize a Trustee or are bond proceeds internally managed ?</label>
                    <div className='ars-lt-aln'><label className="text text-primary text-cap">{(arsDetails) ? arsDetails.bondManage : '----'}  </label></div>
                </div>

                <span></span>
            </div>


            {(corporateVal && corporateVal.length > 0) ?
                <div className="form-row">
                    <div className="col-md-3 form-group-margin">
                        <label className="label-text label-service">Type Of Service </label>
                    </div>
                    <div className="col-md-4 form-group-margin">
                        <label className="label-text label-service">Acceptance Fee</label>
                    </div>
                    <div className="col-md-4 form-group-margin">
                        <label className="label-text label-service">Annual Fee </label>
                    </div>
                    <div className="form-group col-md-12">
                        {corporateDesign ?? ''}
                    </div>
                </div>
                : ''}
            {(custom && custom.length > 0) ?
                <div className="form-row">
                    <div className="col-md-3 form-group-margin">
                        <label className="label-text label-service">Additional Service
                            {/* {
                            (arsDetails.tranchesNumber != 'single') ?
                                ' (per Tranche)' : null
                        } */}
                        </label>
                    </div>
                    <div className="col-md-4 form-group-margin">
                        {/* <label>Acceptance Fee </label> */}
                    </div>
                    <div className="col-md-4 form-group-margin">
                        {/* <label>Annual Fee </label> */}
                    </div>
                    {(serviceDesign) ? serviceDesign : ''}
                </div>
                : ''}

            <div className="form-row">
                <div className="form-group form-group-margin col-md-12">
                    <label className="label-text">Transaction Summary  </label>
                    <textarea name='transactionSummary' rows={6} className="form-control" {...formik.getFieldProps('transactionSummary')} >
                        {(formik.values.transactionSummary) ? formik.values.transactionSummary : ''}
                    </textarea>
                </div>
            </div>
        </>
    );
};


export default Step2;