import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as RfpService from '@services/rfp';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import dateFormat from "dateformat";
import Swal from 'sweetalert2';
import AssignUserModal from '../../../components/global/modal/AssignUserModal';
import { getUsersByRfp } from '../../../services/rfp';
import { assignUserToForm } from '../../../services/auth';

const SavedList = ({ currentUser }) => {

  const [savedData, setSavedData] = useState([]);
  const [rfpUserData, setRfpUserData] = useState([]);
  const [selectedRowRfp, setSelectedRowRfp] = useState([]);
  const [rfpUserModalOpen,setRfpUserModalOpen] = useState(false);
  const getSavedList = async (requestData) => {
    try {
      const data = await RfpService.GetSavedLists(requestData);
      let draftList = (data.data.rfpData) ? data.data.rfpData : [];
      console.log("draftList",draftList);
      setSavedData(draftList);
    } catch (error) {
      // toast.error(error);
    }
    document.getElementById('preloader').style.display = "none";
  }

  // deleteRFPData
  const removeRFPFromDB = async (formId) => {
    Swal.fire({
      title: 'Are you sure ?',
      text: "You want to delete this request ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const removeParam = {
          id: formId,
        }
        try {
          await RfpService.removeRFPS(removeParam);
          const query = {
            userId: currentUser['custom:id'],
            type: "draft"
          };
          getSavedList(query);
          toast.success('Request has been deleted.');
        } catch (error) {
          // if (error.message) {
          //   toast.error(error.message);
          // } else {
          //   toast.error("Something went wrong");
          // }
        }
      }
    });
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    if(currentUser['custom:id']){
      const query = {
        userId: currentUser['custom:id'],
        type: "draft"
      };
      getSavedList(query);  
    }
    document.getElementById('preloader').style.display = "block";
  }, [currentUser['custom:id']]);



  const showRfpUserList = async (row) => {
    document.getElementById('preloader').style.display = "block";
    const {
      rfpData
    } = await getUsersByRfp(row.uniqueNo,currentUser['custom:id'])
    setRfpUserData(rfpData);
    setSelectedRowRfp(row);
    setRfpUserModalOpen(true)
    document.getElementById('preloader').style.display = "none";

  }


  const columns = [
    {
      name: 'Transaction Id',
      selector: row => row.TransId,
      sortable: true,
      width:'15%',
      cell: (row) => <>
          {
            (row.isEdit) ?
              (row.reqType == 'municipality') ? 
                    <Link to={'/edit-municipality/' + row.uniqueNo}>{row.uniqueNo ?? '...'}</Link>:
                    <Link to={'/edit-rfp/' + row.uniqueNo}>{row.uniqueNo ?? '...'}</Link>
                    :<Link to={(row.reqType == 'municipality') ? '/view-municipal-rfp/' + row.uniqueNo : '/view-rfp/' + row.uniqueNo}>{row.uniqueNo ?? '...'}</Link>
          }
        </>
    },
    {
      name: 'Saved Date',
      sortable: true,
      cell: (row) => (row.createdAt) ? dateFormat(row.createdAt, "UTC:mmm d, yyyy"): ""
    },
    {
      name: 'Issuer Name',
      selector: row => row.issuerName ?? '...',
      sortable: true,
    },
    {
      name: 'Transaction/Project Name',
      selector: row => row.projectName ?? '...',
      sortable: true,
    },
    {
      name: 'RFP Type',
      selector: row => (row.reqType == 'regular')?'Corp':(row.reqType == 'municipality')?'Muni':'...',
      sortable: true,
      width: '8rem'
    },
    {
      name: 'Action',
      sortable: true,
      cell: (row) => <>
                        {/* {row._id} */}
                        {
                          (row.isEdit) &&
                          <>
                              <button onClick={e => showRfpUserList(row)} className="btn btn-success btn-sm" style={{
                                marginRight : "1em"
                              }}><i className="fa fa-user-plus" title="Assign User"/></button>
                          
                            <button onClick={e => removeRFPFromDB(row._id)} className="btn btn-danger btn-sm"><i className="fa fa-trash" title="Delete RFP"/></button>
                          </>
                        }
                        {
                          (row.isView && !row.isEdit) &&
                            <>
                                <button disabled className="btn btn-success btn-sm" style={{
                                  marginRight : "1em"
                                }}><i className="fa fa-user-plus" title="Assign User"/></button>
                                <button disabled className="btn btn-danger btn-sm"><i className="fa fa-trash" title="Delete RFP"/></button>
                            </>
                        }
                        {
                          (!row.isView && !row.isEdit) &&
                            <>
                                <button disabled className="btn btn-success btn-sm" style={{
                                  marginRight : "1em"
                                }}><i className="fa fa-user-plus" title="Assign User"/></button>
                                <button disabled className="btn btn-danger btn-sm"><i className="fa fa-trash" title="Delete RFP"/></button>
                            </>
                        }
                        
                    </>
    },
  ];

  const handlePermissions = async (row,type) => {
    const view =  document.getElementById('permission-view-' + row._id).checked
    const edit =  document.getElementById('permission-edit-' + row._id).checked
    
    let updateCriteria = { 
      userId: row._id,
      formType :"rfp",
      isEdit : edit,
      isView : view,
      formId : selectedRowRfp._id
    }

    if(type == "edit"){ 
      document.getElementById('permission-view-' + row._id).checked = true
      document.getElementById('permission-edit-' + row._id).checked = (!edit) ? false : true
      updateCriteria.isView = true
      updateCriteria.isEdit = (!edit) ? false : true
    }else if(type == "view"){
      document.getElementById('permission-view-' + row._id).checked = (!view) ? false : true
      document.getElementById('permission-edit-' + row._id).checked = false
      updateCriteria.isView =  (!view) ? false : true
      updateCriteria.isEdit = false
    }
    
    await assignUserToForm(updateCriteria)
    // setTimeout(() => {
    //   setRfpUserModalOpen(false)
    // },1000)
}


  
  const rfpUserColumns = [
    {
      name: 'Name',
      cell: (row) => (row.firstName +" "+ row.lastName)
    },
    {
      name: 'Email',
      cell: (row) => <p style={{
                        textTransform: 'none'
                      }}>
                        {(row.email) ? row.email : "N/A"}
                    </p>
    },
    {
      name: 'Permissions',
      cell: (row) => <>
                       <label>
                            <input type="checkbox" name="remember" onClick={
                              () => handlePermissions(row,'view')
                            } defaultChecked={(row.assignIsView) ? row.assignIsView : false} className="remember-box" value="" id={'permission-view-' + row._id}/>
                            <span> View</span>
                        </label>
                        
                       <label style={{
                         marginLeft : "1em"
                       }}>
                            <input type="checkbox" name="remember" onClick={
                              () => handlePermissions(row,'edit')
                            } defaultChecked={(row.assignIsEdit) ? row.assignIsEdit : false} className="remember-box" value=""  id={'permission-edit-' + row._id}/>
                            <span> Edit</span>
                        </label>
                    </>
    },
  ];


  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '16px',
        minHeight: '50px'
      },
    },
    //heading row style
    headRow: {
      style: {
        backgroundColor: '#f5f6fa',
       
      }
    },
    cells: {
      style: {
        padding: '10px', // override the cell padding for data cells
        paddingRight: '8px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      },
    },
  };


  return (
    <>
      <section>
        <div className="auto-container">
          <h1 className="h1-text text-black">Saved RFP's List</h1>
        </div>
      </section>
      <section id="TrusteeDashboard" className="contact-section"  style={{'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat',backgroundSize:'cover', backgroundPosition: 'top' }}>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="form-column col-md-12 col-sm-12 col-xs-12">
              {
                (rfpUserModalOpen) &&
                      <AssignUserModal
                        title={"Assign Permission To Users"} 
                        columns={rfpUserColumns}
                        data={rfpUserData}
                        handleConfirmClose= { () =>  setRfpUserModalOpen(!rfpUserModalOpen)}
                      />
              }
              
              <DataTable
                columns={columns}
                data={savedData}
                pagination
                customStyles={customStyles}
                dense
              />
            </div>
            <div className="col-md-12 clr-both">
                <div className="col-md-12 p-0" align="left"><Link to={'/trustee'} className="btn btn-primary "><i className="fa fa-arrow-left"></i> Back</Link></div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};


const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser
});

export default connect(mapStateToProps)(SavedList);
