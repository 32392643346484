import './App.css';
import React,{useState,useEffect} from 'react';
import Route from './routes'
import * as ActionTypes from '@store/actions';
import {connect} from 'react-redux';
import { Auth } from 'aws-amplify';
import {toast} from 'react-toastify';
import * as AuthService from '@services/auth';

 const App = (props) => {
  const [userData,setUserData] = useState();
  const checkUserAuth = async () => {
    try {
        const session = await Auth.currentSession();
        props.onUserLogin(session.idToken.jwtToken);
        const user = await Auth.currentAuthenticatedUser();
        const userDetail = user.attributes;
        props.onUserDetail(userDetail);
        setUserData(userDetail);
    } catch(error) {
      if (error !== 'No current user') {
        // toast.error("Session Expired");
      }
    }
  }

  useEffect(() => {
    checkUserAuth();
  },[]);

  return (
    <div className="App">
      <div id="load"></div>
      <Route />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogin: (token) => dispatch({
    type: ActionTypes.LOGIN_USER, token
}),
  onUserDetail: (currentUser) => dispatch({
      type: ActionTypes.LOAD_USER, currentUser
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
