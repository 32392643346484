import React from 'react';
import dateFormat from "dateformat";
import * as AppConstant from '@app/appConstants';


const Section2 = ({ data }) => {
    let issuanceAmtDesign = typeof (data.issuanceAmount) == "object" && data.issuanceAmount.length > 0
        && data.issuanceAmount.map((item, i) => {
            return <>
                <div className="form-group form-group-margin details-row-left col-md-4 col-sm-6">
                    <label className="label-text">{AppConstant.globalConst.CORPESCROWISSUANCEID == data.issuanceType._id?"Amount of Deposit":"Amount of Issuance"} </label>
                    {
                        (item.issuanceAmount && item.issuanceAmount != 0)?<p className="paragraph-text">{item.issuanceCurrencyType} {item.issuanceAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</p>:<p className="paragraph-text">----</p>
                    }
                </div>
                
            </>;
        });

    const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    return (
        <>
            <div className="form-row col-md-12 padding-0">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">{AppConstant.globalConst.CORPESCROWISSUANCEID == data.issuanceType._id?"Escrow Information":"ISSUER INFORMATION"}</h1>
                    </div>
                </div>
            </div>
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Name of Issuer</label>
                    <p className="paragraph-text">{(data.issuerName)?data.issuerName:'----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Transaction/Project Name</label>
                    <p className="paragraph-text">{(data.projectName)?data.projectName:'----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Corporate Trust Service</label>
                    <p className="paragraph-text">{(data.corporateTrustService)?data.corporateTrustService.replace('_',' '):'----'}</p>
                </div>
            </div>
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Type of Issuance</label>
                    <p className="paragraph-text">{(data.issuanceType)?data.issuanceType.name:'----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >{(data.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE)?'Tenure of Issuance (Year)':'How many months will the funds be held in Escrow?'}</label>
                    <p className="paragraph-text">{(data.issuanceTenure)?data.issuanceTenure:'----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >{(data.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE)?'Intended Date of Issuance':'Intended Date of Escrow Signing'}</label>
                    <p className="paragraph-text">
                        { 
                            (data.issuanceIntendedDate) ? dateFormat(data.issuanceIntendedDate, "UTC:mm/d/yyyy") : '----'
                        }
                    </p>
                </div>
            </div>
            <div className="form-row col-md-12">
               <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >{(data.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE)?'Number of Tranches':'Number of Accounts'}</label>
                    <p className="paragraph-text">{(data.tranchesNumber)?(data.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE)?data.tranchesNumber:(data.tranchesNumber == 'single')?'One':(data.tranchesNumber == 'dual')?'Two':(data.tranchesNumber == 'multiple')?'Three':'----':'----'}</p>
                </div>
                {issuanceAmtDesign}

            </div>

            <div className="col-md-12  margin-head-info radio-form-bg " style={{
                marginBottom: '0'
            }}>
                {(data.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE)?
                <>
                <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Will this Issuance be rated?</label>
                        <p className="paragraph-text">{(data.issuanceRated)?data.issuanceRated:'----'}</p>
                </div>
                <div className="form-row">
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >If Issuance will be rated, what will the rating be?</label>
                        <p className="paragraph-text">{(data.ratedDescription)?data.ratedDescription:'----'}</p>
                    </div>
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Is this a first-time Issuer?</label>
                        <p className="paragraph-text">{(data.firstTimeIssuer)?data.firstTimeIssuer:'----'}</p>
                    </div>
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Is this a Sovereign Issuer?</label>
                        <p className="paragraph-text">{(data.sovereignIssuer)?data.sovereignIssuer:'----'}</p>
                    </div>
                    {(data.issuanceType && data.issuanceType._id != AppConstant.globalConst.ISSUANCETYPE_ID) ?
                        <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text" >Is this Issuance part of an Exchange?</label>
                            <p className="paragraph-text">{(data.exchangeIssuer)?data.exchangeIssuer:'----'}</p>
                        </div>
                        : ''}
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Is the Issuer a Publicly Traded Company?</label>
                        <p className="paragraph-text">{(data.publiclyTradedCompany)?data.publiclyTradedCompany:'----'}</p>

                    </div>
                </div>
                </>
                :''}
            </div>
            <div className="form-row">
                <div className="form-group form-group-margin col-md-12  radio-form-bg" style={{
                    marginTop: 0
                }}>
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Governing Law</label>
                        <p className="paragraph-text">{(data.governingLaw)?data.governingLaw:'----'}</p>
                    </div>
                    {(data.corporateTrustService != AppConstant.globalConst.CORPCORPATETRUSTSERVICE)?
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Is this TIA qualified?</label>
                        <p className="paragraph-text">{(data.tiaQualified)?data.tiaQualified:'----'}</p>
                    </div>
                    :''}  
                </div>
            </div>

            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-12">
                    <label className="label-text" >Transaction Summary</label>
                    <p className="text-black"><b>{(data.transactionSummary)?renderHTML(data.transactionSummary):'----'}</b></p>
                </div>

            </div>


        </>
    );
};


export default Section2;