import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import * as ActionTypes from '@store/actions';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import Login from'./Login';
import Register from'./Register';
import ForgotPassword from'@pages/forgot-password/ResetCode';


const Team = ({onUserLogin,isLoggedIn}) => {

    const [form,setForm] = useState(1);

    const handleLoginChange = () => {
		setForm(2);
	};

    const handleRegisterChange = () => {
        setForm(1);
    }

    const handleForgotChange = () => {
        setForm(3);
    }

    return (
        <>
 <section className="fluid-section-three" id="loginid">
        <div className="outer-container clearfix ht-670">
            <div className="left-column">
                <div className="clearfix">
                    <div className="inner">
                        <div className="sec-title"><h2>Have Some Questions?</h2></div>
                        
                        <div className="faqs">
                            <ul className="accordion-box">
                            <Accordion allowZeroExpanded  preExpanded={['first']} className="border-block">
                            <AccordionItem uuid="first" className="accordion block bn">
                                <AccordionItemHeading className="acc-btn p-0">
                                    <AccordionItemButton>
                                    Why Choose Us Today?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                <div className="content p-0" align="left"><p><b>MyTrustMe<sup>TM</sup></b> is the solution for Capital Market transactors to minimize time spent on gathering quotes for Indenture Trustee services. Bankers now have more time to spend on their fee generating transaction and less time on a tertiary service for which there is no direct benefit and some risk for the originating banker to obtain.</p></div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem className="accordion block bn">
                                <AccordionItemHeading className="acc-btn p-0">
                                    <AccordionItemButton>
                                    We are the Best
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                <div className="content p-0" align="left"><p><b>MyTrustMe<sup>TM</sup></b> automates a manual process, gathers data, acts as a repository, analyzes data, and compiles a comprehensive, easy-to-read response. This response and executing a selection is available wherever you are.</p></div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                                
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <div className="right-column" style={{backgroundImage:"url("+process.env.REACT_APP_IMAGE_TAG+"/assets/images/background/image-8.jpg)"}}>
                {(!isLoggedIn)?
                <div className="inner">
                    <div className="sec-title">{(form == 3)?<h2>Reset Password</h2>:<h2>Secure Login / Register</h2>}</div>
                    <div className="skills-box style_2">
                    {(() => {
                            switch (form) {
                            case 2:
                                return <Register  handleChange={e=>handleRegisterChange()} />;
                            case 3:
                                return <ForgotPassword  handleChange={e=>handleRegisterChange()} />;
                            default:
                                return <Login  handleChange={e=>handleLoginChange()} openForgot={e=>handleForgotChange()}/>;
                            }
                        })()}
                        
                    </div>
                </div>
                 :
                ""
                }
            </div>
            
        </div>
    </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);