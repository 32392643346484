import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import Chart from "react-google-charts";
import ServiceModal from '@pages/main/ServiceModal';

const ChooseUs = () => {

    const [modalHandle, setModalHandle] = useState(0);

    const handleClose = () => {
        setModalHandle(0);
    };

    const handleShow = (item) => {
        setModalHandle(item);
    }


    return (
        <>
            {/* <section className="services-style-one p-0">
        <div className="auto-container">
            <div className="row clearfix">
                <div className="column col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <Chart
                    width={'100%'}
                    height={350}
                    chartType="CandlestickChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['Time', 'a', 'b', 'c', 'd'],
                        ['11AM', 20, 28, 38, 45],
                        ['12PM', 31, 38, 55, 66],
                        ['1PM', 50, 55, 77, 80],
                        ['2PM', 77, 77, 66, 50],
                        ['3PM', 54, 58, 22, 15],
                        
                    ]}
                    options={{
                        legend: 'none',
                        bar: { groupWidth: '100%' }, // Remove space between bars.
                        candlestick: {
                        fallingColor: { strokeWidth: 0, fill: '#a52714' }, // red
                        risingColor: { strokeWidth: 0, fill: '#0f9d58' }, // green
                        },
                    }}
                    />
                </div>
                <div className="column col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <Chart
                    width={'100%'}
                    height={350}
                    chartType="CandlestickChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['Time', 'a', 'b', 'c', 'd'],
                        ['11AM', 68, 66, 22, 15],
                        ['12PM', 77, 77, 66, 50],
                        ['1PM', 50, 55, 77, 80],
                        ['2PM', 31, 38, 55, 66],
                        ['3PM', 20, 28, 38, 45],
                    ]}
                    options={{
                        legend: 'none',
                        bar: { groupWidth: '100%' }, // Remove space between bars.
                        candlestick: {
                        fallingColor: { strokeWidth: 0, fill: '#a52714' }, // red
                        risingColor: { strokeWidth: 0, fill: '#0f9d58' }, // green
                        },
                    }}
                    />
                </div>
                <div className="column col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <Chart
                    width={'100%'}
                    height={350}
                    chartType="CandlestickChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['Time', 'a', 'b', 'c', 'd'],
                        ['11AM', 20, 28, 38, 45],
                        ['12PM', 31, 38, 55, 66],
                        ['1PM', 54, 58, 22, 15],
                        ['2PM', 77, 77, 66, 50],
                        ['3PM', 50, 55, 77, 80],
                        
                    ]}
                    options={{
                        legend: 'none',
                        bar: { groupWidth: '100%' }, // Remove space between bars.
                        candlestick: {
                        fallingColor: { strokeWidth: 0, fill: '#a52714' }, // red
                        risingColor: { strokeWidth: 0, fill: '#0f9d58' }, // green
                        },
                    }}
                    />
                </div>

            </div>
        </div>
    </section> */}

            <section className="services-style-one">
                <div className="container-fluid">
                    <div className="sec-title centered">
                        <h2>Who We Serve</h2>

                    </div>

                    <div className="row clearfix">
                        <div className="column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div className="service-block2">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/resource/whychoos-1.jpg"} alt="" />
                                        <div className="caption">Debt & Capital Markets Bankers</div>
                                        <div className="overlay-box">
                                            <h3>Debt & Capital Markets Bankers</h3>
                                            <div className="text"><b>MyTrustMe</b> minimizes the time it takes for capital market transactors to gather quotes for Indenture Trustee services. </div>
                                            <button className="read-more btn btn-primary" onClick={e => handleShow(1)}>READ HOW <span className="fa fa-angle-double-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div className="service-block2">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/resource/whychoos-2.jpg"} alt="" />
                                        <div className="caption">Corporate Trust Bankers</div>
                                        <div className="overlay-box">
                                            <h3>Corporate Trust Bankers</h3>
                                            <div className="text"><b>MyTrustMe’s</b> online platform collects new Indenture Trustee services quotes in an easy-to-navigate online dashboard.  </div>
                                            <button className="read-more btn btn-primary" onClick={e => handleShow(2)}>READ HOW <span className="fa fa-angle-double-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div className="service-block2">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/resource/whychoos-3.jpg"} alt="" />
                                        <div className="caption">Municipal/Corporate Issuers</div>
                                        <div className="overlay-box">
                                            <h3>Municipal/Corporate Issuers</h3>
                                            <div className="text"><b>MyTrustMe</b> gives issuers of debt instruments a range of quotes for services which are typically provided for 7-30 years.</div>
                                            <button className="read-more btn btn-primary" onClick={e => handleShow(3)}>READ HOW <span className="fa fa-angle-double-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div className="service-block2">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/resource/whychoos-4.jpg"} alt="" />
                                        <div className="caption">Financial Advisors & Lawyers</div>
                                        <div className="overlay-box">
                                            <h3>Financial Advisors & Lawyers</h3>
                                            <div className="text"><b>MyTrustMe</b> gives financial advisors and lawyers comprehensive and competitive quotes for trust and agency services on their clients’ behalves. </div>
                                            <button className="read-more btn btn-primary" onClick={e => handleShow(4)}>READ HOW <span className="fa fa-angle-double-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix">
                        <div className="column col-lg-3 col-md-6 col-sm-6 col-xs-12 col-xl-offset-3 col-lg-offset-3 col-md-offset-0 col-sm-offset-0 col-xs-offset-0">
                            <div className="service-block2">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/resource/whychoos-5.jpg"} alt="" />
                                        <div className="caption">Arbitrage Rebate Services</div>
                                        <div className="overlay-box">
                                            <h3>Arbitrage Rebate Services</h3>
                                            <div className="text">Whether you are in need of an Arbitrage Rebate Servicer, Escrow Bidding, or any Post-Issuance Compliance service, our newest module will help you source and secure your specific service needs from a nationwide network of providers. </div>
                                            <button className="read-more btn btn-primary" onClick={e => handleShow(5)}>READ HOW <span className="fa fa-angle-double-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div className="service-block2">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/resource/whychoos-6.jpg"} alt="" />
                                        <div className="caption">Verification Agents</div>
                                        <div className="overlay-box">
                                            <h3>Verification Agents</h3>
                                            <div className="text">Let <b>MyTrustMe.com</b> help you obtain vital post-issuance services for your debt portfolio. </div>
                                            <button className="read-more btn btn-primary" onClick={e => handleShow(6)}>READ HOW <span className="fa fa-angle-double-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {(modalHandle !== 0) ? <ServiceModal isModal="true" handleClose={e => handleClose()} items={modalHandle} /> : ''}
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseUs);