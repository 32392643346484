import API from '@app/utils/axios';

export const NewRfp = async (requestData) => {
    const { data } = await API.post('user/create-rfp', requestData);

    return data;
};


export const NewMunicipalRfp = async (requestData) => {
    const { data } = await API.post('user/create-municipality-rfp', requestData);

    return data;
};



export const GetSavedLists = async (requestData) => {
    const { data } = await API.post('user/get-rfp-by-type', requestData);

    return data;
};

/**
 * 
 * @param {*} uniqueNo 
 * @returns data object
 */
export const GetRFPDetails = async (uniqueNo) => {
    const { data } = await API.post('/user/get-rfp-detail', {
        uniqueNo: uniqueNo
    });
    return data.data;
};


/**
 * 
 * @param {*} uniqueNo 
 * @returns data object
 */
export const GetRFPDetailsById = async (uniqueNo, type) => {
    const {
        data
    } = await API.post('/user/get-rfp-detail-by-id', {
        uniqueNo: uniqueNo,
        type: type
    });
    return data.data;
};


export const uploadDocuments = async (requestData) => {
    const { data } = await API.post('user/upload-rfp-images', requestData);

    return data;
};

export const removeDocuments = async (requestData) => {
    const { data } = await API.post('user/delete-rfp-images', requestData);

    return data.data;
};


export const removeRFPS = async (requestData) => {
    const { data } = await API.post('user/delete-rfp-form', requestData);

    return data.data;
};



export const getAllResponse = async (requestData) => {
    const { data } = await API.post('user/get-rfp-response', requestData);

    return data.data;
};

export const changeResponseStatus = async (requestData) => {
    const { data } = await API.post('user/update-cfr-status', requestData);

    return data.data;
};

export const getBlogData = async (requestData) => {
    const {
        data
    } = await API.post(`user/get-blogs`, {
        "pageNo": String(requestData)
    });
    return data.data;
};

export const getUsersByRfp = async (uniqueNo, userId) => {
    const {
        data
    } = await API.post(`user/get-rfp-userid`, {
        uniqueNo: uniqueNo,
        userId: userId
    });
    return data.data;
};