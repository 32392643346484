import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as ArsService from '@services/ars';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import dateFormat from "dateformat";
import AssignUserModal from '../../../components/global/modal/AssignUserModal';
import { assignUserToForm } from '../../../services/auth';
import { datatableDataLimit } from '@app/appConstants';

const HistoryList = ({ currentUser }) => {
  const { filtertype, formtype, rfptype } = useParams();
  const [rfpOption, selectRfpOption] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [rfpUserData, setRfpUserData] = useState([]);
  const [selectedRowRfp, setSelectedRowRfp] = useState([]);
  const [rfpUserModalOpen, setRfpUserModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [dataTotalCount, setDataTotalCount] = useState(0);
  const history = useHistory();

  const getHistoryList = async (requestData) => {
    try {
      requestData.reqType = rfptype;
      requestData.limit = datatableDataLimit;
      requestData.pageNo = page;
      const data = await ArsService.GetSavedLists(requestData);
      let historyList = (data.data.arsData) ? data.data.arsData : [];
      setHistoryData(historyList);
      setDataTotalCount((data.data.totalPages) ? data.data.totalPages : 0);
    } catch (error) {
      // toast.error(error);
    }
    document.getElementById('preloader').style.display = "none";
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (currentUser['custom:id'] && page) {
      const query = {
        userId: currentUser['custom:id'],
        type: "complete"
      };
      if (filtertype == "import") {
        query.reqType = rfptype
      }
      getHistoryList(query);
    }
    document.getElementById('preloader').style.display = "block";
  }, [currentUser['custom:id'], page]);

  const handleSelectOption = () => {
    if (rfpOption) {
      toast.success('Imported successfully')
      let linkType = "/edit-ars/";
      history.push(linkType + rfpOption + "/" + rfptype + "/import")
    }
  }
  const showRfpUserList = async (row) => {
    document.getElementById('preloader').style.display = "block";
    const { rfpData } = await ArsService.getUsersByRfp(row.uniqueNo, currentUser['custom:id'])
    setRfpUserData(rfpData);
    setSelectedRowRfp(row);
    setRfpUserModalOpen(true)
    document.getElementById('preloader').style.display = "none";
  }
  const columns = [
    {
      name: 'Transaction Id',
      sortable: true,
      width: "15%",
      cell: (row) =>
        <>
          {(row.isEdit) && (filtertype == "import") && <input type={"radio"} name='type' className="import-radio-btn" onClick={() => selectRfpOption(row.uniqueNo)} />}
          {(row.isView) ? <Link to={'/ars/view-rfp/' + row.uniqueNo + '/' + row.reqType}>{row.uniqueNo ?? '...'}</Link> : row.uniqueNo}
        </>
    },
    {
      name: 'Submitted Date',
      sortable: true,
      cell: (row) => (row.createdAt) ? dateFormat(row.createdAt, "UTC:mmm d, yyyy") : ""
    },
    {
      name: 'Issuer Name',
      selector: row => row.issuerName ?? '...',
      sortable: true,
    },
    {
      name: 'Transaction/Project Name',
      selector: row => row.projectName ?? '...',
      sortable: true,
    },
    {
      name: 'Owner',
      selector: row => ((row.firstName) ? row.firstName : '') + ' ' + ((row.lastName) ? row.lastName : ''),
      sortable: true,
    },
    {
      name: 'Action',
      sortable: true,
      cell: (row) => <>
        {
          (row.isEdit && row.isView) && <>
            <button onClick={e => showRfpUserList(row)} className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            <div style={{ paddingLeft: '5px' }}>
              <Link to={'/arsr-response/' + row.uniqueNo + "/" + row.reqType} className="btn btn-primary btn-sm"><i className="fa fa-file-text-o" title="View Responses" /></Link>
            </div>
            <div style={{ paddingLeft: '5px' }}>
              {
                (row.arsResponseId && row.arsResponseId.length != 0) ?
                  <Link to={'/ars-compare/' + row.uniqueNo + "/" + row.reqType} className="btn btn-warning btn-sm"><i className="fa fa-signal" title="Analysis" /></Link>
                  : <button className="btn btn-sm gray-bg"><i className="fa fa-signal" title="Analysis" /></button>
              }
            </div>
          </>
        }
        {
          (row.isView && !row.isEdit) && <>
            <button disabled className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            <div style={{ paddingLeft: '5px' }}>
              <button className="btn btn-primary btn-sm" disabled><i className="fa fa-file-text-o" title="View Responses" /></button>
            </div>
            <div style={{ paddingLeft: '5px' }}>
              {
                (row.arsResponseId && row.arsResponseId.length != 0) ?
                  <Link to={'/ars-compare/' + row.uniqueNo + "/" + row.reqType} className="btn btn-warning btn-sm"><i className="fa fa-signal" title="Analysis" /></Link>
                  : <button className="btn btn-sm gray-bg"><i className="fa fa-signal" title="Analysis" /></button>
              }
            </div>
          </>
        }
        {
          (!row.isView && !row.isEdit) && <>
            <button disabled className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            <div style={{ paddingLeft: '5px' }}>
              <button className="btn btn-primary btn-sm" disabled><i className="fa fa-file-text-o" title="View Responses" /></button>
            </div>
            <div style={{ paddingLeft: '5px' }}>
              <button className="btn btn-sm gray-bg" disabled><i className="fa fa-signal" title="Analysis" /></button>
            </div>
          </>
        }
      </>
    }
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '16px',
        minHeight: '50px'
      }
    },
    //heading row style
    headRow: {
      style: {
        backgroundColor: '#f5f6fa'
      }
    },
    cells: {
      style: {
        padding: '10px', // override the cell padding for data cells
        paddingRight: '8px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      }
    }
  };
  const rfpUserColumns = [
    {
      name: 'Name',
      cell: (row) => (row.firstName + " " + row.lastName)
    },
    {
      name: 'Email',
      cell: (row) => <p style={{ textTransform: 'none' }}>{(row.email) ? row.email : "N/A"}</p>
    },
    {
      name: 'Permissions',
      cell: (row) => <>
        <label>
          <input type="checkbox" name="remember" onClick={() => handlePermissions(row, 'view')} defaultChecked={(row.assignIsView) ? row.assignIsView : false} className="remember-box" value="" id={'permission-view-' + row._id} />
          <span> View</span>
        </label>
        <label style={{ marginLeft: "1em" }}>
          <input type="checkbox" name="remember" onClick={() => handlePermissions(row, 'edit')} defaultChecked={(row.assignIsEdit) ? row.assignIsEdit : false} className="remember-box" value="" id={'permission-edit-' + row._id} />
          <span> Edit</span>
        </label>
      </>
    }
  ];
  const handlePermissions = async (row, type) => {
    const view = document.getElementById('permission-view-' + row._id).checked
    const edit = document.getElementById('permission-edit-' + row._id).checked

    let updateCriteria = {
      userId: row._id,
      formType: rfptype,
      isEdit: edit,
      isView: view,
      formId: selectedRowRfp._id
    }
    if (type == "edit") {
      document.getElementById('permission-view-' + row._id).checked = true
      document.getElementById('permission-edit-' + row._id).checked = (!edit) ? false : true
      updateCriteria.isView = true
      updateCriteria.isEdit = (!edit) ? false : true
    } else if (type == "view") {
      document.getElementById('permission-view-' + row._id).checked = (!view) ? false : true
      document.getElementById('permission-edit-' + row._id).checked = false
      updateCriteria.isView = (!view) ? false : true
      updateCriteria.isEdit = false
    }
    await assignUserToForm(updateCriteria)
  }
  return (
    <>
      <section>
        <div className="auto-container">
          <h1 className="h1-text text-black">Previous RFP's  List</h1>
        </div>
      </section>
      <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top' }}>
        <div className="auto-container">
          {
            (filtertype == "import") ? <>
              <div className="form-column col-md-12 col-sm-12 col-xs-12 text-align-left">
                <button type="button" class="btn btn-primary btn-style-submit res-btn import-btn-style" onClick={() => handleSelectOption()}>Import</button>
                <p className='text text-secondary'> Dates and attachments will not imported</p>
              </div>
            </> : null
          }
          <div className="row clearfix">
            <div className="form-column col-md-12 col-sm-12 col-xs-12">
              {(rfpUserModalOpen) && <AssignUserModal title={"Assign Permission To Users"} columns={rfpUserColumns} data={rfpUserData} handleConfirmClose={() => setRfpUserModalOpen(!rfpUserModalOpen)} />}
              <DataTable
                columns={columns}
                data={historyData}
                pagination
                customStyles={customStyles}
                dense
                paginationServer
                paginationTotalRows={dataTotalCount}
                paginationPerPage={datatableDataLimit}
                paginationComponentOptions={{
                  noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
              />
            </div>
            <div className="col-md-12 clr-both">
              <div className="col-md-12 p-0" align="left"><Link to={'/trustee'} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</Link></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser
});
export default connect(mapStateToProps)(HistoryList);