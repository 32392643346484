import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const TrustCompliance = () => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Trust and Compliance</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Trust and Compliance</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 text-left">
                            <b>MyTrustMe.com LLC’s Trust Commitment</b>
                            <p>MyTrustMe.com LLC is committed to achieving and maintaining the trust of our customers. Integral to this mission is providing a robust security and privacy program that carefully considers data protection matters across our suite of services, including protection of Customer Data.</p>

                            <p>MyTrustMe.com LLC limits data access by employees across all our infrastructure.  No transactional, user, pricing, or uploaded documentation is accessible by any employee at anytime.</p>
                            <b>Data Storage & Encryption</b>
                            <p>All our data resides on a secure MongoDB cloud-based database hosted on AWS.  All data stored within this database is fully encrypted.  We have implemented 2-way encryption when it comes to any data transmitted to and from our website.</p>

                            <b>Third-Party Functionality</b>
                            <p>Certain features of our services use functionality provided by third parties.</p>
                            <p>Current Third-Party solutions are provided by Amazon Web Services (AWS), MongoDB, Stripe, and Salesforce.com.</p>
                            <p>The Request for Proposal features within the MyTrustMe.com platform, along with the response module utilized by Respondent customers, work by sending standard fields from the customer’s Account object to MyTrustMe.com LLC's MongoDB cloud-based database hosted by AWS.</p>
                            <p>MyTrustMe.com utilizes Stripe as a secure payment gateway.</p>

                            <b>User Registration Data</b>
                            <p>When users initially register, we utilize AWS Cognito service to manage user authentication, which includes Two Factor Authentication.</p>

                        </div>
                    </div>

                </div>
            </section>

        </>
    );
};

export default TrustCompliance;