import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';

const AdditionalService = ({ props }) => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Privacy Policy</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 text-left">
                            <p>MyTrustMe.com LLC (“MyTrustMe”) takes your privacy seriously. Please read the following to learn more about our privacy policy. The federal government and technology industry have developed practical tips to help you guard against Internet fraud, secure your computer and protect your personal information.</p>
                            <b>1. Personal Information Collection and Use</b>
                            <p>MyTrustMe collects personal information when you register with MyTrustMe, when you visit MyTrustMe pages and when you click MyTrustMe links. When you register we ask for information such as your name and email address. When you register with MyTrustMe and sign in to our services, you are not anonymous to us. MyTrustMe automatically receives and records information from your computer and browser, including your IP address, cookie information, software and hardware attributes, and the page you request. MyTrustMe uses information for the following general purposes: to customize the content you see, fulfill your requests for products and services, improve our services, contact you, and conduct research</p>
                            <b>2. Information Sharing</b>
                            <p>MyTrustMe does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide products or services you have requested, when we have your permission, or when we respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims. If MyTrustMe becomes involved in a merger, acquisition, or any form of sale of some or all of its assets, we will provide notice before personal information is transferred and becomes subject to a different privacy policy</p>
                            <b>3. Cookies</b>
                            <p>MyTrustMe may set and access MyTrustMe cookies on your computer. MyTrustMe uses these cookies in connection with MyTrustMe products and services.</p>
                            <b>4. Non-Personally Identifying Information</b>
                            <p>This is information that is commonly made available by web browsers and servers, such as the browser type, language preference, referring site, and the date and time of each visitor request. MyTrustMe uses this information to understand how visitors use our website.</p>
                            <b>5. User Generated Content</b>
                            <p>The contents of any item that you post directly to the Site, including any text, images, photos, videos and audio, are stored and maintained on our servers in order to publish these items and provide the Service. Your submitted content will be associated with your account.</p>
                            <b>6. Confidentiality and Security</b>
                            <p>We limit access to personal information about you to employees who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations</p>
                            <b>7. Third Party Sites</b>
                            <p>This Policy only applies to the MyTrustMe website and services. We do not exercise control over the sites that our service links to. These other sites may place their own cookies or other files on your computer, collect data or solicit personal information from you. We encourage you to read such privacy policies of any third-party sites you visit. It is the sole responsibility of such third parties to adhere to any applicable restrictions on the disclosure of your personally-identifying information, and MyTrustMe shall not be liable for wrongful use or disclosure of your personally-identifying information by any third party.</p>
                            <b>8. Data Integrity</b>
                            <p>MyTrustMe processes personal information only for the purposes for which it was collected and in accordance with this Policy. We review our data collection, storage and processing practices to ensure that we only collect, store and process the personal information needed to provide or improve our services. We take reasonable steps to ensure that the personal information we process is accurate, complete, and current, but we depend on our users to update or correct their personal information whenever necessary.</p>
                            <b>9. Changes to This Privacy Policy</b>
                            <p>MyTrustMe may update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your MyTrustMe account or by placing a prominent notice on our site.</p>
                            <b>10. E-Mail Opt-Out</b>
                            <p>You may opt-out of receiving email from MyTrustMe at any time by clicking the unsubscribe link in the email. If you are having trouble, contact us at info@MyTrustMe.com, and we will help you get unsubscribed.</p>
                            <b>11. Contact Info & Opt-Out</b>
                            <p>You may contact MyTrustMe at anytime to opt-out of using our service by email to: info@MyTrustMe.com or in writing to: MyTrustMe.com LLC, 18305 Biscayne Blvd., Suite 210, Aventura, FL 33160.</p>
                        </div>
                    </div>

                </div>
            </section>

        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalService);