import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import './howitworks.css'
import RevSlider, { Slide, Caption } from 'react-rev-slider';


const HowitWork = ({ props }) => {
    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    const config = {
        delay:9000,
        startwidth:1170,
        startheight:700,
        hideThumbs:10,
        fullWidth:"on",
        forceFullWidth:"off"
    };

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>How it Works</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">How it Works</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="services-section p-2 bg-blue">
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-md-12" style={{marginBottom:"20px"}}>
                          <img className="edit-profile-image p-0" src={process.env.REACT_APP_IMAGE_TAG+ "/assets/images/how-it-works/mainbnr.png"} />
                        </div>
                        <div className="col-md-12 mt-40">
                            <div className="sec-title centered">
                                <h2>Instructional Videos</h2>
                            </div>
                            <div className='col-md-4'>
                            <iframe width="95%" height="210" src="https://www.youtube.com/embed/4oMMepbXpZ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h3 className='hiw-video-title text-black'><b>Instructional Video for Debt Capital Markets/Bankers/Attorneys</b></h3>
                            </div>
                            <div className='col-md-4'>
                            <iframe width="95%" height="210" src="https://www.youtube.com/embed/zx-VOaiBHes" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h3 className='hiw-video-title text-black'><b>Instructional Video for Municipal/Public Finance Professionals</b></h3>
                            </div>
                            <div className='col-md-4'>
                            <iframe width="95%" height="210" src="https://www.youtube.com/embed/BmNkDnRT8Qo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h3 className='hiw-video-title text-black'><b>Instructional Video for Corporate Trust Professionals</b></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(HowitWork);