import API from '@app/utils/axios';

export const NewArsr = async (requestData) => {
    const { data } = await API.post('user/create-update-arsr', requestData);

    return data;
};


export const GetSavedLists = async (requestData) => {
    const { data } = await API.post('user/get-arsr-by-type', requestData);

    return data;
};

/**
 * 
 * @param {*} id 
 * @returns data object
 */
export const GetArsrDetails = async (uniqueNo) => {
    const { data } = await API.post('/user/get-arsr-detail', {
        uniqueNo: uniqueNo
    });
    return data.data;
};



export const GetArsrDetailsForm = async (uniqueNo) => {
    const { data } = await API.post('/user/get-arsr-detail-form', {
        uniqueNo: uniqueNo
    });
    return data.data;
};


export const uploadDocuments = async (requestData) => {
    const { data } = await API.post('user/upload-arsr-images', requestData);

    return data;
};

export const removeDocuments = async (requestData) => {
    const { data } = await API.post('user/delete-arsr-images', requestData);

    return data.data;
};


export const removeARSR = async (requestData) => {
    const { data } = await API.post('user/delete-arsr-form', requestData);

    return data.data;
};

export const GetCompleteARS = async (requestData) => {
    const { data } = await API.post('user/get-completears-by-type', requestData);

    return data;
};

export const GetAnalytics = async (requestData) => {
    const {
        data
    } = await API.post('/user/get-arsr-analytics', requestData);
    return data.data;
};

export const GetAnalyticsCompare = async (requestData) => {
    const {
        data
    } = await API.post('/user/get-arsr-compare-analytics', requestData);
    return data.data;
};


export const changeConfirmStatus = async (requestData) => {
    const { data } = await API.post('user/accept-arsr', requestData);

    return data.data;
};


export const GetFilterArsrLists = async (requestData) => {
    const { data } = await API.post('user/get-arsr-by-filter', requestData);

    return data;
};

export const getUsersByArsr = async (arsrUserid) => {
    const {
        data
    } = await API.post(`user/get-arsr-userid`, arsrUserid);
    return data.data;
};