import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import RegisterModal from '@pages/main/RegisterModal';
import VerifyOtpModal from '@pages/main/VerifyOtpModal';

const Pricing = (props) => {

    const [registerModal, setRegisterModal] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [msg,setCodeMessage] = useState();
    const [username,setUsername] = useState('');
    const [userId,setUserId] = useState('');

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);


    const handleRegisterModal = (status) => {
        setRegisterModal(status)
    }

    const handleVerifyModal = (status) => {
        if(status){
            setRegisterModal(false)
        }
        setOtpModal(status)
    }

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Pricing</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Pricing</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 text-center p-b-3">
                            <h1 className="text-black">Business Solutions Pricing</h1>
                            <div className="col-md-12 text-center">
                                <p>Put the power of the world’s only Corporate Trust RFP system to work, at a price that works for you.</p>
                            </div>
                        </div>
                        <div className="col-md-12 grid3">
                    <div className="col-md-3 margin-bottom-5">
                            <div className="col-md-12 box-radius-style br-0 price-cards">
                                <div style={{minHeight:'75px'}}>
                                    <h3 className="h3-font-style text-black" style={{fontSize:"13px"}} >Debt Capital Markets Professional,</h3> 
                                    <h3 className="h3-font-style text-black" style={{fontSize:"13px"}}>Corporate Issuer, Banks,</h3>
                                    <h3 className="h3-font-style text-black" style={{fontSize:"13px"}}>& Non-Bank Financial Institutions</h3>
                                </div>
                                <p style={{minHeight:'80px',minHeight: "50px",fontSize: "11px",fontWeight: "400"}}>Reduce the time spent on finding and securing Corporate Trust services for your clients.</p>
                                <div >
                                <h4 className="text text-primary font-weight-700">$1,500 per year</h4>
                                <div class="strike"><span>OR</span></div>
                                <h4 className="text text-primary font-weight-700">$500 per year</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                <div class="strike"><span>OR</span></div>
                                
                                <h4 className="text text-primary font-weight-700">$50 per month</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                </div>
                                <div className='mt-4'>
                                <button onClick={() => setRegisterModal(true)} className="btn btn-primary w-50">Register</button>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-3 margin-bottom-5">
                            <div className="col-md-12 box-radius-style br-0 price-cards">
                                <h3 className="h3-font-style text-black" style={{minHeight:'75px',fontSize: "13px"}}>Corporate Trust Professional</h3>
                                <p style={{minHeight:'80px',minHeight: "50px",fontSize: "11px",fontWeight: "400"}}>Get more business pushed to your<br/> organization.</p>
                                <h4 className="text text-primary font-weight-700">$1,500 per year</h4>
                                <div class="strike"><span>OR</span></div>
                                <h4 className="text text-primary font-weight-700">$500 per year</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                <div class="strike"><span>OR</span></div>
                                <h4 className="text text-primary font-weight-700">$50 per month</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                <div className="mt-4">
                                <button onClick={() => setRegisterModal(true)} className="btn btn-primary w-50">Register</button>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-3 margin-bottom-5">
                            <div className="col-md-12 box-radius-style br-0 price-cards">
                                <h3 className="h3-font-style text-black" style={{minHeight:'75px',fontSize: "13px"}}>Municipal <br/>Professional</h3>
                                <p style={{minHeight:'80px',minHeight: "50px",fontSize: "11px",fontWeight: "400"}}>Secure the lowest, most transparent costs<br/> for your municipality.</p>
                                <h4 className="text text-primary font-weight-700">$1,250 per year</h4>
                                <div class="strike"><span>OR</span></div>
                                <h4 className="text text-primary font-weight-700">$500 per year</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                <div class="strike"><span>OR</span></div>
                                <h4 className="text text-primary font-weight-700">$50 per month</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                <div className="mt-4">
                                <button onClick={() => setRegisterModal(true)} className="btn btn-primary w-50">Register</button>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-3 margin-bottom-5">
                            <div className="col-md-12 box-radius-style br-0 price-cards">
                                <h3 className="h3-font-style text-black" style={{minHeight:'75px',fontSize: "13px"}}>Public Debt Financial Advisors/Attorneys/Other</h3>
                                <p style={{minHeight:'80px',minHeight: "50px",fontSize: "10px",fontWeight: "400"}}>Help your clients obtain Corporate Trust services while <br/>saving you and them time and money.</p>
                                <h4 className="text text-primary font-weight-700">$1,250 per year</h4>
                                <div class="strike"><span>OR</span></div>
                                <h4 className="text text-primary font-weight-700">$500 per year</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                <div class="strike"><span>OR</span></div>
                                <h4 className="text text-primary font-weight-700">$50 per month</h4>
                                <p className='no-margin font-12'>plus</p>
                                <p className='no-margin font-12'>$25 processing fee per transaction</p>
                                <div className="mt-4">
                                <button onClick={() => setRegisterModal(true)} className="btn btn-primary w-50">Register</button>
                                </div>
                            </div>
                    </div>
                </div>
                    </div> 
                    
                </div>
            </section>
            <section class="call-to-action">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="column col-md-12 col-sm-12 col-xs-12">
                            <div class="text">Questions? We’ll put you on the right path.<br />Ask about pricing, implementation, or anything else. We are ready to help.</div>
                        </div>
                        <div class="column col-md-12 col-sm-12 col-xs-12">
                            <Link to={'/contact-us'} class="theme-btn btn-style-two">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            {
            (otpModal) ?
                <VerifyOtpModal userId={userId} username={username} customMessage={msg} handleVerifyModal={(status) => handleVerifyModal(status)}/> : null 
            }
            {
                (registerModal) ?
                    <RegisterModal handleRegisterModal={handleRegisterModal} usernameSet={
                        (username) => setUsername(username)
                    } useridSet={(id)=>setUserId(id)} handleVerifyModal={handleVerifyModal} customMessage={(msg) => setCodeMessage(msg)}/> : null 
            }
        </>
    );
};


export default Pricing;