import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import '../subscription/subs.css';

const AssignUserModal = ({
  handleConfirmCheck,
  handleConfirmClose,
  title,
  columns,
  data
}) => {

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '16px',
        minHeight: '50px'
      },
    },
    //heading row style
    headRow: {
      style: {
        backgroundColor: '#f5f6fa',
        marginTop: '0'

      }
    },
    cells: {
      style: {
        padding: '10px', // override the cell padding for data cells
        paddingRight: '8px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      },
    },
  };
  // const confirmData = props.subsData; 
  return (
    <>
      <div className="modal fade in d-block bd-example-modal-md term-condition" id="myModal" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content br-0" align="left">
            <div className="modal-header modal-header-primary br-0" style={{ background: 'white', color: 'black', padding: '10px 30px' }} align="left">
              <button type="button" onClick={e => handleConfirmClose()} className="close mdl-close-cfm" data-dismiss="modal">&times;</button>
              <h3 className="modal-title w-90">{title || ""}</h3>
            </div>
            <div className="modal-body" style={{ padding: '40px;', overflow: 'auto', maxHeight: '450px', padding: '0 15px' }}>
              <div className='row' style={{ padding: '0 15px', background: '#eaeaea' }}>
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  dense
                />
              </div>
              <div>
              </div>
            </div>
            {/* <div class="modal-footer">
                    <button type="button" onClick={e => handleConfirmCheck('confirm')} class="btn btn-primary">Save changes</button>
                    <button type="button" onClick={e => handleConfirmClose()} class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div> */}
          </div>

        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};


export default AssignUserModal;