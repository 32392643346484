import React, { useState, useEffect } from 'react';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from './step8';
import Step9 from './step9';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as RfpService from '@services/rfp';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import dateFormat from "dateformat";
import * as AppConstant from '@app/appConstants';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

const EditMuni = (props) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [data, setData] = useState({})
    const [checkData, setCheckData] = useState(false);
    const [services, setServices] = useState('')
    const [truste, setTrustee] = useState('')
    const [trusteNo, setNewTrusteeNo] = useState()
    let { uniqueNo, filterType } = useParams();
    const history = useHistory();
    const [finishStatus, setfinishStatus] = useState(false);
    const [additionalEmailMsg, setAdditionalEmailMsg] = useState('');
    const [customErr, setCustomErr] = useState('');


    const fileUploading = async (request, response, reqType) => {
        const checkSheetFile = (request.termSheet) ? request.termSheet : "";

        let fileData = new FormData();
        fileData.append('termSheet', request.termSheet);
        const checkAdditionalDoc = (request.additionalDocuments) ? request.additionalDocuments : [];
        for (const key of Object.keys(request.additionalDocuments)) {
            fileData.append('additionalDocuments', request.additionalDocuments[key])
        }
        fileData.append("id", response._id);
        fileData.append("uniqueNo", response.uniqueNo);
        fileData.append("userId", response.userId);
        try {
            const data = await RfpService.uploadDocuments(fileData);
            toast.success("Your request has been " + reqType);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.responseType && error.response.data.responseType == 'INVALID_USER_ACCESS') {
                setAdditionalEmailMsg(error.response.data.message);
            }
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    const getRfpDetails = async () => {
        try {
            const { rfpDetail } = await RfpService.GetRFPDetailsById(uniqueNo, 'municipality');
            setServices(rfpDetail.additionalServices)
            setTrustee(rfpDetail.trusteeList)
            setNewTrusteeNo(rfpDetail.noOfTrustee)
            setData(rfpDetail)
            setCheckData(true);
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }
    useEffect(() => {
        getRfpDetails();
    }, [])

    const onBackButtonEvent = (e) => {
        setIsSubmit(true)
        e.preventDefault();
        if (!finishStatus) {
            Swal.fire({
                title: 'Are you sure ?',
                text: "You want to exit without saving ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setfinishStatus(true)
                    setIsSubmit(false)
                    history.push('/saved-list');
                } else {
                    setIsSubmit(false)
                    window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'saved-list');
                    setfinishStatus(false)
                }
            });
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'saved-list');
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    /**
     * New Rfp Api
     */
    const addNewRfp = async (request) => {
        setIsSubmit(true)
        try {
            if (filterType == "import") {
                request.uniqueId = '';
            }
            const { data } = await RfpService.NewMunicipalRfp(request);
            if (data && data.saveRfpData) {
                fileUploading(request, data.saveRfpData, 'submitted')
            } else {
                toast.success('Your requested has been submitted.');
            }
            setIsSubmit(false)
            history.push('/previous-list');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.responseType && error.response.data.responseType == 'INVALID_USER_ACCESS') {
                setAdditionalEmailMsg(error.response.data.message);
            }
            setIsSubmit(false)
            //  toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }
    const formik = useFormik({
        initialValues: {
            userId: data.userId,
            uniqueId: uniqueNo,
            institution: data.institution,
            formType: data.formType,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            workTel: data.workTel,
            cellNo: data.cellNo,
            additionalFirstName: data.additionalFirstName,
            additionalLastName: data.additionalLastName,
            additionalEmail: data.additionalEmail,
            additionalWorkTel: data.additionalWorkTel,
            additionalCellNo: data.additionalCellNo,
            issuerName: data.issuerName,
            projectName: data.projectName,
            corporateTrustService: data.corporateTrustService,
            issuanceType: (data.issuanceType) ? data.issuanceType : '',
            issuanceAmount: data.issuanceAmount ?? [],
            issuanceCurrencyType: data.issuanceCurrencyType,
            issuanceTenure: data.issuanceTenure,
            tranchesNumber: data.tranchesNumber,
            issuanceIntendedDate: (filterType != "import") ? (data.issuanceIntendedDate) ? dateFormat(data.issuanceIntendedDate, "UTC:yyyy-mm-dd") : '' : '',
            firstTimeIssuer: data.firstTimeIssuer,
            exchangeIssuer: data.exchangeIssuer,
            issuanceRated: data.issuanceRated,
            corporateBorrower: data.corporateBorrower,
            ratedDescription: data.ratedDescription,
            transactionSummary: data.transactionSummary,
            termSheet: '',
            additionalDocuments: [],
            additionalServices: (data.additionalServices) ? data.additionalServices.toString() : '',
            additionalServicesDescription: data.additionalServicesDescription,
            cusipExchange: data.cusipExchangeCustom,
            offerServices: data.offerServices,
            expirationServices: data.expirationServices,
            legalFee: data.legalFee,
            legalType: data.legalType,
            additionalLegalFee: data.additionalLegalFee,
            noOfTrustee: data.noOfTrustee,
            trusteeList: (data.trusteeList) ? data.trusteeList.toString() : '',
            // noOfTrustee: trusteNo,
            // trusteeList: truste,
            submissionDate: (filterType != "import") ? (data.submissionDate) ? dateFormat(data.submissionDate, "UTC:yyyy-mm-dd") : '' : '',
            governingLaw: data.governingLaw,
            tiaQualified: data.tiaQualified
        },
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        validationSchema: Yup.object({
            institution: Yup.string()
                .required('* Required'),
            firstName: Yup.string()
                .required('* Required'),
            lastName: Yup.string()
                .required('* Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('* Required'),
            workTel: Yup.string()
                .required('* Required')
                .min(12, 'Minimum 10 digits'),
            cellNo: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalWorkTel: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalCellNo: Yup.string()
                .min(12, 'Minimum 10 digits'),
            additionalEmail: Yup.string()
                .email('Invalid additional email address'),
            issuerName: Yup.string()
                .required('* Required'),
            corporateTrustService: Yup.string()
                .required('* Required'),
            issuanceType: Yup.string()
                .required('* Required'),
            issuanceTenure: Yup.string()
                .required('* Required'),
            issuanceAmount: Yup.array()
                .required('* Required'),
            issuanceIntendedDate: Yup.string()
                .required('* Required'),
            firstTimeIssuer: Yup.string()
                .when(['corporateTrustService'], {
                    is: (corporateTrustService) => corporateTrustService != AppConstant.globalConst.MUNICORPORATETRUSTSERVICE,
                    then: Yup.string().required('* Required')
                }),
            // exchangeIssuer: Yup.string()
            // .when(['corporateTrustService'], {
            //     is: (corporateTrustService) => corporateTrustService != AppConstant.globalConst.MUNICORPORATETRUSTSERVICE,
            //     then: Yup.string().required('Exchange issuance is required')
            // }),
            issuanceRated: Yup.string()
                .when(['corporateTrustService'], {
                    is: (corporateTrustService) => corporateTrustService != AppConstant.globalConst.MUNICORPORATETRUSTSERVICE,
                    then: Yup.string().required('* Required')
                }),
            additionalServices: Yup.string()
                .required('* Required'),
            legalFee: Yup.string()
                .required('* Required'),
            legalType: Yup.string()
                .when('legalFee', {
                    is: 'yes',
                    then: Yup.string()
                        .required('* Required')
                }),
            noOfTrustee: Yup.string()
                .required('* Required'),
            trusteeList: Yup.string()
                .required('* Required')
                .test('oneOfRequired', 'Providers should match the selected number of trustee quotes.',
                    function () {
                        const providerCount = formik.values.trusteeList.toString().split(",").length;
                        if (formik.values.noOfTrustee != providerCount) {
                            return false
                        } else {
                            return true
                        }
                    }
                ),
            submissionDate: Yup.string()
                .required('* Required'),
        }),
        onSubmit: (values) => {
            setIsSubmit(true)
            values.asop = (values.asop) ? values.asop[0] : ''
            values.formType = 'complete'
            addNewRfp(values);
        }
    });
    const focusValidations = (errors) => {
        for (var i in errors) {
            if (i == 'additionalServices') {
                document.getElementsByClassName('additionalCls')[0].focus()
            } else if (i == 'trusteeList') {
                document.getElementsByClassName('trustListCls')[0].focus()
            } else {
                document.getElementsByName(i)[0].focus()
            }
            break;
        }
    }

    const onDraft = async () => {
        setIsSubmit(true)
        formik.values.formType = 'draft'
        formik.values.asop = (formik.values.asop) ? formik.values.asop[0] : ''
        let draftData = formik.values;
        if (!draftData.issuerName) {
            for (var i in formik.errors) {
                formik.setFieldError(i, '')
            };
            await formik.setFieldError('issuerName', "* Required")
            await formik.setFieldTouched('issuerName', true)
            setTimeout(() => {
                document.getElementsByName('issuerName')[0].focus()
                // focusValidations(formik.errors)
            }, 1000)
            // setCustomErr("Required")
            //toast.error("Issuer Name is required");
        } else {
            try {

                if (filterType == "import") {
                    draftData.uniqueId = '';
                }
                const { data } = await RfpService.NewMunicipalRfp(draftData);
                if (data && data.saveRfpData) {
                    fileUploading(draftData, data.saveRfpData, 'drafted')
                } else {
                    toast.success('Your requested has been drafted.');
                }
                setIsSubmit(false)
                history.push('/saved-list');
            } catch (error) {
                if (error.response && error.response.data && error.response.data.responseType && error.response.data.responseType == 'INVALID_USER_ACCESS') {
                    setAdditionalEmailMsg(error.response.data.message);
                }
                setIsSubmit(false)
                // toast.error(
                //     (error.response &&
                //         error.response.data &&
                //         error.response.data.message) ||
                //         'Something went wrong'
                // );
            }
        }
    }

    // useEffect(() => {
    //     const errors = formik.errors
    //     if(errors){
    //         focusValidations(errors)
    //     }
    // },[formik.errors])
    useEffect(() => {
        formik.setErrors({
            "institution": "",
            "firstName": "",
            "lastName": "",
            "email": "",
            "workTel": "",
            "issuerName": "",
            "corporateTrustService": "",
            "issuanceType": "",
            "issuanceTenure": "",
            "issuanceIntendedDate": "",
            "firstTimeIssuer": "",
            "issuanceRated": "",
            "additionalServices": "",
            "legalFee": "",
            "noOfTrustee": "",
            "trusteeList": "",
            "submissionDate": ""
        })

    }, [])

    const handleDropdown = (val) => {
        setTrustee([])
        setNewTrusteeNo(val);
    }

    const setCustomsFormVal = (key, value, type = '') => {
        formik.setFieldValue(key, value)
        if (value) {
            setTimeout(() => {
                formik.setFieldError(key, '')
            }, 1000)
        }
        if (!value && type == 'required') {
            setTimeout(async () => {
                await formik.setFieldError(key, "* Required")
                await formik.setFieldTouched(key, true)
            }, 1000)
        }
    }


    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ padding: '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundPosition: 'top center' }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="form-column col-md-12 col-sm-12 col-xs-12">
                            <h1 className="h1-text text-black">Bond Trustee / Escrow RFP Form</h1>
                        </div>
                        <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5">
                            <div className="col-md-12 main-form-outside bg-white">
                                <div className="main-title-head">
                                    <h1 className="h1-font-size-title">Your information</h1>
                                </div>
                                <form onSubmit={formik.handleSubmit} align="left">
                                    {(checkData) ?
                                        <>
                                            <Step1 formik={formik} loggedUserDetail={props.currentUser} additionalEmailMsg={additionalEmailMsg} setCustomsFormVal={setCustomsFormVal} />
                                            <Step2 formik={formik} formType={'edit'} loggedUserDetail={props.currentUser} customErr={customErr} setCustomsFormVal={setCustomsFormVal} />
                                            <Step3 formik={formik} formType={'edit'} loggedUserDetail={props.currentUser} additionalServices={val => setServices(val)} setCustomsFormVal={setCustomsFormVal} />
                                            {(filterType == "import") ? <Step4 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} /> : <Step4 formik={formik} formType={'edit'} term={data.termSheet ?? ''} addiDoc={data.additionalDocuments ?? []} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />}
                                            {(AppConstant.globalConst.SHOWTRANSACTIONSECTION.indexOf(formik.values.corporateTrustService) > -1) ? <Step5 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} /> : ''}
                                            <Step7 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />
                                            <Step8 formik={formik} formType={'edit'} loggedUserDetail={props.currentUser} oldTrusteeNo={(val) => handleDropdown(val)} trusteeList={val => setTrustee(val)} trusteeListData={truste} setCustomsFormVal={setCustomsFormVal} />
                                            <Step9 formik={formik} loggedUserDetail={props.currentUser} setCustomsFormVal={setCustomsFormVal} />
                                        </>
                                        :
                                        ''
                                    }
                                    <div className="form-row" align="right">
                                        <div className="col-md-12 btn-margin-top-bottom">
                                            <button type="button" disabled={isSubmit} onClick={(e) => onBackButtonEvent(e)} className="btn btn-primary btn-style-submit btn-back-style"><i className="fa fa-arrow-left"></i> Back</button>
                                            <button type="button" disabled={isSubmit} onClick={e => onDraft()} className="btn btn-success btn-style-submit rfp-button">Save</button>
                                            <button type="submit" disabled={isSubmit} onClick={e => focusValidations(formik.errors)} className="btn btn-success btn-style-submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

export default connect(mapStateToProps)(EditMuni);
