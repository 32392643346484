import React, { useState, useEffect } from 'react';
// import {
//     ChartComponent,
//     SeriesCollectionDirective,
//     SeriesDirective,
//     Inject,
//     Legend,
//     Category,
//     Tooltip,
//     ColumnSeries,
//     DataLabel,
//   } from '@syncfusion/ej2-react-charts';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid,
    Legend, Tooltip
} from 'recharts';
import PropTypes from 'prop-types';

const Graph = ({
    data,
    handleGraphModal
}) => {

    const [graphData, setGraphData] = useState([])
    const [secondGraphData, setSecondGraphData] = useState([])
    useEffect(() => {
        let tempGraphData = [];
        let tempSecondGraphData = [];
        for (let i = 0; i < data.length; i++) {
            tempGraphData.push({
                name: (data[i]) ? data[i].organizations.name : (i + 1).toString(),
                // TotalCostToIssuer: (data[i].legalAmount) ? parseInt(data[i].legalAmount) : 0,
                TotalCostToIssuer: (data[i].withoutLegalFee) ? parseInt(data[i].withoutLegalFee) : 0
            })
            // tempGraphData.push({
            //     x: (data[i]) ? data[i].organizations.name : (i + 1).toString(),
            //     y: parseInt(data[i].withLegalFee)
            // })
            // tempSecondGraphData.push({
            //     x: (data[i]) ? data[i].organizations.name : (i + 1).toString(),
            //     y: parseInt(data[i].withoutLegalFee)
            // })
        }
        setGraphData(tempGraphData)
        setSecondGraphData(tempSecondGraphData)
    }, [data])
    // console.log(graphData)
    return (
        <>
            <div className="modal fade in d-block bd-example-modal-lg auth-modal" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg" style={{ width: 'auto' }}>
                    <div className="modal-content" style={{
                        borderRadius: "20px"
                    }} align="left">
                        {/* <div className="modal-header login-modal-header" align="center">
                    </div> */}
                        <button type="button" onClick={e => handleGraphModal(false)} className="close mdl-close login-mdl-close" data-dismiss="modal">&times;</button>

                        <div className="modal-body" style={{ padding: '50px' }}>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        (graphData.length != 0 || secondGraphData.length != 0) ?
                                            <BarChart width={(graphData.length > 4) ? 900 : 160 * graphData.length} height={500} data={graphData} >
                                                <CartesianGrid />
                                                <XAxis dataKey="name" />
                                                <YAxis tickFormatter={(value) => new Intl.NumberFormat('en').format(value)} allowDataOverflow={true} />
                                                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                                <Legend />
                                                <Bar dataKey="TotalCostToIssuer" stackId="a" fill="#3169c6" />
                                                {/* <Bar dataKey="TotalCostToIssuer" stackId="a" fill="#99aff8" /> */}
                                            </BarChart>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className='col-md-12'>
                                {/* <div className='col-md-6'><p style={{
                                    float: 'right'
                                }}><span class='graph-clr-box graph-clr-box-green'></span> <span style={{ marginLeft: '5px' }}>Total Cost To Issuer</span></p></div> */}
                                <div className='col-md-12'><p style={{
                                    float: 'left'
                                }}><span class='graph-clr-box graph-clr-box-blue'></span> <span style={{ marginLeft: '5px' }}>Total Cost To Issuer</span></p></div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};
Graph.propTypes = {
    data: PropTypes.array.isRequired
};
export default Graph;