import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Step5 = (props) => {
  let formik = props.formik;
  const [inputList, setInputList] = useState([{ cusipType: "cusip", cusipExchange: "" }]);
  useEffect(() => {
    // console.log(formik.values.cusipExchange.length,'cusip')
    setInputList((formik.values.cusipExchange.length != 0) ? formik.values.cusipExchange : [{ cusipType: "cusip", cusipExchange: "" }]);
  }, [formik.values.cusipExchange]);


  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    // props.formik.values.cusipExchange = list ?? []
    props.setCustomsFormVal('cusipExchange', list ?? []);
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    // props.formik.values.cusipExchange = list ?? []
    props.setCustomsFormVal('cusipExchange', list ?? []);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { cusipType: "cusip", cusipExchange: "" }]);
  };


  const cusipDesign = inputList.map((x, i) => {
    return (
      <>
        <div className="col-md-12">
          {/* <div className="form-group form-group-margin col-md-3">
              <select className="form-control"  name="cusipType" value={x.cusipType} onChange={e => handleInputChange(e, i)}>
                    <option readOnly defaultValue="">CUSIP/ISIN</option>
                    <option value="cusip">CUSIP</option>
                    <option value="isin">ISIN</option>
                </select>
              </div> */}
          <div className="form-group form-group-margin col-md-8  p-0">
            <input type="text" className="form-control" name="cusipExchange" value={(x.cusipExchange) ? x.cusipExchange : ''} onChange={e => handleInputChange(e, i)} />
          </div>
          <div className="col-md-4">
            {inputList.length !== 1 && <button type="button"
              className="btn btn-danger " style={{ width: '50px', padding: '10px 12px', marginRight: '15px', marginTop: '10px' }}
              onClick={() => handleRemoveClick(i)}><i className='fa fa-minus'></i></button>}
            {inputList.length - 1 === i && <button type="button" style={{ width: '50px', padding: '10px 12px', marginTop: '10px' }} className="btn btn-success btn-class-add-remove" onClick={handleAddClick}><i className='fa fa-plus'></i></button>}
          </div>
        </div>
      </>
    );
  })

  return (
    <>
      {/* <div className="form-row">
            <div className="col-md-12 padding-0 margin-head-info">
                <div className="main-title-head text-center">
                    <h1 className="h1-font-size-title">ADDITIONAL SERVICES FOR Successor Trustee / Bond Defeasance TRANSACTIONS ONLY</h1>
                </div>
            </div>
        </div> */}
      {/* <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Additional Services for Exchange Transactions only</h2></div></div> */}
      <div className="form-row">
        <div className="form-group form-group-margin col-md-8" style={{ padding: '0px' }}>
          <div className="col-md-12">
            <label className="label-text">Please provide CUSIP information for each Issuance</label>
          </div>
          {cusipDesign}
          {formik.touched.cusipExchange && formik.errors.cusipExchange ? (
            <div className="text text-danger" align="left">{formik.errors.cusipExchange}</div>
          ) : null}
        </div>
        <div className="form-group form-group-margin col-md-4">
          <div style={{ paddingTop: '30px' }}>
            <ul>
              <li><h4><Link onClick={() => window.open("https://sec.report/Ticker/", '_blank')} title="CUSIP">CUSIP Lookup</Link></h4></li>
              <li><h4><Link onClick={() => window.open("https://emma.msrb.org/IssuerHomePage/Map", '_blank')} title="MSRB:EMMA CUSIP Search">MSRB:EMMA CUSIP Search</Link></h4></li>
            </ul>
          </div>
        </div>
      </div>

    </>
  );
};


export default Step5;