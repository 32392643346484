import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import 'react-credit-cards/es/styles-compiled.css';
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import LoginModal from '@pages/main/LoginModal';
import ForgetModel from '@pages/forgot-password/ForgetModal';
require("dotenv").config();

const Payment = (props) => {

    const history = useHistory();
    let { isLogin } = useParams();
    const [loginModal, setLoginModal] = useState(false);
    const [forgetModal, setForgetModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('preloader').style.display = "none";
    }, []);
   console.log("props",props)
    const handleForgetModal = (status) => {
        if(status){
            setLoginModal(false)
        }
        setForgetModal(status)
    }

    const handleLoginModal = (status) => {
        if(status){
            setForgetModal(false)
        }
        setLoginModal(status)
    }

    let emailTemplate = '';
    if(isLogin == 'updatedetail'){
        emailTemplate =  <>
        <section >
            <div className="auto-container">
                <h1 className="h1-text text-black">Update Card Info.</h1>
            </div>
        </section>
        <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
            <div className="auto-container">
                <div className="row clearfix row-container-margin-width-pay">
                    <div className="col-md-12 margin-top-5 form-edit-col-main">
                    <div className="col-md-12 edit-profile-image">
                            <img className="edit-profile-image" style={{ width: '50%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/success-pay.gif"} />
                        </div>
                        <div className="col-md-12 form-edit-col">
                            <div className="col-md-12 unset-padding text-dark">
                                <><h4><b>Your card has been updated successfully and set as default payment card for subscription. Please <Link to={'/'}>click here</Link> to proceed.</b></h4></>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    }else if(isLogin == 'cancelsubs'){
        emailTemplate =  <>
        <section >
            <div className="auto-container">
                <h1 className="h1-text text-black">Subscription Cancelled.</h1>
            </div>
        </section>
        <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
            <div className="auto-container">
                <div className="row clearfix row-container-margin-width-pay">
                    <div className="col-md-12 margin-top-5 form-edit-col-main">
                    <div className="col-md-12 edit-profile-image">
                            <img className="edit-profile-image" style={{ width: '25%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/cancel-sub.png"} />
                        </div>
                        <div className="col-md-12 form-edit-col">
                            <div className="col-md-12 unset-padding text-dark">
                                <><h4><b>Your subscription has been cancelled. Please <Link to={'/'}>click here</Link> to proceed.</b></h4></>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    }else{
        emailTemplate = <>
        <section >
        <div className="auto-container">
            <h1 className="h1-text text-black">Subscription</h1>
        </div>
    </section>
    <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
        <div className="auto-container">
            <div className="row clearfix row-container-margin-width-pay">
                <div className="col-md-12 margin-top-5 form-edit-col-main" >
                <div className="col-md-12 edit-profile-image">
                        <img className="edit-profile-image" style={{ width: '50%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/success-pay.gif"} />
                    </div>
                    <div className="col-md-12 form-edit-col">
                        <div>
                            <h1><strong>Subscribed successful.</strong></h1>
                        </div>
                        <div className="col-md-12 unset-padding text-dark" style={{color: 'black !important'}}>
                            {(isLogin == 'register' && !props.isLoggedIn)?
                            <><h1><b>Your registration is complete.</b></h1>
                            <h4>You will receive confirmation email shortly. Please <span style={{cursor:'pointer', color: 'cornflowerblue'}} onClick={()=>setLoginModal(true)}>click here</span> to log-in.</h4></>
                            :
                            <><h1><b>Your subscription is complete.</b></h1>
                            <h4>You will receive confirmation email shortly. Please <Link to={'/'}>click here</Link> to proceed.</h4></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {
    (loginModal) ?
    <><LoginModal handleLoginModal={(status) =>setLoginModal(status)} handleRegisterModal="" handleForgetModal={handleForgetModal} usernameSet="" useridSet=""  handleVerifyModal="" customMessage=""/></> : null 
    }
    {
        (forgetModal) ?
            <ForgetModel handleForgetModal={handleForgetModal} handleLoginModal={(status) => handleLoginModal(status)}/> : null 
    }
    </>
    }


    return (
        <>
        {emailTemplate}
        </>
    )
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);