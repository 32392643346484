import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Login.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import * as ActionTypes from '@store/actions';
import { connect } from 'react-redux';
import "react-phone-input-2/lib/style.css";
import PhoneInput from 'react-phone-input-2';
import './Custom.css';
import store from '@store/index';
import TermModal from '@pages/main/TermConditionModal';

const RegisterModal = (props) => {

    const history = useHistory();
    const [roleopt, setRoleOpt] = useState([]);
    const [orgopt, setOrgOpt] = useState([]);
    const [otherRole, setOtherRole] = useState(false);
    const [otherOrg, setOtherOrg] = useState(false);
    const [roleValue, setRoleValue] = useState("");
    const [orgValue, setOrgValue] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
    const [mailmsg, setMailMsgValue] = useState(false);
    const [termModal, setTermModal] = useState(false);
    const [termCheck, setTermCheck] = useState(false);

    const getRole = async () => {
        try {
            const role = await AuthService.GetRole();
            setRoleOpt(role.roleData ?? []);
        } catch (error) {
            // console.log(error);
            // if (error.message) {
            //     toast.error(error.message);
            // } else {
            //     toast.error("Something went wrong");
            // }
        }
    }

    const getOrganization = async (val) => {
        try {
            const organization = await AuthService.GetOrganization(val);
            setOrgOpt(organization.organizationData ?? []);
        } catch (error) {
            // if (error.message) {
            //     toast.error(error.message);
            // } else {
            //     toast.error("Something went wrong");
            // }
        }
    }

    useEffect(() => {
        getRole();
    }, [])


    const setCustomsFormVal = (key,value,type) => {
        formik.setFieldValue(key, value)
        if(value){
            setTimeout(()=>{
                formik.setFieldError(key, '')
            },1000)
        }
        if(!value && type == 'required'){
            setTimeout(async()=>{
                await formik.setFieldError(key, "* Required")
                await formik.setFieldTouched(key, true)
            },1000)
        }
        if(key == 'password' && value){
            if (!/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(value)) {
                setTimeout(async()=>{
                    await formik.setFieldError(key, "Password must contain 8 characters, One uppercase,  One number and One special case character")
                    await formik.setFieldTouched(key, true)
                },1000)
            }
        }
    }

    const togglePasswordVisiblity = (typeOf) => {
        if(typeOf == 'password'){
            setPasswordShown(passwordShown ? false : true);
        }
        if(typeOf == 'confirm'){
            setPasswordConfirmShown(passwordConfirmShown ? false : true);
        }
      };


    const userDetail = async (value) => {
        let username = value.email;
        let password = value.password;
        value.acceptTerms = undefined;
        value.passwordConfirmation = undefined;
        value.isSubscribed =  false
        // setMailMsgValue(false)
        try {
            const { userData } = await AuthService.registerUser(value);
            if (userData) {
                const signUpData = await Auth.signUp({
                        username,
                        password,
                        attributes: {
                            email: username,
                            'custom:user_type': userData.role,
                            'custom:organization': userData.organization,
                            name: value.firstName,
                            family_name: value.lastName,
                            phone_number: (value.phoneNo) ? value.phoneNo : '',
                            'custom:id': userData._id
                        }
                    });
                    props.usernameSet(signUpData.userSub)
                    props.useridSet(userData._id)
                // props.handleRegisterModal(false)
                setMailMsgValue(true)
                props.handleVerifyModal(true)
                // setTimeout(()=>setMailMsgValue(false),3000)
                props.customMessage("Please check your email for verification code. Check spam/junk folder also.");
                // toast.success("Please check your email for verification code. Check spam/junk folder also."); 
                setRoleValue('');
                setOrgValue('');
                formik.resetForm();
                // history.push('/');

            } else {
                toast.error("Something went wrong");
            }
            document.getElementById('preloader').style.display = "none";

        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // if (error.response) {
            //     toast.error(error.response.data.message);
            // } else {
            //     toast.error('Something went wrong');
            // }
        }
    }
   




    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
            password: '',
            role: '',
            organization: '',
            roleName: '',
            organizationName: '',
            isSubscribed: false,
            acceptTerms: false
        },
        enableReinitialize: false,
        validationSchema: Yup.object({
            firstName: Yup.string()
                .test('alphabets', 'First Name must only contain alphabets', (value) => {
                    return /^[a-zA-Z\s]*$/.test(value);
                })
                .required('First Name is required'),
            lastName: Yup.string()
                .test('alphabets', 'Last Name must only contain alphabets', (value) => {
                    return /^[a-zA-Z\s]*$/.test(value);
                })
                .required('Last Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email address is required'),
            phoneNo: Yup.string().min(12,'Minimum 10 digits'),
            password: Yup.string()
                .required('Password is required')
                .matches(
                    /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Password must contain 8 characters, One uppercase,  One number and One special case character"
                  ),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Password does not match'),
            role: Yup.string()
                .required('Please select role.'),
            organization: Yup.string()
                .required('Please select organization.'),
            acceptTerms: Yup.bool().
                oneOf([true], ' Terms & Conditions is required')
        }),
        onSubmit: (values) => {
            let checkError = false;
            if (otherRole && roleValue == '') {
                toast.error("Role name is required.");
                checkError = false;
            } else {
                values.roleName = roleValue;
                checkError = true;
            }
            if (otherOrg && orgValue == '') {
                toast.error("Organization name is required.");
                checkError = false;
            } else {
                values.organizationName = orgValue;
                checkError = true;
            }
            if (checkError) {
                userDetail(values);
                document.getElementById('preloader').style.display = "block";
            }
        }
    });


    const handleTermClose = () => {
		setTermModal(false);
	};

    const handleTermShow = () => {
		setTermModal(true);
	}

    const handleTermCheck = () => {
		setTermCheck(termCheck ? false : true);
        setTermModal(false);
	}
    
    useEffect(() => {
        // formik.values.acceptTerms = termCheck;
        formik.setFieldValue('acceptTerms', termCheck)
    }, [termCheck == true])


    const selectedRole = (selRole) => {
        if (selRole == 'other') {
            setOtherRole(true);
        } else {
            if (selRole) {
                getOrganization(selRole);
            } else {
                setOrgOpt([]);
            }
            setOtherRole(false);
        }
        // formik.values.role = (selRole) ? selRole : '';
        setCustomsFormVal('role',(selRole) ? selRole : '','required');
    }

    const selectedOrganization = (selOrg) => {
        if (selOrg == 'other') {
            setOtherOrg(true);
        } else {
            setOtherOrg(false);
        }
        // formik.values.organization = (selOrg) ? selOrg : '';
        setCustomsFormVal('organization',(selOrg) ? selOrg : '','required');
    }

    const roleDesign = roleopt.length > 0
        && roleopt.map((item, i) => {
            return (
                <option value={(item) ? item._id : ''}>{(item) ? item.name : ''}</option>
            );
        });

    const orgDesign = orgopt.length > 0
        && orgopt.map((item, i) => {
            return (
                <option value={(item) ? item._id : ''}>{(item) ? item.name : ''}</option>
            );
        });
    
    const handlePhoneNo = (phone) => {
        // formik.values.phoneNo = "+" + phone
        setCustomsFormVal('phoneNo',(phone.length >= 2)?"+"+phone:'');
        if(phone && phone.length < 12){
            setTimeout(async()=>{
                await formik.setFieldError('phoneNo', "Minimum 10 digits")
                await formik.setFieldTouched('phoneNo', true)
            },1000)
        }else{
            setTimeout(async()=>{
                await formik.setFieldError('phoneNo', "")
                await formik.setFieldTouched('phoneNo', false)
            },1000)
        }
    }
    

    return (
        <>
            <div className="modal fade in d-block bd-example-modal-lg auth-modal" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content br-0" align="left">
                        {/* <div className="modal-header login-modal-header" align="center"> */}
                        <button type="button" onClick={e => props.handleRegisterModal(false)} className="close mdl-close login-mdl-close" data-dismiss="modal">&times;</button>
                        {/* </div> */}
                        <div className="modal-body" style={{ padding: '40px' }}>
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/Login.png"} style={{
                                        marginTop: '30%'
                                    }} />
                                </div>
                                <div className="col-md-8">
                                    <div className="col-md-12 col-sm-12">
                                        <div align="center" className="pt-2">
                                            <h2 className="main-title">Secure Register</h2>
                                            <p>Please Register to your account.</p>
                                        </div>
                                        <div className="default-form contact-form">
                                            <form onSubmit={formik.handleSubmit} id="contact-form">
                                                <div className="row clearfix" style={{marginBottom:"10px"}}>
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <input type="text" name="firstName" placeholder="First Name *" {...formik.getFieldProps('firstName')} />
                                                                {formik.touched.firstName && formik.errors.firstName ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.firstName}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <input type="text" name="lastName" placeholder="Last Name *" {...formik.getFieldProps('lastName')} />
                                                                {formik.touched.lastName && formik.errors.lastName ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.lastName}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <input type="text" name="email" placeholder="Email *" {...formik.getFieldProps('email')} />
                                                                {formik.touched.email && formik.errors.email ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.email}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <PhoneInput
                                                                    className="phone-no-input"
                                                                    country={'us'}
                                                                    placeholder="Phone No (optional)"
                                                                    countryCodeEditable={false}
                                                                    onChange={phone => handlePhoneNo(phone)}
                                                                    // onChange={phone => formik.values.phoneNo = (phone) ? "+" + phone : ''}
                                                                    value={(formik.values.phoneNo.length > 2)?formik.values.phoneNo:'+1'}
                                                                />
                                                                {formik.touched.phoneNo && formik.errors.phoneNo ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.phoneNo}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <input type={passwordShown ? "text" : "password"} name="password" placeholder="Password *" onChange={e=> setCustomsFormVal('password',e.target.value,'required')} />
                                                                <span className="p-viewer" onClick={e=>togglePasswordVisiblity('password')}><i class={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></span>
                                                                {formik.touched.password && formik.errors.password ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.password}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <input type={passwordConfirmShown ? "text" : "password"} name="passwordConfirmation" placeholder="Confirm Password *" onChange={e=> setCustomsFormVal('passwordConfirmation',e.target.value,'required')} />
                                                                <span className="p-viewer" onClick={e=>togglePasswordVisiblity('confirm')}><i class={passwordConfirmShown ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></span>
                                                                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.passwordConfirmation}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <select className="form-control" id="sel" name="role" defaultValue={formik.values.role} onChange={e => selectedRole(e.target.value)}>
                                                                    <option selected value="">Select Role</option>
                                                                    {roleDesign ?
                                                                        roleDesign
                                                                        :
                                                                        ''
                                                                    }
                                                                    {
                                                                        (roleDesign) ?
                                                                            <option value="other">Other</option> :
                                                                            ""
                                                                    }
                                                                </select>
                                                                {formik.touched.role && formik.errors.role ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.role}</div>
                                                                ) : null}
                                                            </div>
                                                            {(otherRole) ?
                                                                <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                    <input type="text" name="otherrole" placeholder="Role Name *" value={roleValue} onChange={e => setRoleValue(e.target.value)} />
                                                                </div>
                                                                : ''}

                                                            <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                <select className="form-control" id="sel" name="organization" defaultValue={formik.values.organization} onChange={e => selectedOrganization(e.target.value)}>
                                                                    <option selected value="">Select Organization</option>
                                                                    {(orgDesign) ? orgDesign : ''}
                                                                    <option value="other">Other</option>
                                                                </select>
                                                                {formik.touched.organization && formik.errors.organization ? (
                                                                    <div className="text text-danger" align="left">{formik.errors.organization}</div>
                                                                ) : null}
                                                            </div>
                                                            {
                                                                (otherOrg) ?
                                                                    <div className="form-group col-md-6 col-sm-12 col-xs-12">
                                                                        <input type="text" name="otherorg" placeholder="Organization Name *" value={orgValue} onChange={e => setOrgValue(e.target.value)} />
                                                                    </div>
                                                                    : ''}
                                                        </div>

                                                    </div>

                                                    <div className="checkboxes col-md-12 font-18 text-align-left" style={{textAlign:"center",clear: "both"}}>
                                                        <label>

                                                            <input type="checkbox" checked={termCheck}  name="acceptTerms" className="remember-box" />

                                                            <span> I have read and agree to the <a onClick={e=>handleTermShow()}>Terms Of Use statement</a> <span className="text text-danger">*</span></span>

                                                        </label>
                                                        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                                                            <div className="text text-danger " style={{ fontSize: "14px" }} align="left">{formik.errors.acceptTerms}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign:"center"}} >
                                                        <button type="submit" className="theme-btn btn-style-one btn-lg active-btn" >Sign Up</button>
                                                    </div>
                                                    {/* <div className="col-md-6 col-sm-6 col-xs-6 text-align-left">
                                                        <button type="button" className="theme-btn btn-style-one btn-lg in-active-btn" onClick={() => props.handleLoginModal(true)}>Login</button>
                                                    </div> */}
                                                </div>
                                                {(mailmsg)?
                                                <div className="alert alert-success">
                                                    <span>
                                                        Please check your email for verification link. Check spam/junk folder also.
                                                    </span>
                                                </div>
                                                :null }
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
            {(termModal)?<TermModal termCheck={termCheck} handleTermCheck={e => handleTermCheck()}  handleTermClose={e=>handleTermClose()}/>:''}
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);