import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './PasswordReset';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import * as ActionTypes from '@store/actions';
import { connect } from 'react-redux';
import store from '@store/index';


const ForgetModal = (props) => {
    const [resetModal,setResetModal] = useState(false);
    const history = useHistory();
    /**
     * 
     * @param {*} value 
     */
    const forgotPassword = async (value) => {
        let username = value.email;
        try {
            const { userData } = await AuthService.checkEmailExist(value);
            if (userData.email) {
                await Auth.forgotPassword(username);
               // toast.success("Otp sent to your E-mail. please check");
                history.push('/reset-password',"Otp sent to your E-mail. please check" );
                props.handleForgetModal(false)

            } else {
                toast.error("Entered email does not exist. Please check your email");
            }
            document.getElementById('preloader').style.display = "none";
        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // toast.error(
            //     (error.message) ||
            //     'Failed'
            // );
        }
    }
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email address is required')
        }),
        onSubmit: (values) => {
            forgotPassword(values);
            document.getElementById('preloader').style.display = "block";
        }
    });

    return (
        <>
            <div className="modal fade in d-block bd-example-modal-lg auth-modal" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content br-0" align="left">
                        <div className="modal-header login-modal-header" align="center">
                            <button type="button" onClick={e => props.handleForgetModal(false)} className="close mdl-close login-mdl-close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body" style={{ padding: '40px' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/Login.png"} />
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-12 col-sm-12">
                                        <div align="center" className="pt-2">
                                            <h2 className="main-title">Reset Password</h2>

                                        </div>
                                        <div className="default-form contact-form">
                                            <form onSubmit={formik.handleSubmit} id="contact-form">
                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        <div className="form-group">
                                                            <input type="text" name="email" placeholder="Email *" {...formik.getFieldProps('email')} />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text text-danger" align="left">{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-xs-6 text-align-right">
                                                        <button type="submit" className="theme-btn btn-style-one btn-lg active-btn">Reset</button>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-xs-6 text-align-left">
                                                    <button type="button" className="theme-btn btn-style-one btn-lg in-active-btn" onClick={() => props.handleLoginModal(true)}>Log in</button>
                                                
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );

}
export default ForgetModal;