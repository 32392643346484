import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var _ = require('lodash');

const Step2 = ({formik, services, formType, issuanceAmount, rfpDetails, filterType, setCustomsFormVal}) => {
    const [custom,setCustom] = useState([]);
    const [corporateVal,setCorporateVal] = useState([]);
    const [rfpService,setRfpService] = useState([]);
    const [isCustom,setIsCustom] = useState(false);
    
    useEffect(() => {
        setTimeout(() => {
            if(formik.values.typeOfServices.length != 0){
                setCustom(formik.values.typeOfServices)
            }else{
                const emptySer = []; 
                if(services && services.length > 0){
                  for (let index = 0; index < services.length; index++) {
                    emptySer.push({
                        id : services[index]._id,
                        currency : '$',
                        acceptanceFee : "0",
                        annualFee : "0",
                    });
                  }
                }
                // formik.values.typeOfServices = emptySer
                setCustomsFormVal('typeOfServices',emptySer);
                setCustom(emptySer);
            }
    
            if(formik.values.corporateFees.length != 0){
                setCorporateVal(formik.values.corporateFees);
            }else{
                const emptyCorp = []; 
                if(issuanceAmount && issuanceAmount.length > 0){
                  for (let index = 0; index < issuanceAmount.length; index++) {
                    emptyCorp.push({
                        type : issuanceAmount[index].type,
                        currency : issuanceAmount[index].issuanceCurrencyType,
                        corporateAcceptanceFee : '0',
                        corporateAnnualFee : '0',
                    });
                  }
                } 
                // formik.values.corporateFees = emptyCorp
                setCustomsFormVal('corporateFees',emptyCorp);
                setCorporateVal(emptyCorp);
            }
        },2000)
    },[issuanceAmount,services])

    const serviceValue = (id,e) => {
        let valueCheck = _.find(custom, (o) => { return _.isMatch(o.id, id) });
        if(valueCheck){
            const name = e.target.name;
            if(name == 'issuanceCurrencyType'){
                valueCheck.issuanceCurrencyType = e.target.value
            }else if(name == 'acceptanceFee' || name == "annualFee"){
                if('/^\d+$/',/^\d+$/.test(e.target.value.replaceAll(',',''))){
                    valueCheck[name] = e.target.value.replaceAll(',','')
                    e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")    
                }else{
                    e.target.value = "";
                    valueCheck[name] = "0";
                    // toast.error("Value must be a number");
                }
                // valueCheck[name] = e.target.value.replaceAll(',','')
                // e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            }
        }else{
            custom.push({
                id : id,
                currency : '$',
                [e.target.name]: e.target.value
            })
        }
        setCustom(custom)
        setIsCustom(true)
        console.log(custom)
        // formik.values.typeOfServices = custom
        setCustomsFormVal('typeOfServices',custom);
    }
    useEffect(() => {
        setIsCustom(false)
    },[isCustom])

    useEffect(() => {
        if(rfpDetails && rfpDetails.additionalServices){
            let followingIds = rfpDetails.additionalServices.length > 0 && rfpDetails.additionalServices.map(service => service._id);
            setRfpService(followingIds)
        }
    },[rfpDetails.additionalServices])

    let serviceDesign = "";
    
    if(!formType || formik.values.typeOfServices.length == 0){
        serviceDesign = services.length > 0
        && services.map((item, i) => {
        return <>
                    <div className="form-group col-md-12">
                        <div className="col-md-3">
                            <label className="label-text">{item.name ?? ''}</label>
                        </div>

                        <div className="col-md-8 p-0">
                        <div className="col-md-6">
                        <div className="form-row">
                                <div className="col-md-3 p-0">
                                   <input type="text" className="form-control" readOnly disabled value="$" />
                                </div>
                                <div className="col-md-9 p-0">
                                <input type="text" className="form-control" onChange={e=>serviceValue(item._id,e)}  name="acceptanceFee"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-row">
                                <div className="col-md-3 p-0">
                                   <input type="text" className="form-control" className="form-control" readOnly disabled value="$" />
                                </div>
                                <div className="col-md-9 p-0">
                                <input type="text" className="form-control" onChange={e=>serviceValue(item._id,e)} name="annualFee"/>
                                </div>
                            </div>
                        </div>
                        </div>


                    </div>
                </>;
        });
    }else if(filterType == 'import'){
            
        serviceDesign = services.length > 0
        && services.map((item, i) => {
              var data = custom.filter(element => element.id == item._id)
              if(data && data[0]){
                    return <>
                    <div className="form-group col-md-12">
                        <div className="col-md-3">
                            <label className="label-text">{item.name ?? ''}</label>
                        </div>

                        <div className="col-md-8 p-0">
                        <div className="col-md-6">
                        <div className="form-row">
                                <div className="col-md-3 p-0">
                                <input type="text" className="form-control" readOnly disabled value="$" />
                                </div>
                                <div className="col-md-9 p-0">
                                <input type="text" className="form-control" onChange={e=>serviceValue(item._id,e)}  name="acceptanceFee" defaultValue={(data[0].acceptanceFee == '0') ? '' : data[0].acceptanceFee}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-row">
                                <div className="col-md-3 p-0">
                                <input type="text" className="form-control" className="form-control" readOnly disabled value="$" />
                                </div>
                                <div className="col-md-9 p-0">
                                <input type="text" className="form-control" onChange={e=>serviceValue(item._id,e)} name="annualFee" defaultValue={(data[0].annualFee == '0') ? '' : data[0].annualFee}/>
                                </div>
                            </div>
                        </div>
                        </div>


                    </div>
                </>;
              }else{
                    return <>
                    <div className="form-group col-md-12">
                        <div className="col-md-3">
                            <label className="label-text">{item.name ?? ''}</label>
                        </div>

                        <div className="col-md-8 p-0">
                        <div className="col-md-6">
                        <div className="form-row">
                                <div className="col-md-3 p-0">
                                <input type="text" className="form-control" readOnly disabled value="$" />
                                </div>
                                <div className="col-md-9 p-0">
                                <input type="text" className="form-control" onChange={e=>serviceValue(item._id,e)}  name="acceptanceFee"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-row">
                                <div className="col-md-3 p-0">
                                <input type="text" className="form-control" className="form-control" readOnly disabled value="$" />
                                </div>
                                <div className="col-md-9 p-0">
                                <input type="text" className="form-control" onChange={e=>serviceValue(item._id,e)} name="annualFee"/>
                                </div>
                            </div>
                        </div>
                        </div>


                    </div>
                </>;
              }
        });   
    }else{
        serviceDesign = custom.length > 0
        && custom.map((item, i) => {
                return <>
                            <div className="form-group col-md-12">
                                <div className="col-md-3">
                                    <label className="label-text">{services[i] ? services[i].name : ''}</label>
                                </div>
                                <div className="col-md-8 p-0">
                                <div className="col-md-6">
                                <div className="form-row">
                                        <div className="col-md-3 p-0">
                                        <input type="text" className="form-control" readOnly disabled value="$" />
                                        </div>
                                        <div className="col-md-9 p-0">
                                        <input type="text" className="form-control" onChange={e=>serviceValue(item.id,e)} name="acceptanceFee" defaultValue={(item.acceptanceFee == '0') ? '' : item.acceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-row">
                                        <div className="col-md-3 p-0">
                                        <input type="text" className="form-control" readOnly disabled value="$" />
                                        </div>
                                        <div className="col-md-9 p-0">
                                        <input type="text" className="form-control" onChange={e=>serviceValue(item.id,e)} name="annualFee" defaultValue={(item.annualFee == '0') ? '' : item.annualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}/>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </>;
        });
    }

   
    const corporateValue = (itm,e) => {
        let valueCheck = _.find(corporateVal, (o) => { return _.isMatch(o.type, itm) });
        if(valueCheck){
            const name = e.target.name;
            if(name == 'issuanceCurrencyType'){
                valueCheck.issuanceCurrencyType = e.target.value
            }else if(name == 'corporateAcceptanceFee' || name == "corporateAnnualFee"){
                // console.log(e.target.value,"vall")
                    if('/^\d+$/',/^\d+$/.test(e.target.value.replaceAll(',',''))){
                        valueCheck[name] = e.target.value.replaceAll(',','')
                        e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")    
                    }else{
                        e.target.value = "";
                        valueCheck[name] = "0";
                        // toast.error("Value must be a number");
                    }

                // valueCheck[name] = e.target.value.replaceAll(',','')
                // e.target.value = valueCheck[name].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            }
        }else{
            corporateVal.push({
                type : itm.type,
                currency : itm.issuanceCurrencyType,
                [e.target.name]: e.target.value
            })
        }
        setCorporateVal(corporateVal);
        // console.log(corporateVal,"corpval");
        // formik.values.corporateFees = corporateVal;
        setCustomsFormVal('corporateFees',corporateVal);
    }

    
    let corporateDesign = corporateVal && corporateVal.length > 0
    && corporateVal.map((item, i) => {
    return <>
                <div className="col-md-3" style={{textTransform: 'capitalize'}}>
                    {
                        i == 0 ? <label className="label-text">{formik.values.corporateService.replaceAll('_', ' ')}</label>:
                        null
                    }
                </div>
               <div className="col-md-8 p-0">
                    <div className="col-md-6" style={{
                            marginBottom: '20px'
                        }}>
                            <div className="form-row">
                                <div className="col-md-3 p-0 mb-2">
                                   <input type="text" className="form-control" readOnly disabled value={item.currency} />
                                </div>
                                <div className="col-md-9 p-0 mb-2">
                                      <input type="text" className="form-control"  name="corporateAcceptanceFee" onChange={e=>corporateValue(item.type,e)} defaultValue={(item.corporateAcceptanceFee == 0) ? '' :  item.corporateAcceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}/>
                                </div>
                            </div>
                            
                            {formik.touched.corporateAcceptanceFee && formik.errors.corporateAcceptanceFee ? (
                                <div className="text text-danger" align="left">{formik.errors.corporateAcceptanceFee}</div>
                            ) : null}
                    </div>
                    <div className="col-md-6">
                            <div className="form-row">
                                <div className="col-md-3 p-0">
                                   <input type="text" className="form-control" readOnly disabled value={item.currency} />
                                </div>
                                <div className="col-md-9 p-0">
                                   <input type="text" className="form-control"  name="corporateAnnualFee" onChange={e=>corporateValue(item.type,e)} defaultValue={(item.corporateAnnualFee == 0) ? '' :  item.corporateAnnualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}/>
                                </div>
                            </div>
                            {formik.touched.corporateAnnualFee && formik.errors.corporateAnnualFee ? (
                                <div className="text text-danger" align="left">{formik.errors.corporateAnnualFee}</div>
                            ) : null}
                    </div>
                </div>
            </>;
    });

    const initAccFee = (e) => {
        // e.target.value =  e.target.value.replaceAll(',','').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        // formik.values.additionalAcceptanceFee = e.target.value.replaceAll(',','');
        if('/^\d+$/',/^\d+$/.test(e.target.value.replaceAll(',',''))){
            // formik.values.additionalAcceptanceFee = e.target.value.replaceAll(',','')
            const initAmtVal = e.target.value.replaceAll(',','')
            setCustomsFormVal('additionalAcceptanceFee',initAmtVal);
            e.target.value = initAmtVal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")    
        }else{
            e.target.value = "";
            setCustomsFormVal('additionalAcceptanceFee','0');
            // toast.error("Additional Acceptance Fee must be a number");
        }
    }

    const initAnnFee = (e) => {
        // e.target.value =  e.target.value.replaceAll(',','').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        // formik.values.additionalAnnualFee = e.target.value.replaceAll(',','');
        if('/^\d+$/',/^\d+$/.test(e.target.value.replaceAll(',',''))){
            // formik.values.additionalAnnualFee = e.target.value.replaceAll(',','')
            const initAnnVal = e.target.value.replaceAll(',','')
            setCustomsFormVal('additionalAnnualFee',initAnnVal);
            e.target.value = initAnnVal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")    
        }else{
            e.target.value = "";
            setCustomsFormVal('additionalAnnualFee','0');
            // toast.error("Additional Annual Fee must be a number");
        }
    }


    const issuancsAmtDesign = rfpDetails && rfpDetails.issuanceAmount && rfpDetails.issuanceAmount.length > 0
    && rfpDetails.issuanceAmount.map((item, i) => {
        if(item.issuanceAmount && item.issuanceAmount != 0){
            return <>
            {(i != 0)?', ':''}<span>{(item.issuanceCurrencyType)?item.issuanceCurrencyType:'----'} {(item.issuanceAmount)?item.issuanceAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):'----'}</span>
            </>
        }else{
            return <>
            {(i != 0)?', ':''}<span>----</span>
            </>
        }
    });
    return (
        <>
         <div className="form-row">
            <div className="col-md-12 padding-0 margin-head-info" style={{marginBottom: '0px'}}>
                <div className="main-title-head text-center">
                    <h1 className="h1-font-size-title">Services</h1>
                </div>
            </div>
        </div>
        {/* <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Services</h2></div></div> */}
        <div className="col-md-12 rfp-info" style={{background: '#fbfbfb', marginTop: '0px'}} align="center">
            <div className="col-md-2">
                <label className="label-text label-service">Institution </label>
                <div className="crf-detail-1"><label className="text text-primary text-cap">{(rfpDetails)?rfpDetails.institution:'----'} </label></div>
            </div>
            <div className="col-md-2">
                <label className="label-text label-service">Name of Issuer </label>
                <div className="crf-detail-1"><label className="text text-primary text-cap">{(rfpDetails)?rfpDetails.issuerName:'----'}  {(rfpDetails)?(rfpDetails.corporateBorrower)?'('+rfpDetails.corporateBorrower+')':'':''}</label></div>
            </div>
            <div className="col-md-2">
                <label className="label-text label-service">Issuance Type </label>
                <div className="crf-detail-1"><label className="text text-primary text-cap">{(rfpDetails)?(rfpDetails.issuanceType)?rfpDetails.issuanceType.name:'----':'----'}  </label></div>
            </div>
            <div className="col-md-2">
                <label className="label-text label-service">How many months will the funds be held in Escrow? </label>
                <div><label className="text text-primary text-cap">{(rfpDetails)?rfpDetails.issuanceTenure:'----'}  </label></div>
            </div>
            <div className="col-md-2">
                <label className="label-text label-service">Amount of Issuance </label>
                <div className="crf-detail-1"><label className="text text-primary text-cap">{(issuancsAmtDesign)?issuancsAmtDesign:'----'}  </label></div>
            </div>
            
        <span></span>
        </div>


        {(formik.values.corporateService && formik.values.corporateService != 'none')?
        <div className="form-row">
            <div className="col-md-3 form-group-margin">
            <label className="label-text label-service">Corporate Trust Service </label>
            </div>
            <div className="col-md-4 form-group-margin">
            <label className="label-text label-service">Acceptance Fee</label>
            </div>
            <div className="col-md-4 form-group-margin">
            <label className="label-text label-service">Annual Fee </label>
            </div>
            <div className="form-group col-md-12">
                {/* <div className="col-md-4" style={{textTransform: 'capitalize'}}>
                    <label>{formik.values.corporateService.replace('_', ' ')}</label>
                </div> */}
                {corporateDesign ?? ''}
            </div>
        </div>
        :''}

        <div className="form-row">
            <div className="col-md-3 form-group-margin">
                <label  className="label-text label-service">Additional Service 
                    {
                        (rfpDetails.tranchesNumber != 'single')?
                            ' (per Tranche)':null
                    }
                </label>
            </div>
            <div className="col-md-4 form-group-margin">
            {/* <label>Acceptance Fee </label> */}
            </div>
            <div className="col-md-4 form-group-margin">
            {/* <label>Annual Fee </label> */}
            </div>
             {(serviceDesign)?serviceDesign:''}
             {(formik.values.additionalServices && rfpDetails.additionalServicesDescription)?
                <div className="col-md-12">
                    <div className="col-md-3">
                        <label className="label-text">{formik.values.additionalServices}</label>
                    </div>
                    <div className="col-md-8 p-0 ">
                        <div className="col-md-6">
                                <div className="form-row">
                                    <div className="col-md-3 p-0">
                                    <input type="text" className="form-control" readOnly disabled value="$" />
                                    </div>
                                    <div className="col-md-9 p-0">
                                        <input type="text" className="form-control"  name="additionalAcceptanceFee"  onChange={
                                                e => initAccFee(e)
                                            }   defaultValue={((formik.values.additionalAcceptanceFee) ? formik.values.additionalAcceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '')}/> 
                                    </div>
                                </div>
                                {formik.touched.additionalAcceptanceFee && formik.errors.additionalAcceptanceFee ? (
                                    <div className="text text-danger" align="left">{formik.errors.additionalAcceptanceFee}</div>
                                ) : null}
                        </div>

                        <div className="col-md-6">
                                <div className="form-row">
                                    <div className="col-md-3 p-0">
                                    <input type="text" className="form-control" readOnly disabled value="$" />
                                    </div>
                                    <div className="col-md-9 p-0">
                                        <input type="text" className="form-control"  name="additionalAnnualFee" onChange={
                                                e => initAnnFee(e)
                                            }   defaultValue={((formik.values.additionalAnnualFee) ? formik.values.additionalAnnualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '')}/>
                                    </div>
                                </div>
                                {formik.touched.additionalAnnualFee && formik.errors.additionalAnnualFee ? (
                                    <div className="text text-danger" align="left">{formik.errors.additionalAnnualFee}</div>
                                ) : null}
                        </div>

                    </div>
                </div>
                :''}
        </div>

        <div className="form-row">
        <div className="form-group form-group-margin col-md-12">
            <label className="label-text">Transaction Summary  </label>
            {/* <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable'],
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                            ]
                        }
                    }}
                    data={(formik.values.transactionSummary)?formik.values.transactionSummary:''}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor ) => {
                        const data = editor.getData();
                        // formik.values.transactionSummary = (data)?data:'';
                        setCustomsFormVal('transactionSummary',(data)?data:'');
                        // console.log( { event, editor, data } );
                    }}
                /> */}
                
                <textarea name='transactionSummary' rows={6} className="form-control" {...formik.getFieldProps('transactionSummary')} >
                    {(formik.values.transactionSummary)?formik.values.transactionSummary:''}
                </textarea>
        </div>
        </div>
        </>
    );
};


export default Step2;