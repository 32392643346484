import React, { useState, useEffect } from 'react';
import IncomeChart from './IncomeChart';
import { toast } from 'react-toastify';
import './Style.css'
import * as ArsrService from '@services/arsr';
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Graph from './Graph';

const Compare = (props) => {
    let { uniqueNo, reqtype } = useParams();
    // define states for handle data
    const [data, setData] = useState([]);
    const [graphModal, setGraphModal] = useState(false)
    const [rfpData, setRfpData] = useState({});
    const [compare, setCompare] = useState({
        higestData: "",
        lowestData: ""
    });

    const handleData = async () => {
        try {
            const {
                higestData,
                lowestData,
                arsrData,
                arsfinalData,
            } = await ArsrService.GetAnalyticsCompare({
                uniqueNo: uniqueNo,
                reqType: reqtype
            });

            setData(arsrData)
            setCompare({
                higestData: higestData,
                lowestData: lowestData
            })
            setRfpData(arsfinalData)
            setTimeout(() => document.getElementById('preloader').style.display = "none", 1000);
        } catch (error) {
            // if(error.message){
            //     toast.error(error.message);
            // }else{
            //     toast.error("Something went wrong");
            // }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('preloader').style.display = "block";
        handleData()
    }, []);

    const dataDesign = data.length > 0
        && data.map((item, i) => {
            return (
                <div className='col-md-12 card-strip-bs strip-mt-5' style={{ backgroundColor: "white" }}>
                    <div className='col-md-4 pd-15-logo'>
                        <img src={process.env.REACT_APP_AWS_OBJECT_URL + item.organizations.logo} style={{
                            width: '70px'
                        }} />
                    </div>

                    <div className='col-md-4 prod-column-li'>
                        <div class="tbl-block-centered">
                            {
                                (compare.higestData == item._id) ?
                                    <h4 class="prod-title badge badge-danger label-font-size">Highest</h4> : null
                            }
                            {
                                (compare.lowestData == item._id) ?
                                    <h4 class="prod-title badge badge-success label-font-size">Lowest</h4> : null
                            }
                        </div>
                    </div>
                    <div className='col-md-4 prod-column-li'>
                        <div class="tbl-block-centered">
                            <h4 class="prod-title td-values-fw">${item.withLegalFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</h4>
                        </div>
                    </div>
                </div>
            )
        })

    return (
        <>
            {
                (graphModal) ? <Graph data={data} handleGraphModal={(status) => setGraphModal(status)} /> : null
            }
            <section class="cart-section cart-sec-pd analytics-page " >
                <div class="auto-container">
                    <div className="row clearfix">
                        <div class="col-md-12 text-center p-b-3"><h2 class="text-black fw-800">Value Analysis of Submitted Proposals</h2></div>
                        <div className='col-md-12 col-xs-12'>
                            <div className='col-md-4 col-xs-12 p-0' align="left">
                                <Link to={'/ars/previous-list/' + reqtype} className="btn btn-primary "><i className="fa fa-arrow-left"></i> Back</Link>
                            </div>
                            <div className='col-md-8 col-xs-12 p-0' align="right">
                                <span className='p-2'><Link to={`/ars-analytics/${uniqueNo}/${reqtype}`} className="btn btn-success"> Detailed Comparison</Link></span>
                                <p className="btn btn-primary" onClick={
                                    () => setGraphModal(true)
                                }> Visual Analysis</p>
                            </div>
                        </div>
                        <div class="cart-outer">
                            <div className='col-md-12 strip-mt-5'>
                                <div className='col-md-4'>
                                    <label>PROVIDERS</label>
                                </div>

                                <div className='col-md-4'>
                                </div>

                                <div className='col-md-4'>
                                    <div class="tbl-block-centered">
                                        <label>TOTAL COST TO ISSUER</label>
                                    </div>
                                </div>
                            </div>
                            {dataDesign}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default Compare;