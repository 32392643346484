import React from 'react';
import dateFormat from "dateformat";
import * as AppConstant from '@app/appConstants';

const Section2 = ({ data }) => {
    let issuanceAmtDesign = typeof (data.issuanceAmount) == "object" && data.issuanceAmount.length > 0
        && data.issuanceAmount.map((item, i) => {
            return <>
                <div className="form-group form-group-margin details-row-left col-md-4 col-sm-6">
                    <label className="label-text">Amount of Issuance/Escrow </label>
                    {(item.issuanceAmount && item.issuanceAmount != 0)?<p className="paragraph-text">{item.issuanceCurrencyType} {item.issuanceAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</p>:<p className="paragraph-text">----</p>}
                </div>
                
            </>;
        });
    const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
        <>
            <div className="form-row col-md-12 padding-0">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Transaction Information</h1>
                    </div>
                </div>
            </div>
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Corporate Trust Service</label>
                    <p className="paragraph-text">{(data.corporateTrustService)?data.corporateTrustService.replaceAll('_',' '):'----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Type of Transaction</label>
                    <p className="paragraph-text">{(data.issuanceType)?data.issuanceType.name:'----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Name of Issuer (Debt Issuance) or Escrow Party (Defeasance Escrow)</label>
                    <p className="paragraph-text">{(data.issuerName)?data.issuerName:'----'} {(data.issuanceType._id == AppConstant.globalConst.SERVICE_ID)? (data.corporateBorrower)?'('+data.corporateBorrower+')':'----' :''}</p>
                </div>
            </div>
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Transaction/Project Name</label>
                    <p className="paragraph-text">{(data.projectName)?data.projectName:'----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Tenure of Issuance/Escrow (Year)</label>
                    <p className="paragraph-text">{(data.issuanceTenure)?data.issuanceTenure:'----'}</p>
                </div>
                {issuanceAmtDesign}
            </div>

            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Intended Date of Issuance/Escrow Signing </label>
                    <p className="paragraph-text">
                        { 
                            (data.issuanceIntendedDate) ? dateFormat(data.issuanceIntendedDate, "UTC:mm/d/yyyy") : '----'
                        }
                    </p>
                </div>
            </div>
            {(data.corporateTrustService != AppConstant.globalConst.MUNICORPORATETRUSTSERVICE)?
            <>
            <div className="col-md-12  margin-head-info radio-form-bg" style={{
                    marginBottom: 0,
                }}>
                <div className="form-row">
                    <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text" >Will this Issuance be rated?</label>
                            <p className="paragraph-text">{(data.issuanceRated)?data.issuanceRated:'----'}</p>
                    </div>
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >If Issuance will be rated, what will the rating be?</label>
                        <p className="paragraph-text">{(data.ratedDescription)?data.ratedDescription:'----'}</p>
                    </div>
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Is this a first-time Issuer?</label>
                        <p className="paragraph-text">{(data.firstTimeIssuer)?data.firstTimeIssuer:'----'}</p>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group form-group-margin col-md-12  radio-form-bg" style={{
                    marginTop: 0,
                }}>
                    {/* <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Is this Issuance part of an Exchange?</label>
                        <p className="paragraph-text">{(data.exchangeIssuer)?data.exchangeIssuer:'----'}</p>
                    </div> */}
                    <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Governing Law</label>
                        <p className="paragraph-text">{(data.governingLaw)?data.governingLaw:'----'}</p>
                    </div>
                    {/* <div className="form-group form-group-margin details-row-left col-md-4">
                        <label className="label-text" >Is this TIA qualified?</label>
                        <p className="paragraph-text">{(data.tiaQualified)?data.tiaQualified:'----'}</p>
                    </div>   */}
                </div>
            </div>
            </>
            :''}
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-12">
                    <label className="label-text" >Transaction Summary</label>
                    <p className="text-black"><b>{(data.transactionSummary)?renderHTML(data.transactionSummary):'----'}</b></p>
                </div>

            </div>


        </>
    );
};


export default Section2;