import React, { useState, useEffect } from 'react';


const Section7 = ({ data }) => {
    return (
        <>
            <div className="form-row">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Trustee / Escrow Legal Fees</h1>
                    </div>
                </div>
            </div>
            <div className="form-row col-md-12 details-row-left">
                <div className="col-md-4">
                    <label className="label-text">Legal Fee Quote</label>
                    <p className="paragraph-text">{(data.legalFee)?data.legalFee:'----'}</p>
                </div>
                <div className="form-group col-md-4">
                    <label className="label-text">Legal Fee Type</label>
                    <p className="paragraph-text">{(data.legalType)?data.legalType:'----'}</p>
                </div>
                <div className="col-md-4">
                    <label className="label-text">Additional comments regarding legal expenses</label>
                    <p className="paragraph-text">{(data.additionalLegalFee)?data.additionalLegalFee:'----'}</p>
                </div>
            </div>

        </>
    );
};


export default Section7;