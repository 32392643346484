import React from 'react';
import dateFormat from "dateformat";

const Section2 = ({ data }) => {
    const serviceDes = data.typeOfServices && data.typeOfServices.length > 0 && data.typeOfServices.map((item, i) => {
        return <>
            <div className="col-md-12">
                <div className="col-md-4 padding-0">
                    <label className="paragraph-text padding-0">{item.id.name ?? ''}</label>
                </div>
                <div className="col-md-4">
                    {(item.acceptanceFee && item.acceptanceFee != 0)?<p className="paragraph-text">$ {item.acceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? ''}</p>:'----'}
                </div>
                <div className="col-md-4">
                   {(item.annualFee && item.annualFee != 0)?<p className="paragraph-text">$ {item.annualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? ''}</p>:"----"}
                </div>
            </div>
        </>;
    });

    const corpServ = data.corporateFees && data.corporateFees.length > 0 && data.corporateFees.map((item, i) => {
        return <>
            <div className="col-md-12">
                <div className="col-md-4 padding-0">
                    {i == 0 ? <label>{data.corporateService.toString().replaceAll('_', ' ') ?? ''}</label> : null}
                </div>
                <div className="col-md-8 p-0">
                    <div className="col-md-6">
                       {(item.corporateAcceptanceFee && item.corporateAcceptanceFee != 0)?<p>{item.currency} {item.corporateAcceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? ''}</p>:'----'}
                    </div>
                    <div className="col-md-6">
                        {(item.corporateAnnualFee && item.corporateAnnualFee !=0)?<p>{item.currency} {item.corporateAnnualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? ''}</p>:'----'}
                    </div>
                </div>
            </div>
        </>;
    });

        let comma = 0;
        const issuancsAmtDesign = data.rfpId && data.rfpId.issuanceAmount && data.rfpId.issuanceAmount.length > 0
        && data.rfpId.issuanceAmount.map((item, i) => {
            if(item.issuanceAmount && item.issuanceAmount != 0){
                comma += 1
                return <>
                {(comma != 1 )?', ':''}<span>{(item.issuanceCurrencyType)?item.issuanceCurrencyType:''} {(item.issuanceAmount)?item.issuanceAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):''}</span>
                </>
            }else{
                return <>
                {(i != 0)?', ':''}<span>----</span>
                </>
            }
        });

        const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
        <>
            <div className="form-row col-md-12 padding-0 bg-white">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">SERVICES</h1>
                    </div>
                </div>


                <div className="col-md-12 rfp-info bg-white form-group form-group-margin" style={{background: '#fbfbfb', marginTop: '0px'}} align="center">
                    <div className="col-md-2">
                        <label className="label-text">Institution </label>
                        <div className="crf-detail-1"><label className="paragraph-text">{(data.rfpId) ? data.rfpId.institution : ''} </label></div>
                    </div>
                    <div className="col-md-2">
                        <label className="label-text">Name of Issuer </label>
                        <div className="crf-detail-1"><label className="paragraph-text">{(data.rfpId) ? data.rfpId.issuerName : ''}  {(data.rfpId)?(data.rfpId.corporateBorrower)?'('+data.rfpId.corporateBorrower+')':'':''}</label></div>
                    </div>
                    <div className="col-md-2">
                        <label className="label-text">Issuance Type </label>
                        <div className="crf-detail-1"><label className="paragraph-text">{(data.rfpId) ? (data.rfpId.issuanceType) ? data.rfpId.issuanceType.name : '' : ''}  </label></div>
                    </div>
                    <div className="col-md-2">
                        <label className="label-text">How many months will the funds be held in Escrow? </label>
                        <div><label className="paragraph-text">{(data.rfpId) ? data.rfpId.issuanceTenure : ''}  </label></div>
                    </div>
                    <div className="col-md-2">
                        <label className="label-text">Amount of Issuance </label>
                        <div className="crf-detail-1"><label className="paragraph-text">{(issuancsAmtDesign) ? issuancsAmtDesign : '----'}  </label></div>
                    </div>

                </div>

                {(data.corporateService && data.corporateService != 'none') ?
                    <div className="col-md-12 bg-white">
                        <div className="col-md-4 form-group-margin">
                            <label className="label-text">Corporate Trust Service</label>
                        </div>
                        <div className="col-md-4 form-group-margin">
                            <label className="label-text">Acceptance Fee</label>
                        </div>
                        <div className="col-md-4 form-group-margin padding-0">
                            <label className="label-text">Annual Fee</label>
                        </div>
                        <div className="col-md-12 paragraph-text padding-0">
                            {(corpServ) ? corpServ :  <div className="col-md-12 paragraph-text">----</div>}
                        </div>
                    </div>
                    : ''}

                <div className="col-md-12 paragraph-text bg-white">
                    <div className="col-md-4 form-group-margin">
                        <label className="label-text">Additional Services
                            {
                                (data.rfpId.tranchesNumber != 'single') ?
                                    ' (per Tranche)' : null
                            }
                        </label>
                    </div>
                    <div className="col-md-4 form-group-margin">
                        <label className="label-text">Acceptance Fee</label>
                    </div>
                    <div className="col-md-4 form-group-margin padding-0">
                        <label className="label-text">Annual Fee</label>
                    </div>
                    {serviceDes ?? '----'}
                    {(data.additionalServices) ?
                        <div className="col-md-12  ">
                            <div className="col-md-4 padding-0">
                                <label className="paragraph-text">{(data.additionalServices) ? data.additionalServices : '----'}</label>
                            </div>
                            <div className="col-md-4">
                                <p className="paragraph-text">$ {(data.additionalAcceptanceFee) ? data.additionalAcceptanceFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '0'}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="paragraph-text">$ {(data.additionalAnnualFee) ? data.additionalAnnualFee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '0'}</p>
                            </div>
                        </div>
                        :''}
                </div>
                <div className="col-md-12" style={{marginTop: '22px'}}> 
                    <div className="col-md-12">
                        <label className="label-text">Transaction Summary</label>
                        <p className="text-black"><b>{(data.transactionSummary)?renderHTML(data.transactionSummary):<div className="paragraph-text">----</div>}</b></p>
                    </div>
                </div>
            </div>

        </>
    );
};


export default Section2;