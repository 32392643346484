import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import './events.css'
import * as RfpService from '@services/rfp';
import { sortedLastIndexOf } from 'lodash';
import API from '@app/utils/axios';
import Pagination from "react-js-pagination";

const Blogs = ({ props }) => {
    const [blogData, setBlogData] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [totalBlogData, setTotalBlogData] = useState(0);
    const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
     

    const getBlogData = async () => {
        const {
            blogData,
            blogDataLength
        } = await RfpService.getBlogData(activePage);
        setBlogData(blogData);
        setTotalBlogData(blogDataLength)
    }

    const CardDesign = blogData.length > 0 && blogData.map((key, i) => {
        let blogImg = (key.image) ? process.env.REACT_APP_AWS_OBJECT_URL + key.image.replaceAll("\\", "/") : '';
        const description =renderHTML(key.description)
        const descriptionlength = description
        const title = key.title;
        return (
            <>
                <div className="col-md-4 col-xs-12 col-sm-4 single-blog">
                    <div class="card bg-white">
                        <div class="blog-image no-padding"
                            style={{
                                fontSize: "30px",
                                backgroundColor: "white",
                                backgroundImage: `url(${blogImg})`
                            }}>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title blog-title">{title.length>58 ? title.substring(0,58)+" " : title}</h5>
                            <p class="card-text blog-text-p" >{
                               
                               descriptionlength.length> 400 ? descriptionlength.substring(0, 400)+"..." : description
                                    } </p>
                            
                        </div>
                        <div className='btn-block'>
                                <a href={key.url} target="_blank" class="btn btn-primary">Read More</a>
                            </div>
                    </div>
                </div>
            </>
        )
    })

    
    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
        getBlogData()
    }, []);

    useEffect(() => {
        getBlogData()
    },[activePage])
    
    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    return (
        <>

            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Blogs</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Blogs</li>
                        </ul>
                    </div>

                </div>
            </section>
            <div class="sidebar-page-container with-right-sidebar bg-blue blog-section">
                <div class="auto-container">
                    <div class="row clearfix ">
                        <div className='col-md-12 col-xs-12 col-sm-12'>
                            <div className='row'>
                                {CardDesign}
                            </div>
                        </div>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={9}
                            totalItemsCount={totalBlogData}
                            pageRangeDisplayed={5}
                            onChange={
                                (activePage) => setActivePage(activePage)
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );

}


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);




