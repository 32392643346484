import React, { useState, useEffect } from 'react';
import Section1 from '@components/rfp/ars/Section1'
import Section2 from '@components/rfp/ars/Section2'
import Section3 from '@components/rfp/ars/Section3'
import Section4 from '@components/rfp/ars/Section4'
import Section5 from '@components/rfp/ars/Section5'
import Section6 from '@components/rfp/ars/Section6'
import Section7 from '@components/rfp/ars/Section7'
import Section8 from '@components/rfp/ars/Section8'
import Section9 from '@components/rfp/ars/Section9'
import * as ArsService from '@services/ars';
import { useParams, useHistory } from 'react-router-dom';

const ViewRfp = () => {
    const history = useHistory();
    const [data, setData] = useState({
        exchangeIssuer: '',
        issuanceType: ''
    })
    const [isFetching, setIsFetching] = useState(false)
    let { uniqueNo, formtype } = useParams();

    const getRfpDetails = async () => {
        try {
            const { arsDetail } = await ArsService.GetARSDetails(uniqueNo);
            await setData(arsDetail);
            console.clear();
            setIsFetching(true);
        } catch (error) {
            // if(error.message){
            //     toast.error(error.message);
            // }else{
            //     toast.error("Something went wrong");
            // }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getRfpDetails();
    }, [])
    // console.log('get',data.exchangeIssuer);
    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundPosition: 'top center' }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="form-column col-md-12 col-sm-12 col-xs-12">
                            <h1 className="h1-text text-black">{(formtype == 'ars') ? "Arbitrage Rebate Services" : "Verification Agent Services"}</h1>
                        </div>
                        <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5">
                            <div className="col-md-12 col-xs-12  col-sm-12 main-form-outside bg-white">
                                <div className="main-title-head">
                                    <h1 className="h1-font-size-title">Your information</h1>
                                </div>
                                {
                                    (isFetching) ?

                                        <div className=" clearfix">
                                            <Section1 data={data} formtype={formtype} />
                                            <Section2 data={data} formtype={formtype} />
                                            <Section3 data={data} formtype={formtype} />
                                            <Section4 data={data} formtype={formtype} />
                                            <Section5 data={data} formtype={formtype} />
                                            {/* <Section7 data={data} /> */}
                                            <Section8 data={data} formtype={formtype} />
                                            <Section9 data={data} formtype={formtype} />
                                        </div>
                                        : null
                                }
                                <div className="col-md-12 btn-margin-top-bottom ">
                                    <button onClick={() => history.goBack()} className="btn btn-primary btn-style-submit btn-back-style res-btn m-b"><i className="fa fa-arrow-left"></i> Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section>
        <div className="auto-container">
        <div className="col-md-12" style={{zIndex: '999999'}}>
        <div className="col-md-12 p-0" align="left"><button onClick={() => history.goBack()} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</button></div>
        </div>
            <h1 className="text-black">Indenture Trustee RFP Form</h1>
        </div>
    </section>
    <section className="contact-section" style={{
        textAlign: 'left', textTransform: 'capitalize'
    }}>
    	<div className="auto-container">
            {
                (isFetching) ? 
                    <div className="row clearfix">
                        <Section1 data={data}/>
                        <Section2 data={data}/>
                        <Section3 data={data}/>
                        <Section4 data={data} />
                        {(data.issuanceType && data.issuanceType._id == AppConstant.globalConst.ISSUANCETYPE_ID || data.exchangeIssuer == 'no') ? '' :<Section5 data={data}/>} */}
            {/* <Section6 data={data} /> */}
            {/* <Section7 data={data} />
                        <Section8 data={data} />
                        <Section9 data ={data} />
                    </div>
                :null
            }
        </div>
    </section> */}
        </>
    );
};

export default ViewRfp;
