import React, { useEffect, useState } from 'react';
import * as PaymentService from '@services/payment';
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import valid from "card-validator";
import 'react-credit-cards/es/styles-compiled.css';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
require("dotenv").config();

const UpdateCard = (props) => {

    const history = useHistory();
    let { id } = useParams();
    const [cardDetail,setCardDetail] = useState('');
    
    const handleSubmit = async (values) => {
        document.getElementById("load").style.display = "block";
        try {
            values.userId = window.atob(id)

            if (values.expiry) {
                let selectedDate = values.expiry.split("-");
                values.exp_month = selectedDate[1]
                values.exp_year = selectedDate[0]
                // values.expiry = undefined
                // values.name = undefined
            }
            const paymentData = await PaymentService.updateCardById(values);
            if (paymentData) {
                history.push('/payment-response/updatedetail');
                toast.success('Congrats. Your card has been updated successfully.');
            }

        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     'Failed'
            // );
        }
        document.getElementById('preloader').style.display = "none";
    }

    const formik = useFormik({
        initialValues: {
            cvc: "",
            expiry: "",
            name: "",
            number: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            cvc: Yup.string().required('Enter your card cvv').matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Please enter correct cvv"),
            expiry: Yup.string().required('Enter your card expiry date'),
            name: Yup.string().required('Enter your name'),
            // number: Yup.string().required('Please enter card number yourself').matches(
            //     /^(?:5[1-5][0-9]{14})$/,
            //     "Please enter a valid card no"
            //   ),
            number: Yup.string().required('Enter your card number')
            // .min(16,'Card number should not be less than 16 digits')
            // .max(16,'Card number should not be more than 16 digits')
            // .test(
            //     "test-number",
            //     "Credit Card number is invalid",
            //     value => valid.number(value).isValid
            // )
        }),
        onSubmit: (values) => {
            handleSubmit(values);
            document.getElementById('preloader').style.display = "block";
        }
    });


    const getCardById = async (userId) => {
        try {
            const cardDtl = await PaymentService.getCurrentCardById(window.atob(userId));
            setCardDetail((cardDtl)?cardDtl:'');
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //     'Failed'
            // );
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getCardById(id);
        document.getElementById('preloader').style.display = "none";
    }, []);

    return (


        <>
            <section >
                <div className="auto-container">
                    <h1 className="h1-text text-black">Update Card Info.</h1>
                    <p>Please enter your card details to update card information.</p>
                </div>
            </section>
            <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                <div className="auto-container">
                    <div className="row clearfix row-container-margin-width ">
                        <div className="col-md-12 bg-white margin-top-5 form-edit-col-main">
                            <div className="col-md-6 edit-profile-image">
                                <img className="edit-profile-image" style={{ width: '92%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Plan.png"} />

                                {/* <Cards
                                    cvc={formik.values.cvc}
                                    expiry={formik.values.expiry.replace('-', '/')}
                                    name={formik.values.name}
                                    number={formik.values.number}
                                /> */}
                            </div>

                            <div className="col-md-6 form-edit-col">
                                <div className="col-md-12 unset-padding padding-edit-profile">
                                    <div className="Inside">
                                        <h1 className="text-black h1-edit-profile">Current Card</h1>
                                    </div>
                                    <div className="form-row ">
                                    <div className="form-group col-md-12" style={{paddingTop: "8px"}}>
                                       <div className="label-color text-left col-md-4">Card Type
                                        <h1 className="label-color text-left" style={{ fontSize:"50px !important"}}><b>{(cardDetail)?cardDetail.brand:''}</b></h1>
                                        </div>
                                        {/* <img className="col-md-9"  src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/unnamed.png"} /> */}
                                        <div className="label-color text-left col-md-4">Card No <h1 className="label-color text-left" style={{ fontSize:"50px !important"}}><b>{(cardDetail)?'************'+cardDetail.last4:''}</b></h1></div>
                                        <div className="label-color text-left col-md-4">Expiry Date <h1 className="label-color text-left" style={{ fontSize:"50px !important"}}><b>{(cardDetail)?(cardDetail.exp_month).toString().padStart(2, "0")+'/':''}{(cardDetail)?cardDetail.exp_year:''}</b></h1></div>
                                    </div>
                                    <hr style={{
                                        border:"none",
                                        borderTop:"1px dashed rgb(204, 204, 204)",
                                        backgroundColor:"#fff",
                                        height:"1px",
                                        width:"100%"
                                    }}/>
                                    </div>
                                    <div className="Inside">
                                        <h1 className="text-black h1-edit-profile br-0" style={{ borderRadius: "unset !important"}}>Enter New Card Detail</h1>
                                    </div>
                                    <div className="default-form contact-form form-margin text-left ">
                                        
                                        <form align="left" onSubmit={formik.handleSubmit} autoComplete="off">
                                            <div className="form-row ">
                                                <div className="form-group col-md-12">
                                                <label className="label-color">Name</label>
                                                <input type="text" name="name" className="form-control" autoComplete="off" placeholder="Your Name" {...formik.getFieldProps('name')} />
                                                {formik.touched.name && formik.errors.name ? (
                                                    <div className="text text-danger">{formik.errors.name}</div>
                                                ) : null}
                                                </div>
                                                <div className="form-group col-md-12">
                                                <label className="label-color">Card Number
                                                </label>
                                                    <input type="text" name="number" className="form-control" autoComplete="cc-csc" placeholder="Card Number" {...formik.getFieldProps('number')} />
                                                    {formik.touched.number && formik.errors.number ? (
                                                        <div className="text text-danger">{formik.errors.number}</div>
                                                    ) : null}
                                                </div>
                                                <div className="form-group col-md-7">
                                                <label className="label-color">Expiry Date</label>
                                                    <input type="month" name="expiry" className="form-control" autoComplete="off" placeholder="Expire Date" format="MM-YYYY" {...formik.getFieldProps('expiry')} />
                                                    {formik.touched.expiry && formik.errors.expiry ? (
                                                        <div className="text text-danger">{formik.errors.expiry}</div>
                                                    ) : null}
                                                </div>
                                                <div className="form-group col-md-5">
                                                    <label className="label-color">CVV</label>
                                                    <input type="text" className="form-control" name="cvc" autoComplete="off" placeholder="CVV" {...formik.getFieldProps('cvc')} />
                                                    {formik.touched.cvc && formik.errors.cvc ? (
                                                        <div className="text text-danger">{formik.errors.cvc}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group  col-md-12 text-center">
                                                <button type="submit" className="btn btn-primary">Update</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCard);