import API from '@app/utils/axios';
export const NewArs = async (requestData) => {
    const { data } = await API.post('user/create-update-Ars', requestData);

    return data;
};

// export const GetProviderList = async (uniqueNo) => {
//     const { data } = await API.post('/user/get-rfp-detail', {
//         uniqueNo: uniqueNo
//     });
//     return data.data;
// };

export const GetProviderList = async (roleid) => {
    const { data } = await API.post('user/get-ars-roles', { roleId: roleid });

    return data;
};

export const uploadDocuments = async (requestData) => {
    const { data } = await API.post('user/upload-ars-images', requestData);

    return data;
};

export const removeDocuments = async (requestData) => {
    const { data } = await API.post('user/delete-ars-images', requestData);

    return data.data;
};


export const GetSavedLists = async (requestData) => {
    const { data } = await API.post('user/get-ars-by-type', requestData);

    return data;
};

export const removeRFPS = async (requestData) => {
    const { data } = await API.post('user/delete-ars-form', requestData);

    return data.data;
};


export const getUsersByRfp = async (uniqueNo, userId) => {
    const {
        data
    } = await API.post(`user/get-ars-userid`, {
        uniqueNo: uniqueNo,
        userId: userId
    });
    return data.data;
};

/**
 * 
 * @param {*} uniqueNo 
 * @returns data object
 */
export const GetARSDetailsById = async (uniqueNo, type) => {
    const {
        data
    } = await API.post('/user/get-ars-detail-by-id', {
        uniqueNo: uniqueNo,
        type: type
    });
    return data.data;
};

/**
 * 
 * @param {*} uniqueNo 
 * @returns data object
 */
export const GetARSDetails = async (uniqueNo) => {
    const { data } = await API.post('/user/get-ars-detail', {
        uniqueNo: uniqueNo
    });
    return data.data;
};

/**
 * 
 * @param {*} requestData 
 * @returns 
 */
export const getAllResponse = async (requestData) => {
    const { data } = await API.post('user/get-ars-response', requestData);

    return data.data;
};

/**
 * 
 * @param {*} requestData 
 * @returns 
 */
export const changeResponseStatus = async (requestData) => {
    const { data } = await API.post('user/update-arsr-status', requestData);

    return data.data;
};