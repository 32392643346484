import React, { useState, useEffect } from 'react';


const Section4 = ({ data }) => {

    const additionalServicesDesign = data.additionalServices.length > 0
        && data.additionalServices.map((item, i) => {
            return (
                <li>
                    {item.additionalServices}
                </li>
            )
        });
    return (
        <>
            <div className="form-row col-md-12 padding-0">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">ADDITIONAL SERVICES</h1>
                    </div>
                </div>
            </div>

            <div className="form-row col-md-12 details-row-left">
                <div className="col-md-6 ">
                    <label className="label-text">Additional services</label>
                    <ul className="paragraph-text">{(data.additionalServices.length != 0) ? additionalServicesDesign : '----'}</ul>
                </div>
            </div>

        </>
    );
};


export default Section4;