import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as ArsrService from '@services/arsr';
import * as ArsService from '@services/ars';
import * as AuthService from '@services/auth';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import dateFormat from "dateformat";
import Swal from 'sweetalert2';
import AssignUserModal from '../../../components/global/modal/AssignUserModal';

const QuoteHistory = ({ currentUser }) => {
  const { filtertype, arsunique, formtype } = useParams();
  const [arsrOption, selectArsrOption] = useState('');
  const [savedData, setSavedData] = useState([]);
  const [profile, setProfile] = useState();
  const [userData, setUserData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const history = useHistory()

  const getProfile = async () => {
    if (currentUser['custom:id']) {
      try {
        const { userData } = await AuthService.GetProfile(currentUser['custom:id']);
        setProfile(userData);
        document.getElementById('preloader').style.display = "none";
      } catch (error) {
        document.getElementById('preloader').style.display = "none";
        // toast.error((error.message) ||'Failed');
      }
    }
  }
  const getSavedList = async (userId) => {
    if (userId) {
      const requestData = {
        userId: userId,
        type: "complete",
        reqType: formtype
      };
      try {
        const data = await ArsrService.GetSavedLists(requestData);
        let draftList = (data.data.arsrData) ? data.data.arsrData : [];
        setSavedData(draftList);
      } catch (error) {
        // toast.error(error);
      }
    }
    document.getElementById('preloader').style.display = "none";
  }
  const getFilterSavedList = async (userId) => {
    if (userId) {
      const { arsDetail } = await ArsService.GetARSDetails(arsunique);
      const requestData = {
        userId: userId,
        issuerName: arsDetail.issuerName,
        issuanceType: arsDetail.issuanceType._id,
        reqType: arsDetail.reqType,
        transactionTrustService: arsDetail.transactionTrustService
      };
      try {
        const data = await ArsrService.GetFilterArsrLists(requestData);
        let draftList = (data.data.arsrData) ? data.data.arsrData : [];
        setSavedData(draftList);
      } catch (error) {
        // toast.error(error);
      }
    }
    document.getElementById('preloader').style.display = "none";
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getProfile();
    if (filtertype == "import") {
      getFilterSavedList(currentUser['custom:id']);
    } else {
      getSavedList(currentUser['custom:id']);
    }
    document.getElementById('preloader').style.display = "block";
  }, [currentUser['custom:id']]);

  const confirmResponse = async (arsrId, userID) => {
    try {
      const { message } = await ArsrService.changeConfirmStatus({ arsrId: arsrId });
      toast.success("Your response has been confirmed.");
      getSavedList(userID);
    } catch (error) {
      // toast.error(error);
    }
    document.getElementById('preloader').style.display = "none";
  }

  const confirmArs = (arsId, userID) => {
    let message = "You want to accept this response ?";
    // if(process.env.REACT_APP_SECOND_PLAN == profile.planId || process.env.REACT_APP_THIRD_PLAN == profile.planId){
    //     message = "The amount $25 will be deducted from your account.";
    // }
    Swal.fire({
      title: 'Are you sure ?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        confirmResponse(arsId, userID);
        document.getElementById('preloader').style.display = "block";
      }
    });
  }

  const handleSelectOption = () => {
    if (arsrOption) {
      toast.success('Imported successfully')
      history.push('/edit-response/' + arsrOption + "/" + formtype + "/import/" + arsunique)
    }
  }

  const showUserList = async (row) => {
    document.getElementById('preloader').style.display = "block";
    const { arsrData } = await ArsrService.getUsersByArsr({ arsrUserid: currentUser['custom:id'], arsId: row.arsId._id })
    setUserData(arsrData);
    setSelectedRow(row.arsId);
    setUserModalOpen(true);
    document.getElementById('preloader').style.display = "none";
  }


  const handlePermissions = async (row, type) => {
    const view = document.getElementById('permission-view-' + row._id).checked
    const edit = document.getElementById('permission-edit-' + row._id).checked
    let updateCriteria = {
      userId: row._id,
      formType: "arsr",
      isEdit: edit,
      isView: view,
      formId: selectedRow._id
    }
    if (type == "edit") {
      document.getElementById('permission-view-' + row._id).checked = true
      document.getElementById('permission-edit-' + row._id).checked = (!edit) ? false : true
      updateCriteria.isView = true
      updateCriteria.isEdit = (!edit) ? false : true
    } else if (type == "view") {
      document.getElementById('permission-view-' + row._id).checked = (!view) ? false : true
      document.getElementById('permission-edit-' + row._id).checked = false
      updateCriteria.isView = (!view) ? false : true
      updateCriteria.isEdit = false
    }
    await AuthService.assignUserToForm(updateCriteria)
  }
  const columns = [
    {
      name: 'Transaction Id',
      sortable: true,
      width: "15%",
      cell: (row) => <>
        {(filtertype == "import" && row.isEdit) && <input type={"radio"} name='type' className="import-radio-btn" onClick={() => selectArsrOption(row.uniqueNo)} />}
        {row.isView && <Link to={'/ars/view-rfp/' + row.arsId.uniqueNo + "/" + formtype}>{row.uniqueNo ?? '...'}</Link>}
        {!row.isView && !row.isEdit && row.uniqueNo}
      </>
    },
    {
      name: 'Submitted Date',
      sortable: true,
      cell: (row) => (row.arsId) ? dateFormat(row.arsId.createdAt, "UTC:mmm d, yyyy") : ""
    },
    {
      name: 'Issuer Name',
      selector: row => (row.arsId) ? row.arsId.issuerName : '...',
      sortable: true,
    },
    {
      name: 'Owner',
      selector: row => ((row.arsId) ? row.arsId.firstName : '') + ' ' + ((row.arsId) ? row.arsId.lastName : ''),
      sortable: true,
    },
    {
      name: 'Transaction/Project Name',
      selector: row => (row.arsId) ? row.arsId.projectName : '...',
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (!row.status) ? <p>Awaiting Decision</p> : <p style={{ textTransform: 'capitalize' }}>{row.status.replace('_', ' ')}</p>,
      sortable: true,
      width: '16rem'
    },
    {
      name: 'Action',
      sortable: true,
      cell: (row) => <>
        {
          row.isEdit ?
            <button onClick={e => showUserList(row)} className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            : <button disabled className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
        }
        {
          row.isEdit && (row.status == 'confirm_acceptance') && <>
            <div style={{ paddingLeft: '5px' }}>
              <button onClick={e => confirmArs(row._id, (row.userId) ? row.userId._id : '')} className="btn btn-success btn-sm" title="Confirm">
                <i className="fa fa-check" />
              </button>
            </div>
          </>
        }
        {
          row.isView && <>
            <div style={{ paddingLeft: '5px' }}>
              <Link to={'/arsr-detail/' + row.uniqueNo} className="btn btn-primary btn-sm" title="View Response">
                <i style={{ color: 'white' }} className="fa fa-eye" />
              </Link>
            </div>
          </>
        }
        {
          !row.isView && !row.isEdit && <>
            {(row.status == 'confirm_acceptance') ?
              <div style={{ paddingLeft: '5px' }}>
                <button disabled className="btn btn-success btn-sm" title="Confirm">
                  <i className="fa fa-check" />
                </button>
              </div>
              : ''}
            <div style={{ paddingLeft: '5px' }}>
              <button disabled className="btn btn-primary btn-sm" title="View Response">
                <i style={{ color: 'white' }} className="fa fa-eye" />
              </button>
            </div>
          </>
        }
      </>
    }
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '16px',
        minHeight: '50px'
      }
    },
    //heading row style
    headRow: {
      style: {
        backgroundColor: '#f5f6fa',
        marginTop: '20px'
      }
    },
    cells: {
      style: {
        padding: '10px', // override the cell padding for data cells
        paddingRight: '8px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      }
    }
  };
  const userColumns = [
    {
      name: 'Name',
      cell: (row) => (row.firstName + " " + row.lastName)
    },
    {
      name: 'Email',
      cell: (row) => <p style={{ textTransform: 'none' }}>{(row.email) ? row.email : "N/A"}</p>
    },
    {
      name: 'Permissions',
      cell: (row) => <>
        <label>
          <input type="checkbox" name="remember" onClick={() => handlePermissions(row, 'view')} defaultChecked={(row.assignIsView) ? row.assignIsView : false} className="remember-box" value="" id={'permission-view-' + row._id} />
          <span> View</span>
        </label>
        <label style={{ marginLeft: "1em" }}>
          <input type="checkbox" name="remember" onClick={() => handlePermissions(row, 'edit')} defaultChecked={(row.assignIsEdit) ? row.assignIsEdit : false} className="remember-box" value="" id={'permission-edit-' + row._id} />
          <span> Edit</span>
        </label>
      </>
    }
  ];
  return (
    <>
      <section>
        <div className="auto-container">
          <h1 className="h1-text text-black">Previous Quote List</h1>
        </div>
      </section>
      <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top' }}>
        <div className="auto-container">
          {
            (filtertype == "import") ? <>
              <div className="form-column col-md-12 col-sm-12 col-xs-12 text-align-left">
                <button type="button" class="btn btn-primary btn-style-submit res-btn import-btn-style" onClick={() => handleSelectOption()}>Import</button>
                <p className='text text-secondary'> Dates and attachments will not imported</p>
              </div>
            </> : null
          }
          <div className="row clearfix">
            <div className="form-column col-md-12 col-sm-12 col-xs-12">
              {
                (userModalOpen) &&
                <AssignUserModal
                  title={"Assign Permission To Users"}
                  columns={userColumns}
                  data={userData}
                  handleConfirmClose={() => setUserModalOpen(!userModalOpen)}
                />
              }
              <DataTable
                columns={columns}
                data={savedData}
                pagination
                customStyles={customStyles}
                responsive={true}
              />
            </div>
            <div className="col-md-12 clr-both">
              <div className="col-md-12 p-0" align="left">
                <Link to={'/trustee'} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser
});
export default connect(mapStateToProps)(QuoteHistory);