import React, { useState, useEffect } from 'react';
import BannerSection from './BannerSection';
import ChooseUs from './ChooseUs';
import HowWeWork from './HowWeWork';
import Team from './Team';
import WhyUs from './WhyUs';
import LoginModal from './LoginModal';

const Main = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('preloader').style.display = "none";
    }, []);
    return (
        <>
            <BannerSection />
            <ChooseUs />
            {/* <Team />    */}
            <HowWeWork />
            <WhyUs />
        </>
    );
};

export default Main;
