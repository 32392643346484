import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Files from 'react-files';
import { toast } from 'react-toastify';
import * as ArsrService from '@services/arsr';

const Step3 = ({ formik, formType, docs, addiDoc, setCustomsFormVal }) => {
  const [documentImg, setDocumentImg] = useState();
  const [removedFiles, setRemovedFiles] = useState([]);
  const [additionalFile, setAdditionalFile] = useState([]);
  const [additionalDoc, setUpdateAdditionalDoc] = useState([]);
  const [updateTerm, setUpdateTerm] = useState();

  // START Document File Upload 
  const onDocumentChange = (files) => {
    const documentFile = (files) ? files[0] : {};
    setDocumentImg(documentFile);
    const checkSheetFile = (documentFile) ? documentFile : "";
    // formik.values.document = checkSheetFile;
    setCustomsFormVal('document', checkSheetFile);
  }

  const onDocumentError = (error, file) => {
    toast.error('Error ' + error.code + ': ' + error.message);
    // formik.values.document = "";
    setCustomsFormVal('document', "");
  }

  const removeDocument = async () => {
    await setDocumentImg();
    // formik.values.document = "";
    setCustomsFormVal('document', "");
  }
  // END Document File Upload 

  // START Additional Document
  const onFilesChange = (files) => {
    const additionalFileCheck = files.filter(val => !removedFiles.includes(val));
    setAdditionalFile(additionalFileCheck);
    const checkAdditionalDoc = (additionalFileCheck) ? additionalFileCheck : [];
    // formik.values.additionalDocuments = checkAdditionalDoc;
    setCustomsFormVal('additionalDocuments', checkAdditionalDoc);
  }

  const onFilesError = (error, file) => {
    toast.error('Error ' + error.code + ': ' + error.message)
    const checkAdditionalDoc = [];
    // formik.values.additionalDocuments = checkAdditionalDoc;
    setCustomsFormVal('additionalDocuments', checkAdditionalDoc);
  }

  const removeFile = async (fileToRemove) => {
    let removeFile = additionalFile.filter(file => file.id !== fileToRemove.id);
    const removedFile = additionalFile.filter(file => file.id === fileToRemove.id);
    await setRemovedFiles([...removedFiles, removedFile[0]])
    await setAdditionalFile(removeFile);
    const checkAdditionalDoc = (removeFile) ? removeFile : [];
    // formik.values.additionalDocuments = checkAdditionalDoc;
    setCustomsFormVal('additionalDocuments', checkAdditionalDoc);
  }

  const removeAllFiles = async () => {
    await setRemovedFiles([...removedFiles, ...additionalFile])
    await setAdditionalFile([]);
    formik.values.additionalDocuments = []
  }
  // END Additional Document


  // Remove File from Db
  const removeFilesFromDB = async (url, type) => {
    // if (window.confirm("Are you sure ? you want to delete this document")) {
    const removeParam = {
      uniqueId: formik.values.uniqueId,
      type: type,
      documentUrl: url
    }
    try {
      const { data } = await ArsrService.removeDocuments(removeParam);
      // formik.values.document = data.document;
      setUpdateTerm(data.document);
      setUpdateAdditionalDoc(data.additionalDocuments);
      console.log("updateTerm", updateTerm)
      setCustomsFormVal('document', data.document);
      addiDoc = data.additionalDocuments;
      toast.success('Document has been deleted.');
    } catch (error) {
      // if(error.message){
      //     toast.error(error.message);
      // }else{
      //    toast.error("Something went wrong");
      // }
    }
    docs = '';
    // formik.values.document = "";
    setCustomsFormVal('document', "");
    //  }
  }

  useEffect(() => {
    const termSheet = (docs) ? docs : '';
    setUpdateTerm(termSheet);
    const additionDocu = (addiDoc) ? addiDoc : [];
    setUpdateAdditionalDoc(additionDocu);
  }, []);

  return (
    <>
      <div className="form-row">
        <div className="col-md-12 padding-0 margin-head-info">
          <div className="main-title-head text-center">
            <h1 className="h1-font-size-title">Attachments</h1>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="label-text">Document</label>
          <Files
            className='files-dropzone form-control'
            onChange={onDocumentChange}
            onError={onDocumentError}
            maxFileSize={30000000}
            minFileSize={0}
            clickable
            multiple={false}
          >
            Drop files here or click to upload

          </Files>
          {formik.touched.document && formik.errors.document ? (
            <div className="text text-danger" align="left">{formik.errors.document}</div>
          ) : null}

          {
            (documentImg)
              ?
              <>
                <li>
                  <span className='files-list-item-content-item files-list-item-content-item-1'>
                    {(documentImg.name.length > 40) ? documentImg.name.substring(0, 40) + "..." : documentImg.name} ({documentImg.sizeReadable})
                  </span>
                  <span id={documentImg.id} className='files-list-item-remove fa fa-times' onClick={e => removeDocument()} >
                  </span>
                </li>
              </>
              :
              null
          }
          {
            (formType == 'edit' && updateTerm)
              ?
              <>
                <li>
                  <span className='files-list-item-content-item files-list-item-content-item-1'>
                    {
                      (updateTerm.split('MTM')[1]) ?
                        'MTM' + updateTerm.split('MTM')[1] :
                        null
                    }
                  </span>
                  <span className='files-list-item-remove fa fa-times' onClick={e => removeFilesFromDB(updateTerm, "document")} >
                  </span>
                </li>
              </>
              :
              null
          }
        </div>
        <div className="form-group col-md-6">
          <label className="label-text">Additional documents (select multiple) </label>
          <Files
            className='files-dropzone form-control'
            onChange={onFilesChange}
            onError={onFilesError}
            multiple
            maxFileSize={30000000}
            minFileSize={0}
            clickable
          >
            Drop files here or click to upload

          </Files>

          {formik.touched.additionalDocuments && formik.errors.additionalDocuments ? (
            <div className="text text-danger" align="left">{formik.errors.additionalDocuments}</div>
          ) : null}
          {
            additionalFile.length > 0
              ?
              <>
                <div className="col-md-12 mt-4">
                  <div className="col-md-12" align="right">
                    <button className="btn btn-danger" onClick={e => removeAllFiles()}>Remove All Files</button>
                  </div>
                  <div className='files-list col-md-12'>
                    <ul>
                      {additionalFile.map((file) =>
                        <li>
                          <span className='files-list-item-content-item files-list-item-content-item-1'>
                            {(file.name.length > 40) ? file.name.substring(0, 40) + "..." : file.name} ({file.sizeReadable})
                          </span>
                          <span id={file.id} className='files-list-item-remove fa fa-times' onClick={e => removeFile(file)}>
                          </span></li>
                      )}
                    </ul>
                  </div>
                </div>
              </>
              : null
          }
          {
            formType == 'edit' && additionalDoc && additionalDoc.length > 0
              ?
              <>
                <div className="col-md-12 mt-4">
                  <div className='files-list col-md-12'>
                    <ul>
                      {additionalDoc.map((file) =>
                        <li>
                          <span className='files-list-item-content-item files-list-item-content-item-1'>
                            {
                              (file.split('MTM')[1]) ?
                                'MTM' + file.split('MTM')[1] :
                                null
                            }
                            {/* {(file.substring(file.lastIndexOf("/") + 1).length > 40)?file.substring(file.lastIndexOf("/") + 1).substring(0,40)+"...":file.substring(file.lastIndexOf("/") + 1)} */}
                          </span>
                          <span className='files-list-item-remove fa fa-times' onClick={e => removeFilesFromDB(file, "additionalDocuments")}>
                          </span></li>
                      )}
                    </ul>
                  </div>
                </div>
              </>
              : null
          }
        </div>
      </div>
    </>
  );
};


export default Step3;