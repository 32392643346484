import React, { useState, useEffect } from 'react';
import IncomeChart from './IncomeChart';
import { toast } from 'react-toastify';
import './Style.css'
import * as CrfpService from '@services/crfp';
import { Link, useParams, useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Analytics = (props) => {
    let { uniqueNo } = useParams();
    // define states for handle data
    const [additionalAmounts, setAdditionalAmounts] = useState([])
    const [additionalServicesData, setAdditionalServicesData] = useState([])
    const [data, setData] = useState([]);
    const [rfpData, setRfpData] = useState({});
    const [servicesFirstYearTotal, setServicesFirstYearTotal] = useState({})
    const [servicesAnnualTotal, setServicesAnnualTotal] = useState({})
    const [sFirstYearTotal, setSFirstYearTotal] = useState({})
    const [sAnnualTotal, setSAnnualTotal] = useState({})
    const [sCumulativeTotal, setSCumulativeTotal] = useState({})
    const [finalAnnualTotal, setFinalAnnualTotal] = useState({})
    const [finalFirstYearTotal, setFinalFirstYearTotal] = useState({})
    const [serviceCumulativeTotal, setServiceCumulativeTotal] = useState({})
    const [colSpanTd, setColSpanTd] = useState(2)
    const history = useHistory();
    const [tables,showTables]  =useState(false);
    const [showAttr,showTablesAttr]  =useState(false);

    
    

    // for decode html
    const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const handleData = async () => {
        try {
            const {
                cfrData,
                rfpfinalData,
                additionalAmounts,
                additionalServicesData,
                servicesFirstYearTotal,
                servicesAnnualTotal,
                sFirstYearTotal,
                sAnnualTotal,
                sCumulativeTotal,
                finalAnnualTotal,
                finalFirstYearTotal,
                serviceCumulativeTotal
            } = await CrfpService.GetAnalytics({
                uniqueNo: uniqueNo
            });

            setAdditionalServicesData(additionalServicesData)
            setAdditionalAmounts(additionalAmounts)
            setData(cfrData)
            setColSpanTd(cfrData.length + 1)
            setRfpData(rfpfinalData)
            setServicesFirstYearTotal(servicesFirstYearTotal)
            setServicesAnnualTotal(servicesAnnualTotal)
            setSFirstYearTotal(sFirstYearTotal)
            setSAnnualTotal(sAnnualTotal)
            setSCumulativeTotal(sCumulativeTotal)
            setFinalAnnualTotal(finalAnnualTotal)
            setFinalFirstYearTotal(finalFirstYearTotal)
            setServiceCumulativeTotal(serviceCumulativeTotal)
            setTimeout(() => document.getElementById('preloader').style.display = "none", 1000);
            showTables(true);
        } catch (error) {
            // if (error.message) {
            //     toast.error(error.message);
            // } else {
            //     toast.error("Something went wrong");
            // }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('preloader').style.display = "block";
        handleData()
    }, []);

    const toTitleCase = (str) => {
     return str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
    }

    const amtSept = (amount) => {
        return (amount)?amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):'0';
    }

const exportTableToExcel = async (tableID, filename = '') => {
    showTablesAttr(true);
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    // Find all items that need to be removed
    // Find all items that need to be removed
    var elements = document.getElementsByClassName("notToExport");

    // Loop through items and remove from the DOM
    for(var i=0; i < elements.length; i++)
    {
        elements[i].style.display = 'block';
    
    }
    var tableSelect = document.getElementById(tableID);
    var elements = tableSelect.getElementsByTagName("img");
    // Loop through items and remove from the DOM
    for(var i=0; i < elements.length; i++)
    {
        elements[i].setAttribute("width","6%");
        elements[i].setAttribute("height","6%");
        
    }
    // var newDesign = document.createElement('div');
    // newDesign.appendChild(document.createTextNode('<div className="block-design col-md-12 col-sm-12 col-xs-12 notToExport"><div><b className="print-text-dark print-rm-fw">* Not included in cummulative costs</b><br /><b className="print-text-dark print-rm-fw">** Total Analysis reflects pricing for Single Tranche</b></div><br /><br /></div>'));
    // var newDesign = ;
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    // tableHTML.appendChild(newDesign);
    // console.log('tableHTML',tableHTML);
    let todayDate = Math.floor(Date.now()/1000); //unix timestamp in seconds();
    // Specify file name
    filename = filename?filename+'-'+todayDate+'.xls':'analysis.xls';
    
    // Create download link element
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();

        var elements = document.getElementsByClassName("notToExport");
        for(var i=0; i < elements.length; i++)
        {
            elements[i].style.display = 'none';
        
        }
    }
        // Loop through items and remove from the DOM

}



    const printTableData = async (printDiv) => {
        var tab = document.getElementById(printDiv);
        var elements = tab.getElementsByTagName("img");
        // Loop through items and remove from the DOM
        for(var i=0; i < elements.length; i++)
        {
            elements[i].removeAttribute("width");
            elements[i].removeAttribute("height");
        }
        var tab = document.getElementById(printDiv);
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<link href="'+process.env.REACT_APP_IMAGE_TAG+'/assets/css/Print.css" rel="stylesheet" >');
        win.document.write(tab.outerHTML);
        // win.document.close();
        setTimeout(function() {
                 win.print();
                //  win.close();
        }, 3000);
    }


    let serviceNameCorporate = 'Trustee'
    const starSingle = "<span class='text text-danger' style='color:red'>* </span>"

    if (rfpData.corporateTrustService && rfpData.corporateTrustService != 'none') {
        serviceNameCorporate = rfpData.corporateTrustService.toString().replaceAll('_', ' ')
    }
    // Indenture Trustee Services
    const annualFeeAdditionalAmountsLabelDesign = additionalAmounts.length > 0
        && additionalAmounts.map((item, i) => {
            const tdItems = item.length > 0
                && item.map((itemInnerData, j) =>
                    (j == 0) ? <td className='print-rm-fw' style={(item.length - 1 != j)?{textTransform: 'capitalize',border: '1px solid #808080', borderRight: '0', borderTop: '0', borderTopColor: '#808080', borderRightColor: 'gray' }:{textTransform: 'capitalize',border: '1px solid #808080', borderTop: '0', borderTopColor: '#808080', borderRightColor: 'gray' }}>
                        {(itemInnerData.currency == "$") ? toTitleCase(serviceNameCorporate)+ " Acceptance Fee" : renderHTML(starSingle + " Additional Tranche " + toTitleCase(serviceNameCorporate)+ " Acceptance Fee")}
                    </td> : null
                )
            const tdItemsData = item.length > 0
                && item.map((itemInnerData, j) => {
                    return (
                        (itemInnerData.corporateAcceptanceFee != 0)?
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}:{textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }}>
                           {itemInnerData.currency}{(itemInnerData.corporateAcceptanceFee) ? amtSept(itemInnerData.corporateAcceptanceFee) : '$0'}
                        </td>
                        :
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}:{textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }}>
                            $0
                        </td>
                    )
                })
            const tdAnnualItems = item.length > 0
                && item.map((itemInnerData, j) =>
                    (j == 0) ? <td className='print-rm-fw' style={(item.length - 1 != j)?{textTransform: 'capitalize',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{textTransform: 'capitalize',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>
                        {(itemInnerData.currency == "$") ? toTitleCase(serviceNameCorporate)+ " Annual Fee" : renderHTML(starSingle + " Additional Tranche " + toTitleCase(serviceNameCorporate)+ " Annual Fee")}
                    </td> : null
                )
            const tdAnnualItemsData = item.length > 0
                && item.map((itemInnerData, j) => {
                    return (
                        (itemInnerData.corporateAcceptanceFee != 0)?
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>
                            {itemInnerData.currency}{(itemInnerData.corporateAnnualFee) ? amtSept(itemInnerData.corporateAnnualFee) : '$0'}
                        </td>
                        :
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>
                            $0
                        </td>
                    )
                })

            const Cumulativecost = data.length > 0
                && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>${(sCumulativeTotal[i]) ? amtSept(sCumulativeTotal[i]) : '0'}</td>)

            const firstYearTotalAmount = data.length > 0
                && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>${(sFirstYearTotal[i]) ? amtSept(sFirstYearTotal[i]) : '0'}</td>)

            const annualTotalAmount = data.length > 0
                && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>${(sAnnualTotal[i]) ? amtSept(sAnnualTotal[i]) : "0"}</td>)

            return (
                <>
                    {
                        (rfpData.corporateTrustService != "none") ?
                            <>
                                <tr className="text-align-right">
                                    {tdItems}
                                    {tdItemsData}
                                </tr>
                                <tr className="text-align-right">
                                    {tdAnnualItems}
                                    {tdAnnualItemsData}
                                </tr>
                            </> : null
                    }
                    {
                        (additionalAmounts.length == (i + 1)) ?
                            <>

                                <tr className="text-align-right">
                                    <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>First Year Total</b></td>
                                    {firstYearTotalAmount}
                                </tr>

                                <tr className="text-align-right">
                                    <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>Annual Total</b></td>
                                    {annualTotalAmount}
                                </tr>

                                <tr className="text-align-right">
                                    <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>Cumulative cost over the life of the issuance</b></td>
                                    {Cumulativecost}
                                </tr>
                            </> : null
                    }
                </>
            )
        });

    let firstYearServiceTotalAmount = 0;
    let annualServiceTotalAmount = 0;
    let cumulativeServicecost = 0;
// console.log(additionalServicesData)
    // Additional Services (per Tranche)
    const additionalServicesDesign = additionalServicesData.length > 0
        && additionalServicesData.map((item, i) => {
            const tdItems = item.length > 0
                && item.map((itemInnerData, j) =>
                    (j == 0) ?
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textTransform: 'capitalize',border: '1px solid #808080', borderRight: '0', borderTop: '0', borderTopColor: '#808080', borderRightColor: 'gray' }:{textTransform: 'capitalize',border: '1px solid #808080', borderTop: '0', borderTopColor: '#808080', borderRightColor: 'gray' }}>
                            {
                                (itemInnerData) ?
                                    (itemInnerData.id)?toTitleCase(itemInnerData.id.name)+ " Acceptance Fee": itemInnerData.id.name+ " Acceptance Fee" :
                                    null
                            }
                        </td>
                        : null
                )

            const tdItemsData = item.length > 0
                && item.map((itemInnerData, j) => {
                    return (
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}:{textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }}>
                            {
                                (itemInnerData) ?
                                    itemInnerData.currency + amtSept(itemInnerData.acceptanceFee) : 0
                            }
                        </td>
                    )
                })

            const tdAnnualItems = item.length > 0
                && item.map((itemInnerData, j) =>
                    (j == 0) ?
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textTransform: 'capitalize',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{textTransform: 'capitalize',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>
                            {
                                (itemInnerData) ?
                                    (itemInnerData.id)?toTitleCase(itemInnerData.id.name)+ " Annual Fee":itemInnerData.id.name+ " Annual Fee" :
                                    null
                            }
                        </td>
                        : null
                )

            const tdAnnualItemsData = item.length > 0
                && item.map((itemInnerData, j) => {
                    return (
                        <td className='print-rm-fw' style={(item.length - 1 != j)?{textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>
                            {
                                (itemInnerData) ?
                                    itemInnerData.currency + amtSept(itemInnerData.annualFee) : 0
                            }
                        </td>
                    )
                })

            const firstYearServiceTotalAmountDesign = data.length > 0
                && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>${(servicesFirstYearTotal[i]) ? amtSept(servicesFirstYearTotal[i]) : 0}</td>)

            const annualAmountDesign = data.length > 0
                && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>${(servicesAnnualTotal[i]) ? amtSept(servicesAnnualTotal[i]) : 0}</td>)

            const cumulativeAmountDesign = data.length > 0
                && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080' }:{color: '#3169c6', textAlign: 'center',border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'  }}>$
                    {
                        (servicesAnnualTotal[i] || servicesFirstYearTotal[i]) ?
                        amtSept(
                                servicesFirstYearTotal[i] + (servicesAnnualTotal[i] * (parseInt(rfpData.issuanceTenure) - 1))

                            ) : 0
                    }
                </td>
                )

            return (
                <>

                    <tr className="text-align-right">
                        {tdItems}
                        {tdItemsData}
                    </tr>
                    <tr className="text-align-right">
                        {tdAnnualItems}
                        {tdAnnualItemsData}
                    </tr>
                    {
                        (additionalServicesData.length == (i + 1)) ?
                            <>

                                <tr className="text-align-right">
                                    <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}} ><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>First Year Total</b></td>
                                    {firstYearServiceTotalAmountDesign}
                                </tr>

                                <tr className="text-align-right">
                                    <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}} ><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>Annual Total</b></td>
                                    {annualAmountDesign}
                                </tr>

                                <tr className="text-align-right">
                                    <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}} ><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>Cumulative cost over the life of the issuance</b></td>
                                    {cumulativeAmountDesign}
                                </tr>
                            </> : null
                    }
                </>
            )
        });

    // getting all organizations and make dinamic table headings
    const thDesign = data.length > 0
        && data.map((item, i) => {
        return (
            <th style={(data.length - 1 != i)?{border: '1px solid #808080', borderRight: '0'}:{border: '1px solid #808080', borderRightColor: '#808080'}} className="text-align-cntr">
                <div>
                    <h4><img src={process.env.REACT_APP_AWS_OBJECT_URL + item.organizations.logo} alt="Bald Eagle" style={{
                        width: '50px'
                    }}/></h4>
                    <br/>
                    <span>{item.organizations.name}</span>
                </div>
            </th>
        )
    })
    
    const legalAmountDesign = data.length > 0
        && data.map((item, i) =>  <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center' }:{border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center'}}>${(item.legalAmount) ? amtSept(item.legalAmount) : 0}</td>)

    const finalAnnualTotalDesign = data.length > 0
        && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6' }:{border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6'}}>${amtSept(finalAnnualTotal[i])}</td>)

    const finalFirstYearTotalDesign = data.length > 0
        && data.map((item, i) => <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6' }:{border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6'}}>${amtSept(finalFirstYearTotal[i])}</td>)

    const finalCumulativeDesign = data.length > 0
        && data.map((item, i) => {
            const tempsCumulativeTotal = (sCumulativeTotal[i]) ? parseInt(sCumulativeTotal[i]) : 0
            const tempserviceCumulativeTotal = (serviceCumulativeTotal[i]) ? parseInt(serviceCumulativeTotal[i]) : 0
            return (
                <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6' }:{border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6'}}>
                    ${amtSept(parseInt(tempsCumulativeTotal) + tempserviceCumulativeTotal)}
                </td>
            )
        })

    const finalTotalLegalDesign = data.length > 0
        && data.map((item, i) => {
            const tempsCumulativeTotal = (sCumulativeTotal[i]) ? parseInt(sCumulativeTotal[i]) : 0
            const tempserviceCumulativeTotal = (serviceCumulativeTotal[i]) ? parseInt(serviceCumulativeTotal[i]) : 0
            return (
                <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6' }:{border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6'}}>$
                    {amtSept(parseInt((item.legalAmount) ? item.legalAmount : 0) + (tempsCumulativeTotal + tempserviceCumulativeTotal))}
                </td>
            )
        })

    const finalTotalLegal = data.length > 0
        && data.map((item, i) => {
            const tempsCumulativeTotal = (sCumulativeTotal[i]) ? parseInt(sCumulativeTotal[i]) : 0
            const tempserviceCumulativeTotal = (serviceCumulativeTotal[i]) ? parseInt(serviceCumulativeTotal[i]) : 0
            return parseInt((item.legalAmount) ? item.legalAmount : 0) + (tempsCumulativeTotal + tempserviceCumulativeTotal)
        })
    const finalTotalLegalDifference = data.length > 0
        && data.map((item, i) =>
            <td className='print-text-dark print-rm-fw' style={(data.length - 1 != i)?{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6' }:{border: '1px solid #808080', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080',textAlign: 'center', color: '#3169c6'}}>$
                {amtSept(parseInt((finalTotalLegal[i]) ? finalTotalLegal[i] : 0) - Math.min(...finalTotalLegal))}
                </td>
            )
    // console.log('additionalServicesDesign',additionalServicesDesign)
    return (
        <>
            <section className="analytics-page">
                <div className="auto-container">
                    <div className="row clearfix ">

                        <div className="block-design content-column col-md-12 col-sm-12 col-xs-12">
                        <div className="col-md-6 p-0" align="left"><button type="button" onClick={() => history.goBack()}  className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</button></div>

                        <div className='col-md-6' align="end">
                         <span style={{padding: '10px'}}><button className="btn btn-primary" onClick={e=>exportTableToExcel("table-to-xls","analysis")}><i className='fa fa-download'></i></button></span>   
                         <span><button className="btn btn-primary" type='button' onClick={()=>printTableData('printAnalysis')}><i className='fa fa-print'></i></button></span>   
                        </div>
                        <div className='col-md-12'>
                        <h4 style={{
                            textAlign: 'center',
                            color: 'black',
                            fontWeight: '700',
                            marginBottom:"25px"
                        }}>Value Analysis of Submitted Proposals for a { (rfpData.issuanceType) ? rfpData.issuanceType.name : ""} {rfpData.issuanceTenure} Year Issuance</h4> 
                        </div>
                        {(tables)?
                            <div id='printAnalysis'>
                                <table id="table-to-xls" className="table table-border gridtable" style={{
                                        tableLayout: 'auto',
                                        width: 'max-content',
                                        margin: 'auto',
                                        fontWeight: '800',
                                        marginTop: '30px'
                                }}>
                                    <thead style={{border: '1px solid #808080'}}>
                                        <tr>
                                            <th style={{background: '#e6e6e6', border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray'}}>
                                                <div align="center">
                                                    <h4 style={{ fontWeight: '700', color: 'black', textTransform: 'uppercase' }}>Corporate Trust Provider</h4>
                                                </div>
                                            </th>
                                            {thDesign}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='print-text-dark rm-bl-bg'><td style={{
                                            background: '#5180ce',
                                            color: 'white',
                                            border: '1px solid #808080',
                                            borderTop: '0',
                                            borderTopColor: '#808080'
                                        }} colSpan={colSpanTd}><h5 className='print-text-dark rm-bl-bg' style={{ fontWeight: '700', textTransform: 'uppercase' }}>Indenture Trustee Services</h5></td></tr>
                                        {annualFeeAdditionalAmountsLabelDesign}

                                        <tr className='print-text-dark rm-bl-bg'><td style={{
                                            background: '#5180ce',
                                            color: 'white',
                                            border: '1px solid #808080',
                                            borderTop: '0',
                                            borderTopColor: '#808080'
                                        }} colSpan={colSpanTd}>
                                                <h5 className='print-text-dark rm-bl-bg' style={{ fontWeight: '700', textTransform: 'uppercase' }}>Additional Services</h5>
                                                {
                                                    (rfpData.tranchesNumber != 'single') ?
                                                        '(per Tranche)' : null
                                                }
                                            </td></tr>
                                        {additionalServicesDesign}
                                        <tr className='print-text-dark rm-bl-bg'><td style={{
                                            background: '#5180ce',
                                            color: 'white',
                                            border: '1px solid #808080',
                                            borderTop: '0',
                                            borderTopColor: '#808080'
                                        }} colSpan={colSpanTd}><h5 className='print-text-dark rm-bl-bg' style={{ fontWeight: '700', textTransform: 'uppercase' }}>Legal Fees</h5></td></tr>
                                        <tr>
                                            <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-rm-fw' style={{ fontWeight: '800' }}>Estimated Legal Fees</b></td>
                                            {legalAmountDesign}
                                        </tr>
                                        <tr className='print-text-dark rm-bl-bg'><td style={{
                                            background: '#5180ce',
                                            color: 'white',
                                            border: '1px solid #808080',
                                            borderTop: '0',
                                            borderTopColor: '#808080'
                                        }} colSpan={colSpanTd}><b><h5 className='print-text-dark rm-bl-bg' style={{ fontWeight: '700', textTransform: 'uppercase' }}>Total Cost to Issuer</h5></b></td></tr>
                                        <tr>
                                            <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>First Year Total</b></td>
                                            {finalFirstYearTotalDesign}
                                        </tr>
                                        <tr>
                                            <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>Annual Total</b></td>
                                            {finalAnnualTotalDesign}
                                        </tr>
                                        <tr>
                                            <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>Cumulative cost over the life of the issuance</b></td>
                                            {finalCumulativeDesign}
                                        </tr>
                                        <tr>
                                            <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>
                                                {
                                                    (rfpData.tranchesNumber != 'single') ?
                                                        <span className="text text-danger" style={{color: 'red'}}>**</span> : null
                                                }
                                                Total with Legal Fees</b></td>
                                            {finalTotalLegalDesign}
                                        </tr>
                                        <tr>
                                            <td style={{border: '1px solid #808080', borderRight: '0', borderRightColor: 'gray', borderTop: '0', borderTopColor: '#808080'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: '#3169c6' }}>Difference</b></td>
                                            {finalTotalLegalDifference}
                                        </tr>
                                        {
                                        (rfpData.tranchesNumber != "single")?
                                        <>
                                        <tr className='notToExport' style={{display: 'none'}}><td></td></tr>
                                        <tr className='notToExport' style={{display: 'none'}}><td></td></tr>
                                        <tr className='notToExport' style={{display: 'none'}}>
                                        <td colSpan={5}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: 'red' }}>* Not included in cummulative costs</b></td>
                                        </tr>
                                        <tr className='notToExport' style={{display: 'none'}}>
                                        <td colSpan={5} style={{borderTop: '0'}}><b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: 'red' }}>** Total Analysis reflects pricing for Single Tranche</b></td>
                                        </tr></>
                                    //     <div className="block-design col-md-12 col-sm-12 col-xs-12 notToExport" style={{display: 'none'}}>
                                    //     {
                                    //         (rfpData.tranchesNumber != "single")?
                                    //         <div style={{
                                    //             textAlign: 'left'
                                    //         }}>
                                    //             <b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: 'red' }}>* Not included in cummulative costs</b><br />
                                    //             <b className='print-text-dark print-rm-fw' style={{ fontWeight: '800', color: 'red' }}>** Total Analysis reflects pricing for Single Tranche</b>
                                    //         </div>:null
                                    //     }
                                    //     <br />
                                    //     <br />
                                    //   </div>
                                        :null }
                                    </tbody>
                                </table>
                                 <div className="block-design col-md-12 col-sm-12 col-xs-12">
                                    {
                                        (rfpData.tranchesNumber != "single")?
                                        <div style={{
                                            textAlign: 'left'
                                        }}>
                                            <h6 className="text text-danger" style={{fontWeight: '700', fontSize: '14px'}}>* Not included in cummulative costs</h6>
                                            <h6 className="text text-danger" style={{fontWeight: '700', fontSize: '14px'}}>** Total Analysis reflects pricing for Single Tranche</h6>
                                        </div>:null
                                    }
                                  </div>
                            </div>
                        :''}
                        </div>



                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});


export default connect(mapStateToProps)(Analytics);