import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as AuthService from '@services/auth';
import * as AppConstant from '@app/appConstants';
import './trustee-style.css';

const TrusteeDashboard = ({
    currentUser
}) => {
    const [org, setOrg] = useState({});
    const [roleId, setRoleId] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
        if (currentUser) {
            setRoleId(currentUser['custom:user_type']);
        }
        getOrganization();
        document.getElementById('preloader').style.display = "block";
    }, [currentUser]);

    const getOrganization = async () => {
        if (currentUser['custom:organization']) {
            try {
                const { orgData } = await AuthService.GetOrganizationById(currentUser['custom:organization']);
                setOrg(orgData);
                document.getElementById('preloader').style.display = "none";
            } catch (error) {
                document.getElementById('preloader').style.display = "none";
                // toast.error(
                //     (error.message) ||
                //     'Failed'
                // );
            }
        }
    }
    return (
        <>
            <section id="TrusteeDashboard">
                <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/bg/third.png"} className="dashboard-bg-image" />
                <div className="auto-container">
                    <div className="row clearfix">
                        <h1 className="h1-text1  text-black">Welcome {(currentUser.name) ? currentUser.name : ''} {(currentUser.family_name) ? currentUser.family_name : ''}</h1>
                        {(org) ? (org.logo) ? <img src={process.env.REACT_APP_AWS_OBJECT_URL + org.logo} className="w-12" /> : <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/no-org.png"} className="w-12" /> : <h1 className="text-black">Dashboard</h1>}
                        <h3 className="h1-text1 h1-text2  text-black">Trustee / Fiscal Agent & Paying Agent Services</h3>
                        <div className="col-md-12 grid3">
                            <div className="col-md-3 margin-bottom-5 px-15">
                                <Link to={'/rfp'}>
                                    <div className="col-md-12 box-radius-style">
                                        <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/new.png"} alt="" />
                                        <h3 className="h3-font-style text-black">NEW RFP (Corp)</h3>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3 margin-bottom-5 px-15">
                                <Link to={'/municipality'}>
                                    <div className="col-md-12 box-radius-style">
                                        <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/muni.png"} alt="" />
                                        <h3 className="h3-font-style text-black">NEW RFP (Muni)</h3>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3 margin-bottom-5 px-15">
                                <Link to={'/saved-list'}>
                                    <div className="col-md-12 box-radius-style">
                                        <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/proposal.png"} alt="" />
                                        <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's</h3>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3 margin-bottom-5 px-15">
                                <Link to={'/previous-list'}>
                                    <div className="col-md-12 box-radius-style">
                                        <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/history.png"} alt="" />
                                        <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFP's</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="TrusteeDashboard">
                <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/bg/third.png"} className="dashboard-bg-image" />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 grid4 grid3">
                            <h3 className="h1-text1 h1-text2  text-black h3-margin">Pre Issuance Services</h3>
                            {(AppConstant.globalConst.ARSFORMHANDLE.indexOf(roleId) > -1) ?
                                <>
                                    <div className='cust-navs-btn col-md-12'>
                                        <ul class="nav nav-pills">
                                            <li class="active"><a className='tab-ars' data-toggle="tab" href="#varfp">RFP</a></li>
                                            <li><a data-toggle="tab" className='tab-ars' href="#varespond">Respond</a></li>
                                        </ul>
                                    </div>
                                    <div class="tab-content">
                                        <div id="varfp" class="tab-pane fade in active">
                                            <div className="col-md-12 grid4 grid3">
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/ars/rfp/va'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/varfp.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">NEW RFP </h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/ars/saved-list/va'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/vasaved.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's</h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/ars/previous-list/va'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/vasubmit.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFP's</h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="varespond" class="tab-pane fade">
                                            <div className="col-md-12 grid4 grid3">
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/new-ars-list/va'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrva.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">NEW RFP </h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/saved-responses/va'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrvasave.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's </h3>

                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/previous-response/va'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrvasubmit.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFP's </h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-md-3 margin-bottom-5 px-15">
                                        <Link to={'/ars/rfp/va'}>
                                            <div className="col-md-12 box-radius-style">
                                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/varfp.png"} alt="" />
                                                <h3 className="h3-font-style text-black">NEW RFP </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 margin-bottom-5 px-15">
                                        <Link to={'/ars/saved-list/va'}>
                                            <div className="col-md-12 box-radius-style">
                                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/vasaved.png"} alt="" />
                                                <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's</h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 margin-bottom-5 px-15">
                                        <Link to={'/ars/previous-list/va'}>
                                            <div className="col-md-12 box-radius-style">
                                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/vasubmit.png"} alt="" />
                                                <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFP's</h3>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section id="TrusteeDashboard">
                <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/bg/third.png"} className="dashboard-bg-image" />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 grid4 grid3">
                            <h3 className="h1-text1 h1-text2  text-black h3-margin">Post Issuance Services</h3>
                            {(AppConstant.globalConst.ARSFORMHANDLE.indexOf(roleId) > -1) ?
                                <>
                                    <div className='cust-navs-btn col-md-12'>
                                        <ul class="nav nav-pills">
                                            <li class="active"><a className='tab-ars' data-toggle="tab" href="#rfp">RFP</a></li>
                                            <li><a data-toggle="tab" className='tab-ars' href="#respond">Respond</a></li>
                                        </ul>
                                    </div>
                                    <div class="tab-content">
                                        <div id="rfp" class="tab-pane fade in active">
                                            <div className="col-md-12 grid4 grid3">
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/ars/rfp/ars'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrfp.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">NEW RFP </h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/ars/saved-list/ars'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arssaved.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's</h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/ars/previous-list/ars'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arssubmit.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFP's</h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="respond" class="tab-pane fade">
                                            <div className="col-md-12 grid4 grid3">
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/new-ars-list/ars'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrrfpars.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">NEW RFP </h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/saved-responses/ars'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrsavedars.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's </h3>

                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 margin-bottom-5 px-15 board-card-w-30">
                                                    <Link to={'/previous-response/ars'}>
                                                        <div className="col-md-12 box-radius-style">
                                                            <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrsubmitars.png"} alt="" />
                                                            <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFP's </h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-md-3 margin-bottom-5 px-15">
                                        <Link to={'/ars/rfp/ars'}>
                                            <div className="col-md-12 box-radius-style">
                                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arsrfp.png"} alt="" />
                                                <h3 className="h3-font-style text-black">NEW RFP </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 margin-bottom-5 px-15">
                                        <Link to={'/ars/saved-list/ars'}>
                                            <div className="col-md-12 box-radius-style">
                                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arssaved.png"} alt="" />
                                                <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's</h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-3 margin-bottom-5 px-15">
                                        <Link to={'/ars/previous-list/ars'}>
                                            <div className="col-md-12 box-radius-style">
                                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/arssubmit.png"} alt="" />
                                                <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFP's</h3>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section id="TrusteeDashboard">
                <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/bg/third.png"} className="dashboard-bg-image" />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 grid4 grid3">
                            <h3 className="h1-text1 h1-text2  text-black h3-margin">Escrow Services<span className='coming-soon'> (COMING SOON)</span></h3>

                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/rfp'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/agreement.png"} alt="" />
                                    <h3 className="h3-font-style text-black">NEW ESCROW </h3>
                                </div>
                                {/* </Link> */}
                            </div>
                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/municipality'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/save_escrow.png"} alt="" />
                                    <h3 className="h3-font-style text-black">SAVED / IN PROCESS ESCROWS </h3>

                                </div>
                                {/* </Link> */}
                            </div>

                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/municipality'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/form.png"} alt="" />
                                    <h3 className="h3-font-style text-black">PREVIOUS ESCROWS </h3>

                                </div>
                                {/* </Link> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section id="TrusteeDashboard">
                <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/bg/third.png"} className="dashboard-bg-image" />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 grid4 grid3">
                            <h3 className="h1-text1 h1-text2  text-black h3-margin">Project Finance Administration<span className='coming-soon'> (COMING SOON)</span></h3>

                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/rfp'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/new.png"} alt="" />
                                    <h3 className="h3-font-style text-black">NEW PROJECT RFP </h3>
                                </div>
                                {/* </Link> */}
                            </div>
                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/municipality'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/muni.png"} alt="" />
                                    <h3 className="h3-font-style text-black">SAVED / IN PROGRESS RFP's </h3>

                                </div>
                                {/* </Link> */}
                            </div>

                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/municipality'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/currents.png"} alt="" />
                                    <h3 className="h3-font-style text-black">CURRENT PROJECTS </h3>

                                </div>
                                {/* </Link> */}
                            </div>

                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/municipality'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/projects11.png"} alt="" />
                                    <h3 className="h3-font-style text-black">PREVIOUS PROJECTS </h3>

                                </div>
                                {/* </Link> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section id="TrusteeDashboard">
                <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/bg/third.png"} className="dashboard-bg-image" />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 grid4 grid3">
                            <h3 className="h1-text1 h1-text2  text-black h3-margin" style={{ textTransform: 'unset' }}>Agent for Service of Process (ASOP)<span className='coming-soon'> (COMING SOON)</span></h3>

                            <div className="col-md-3 margin-bottom-5 px-15">
                                {/* <Link to={'/rfp'}> */}
                                <div className="col-md-12 box-radius-style">
                                    <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/call-center.png"} alt="" />
                                    <h3 className="h3-font-style text-black">AGENT FOR SERVICE OF PROCESS </h3>
                                </div>
                                {/* </Link> */}
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

export default connect(mapStateToProps, null)(TrusteeDashboard);