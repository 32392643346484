import axios from 'axios';
import store from '../store/index';
import { toast } from 'react-toastify';
import * as ActionTypes from '@store/actions';
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
require("dotenv").config();



/**
 * Define Backend Api Url
 */
const intance = axios.create({
    // baseURL: `http://3.15.71.33:5000/api/`,
    // baseURL: `http://localhost:5000/api/`,
    // baseURL: `http://18.221.1.248:5000/api/`,
    baseURL: process.env.REACT_APP_API_URL,
});


// const updateSubscribe = async (data) =>{
//     const {userData} = await AuthService.GetProfile(data.attributes['custom:id']);
//     Auth.updateUserAttributes(data, {
//         'custom:isSubscribed': (userData.isSubscribed)?'1':'0',
//         'custom:expiryDate': userData.expiryDate,
//         });
//         console.log(userData);
// }

// Auth.currentAuthenticatedUser()
// .then(data => {
//     onUserLogin(data.signInUserSession.idToken.jwtToken);
//     onUserDetail(data.attributes);
//     updateSubscribe(data);
// })
// .catch(err => {
// //   console.log('err', err)
// });

// const matchUrls = [
//     'user/create-rfp',
// 'user/create-municipality-rfp',
// 'user/get-rfp-by-type',
// 'user/upload-rfp-images',
// 'user/delete-rfp-images',
// 'user/delete-rfp-form',
// 'user/update-cfr-status',
// 'payment/create-customer-card-subscription',
// 'payment/update-card-detail',
// 'payment/cancel-subscription',
// 'payment/update-subscription',
// 'user/create-update-cfr',
// 'user/upload-cfr-images',
// 'user/delete-cfr-images',
// 'user/delete-cfr-form',
// 'user/accept-cfr',
// 'user/registration',
// 'user/update-profile',
// 'user/change-password',
// 'user/resetPassword',
// 'user/add-assignedrequest'
// ]
/* send user logged in token in header */
intance.interceptors.request.use(
    (request) => {
        // {
        //     if (request.method == "post" && matchUrls.indexOf(request.url) != -1) {
        //         const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //         request.data.timeZone = timezone;
        //         console.log("timezone", timezone);
        //     }
        // }
        const {
            token
        } = store.getState().auth;
        if (token) {
            request.headers.Authorization = `${token}`;
        }
        return request;
    },
    (error) => {
        // console.log(error.response,"api")
        Swal.fire({
            title: 'Message',
            text: (error.response && error.response.data && error.response.data.message) || 'Something went wrong.Please try again after some time',
            showCloseButton: true,
            customClass: {
                title: 'popup-title',
                closeButton: 'popup-close-btn',
                confirmButton: 'primary-confirm-popup-btn',
                htmlContainer: 'popup-popup-body'
            }
        })
        //  toast.error(
        //     (error.response &&
        //         error.response.data &&
        //         error.response.data.message) ||
        //         'Something went wrong.Please try again after some time'
        //   );
        return Promise.reject(error);
    }
);

/**Check if user is logged in or  not if user is not logged in . it will logout the user */
intance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response.data.statusCode === 401) {
            await Auth.signOut();
            store.dispatch({ type: ActionTypes.LOGOUT_USER });
            console.log(error.response)
            // toast.error("Session Expired")
        }
        console.log("err")
        Swal.fire({
            title: 'Message',
            text: (error.response && error.response.data && error.response.data.message) || 'Something went wrong.Please try again after some time',
            showCloseButton: true,
            customClass: {
                title: 'popup-title',
                closeButton: 'popup-close-btn',
                confirmButton: 'primary-confirm-popup-btn',
                htmlContainer: 'popup-popup-body'
            }
        })
        // toast.error(
        //     (error.response &&
        //         error.response.data &&
        //         error.response.data.message) ||
        //         'Something went wrong.Please try again after some time'
        // );
        return Promise.reject(error);
    }
);
// console.clear()
export default intance;
