import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Files from 'react-files';
import {toast} from 'react-toastify';
import * as RfpService from '@services/rfp';
import * as AppConstant from '@app/appConstants';

const Step3 = (props) => {
    let formik = props.formik;
    const [additionalFile, setAdditionalFile] = useState([]);
    const [termSheet, setTermSheet] = useState();
    const [removedFiles, setRemovedFiles] = useState([]);
    const [additionalDoc, setUpdateAdditionalDoc] = useState([]);
    const [updateTerm, setUpdateTerm] = useState();

    const onTermSheetChange = (files) => {
      const termSheetFile = (files)?files[0]:{};
      setTermSheet(termSheetFile);
      const checkSheetFile = (termSheetFile)?termSheetFile:"";
      // props.formik.values.termSheet = checkSheetFile;
      props.setCustomsFormVal('termSheet',checkSheetFile);
    }

    const onTermSheetError = (error, file) => {
        toast.error('Error ' + error.code + ': ' + error.message);
        // props.formik.values.termSheet = "";
        props.setCustomsFormVal('termSheet',"");
    }

    const removeTermSheet = async () => {
      await setTermSheet();
      // props.formik.values.termSheet = "";
      props.setCustomsFormVal('termSheet',"");
    }

    const removeTermSheetFromDB = async (url,type) => {
      // if (window.confirm("Are you sure ? you want to delete this image")) {3
      const removeParam = {
        uniqueId: formik.values.uniqueId,
        type: type,
        documentUrl: url
      }
      try {
        const {data} = await RfpService.removeDocuments(removeParam);
        console.log("file",data);
        setUpdateTerm(data.termSheet)
        setUpdateAdditionalDoc(data.additionalDocuments)
        toast.success('Document has been deleted.');
    } catch (error) {
        // if(error.message){
        //     toast.error(error.message);
        // }else{
        //    toast.error("Something went wrong");
        // }
    }
      // props.formik.values.termSheet = "";
      props.setCustomsFormVal('termSheet',"");
      // setUpdateTerm('');
    //  }
    }

    const onFilesChange = (files) => {
      const additionalFileCheck = files.filter(val => !removedFiles.includes(val));
      // console.log('rem',removedFiles);
      // console.log('addi',additionalFileCheck);
      setAdditionalFile(additionalFileCheck);
      const checkAdditionalDoc = (additionalFileCheck)?additionalFileCheck:[];
      // props.formik.values.additionalDocuments = checkAdditionalDoc;
      props.setCustomsFormVal('additionalDocuments',checkAdditionalDoc);
    }

    const onFilesError = (error, file) => {
      toast.error('Error ' + error.code + ': ' + error.message)
      const checkAdditionalDoc = [];
      // props.formik.values.additionalDocuments = checkAdditionalDoc;
      props.setCustomsFormVal('additionalDocuments',checkAdditionalDoc);
    }

    const removeFile = async (fileToRemove) => {
        let removeFile = additionalFile.filter(file => file.id !== fileToRemove.id);
        const removedFile = additionalFile.filter(file => file.id === fileToRemove.id);
        await setRemovedFiles([...removedFiles, removedFile[0]])
        await setAdditionalFile(removeFile);
        const checkAdditionalDoc = (removeFile)?removeFile:[];
      // props.formik.values.additionalDocuments = checkAdditionalDoc;
      props.setCustomsFormVal('additionalDocuments',checkAdditionalDoc);
      }
    
    const removeAllFiles = async () => {
      await setRemovedFiles([...removedFiles, ...additionalFile])
      await setAdditionalFile([]);
      props.formik.values.additionalDocuments = []
    }

    
    useEffect(() => {
      const termSheet = (props.term)?props.term:'';
      setUpdateTerm(termSheet);
      const additionDocu = (props.addiDoc)?props.addiDoc:[];
      setUpdateAdditionalDoc(additionDocu);
    }, []);

    return (
        <>
        <div className="form-row">
            <div className="col-md-12 padding-0 margin-head-info">
                <div className="main-title-head text-center">
                    <h1 className="h1-font-size-title">Attachments</h1>
                </div>
            </div>
        </div>
        {(AppConstant.globalConst.SHOWMUNIDOCDOWNLOAD.indexOf(formik.values.corporateTrustService) > -1) ?
        <div className="form-row">
            <div className="form-group form-group-margin col-md-12">
            <label className="label-text">Please fill out the <span style={{cursor:'pointer'}} onClick={() => window.location.href = process.env.REACT_APP_IMAGE_TAG + "/assets/Escrowagreement.docx"}  download="Escrowagreement.docx" title="Download Escrow Agreement" className="text text-primary">Escrow Agreement</span>. You can attach and upload it below.</label>
            </div>
        </div>
        :''}
        <div className="form-row">
            <div className="form-group form-group-margin col-md-6">
            <label className="label-text">Term Sheet or Defeasance Escrow Agreement</label>
            <Files
            className='files-dropzone form-control'
            onChange={onTermSheetChange}
            onError={onTermSheetError}
            maxFileSize={30000000}
            minFileSize={0}
            clickable
            multiple={false}
            >
            Drop files here or click to upload
            
            </Files>
            {formik.touched.termSheet && formik.errors.termSheet ? (
                    <div className="text text-danger" align="left">{formik.errors.termSheet}</div>
                ) : null}
            
            {
              (termSheet)
              ?
              <>
              <li> 
                <span className='files-list-item-content-item files-list-item-content-item-1'>
                  {(termSheet.name.length > 40)?termSheet.name.substring(0,40)+"...":termSheet.name} ({termSheet.sizeReadable})
                </span> 
                <span id={termSheet.id} className='files-list-item-remove fa fa-times' onClick={e=>removeTermSheet()} >
                </span>
              </li>
              </>
              :
              null
              }
              {
              (props.formType == 'edit' && updateTerm)
              ?
              <>
              <li> 
                <span className='files-list-item-content-item files-list-item-content-item-1'>
                  {
                    (updateTerm.split('MTM')[1])  ? 
                          'MTM'+updateTerm.split('MTM')[1] : 
                          null
                  } 
                </span> 
                <span  className='files-list-item-remove fa fa-times' onClick={e=>removeTermSheetFromDB(updateTerm, "termSheet")} >
                </span>
              </li>
              </>
              :
              null
              }
            </div>
            <div className="form-group form-group-margin col-md-6">
            <label className="label-text">Additional documents (select multiple)</label>
            <Files
            className='files-dropzone form-control'
            onChange={onFilesChange}
            onError={onFilesError}
            multiple
            maxFileSize={30000000}
            minFileSize={0}
            clickable
            >
            Drop files here or click to upload
            
            </Files>
            
            {formik.touched.additionalDocuments && formik.errors.additionalDocuments ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalDocuments}</div>
                ) : null}
          {
          additionalFile && additionalFile.length > 0
          ?
          <>
          <div className="col-md-12 mt-4">
          <div className="col-md-12" align="right">
             <button className="btn btn-danger" onClick={e=>removeAllFiles()}>Remove All Files</button>
          </div> 
          <div className='files-list col-md-12'>
            <ul>
              {additionalFile.map((file) =>
              <li> 
                <span className='files-list-item-content-item files-list-item-content-item-1'>
                  {(file.name.length > 40)?file.name.substring(0,40)+"...":file.name} ({file.sizeReadable})
                </span> 
                <span id={file.id} className='files-list-item-remove fa fa-times'  onClick={e=>removeFile(file)}>
                </span></li>
            )}
            </ul>
          </div>
          </div>
          </>
          : null
          }
          {
          props.formType == 'edit' && additionalDoc && additionalDoc.length > 0
          ?
          <>
          <div className="col-md-12 mt-4">
          <div className='files-list col-md-12'>
            <ul>
              {additionalDoc.map((file) =>
              <li> 
                <span className='files-list-item-content-item files-list-item-content-item-1'>
                  {
                    (file.split('MTM')[1])  ? 
                          'MTM'+file.split('MTM')[1] : 
                          null
                  }
                  {/* {(file.substring(file.lastIndexOf("\\") + 1).length > 40)?file.substring(file.lastIndexOf("\\") + 1).substring(0,40)+"...":file.substring(file.lastIndexOf("\\") + 1)} */}
                </span> 
                <span  className='files-list-item-remove fa fa-times'  onClick={e=>removeTermSheetFromDB(file,"additionalDocuments")}>
                </span></li>
            )}
            </ul>
          </div>
          </div>
          </>
          : null
          }

          </div>
        </div>
        </>
    );
};


export default Step3;