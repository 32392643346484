import React from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';

const PublicRoute = ({children, isLoggedIn, ...rest}) => {
    // const isAuthenticated = isLoggedIn;
    return (
        <Route
            {...rest}
            render={({location}) =>
                // isAuthenticated ? (
                //     <Redirect
                //         to={{
                //             pathname: '/matches',
                //             state: {from: location}
                //         }}
                //     />
                // ) : (
                    children
                // )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PublicRoute);
