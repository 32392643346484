import React, { useEffect, useState } from 'react';
import * as PaymentService from '@services/payment';
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import valid from "card-validator";
import 'react-credit-cards/es/styles-compiled.css';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import Swal from 'sweetalert2';
import ConfirmModal from '@pages/subscription/ConfirmModal';
require("dotenv").config();

const UpdateSubscription = (props) => {

    const history = useHistory();
    let { id } = useParams();
    const [rolePrice,setRolePrice] = useState('');
    const [planId,setPlanId] = useState('');
    const [userId,setUserId] = useState();
    const [selectPlanId,setSelectPlanId] = useState('');
    const [selectPlanText,setSelectPlanText] = useState('');
    const [manageDetail,setManageDetail] = useState();
    const [confirmOpen,setConfirmOpen] = useState(false);
    
    const handleSubmit = async (values) => {
        // document.getElementById("load").style.display = "block";
        let updateReq = {};
        try {
            updateReq.userId = userId
            updateReq.planId = selectPlanId
            console.log(updateReq)
            const paymentData = await PaymentService.subscriptionUpdatePlan(updateReq);
            if (paymentData) {
                if (props.isLoggedIn) {
                    const user = await Auth.currentAuthenticatedUser();
                    if (user && user.attributes) {
                        const { userData } = await AuthService.GetProfile(userId);
                        const { roleData } = await AuthService.GetRoleById(user.attributes['custom:user_type']);
                        const session = await Auth.currentSession();
                        props.onUserLogin(session.idToken.jwtToken);
                        await Auth.updateUserAttributes(user, {
                            'custom:userrole': roleData.slug,
                            'custom:isSubscribed': (userData.isSubscribed) ? '1' : '0',
                            'custom:expiryDate': userData.expiryDate,
                        });
                    }
                }
                // (props.isLoggedIn)?history.push('/payment-response/login'):history.push('/payment-response/register');
                document.getElementById('preloader').style.display = "none";
                history.push('/manage-subscription')
                Swal.fire({
                    title: 'Message',
                    text: 'Your plan has been updated successfully.',
                    showCloseButton: true,
                    customClass: {
                        title: 'popup-title',
                        closeButton: 'popup-close-btn',
                        confirmButton: 'primary-confirm-popup-btn',
                        htmlContainer: 'popup-popup-body'
                    }
                })
                if (props.isLoggedIn) {
                    const user = await Auth.currentAuthenticatedUser();
                    if (user && user.attributes) {
                        props.onUserDetail(user.attributes);
                    }
                }
            }

        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // console.log(error)
            // catch error
        }
    }

    const formik = useFormik({});

    const updateNewPlan = async () => {
        if(!selectPlanId){
            formik.setFieldError('planType', 'Please select your subscription plan');
        }else{      
            setConfirmOpen(true);      
        }
    }


    const handleConfirmClose = () => {
		setConfirmOpen(false);
	};


    const handleConfirmCheck = async (val) => {
        if(val == 'confirm'){
            document.getElementById('preloader').style.display = "block";
            await handleSubmit(selectPlanId);
        }
        setConfirmOpen(false);
	}


    const getRoleById = async (userId) => {
        try {
            const {roleData} = await PaymentService.getRolePriceById(userId);
            setRolePrice((roleData.role)?roleData.role.amount:'');
            setPlanId((roleData.role)?roleData.role.planId:'');
            // setSelectPlanId((roleData.role)?roleData.role.planId:'')
        } catch (error) {
            // error
        }
    }


    const getCardById = async (userId) => {
        try {
            const cardDtl = await PaymentService.getSubscriptionData(userId);
            setManageDetail((cardDtl)?cardDtl:'');
        } catch (error) {
            // error
        }
    }

    const subsData = {
        manageDetail: manageDetail,
        selectPlanText: selectPlanText
    }
    

    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.currentUser['custom:id']){
            getRoleById(props.currentUser['custom:id']);
            getCardById(props.currentUser['custom:id']);
            setUserId(props.currentUser['custom:id']);
        }
        document.getElementById('preloader').style.display = "none";
    }, [props.currentUser['custom:id']]);

    const setPlanRadioVal = (valId,msg) => {
       setSelectPlanText(msg)
       setSelectPlanId(valId)
       formik.setFieldError('planType', '');
    }

    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                <div className="auto-container">
                    <div className="row clearfix row-container-margin-width ">
                        <div className="col-md-12 bg-white margin-top-5 form-edit-col-main"
                        style={{height: "auto",display: "flex",justifyContent: "center",alignItems: "center"}}>
                            <div className="col-md-6 edit-profile-image">
                                <img className="edit-profile-image" style={{ width: '100%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Plan.png"} />
                            </div>
                            <div className="col-md-6 form-edit-col">
                                <div className="col-md-12 unset-padding padding-edit-profile bg-white">
                                    <div className="Inside">
                                        <h1 className="text-black h1-edit-profile">Select Subscription Plan</h1>
                                    </div>
                                    <div>
                                        <div class="radio" style={{fontSize: "12px",textAlign: "left",marginLeft: "4%" ,marginTop: "5px",marginBottom: "0px",color:"black"}}>
                                            <label><input type="radio" name="planType" onClick={e=>setPlanRadioVal(planId,'$'+`${rolePrice}`+' per year (billed annually)')} id="Radios1" value={planId}/>${rolePrice} per year (billed annually)</label>
                                            <label><input type="radio" name="planType" onClick={e=>setPlanRadioVal(process.env.REACT_APP_SECOND_PLAN,'$500 per year (billed annually) plus $25 processing fee per transaction')} id="Radios1" value={process.env.REACT_APP_SECOND_PLAN}/>$500 per year (billed annually) plus $25 processing fee per transaction</label>
                                            <label><input type="radio" name="planType" onClick={e=>setPlanRadioVal(process.env.REACT_APP_THIRD_PLAN,'$50 per month (billed monthly) plus $25 processing fee per transaction')} id="Radios1" value={process.env.REACT_APP_THIRD_PLAN}/>$50 per month (billed monthly) plus $25 processing fee per transaction</label>
                                        </div>
                                        {formik.errors.planType ? (
                                            <div className='col-md-12' style={{fontSize: "12px",textAlign: "left",marginLeft: "1%" ,marginTop: "5px",marginBottom: "0px",color:"black"}}><div className="text text-danger" align="left">{formik.errors.planType}</div></div>
                                        ) : null}
                                    </div>

                                    <div className='col-md-12' style={{padding: '15px'}}>
                                            <h5>{(selectPlanText)?`*Your plan will change to ${selectPlanText} and won't be charged until ${(manageDetail)?(manageDetail.nextBillingDate)?manageDetail.nextBillingDate:'----':'----'}`:''}</h5>
                                    </div>

                                    <div className="form-group  col-md-12 text-center">
                                        <button onClick={()=>updateNewPlan()} type="button" className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className="modal-backdrop fade show"></div> */}
            {(confirmOpen)?<ConfirmModal subsData={subsData} handleConfirmCheck={e => handleConfirmCheck(e)}  handleConfirmClose={e=>handleConfirmClose()}/>:''}
        </>
    )
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSubscription);