import API from '@app/utils/axios';

export const NewCrfp = async (requestData) => {
    const {data} = await API.post('user/create-update-cfr',requestData);
    
    return data;
};


export const GetSavedLists = async (requestData) => {
    const {data} = await API.post('user/get-cfr-by-type',requestData);
    
    return data;
};

/**
 * 
 * @param {*} id 
 * @returns data object
 */
export const GetCFRDetails = async (uniqueNo) => {
    const {data} = await API.post('/user/get-cfp-detail',{
        uniqueNo : uniqueNo
    });
    return data.data;
};



export const GetCFRDetailsForm = async (uniqueNo) => {
    const {data} = await API.post('/user/get-cfp-detail-form',{
        uniqueNo : uniqueNo
    });
    return data.data;
};


export const uploadDocuments = async (requestData) => {
    const {data} = await API.post('user/upload-cfr-images',requestData);
    
    return data;
};

export const removeDocuments = async (requestData) => {
    const {data} = await API.post('user/delete-cfr-images',requestData);
    
    return data.data;
};


export const removeCRFPS = async (requestData) => {
    const {data} = await API.post('user/delete-cfr-form',requestData);
    
    return data.data;
};

export const GetCompleteRFP = async (requestData) => {
    const {data} = await API.post('user/get-completerfp-by-type',requestData);
    
    return data;
};

export const GetAnalytics = async (requestData) => {
    console.log(requestData)
    const {
        data
    } = await API.post('/user/get-crf-analytics',requestData);
    return data.data;
};

export const GetAnalyticsCompare = async (requestData) => {
    const {
        data
    } = await API.post('/user/get-crf-compare-analytics',requestData);
    return data.data;
};


export const changeConfirmStatus = async (requestData) => {
    const {data} = await API.post('user/accept-cfr',requestData);
    
    return data.data;
};


export const GetFilterCfrLists = async (requestData) => {
    const {data} = await API.post('user/get-cfr-by-filter',requestData);
    
    return data;
};

export const getUsersByCfp = async (crfUserid) => {
    const {
        data
    } = await API.post(`user/get-cfr-userid`,crfUserid);
    return data.data;
};