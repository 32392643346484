import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-date-picker';
import * as AppConstant from '@app/appConstants';

const Step9 = ({ formik, setCustomsFormVal }) => {
    const handleDate = (key, val, type = '') => {
        var dateObj = new Date(val);
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        var date = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear();
        var shortDate = year + '-' + month + '-' + date;
        setCustomsFormVal(key, (val) ? shortDate : '', type);
        // setCustomsFormVal(key,(val)?moment(val, 'DDMMMYYYY').format('YYYY-MM-DD'):'',type);
    }
    return (
        <>
            <div className="form-row">
                <div className="col-md-12 padding-0 margin-head-info">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Timeframe</h1>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group form-group-margin col-md-6 customDatePickerWidth">
                    <label className="label-text">Final date for proposal submission <span className="text text-danger">*</span></label>
                    <DatePicker className="form-control date-input" name="submissionDate"
                        minDate={new Date(moment().tz(AppConstant.appTimeZone))}
                        format={'MM/dd/y'}
                        //  required={true}
                        onChange={(date) => handleDate('submissionDate', date, 'required')}
                        onKeyDown={(e) => e.preventDefault()}
                        value={(formik.values.submissionDate) ? AppConstant.selectDateFormat(formik.values.submissionDate) //new Date("'"+formik.values.submissionDate+"'")
                            : ''}
                    />
                    {formik.touched.submissionDate && formik.errors.submissionDate ? (
                        <div className="text text-danger" align="left">{formik.errors.submissionDate}</div>
                    ) : null}
                </div>
            </div>
        </>
    );
};


export default Step9;