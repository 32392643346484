import API from '@app/utils/axios';


/**
 * 
 * @returns data user register from DB
 */
export const registerUser = async (authData) => {
    //  console.log('auth',authData);
    const { data } = await API.post('user/registration', authData);

    return data.data;
};

/**
 * 
 * @returns data get issuance type from DB
 */
export const GetIssuanceType = async (params) => {
    const { data } = await API.post('user/get-issuance-type', params);

    return data;
};

/**
 * 
 * @returns data get additional service from DB
 */
export const GetAdditionalService = async (type) => {
    const { data } = await API.post('user/get-additional-service', { isMuni: type });

    return data;
};

/**
 * 
 * @returns data get rfp Organization list from DB
 */
export const GetRfpOrganization = async (roleid) => {
    const { data } = await API.post('user/get-organization', { roleId: roleid });

    return data;
};


/**
 * 
 * @returns data get roles list from DB
 */
export const GetRole = async () => {
    const { data } = await API.get('user/get-role');

    return data.data;
};


/**
 * 
 * @returns data get organization list from DB
 */
export const GetOrganization = async (roleId) => {
    const { data } = await API.post('user/get-organization-by-role', { role: roleId });

    return data.data;
};


/**
 * 
 * @returns data get roles list from DB
 */
export const GetRoleById = async (roleID) => {
    const { data } = await API.post('user/get-role-by-id', { id: roleID });

    return data.data;
};


/**
 * 
 * @returns data get organization list from DB
 */
export const GetOrganizationById = async (orgID) => {
    const { data } = await API.post('user/get-organization-by-id', { id: orgID });

    return data.data;
};

/**
 * 
 * @returns data get profile by id from DB
 */
export const GetProfile = async (userId) => {
    const { data } = await API.post('user/get-profile', { id: userId });

    return data.data;
};


/**
 * 
 * @returns data update user profile from DB
 */
export const UpdateProfile = async (updateData) => {
    const { data } = await API.post('user/update-profile', updateData);

    return data.data;
};



/**
 * 
 * @returns data change password
 */
export const ChangePasswords = async (updateData) => {
    const { data } = await API.post('user/change-password', updateData);

    return data.data;
};



/**
 * 
 * @returns data get user by email from DB
 */
export const checkEmailExist = async (email) => {
    const { data } = await API.post('user/check-user-email', email);

    return data.data;
};


/**
 * 
 * @returns data get user by email from DB
 */
export const checkUserAccount = async (email) => {
    const {
        data
    } = await API.post('user/check-user-account', {
        email: email
    });
    return data.data;
};


/**
 * 
 * @returns data get user by email from DB
 */
export const updateResetPassword = async (updatePassword) => {
    const { data } = await API.post('user/resetPassword', updatePassword);

    return data.data;
};


/**
 * 
 * @returns data get user by email from DB
 */
export const contactUs = async (contactData) => {
    const { data } = await API.post('/user/contact-us', contactData);

    return data.data;
};

/**
 * 
 * @returns assign user to rfp and crf
 */
export const assignUserToForm = async (requestData) => {
    const {
        data
    } = await API.post('/user/add-assignedrequest', requestData);
    return data.data;
};

