import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Auth } from "aws-amplify";
import * as ActionTypes from '@store/actions';
import * as AuthService from '@services/auth';
import { connect } from 'react-redux';
import Main from '@pages/main/Main';
import PublicRoute from './PublicRoutes';
import PrivateRoute from './PrivateRoutes';
import Layout from '@components/Layout';
import NotFound from '@pages/notfound/NotFound';
import AboutUs from '@pages/aboutus/AboutUs';
import HowitWork from '@pages/howitwork/HowitWork';
import ContactUs from '@pages/contactus/ContactUs';
import Resource from '@pages/resource/Resource';
import AdditionalService from '@pages/additionalservice/AdditionalService';
import RFP from '@pages/sender/rfp/RFP';
import Muni from '@pages/sender/municipality/Muni';
import Profile from '@pages/user-detail/Profile';
import ChangePassword from '@pages/user-detail/ChangePassword';
import Trustee from '@pages/user-detail/TrusteeDashboard';
import CorporateDashboard from '@pages/user-detail/CorporateDashboard';
import Corporate from '@pages/receiver/quotes/Corporate';
import SavedList from '@pages/sender/saved/SavedList';
import HistoryList from '@pages/sender/history/HistoryList';
import EditRfp from '@pages/sender/rfp/EditRfp';
import EditMuni from '@pages/sender/municipality/EditMuni';
import ViewRfp from '@pages/sender/rfp';
import ViewMunicipal from '@pages/sender/municipality';
import NewArsList from '@pages/ars-response/ars-list/NewArsList';
import ArsResponse from '@pages/ars-response/quotes/Response';
import SavedResponse from '@pages/ars-response/saved/SavedQuote';
import EditArsr from '@pages/ars-response/quotes/EditResponse';
import ArsrHistory from '@pages/ars-response/history/QuoteHistory';
import ArsrDetail from '@pages/ars-response/arsr-detail/ArsrDetail';


import NewRfpList from '@pages/receiver/rfp-list/NewRfpList';
import SavedQuote from '@pages/receiver/saved/SavedQuote';
import QuoteHistory from '@pages/receiver/history/QuoteHistory';
import CfrDetail from '@pages/receiver/cfr-detail/CfrDetail';
import EditCfr from '@pages/receiver/quotes/EditCorporate';
import Subscription from '@pages/subscription/Payment';
import ResetPassword from '@pages/forgot-password/PasswordReset';
import Response from '@pages/sender/response/Response';
import Analytics from '@pages/analytics/Analytics';
import Compare from '@pages/analytics/Compare';
import Terms from '@pages/terms/Terms';
import PrivacyPolicy from '@pages/terms/PrivacyPolicy';
import TrustCompliance from '@pages/terms/TrustCompliance';
import Help from '@pages/terms/Help';
import BetaUserAgreement from '@pages/terms/BetaUserAgreement';
import AcceptableUserAgreement from '@pages/terms/AcceptableUserAgreement';
import DPAAgreement from '@pages/terms/DPAAgreement';
import Pricing from '@pages/terms/Pricing';
import Events from '@pages/events/Events';
import Blogs from '@pages/events/Blog';
import SuccessPayment from '@pages/subscription/SuccessPayment';
import UpdateCard from '@pages/subscription/UpdateCard';
import ManageSubscription from '@pages/subscription/ManageSubscription';
import UpdateSubscription from '@pages/subscription/UpdateSubscription';
import Ars from '@pages/ars/arbitrage-rebate-services/ARS';
import ArsSavedList from '@pages/ars/saved/SavedList';
import ArsHistoryList from '@pages/ars/history/HistoryList';
import EditArs from '@pages/ars/arbitrage-rebate-services/EditArs';
import ViewArs from '@pages/ars/arbitrage-rebate-services';
import FAQ from '@pages/terms/FAQ';
import ArsrResponse from '@pages/ars/response/Response';
import ArsAnalytics from '@pages/ars/analytics/Analytics';
import ArsCompare from '@pages/ars/analytics/Compare';

const Index = ({ onUserLogin, onUserDetail }) => {

    const updateSubscribe = async (data) => {
        const { userData } = await AuthService.GetProfile(data.attributes['custom:id']);
        Auth.updateUserAttributes(data, {
            'custom:isSubscribed': (userData.isSubscribed) ? '1' : '0',
            'custom:expiryDate': userData.expiryDate,
        });
    }

    Auth.currentAuthenticatedUser()
        .then(data => {
            onUserLogin(data.signInUserSession.idToken.jwtToken);
            onUserDetail(data.attributes);
            updateSubscribe(data);
        })
        .catch(err => {
            //   console.log('err', err)
        });
    return (
        <Router basename={'/'}>
            <Switch>
                <PublicRoute exact path="/">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Main />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/about-us">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <AboutUs />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/events">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Events />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/blogs">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Blogs />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/how-it-work">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <HowitWork />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/contact-us">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ContactUs />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/resources">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Resource />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/additional-service">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <AdditionalService />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/privacy-policy">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <PrivacyPolicy />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/terms">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Terms />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/faq">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <FAQ />
                    </Layout>
                </PublicRoute>
                <PrivateRoute exact path="/rfp" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <RFP />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/municipality" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Muni />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/ars/rfp/:formtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Ars />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/profile" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Profile />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/change-password" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ChangePassword />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/trustee" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Trustee />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/corporate" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <CorporateDashboard />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/quote/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Corporate />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/saved-list" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <SavedList />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/ars/saved-list/:formtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsSavedList />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute path="/edit-rfp/:uniqueNo/:filterType?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <EditRfp />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute path="/edit-municipality/:uniqueNo/:filterType?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <EditMuni />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute path="/edit-ars/:uniqueNo/:formtype/:filterType?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <EditArs />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute path="/view-rfp/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ViewRfp />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute path="/ars/view-rfp/:uniqueNo/:formtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ViewArs />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute path="/view-municipal-rfp/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ViewMunicipal />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/previous-list/:filtertype?/:formtype?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <HistoryList />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/ars/previous-list/:rfptype/:filtertype?/:formtype?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsHistoryList />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/new-rfp-list" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <NewRfpList />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/new-ars-list/:formtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <NewArsList />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/ars-response/:uniqueNo/:formtype">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsResponse />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/saved-quote" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <SavedQuote />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/saved-responses/:formtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <SavedResponse />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/previous-quote/:filtertype?/:rfpunique?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <QuoteHistory />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/previous-response/:formtype/:filtertype?/:arsunique?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsrHistory />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/cfr-detail/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <CfrDetail />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/arsr-detail/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsrDetail />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/edit-cfr/:uniqueNo/:filterType?/:rfpunique?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <EditCfr />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/edit-response/:uniqueNo/:formtype/:filterType?/:arsunique?" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <EditArsr />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/response/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Response />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/arsr-response/:uniqueNo/:formtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsrResponse />
                    </Layout>
                </PrivateRoute>
                <PublicRoute exact path="/subscription/:id">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Subscription />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/reset-password">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ResetPassword />
                    </Layout>
                </PublicRoute>
                <PrivateRoute exact path="/analytics/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Analytics />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/ars-analytics/:uniqueNo/:reqtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsAnalytics />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/compare/:uniqueNo" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Compare />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/ars-compare/:uniqueNo/:reqtype" >
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ArsCompare />
                    </Layout>
                </PrivateRoute>
                <PublicRoute exact path="/pricing">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Pricing />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/en/trust-and-compliance-documentation">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <TrustCompliance />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/help">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <Help />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/company/legal/betauseragreements">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <BetaUserAgreement />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/company/legal/agreements">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <AcceptableUserAgreement />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/company/legal/DPAagreements">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <DPAAgreement />
                    </Layout>
                </PublicRoute>
                <PublicRoute exact path="/payment-response/:isLogin">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <SuccessPayment />
                    </Layout>
                </PublicRoute>
                <PrivateRoute exact path="/update-card/:id">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <UpdateCard />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/manage-subscription">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <ManageSubscription />
                    </Layout>
                </PrivateRoute>
                <PrivateRoute exact path="/update-subscription">
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <UpdateSubscription />
                    </Layout>
                </PrivateRoute>
                <PublicRoute>
                    <Layout header="hide" topBar="hide" footer="show" mainMenu="show">
                        <NotFound />
                    </Layout>
                </PublicRoute>
            </Switch>
        </Router>
    );
};


const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});
export default connect(null, mapDispatchToProps)(Index);
