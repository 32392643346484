import React from 'react';
import dateFormat from "dateformat";
import { removeHttpFromUrl } from '@app/appConstants';
import { Link } from 'react-router-dom';

const Section2 = ({ data, formtype }) => {
    console.log(process.env.REACT_APP_MODE, "modee")
    const openFile = (url) => {
        window.open(process.env.REACT_APP_AWS_OBJECT_URL + url, "_blank");
    }

    const serviceListDesign = data.typeOfServices?.length > 0
        && data.typeOfServices.map((item, i) => {
            return (
                <li>
                    {item.name}
                </li>
            )
        });

    const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
        <>
            <div className="form-row col-md-12 padding-0">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Transaction Information</h1>
                    </div>
                </div>
            </div>
            <div className="form-row col-md-12">
                {(formtype == 'va') ?
                    <>
                        <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text" >Type of Service</label>
                            <p className="paragraph-text">{(data.transactionTrustService) ? data.transactionTrustService.replaceAll('_', ' ') : '----'}</p>
                        </div>
                        <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text" >Type of Transaction</label>
                            <p className="paragraph-text">{(data.issuanceType) ? data.issuanceType.name : '----'}</p>
                        </div>
                    </>
                    :
                    <div className="form-group form-group-margin details-row-left col-md-6">
                        <label className="label-text" >Type of Service</label>
                        <p className="paragraph-text">{(data.typeOfServices?.length > 0) ? serviceListDesign : '----'}</p>
                    </div>
                }
                <div className={(formtype == 'va') ? "form-group form-group-margin details-row-left col-md-4" : "form-group form-group-margin details-row-left col-md-6"}>
                    <label className="label-text" >Name of Issuer</label>
                    <p className="paragraph-text">{(data.issuerName) ? data.issuerName : '----'}</p>
                </div>
            </div>
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text custom-margin-top-div">Date Bond Was Issued</label>
                    <p className="paragraph-text">
                        {
                            (data.issuedBondDate) ? dateFormat(data.issuedBondDate, "UTC:mm/d/yyyy") : '----'
                        }
                    </p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Do you utilize a Trustee or are the bond proceeds internally managed ?</label>
                    <p className="paragraph-text">{(data.bondManage) ? data.bondManage : '----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Are funds held in a commingled or segregated account ?</label>
                    <p className="paragraph-text">{(data.accountType) ? data.accountType : '----'}</p>
                </div>
            </div>

            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Transaction/Project Name</label>
                    <p className="paragraph-text">{(data.projectName) ? data.projectName : '----'}</p>
                </div>
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >Intended Date of Transaction Execution </label>
                    <p className="paragraph-text">
                        {
                            (data.transactionIntendedDate) ? dateFormat(data.transactionIntendedDate, "UTC:mm/d/yyyy") : '----'
                        }
                    </p>
                </div>
            </div>
            {/* {(data.transactionTrustService) ? */}
            <div className="form-row col-md-12">
                {/* {(data.transactionTrustService == 'Arbitrage Rebate Services') ?
                        <>
                            <div className="form-group form-group-margin details-row-left col-md-4">
                                <label className="label-text" >How were Bond proceeds spent ?</label>
                                <p className="paragraph-text">{(data.spentBond) ? data.spentBond : '----'}</p>
                            </div>
                        </>
                        : ''} */}

                {(data.transactionTrustService == 'Verification Agent') ?
                    <>
                        <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text" >When is the intended date of paying off the existing issuance ?</label>
                            <p className="paragraph-text">
                                {
                                    (data.payingIntendedDate) ? dateFormat(data.payingIntendedDate, "UTC:mm/d/yyyy") : '----'
                                }
                            </p>
                        </div>
                    </>
                    : ''}
                <div className="form-group form-group-margin details-row-left col-md-4">
                    <label className="label-text" >{(data.transactionTrustService == 'Verification Agent') ? 'Will you provide the information ?' : 'Will you provide an accounting of Bond proceeds ?'}</label>
                    <p className="paragraph-text">{(data.isInfo) ? data.isInfo : '----'}</p>
                </div>
                {(data.isInfo == 'yes') ?
                    <>
                        <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text">Will you provide the accounting via a file upload, direct access via link, or both ?</label>
                            <p className="paragraph-text">{(data.provideAccount) ? data.provideAccount : '----'}</p>
                        </div>
                    </>
                    : ''}
            </div>

            <div className="form-row col-md-12">
                {(data.provideAccount == 'upload' || data.provideAccount == 'both') ?
                    <>
                        <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text">Attached Document</label>
                            {
                                (data && data.document)
                                    ?
                                    <>
                                        <li>
                                            <span className='files-list-item-content-item files-list-item-content-item-1 cursor-pointer paragraph-text' onClick={e => openFile(data.document)}>
                                                {(data.document.substring(data.document.lastIndexOf("/") + 1).length > 60) ? data.document.substring(data.document.lastIndexOf('/') + 1).substring(0, 40) + "..." : data.document.substring(data.document.lastIndexOf('/') + 1)}
                                            </span>
                                        </li>
                                    </>
                                    :
                                    <div className="paragraph-text">----</div>
                            }
                        </div>
                    </>
                    : ''}
                {(data.provideAccount == 'link' || data.provideAccount == 'both') ?
                    <>
                        <div className="form-group form-group-margin details-row-left col-md-4">
                            <label className="label-text">Direct access via link</label>
                            <p className="paragraph-text" style={{ textTransform: 'none', cursor: 'pointer', color: '#306AC6' }}><span onClick={() => window.open("//" + removeHttpFromUrl((data.accessLink) ? data.accessLink : ''), "_blank")}>{(data.accessLink) ? data.accessLink : '----'}</span></p>
                        </div>
                    </>
                    : ''}
            </div>
            <div className="form-row col-md-12">
                <div className="form-group form-group-margin details-row-left col-md-12">
                    <label className="label-text" >Transaction Summary</label>
                    <p className="text-black"><b>{(data.transactionSummary) ? renderHTML(data.transactionSummary) : '----'}</b></p>
                </div>

            </div>


        </>
    );
};


export default Section2;