import React, { useState, useEffect } from 'react';
import * as AuthService from '@services/auth';
import * as AppConstant from '@app/appConstants';
import DatePicker from 'react-date-picker';
import moment from 'moment-timezone';
import Files from 'react-files';
import * as ArsService from '@services/ars';
import { toast } from 'react-toastify';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
var _ = require('lodash');

const Step2 = (props) => {
    let formik = props.formik;
    const [issuance, setIssuance] = useState([]);
    const [bondDocument, setBondDocument] = useState();
    const [updateDox, setUpdateDox] = useState();
    const [transVal, setTransVal] = useState(false);
    const [isInfoVal, setIsInfoVal] = useState(false);
    const [provideAccVal, setProvideAccVal] = useState(false);

    const getIssuanceType = async (type) => {
        try {
            const data = await AuthService.GetIssuanceType({ type: type });
            let typeList = (data.data.issuanceType) ? data.data.issuanceType : [];
            setIssuance(typeList);
        } catch (error) {
            //  toast.error(error);
        }
    }

    const issuanceDesign = issuance.length > 0
        && issuance.map((item, i) => {
            return (
                <option value={(item) ? item._id : ''}>{(item) ? item.name : ''}</option>
            );
        });


    const selectCorpTrust = (e) => {
        props.setCustomsFormVal('transactionTrustService', e.target.value, 'required');
        setTransVal(true)
        if (e.target.value === 'Verification Agent') {
            getIssuanceType("isVerification")
        }
        // else if (e.target.value === 'Arbitrage Rebate Services') {
        //     getIssuanceType('isArs')
        // }
    }


    useEffect(() => {
        if (formik.values.transactionTrustService) {
            if (formik.values.transactionTrustService === 'Verification Agent') {
                getIssuanceType("isVerification")
            }
            //  else if (formik.values.transactionTrustService === 'Arbitrage Rebate Services') {
            //     getIssuanceType('isArs')
            // }
        }

        if (formik.values.transactionTrustService && transVal) {
            formik.setFieldValue('spentBond', '');
            formik.setFieldValue('payingIntendedDate', '');
            formik.setFieldValue('isInfo', '');
            setBondDocument();
            formik.setFieldValue('document', '');
        }
    }, [formik.values.transactionTrustService])



    useEffect(() => {
        if (formik.values.transactionTrustService && transVal) {
            formik.setFieldValue('spentBond', '');
            formik.setFieldValue('payingIntendedDate', '');
            formik.setFieldValue('isInfo', '');
            formik.setFieldValue('provideAccount', '');
            setBondDocument();
            formik.setFieldValue('document', '');
            formik.setFieldValue('accessLink', '');
        }
    }, [formik.values.transactionTrustService])


    useEffect(() => {
        if (formik.values.isInfo && isInfoVal) {
            formik.setFieldValue('provideAccount', '');
            setBondDocument();
            formik.setFieldValue('document', '');
            formik.setFieldValue('accessLink', '');
        }
    }, [formik.values.isInfo])


    useEffect(() => {
        if (formik.values.provideAccount && provideAccVal) {
            setBondDocument();
            formik.setFieldValue('document', '');
            formik.setFieldValue('accessLink', '');
        }
    }, [formik.values.provideAccount])


    const selectTransType = (e) => {
        props.setCustomsFormVal('issuanceType', e.target.value, 'required');
    }

    const isBondHandle = (e) => {
        props.setCustomsFormVal('isInfo', e.target.value, '');
        setIsInfoVal(true)
    }

    const isProvideAccount = (e) => {
        props.setCustomsFormVal('provideAccount', e.target.value, '');
        setProvideAccVal(true)
    }

    const handleIntDate = (key, val, type = '') => {
        var dateObj = new Date(val);
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        var date = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear();
        var shortDate = year + '-' + month + '-' + date;
        // props.setCustomsFormVal(key,(val)?moment(val, 'DDMMMYYYY').format('YYYY-MM-DD'):'',type);
        props.setCustomsFormVal(key, (val) ? shortDate : '', type);
        // props.setCustomsFormVal(key,(val)?moment(val, 'DDMMMYYYY').format('YYYY-MM-DD'):'',type);
    }

    const onDoxChange = (files) => {
        const doxFile = (files) ? files[0] : {};
        setBondDocument(doxFile);
        const checkSheetFile = (doxFile) ? doxFile : "";
        props.setCustomsFormVal('document', checkSheetFile);
    }

    const onDoxError = (error, file) => {
        // toast.error('Error ' + error.code + ': ' + error.message);
        props.setCustomsFormVal('document', "");
    }

    const removeDox = async () => {
        await setBondDocument();
        props.setCustomsFormVal('document', "");
    }

    const removeDoxFromDB = async (url, type) => {
        const removeParam = {
            uniqueId: formik.values.uniqueId,
            type: type,
            documentUrl: url
        }
        try {
            const { data } = await ArsService.removeDocuments(removeParam);
            setUpdateDox(data.document)
            toast.success('Document has been deleted.');
        } catch (error) {
        }
        props.setCustomsFormVal('document', "");
    }

    useEffect(() => {
        const dox = (props.document) ? props.document : '';
        setUpdateDox(dox);
        if (props.formtype == 'ars') {
            getIssuanceType('isArs')
        }
    }, [props.formtype]);

    const issuanceList = issuance.length > 0
        && issuance.map((item, i) => {
            return ({ label: (item) ? item.name : '', value: (item) ? item._id : '' });
        });

    const handleServicesOnchange = val => {
        const updateServices = val;
        props.setCustomsFormVal('typeOfServices', (updateServices) ? updateServices : '', 'required');
    }

    return (
        <>

            <div className="form-row">
                <div className="col-md-12 padding-0 margin-head-info">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">Transaction Information</h1>
                    </div>
                </div>
            </div>
            <div className="form-row bg-white">
                {(props.formtype == 'va') ?
                    <>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text label-margin-for-span-p"><p className="span-hash color-white">.</p>Type of Service <span className="text text-danger">*</span></label>
                            <select className="form-control" name="transactionTrustService" value={(formik.values.transactionTrustService) ? formik.values.transactionTrustService : ''} onChange={e => selectCorpTrust(e)}>
                                <option readOnly defaultValue="" value=''>Type of Service</option>
                                <option value="Verification Agent" >Verification Agent</option>
                                {/* <option value="Arbitrage Rebate Services">Arbitrage Rebate Services</option> */}
                            </select>
                            {formik.touched.transactionTrustService && formik.errors.transactionTrustService ? (
                                <div className="text text-danger" align="left">{formik.errors.transactionTrustService}</div>
                            ) : null}
                        </div>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text label-margin-for-span-p"><p className="span-hash color-white">.</p>Type of Transaction <span className="text text-danger">*</span></label>
                            <select className="form-control" id="sel1" name="issuanceType" value={(formik.values.issuanceType) ? formik.values.issuanceType : ''} onChange={e => selectTransType(e)}>
                                <option readOnly value='' defaultValue="">Select Transaction Type</option>
                                {issuanceDesign}
                            </select>
                            {formik.touched.issuanceType && formik.errors.issuanceType ? (
                                <div className="text text-danger" align="left">{formik.errors.issuanceType}</div>
                            ) : null}
                        </div>
                    </>
                    :
                    <div className="form-group form-group-margin col-md-6">
                        <label className="label-text label-margin-for-span-p"><p className="span-hash color-white">.</p>Type of Service (select multiple) <span className="text text-danger">*</span></label>
                        {(issuanceList && issuanceList.length > 0) ?
                            <MultiSelect
                                onChange={(val) => handleServicesOnchange(val)}
                                options={issuanceList}
                                name="typeOfServices"
                                className="multi-drop tosCls"
                                defaultValue={props.formik.values.typeOfServices}
                                placeholder={(props.formik.values.typeOfServices.length == 0) ? "Select..." : ""}
                            />
                            : ''}
                        {formik.touched.typeOfServices && formik.errors.typeOfServices ? (
                            <div className="text text-danger" align="left">{formik.errors.typeOfServices}</div>
                        ) : null}
                    </div>
                }
                <div className={(props.formtype == 'va') ? "form-group form-group-margin col-md-4" : "form-group form-group-margin col-md-6"}>
                    <label className="label-text" >Name of Issuer
                        <p className="span-hash">(If you do not wish to reveal client name at this time, type "Confidential") <span className="text text-danger">*</span></p>
                    </label>
                    <input type="text" name="issuerName" className="form-control" {...formik.getFieldProps('issuerName')} />
                    {formik.touched.issuerName && formik.errors.issuerName ? (
                        <div className="text text-danger" align="left">{formik.errors.issuerName}</div>
                    ) : null}
                </div>
                <div className="clear-both"></div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p custom-margin-top-div" ><p className="span-hash color-white">.</p>Date Bond Was Issued </label>
                    <DatePicker className="form-control date-input" name="issuedBondDate"
                        // minDate={new Date(moment().tz(AppConstant.appTimeZone))}
                        format={'MM/dd/y'}
                        //  required={true}
                        onChange={(date) => handleIntDate('issuedBondDate', date, '')}
                        onKeyDown={(e) => e.preventDefault()}
                        value={(formik.values.issuedBondDate) ? new Date(formik.values.issuedBondDate) //new Date("'"+formik.values.issuedBondDate+"'")
                            : ''}
                    />
                    {formik.touched.issuedBondDate && formik.errors.issuedBondDate ? (
                        <div className="text text-danger" align="left">{formik.errors.issuedBondDate}</div>
                    ) : null}
                </div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p"><p className="span-hash color-white">.</p>Do you utilize a Trustee or are the bond proceeds internally managed ? <span className="text text-danger">*</span></label>
                    <select className="form-control" name="bondManage" value={(formik.values.bondManage) ? formik.values.bondManage : ''} {...formik.getFieldProps('bondManage')}>
                        <option readOnly defaultValue="" value=''></option>
                        <option value="Trustee">Trustee</option>
                        <option value="Internally Managed">Internally Managed</option>
                        <option value="Other">Other</option>
                    </select>
                    {formik.touched.bondManage && formik.errors.bondManage ? (
                        <div className="text text-danger" align="left">{formik.errors.bondManage}</div>
                    ) : null}
                </div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p"><p className="span-hash color-white">.</p>Are funds held in a commingled or segregated<br /> account ? <span className="text text-danger">*</span></label>
                    <select className="form-control" name="accountType" value={(formik.values.accountType) ? formik.values.accountType : ''} {...formik.getFieldProps('accountType')}>
                        <option readOnly defaultValue="" value=''></option>
                        <option value="Commingled">Commingled</option>
                        <option value="Segregated">Segregated</option>
                    </select>
                    {formik.touched.accountType && formik.errors.accountType ? (
                        <div className="text text-danger" align="left">{formik.errors.accountType}</div>
                    ) : null}
                </div>
                <div className="clear-both"></div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p custom-margin-top-div">Transaction/Project Name</label>
                    <input type="text" name="projectName" className="form-control" {...formik.getFieldProps('projectName')} placeholder="ex. GE 20XX Issuance, Project Alpha, etc." />
                    {formik.touched.projectName && formik.errors.projectName ? (
                        <div className="text text-danger" align="left">{formik.errors.projectName}</div>
                    ) : null}
                </div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p custom-margin-top-div">Intended Date of Transaction Execution  <span className="text text-danger">*</span></label>
                    <DatePicker className="form-control date-input" name="transactionIntendedDate"
                        minDate={new Date(moment().tz(AppConstant.appTimeZone))}
                        format={'MM/dd/y'}
                        //  required={true}
                        onChange={(date) => handleIntDate('transactionIntendedDate', date, 'required')}
                        onKeyDown={(e) => e.preventDefault()}
                        value={(formik.values.transactionIntendedDate) ? new Date(formik.values.transactionIntendedDate) //new Date("'"+formik.values.transactionIntendedDate+"'")
                            : ''}
                    />
                    {/* <input type="date" name="transactionIntendedDate" className="form-control" onKeyDown={(e) => e.preventDefault()} {...formik.getFieldProps('transactionIntendedDate')}/> */}
                    {formik.touched.transactionIntendedDate && formik.errors.transactionIntendedDate ? (
                        <div className="text text-danger" align="left">{formik.errors.transactionIntendedDate}</div>
                    ) : null}
                </div>
                {/* {(formik.values.transactionTrustService) ? */}
                {/* {(formik.values.transactionTrustService == 'Arbitrage Rebate Services') ?
                            <>
                                <div className="form-group form-group-margin col-md-4">
                                    <label className="label-text custom-margin-top-div">How were Bond proceeds spent ?</label>
                                    <input type="text" name="spentBond" className="form-control" {...formik.getFieldProps('spentBond')} />
                                    {formik.touched.spentBond && formik.errors.spentBond ? (
                                        <div className="text text-danger" align="left">{formik.errors.spentBond}</div>
                                    ) : null}
                                </div>
                            </>
                            : ''} */}
                {(formik.values.transactionTrustService == 'Verification Agent') ?
                    <>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text">When is the intended date of paying off the existing issuance ?</label>
                            <DatePicker className="form-control date-input" name="payingIntendedDate"
                                minDate={new Date(moment().tz(AppConstant.appTimeZone))}
                                format={'MM/dd/y'}
                                //  required={true}
                                onChange={(date) => handleIntDate('payingIntendedDate', date, '')}
                                onKeyDown={(e) => e.preventDefault()}
                                value={(formik.values.payingIntendedDate) ? new Date(formik.values.payingIntendedDate) : ''}
                            />
                            {formik.touched.payingIntendedDate && formik.errors.payingIntendedDate ? (
                                <div className="text text-danger" align="left">{formik.errors.payingIntendedDate}</div>
                            ) : null}
                        </div>
                    </>
                    : ''}
                <div className="clear-both"></div>
                <div className="form-group form-group-margin col-md-4">
                    <label className="label-text label-margin-for-span-p custom-margin-top-div">{(props.formtype == 'va') ? 'Will you provide the information ?' : 'Will you provide an accounting of Bond proceeds ?'}</label>
                    <div className="radio">
                        <label className="label-text-black radio-inline"><input type="radio" name="isInfo" onChange={e => isBondHandle(e)} checked={formik.values.isInfo === "yes"} value="yes" />Yes</label>

                        <label className="label-text-black radio-inline"><input type="radio" name="isInfo" onChange={e => isBondHandle(e)} checked={formik.values.isInfo === "no"} value="no" />No</label>
                    </div>
                    {formik.touched.isInfo && formik.errors.isInfo ? (
                        <div className="text text-danger" align="left">{formik.errors.isInfo}</div>
                    ) : null}
                </div>
                {(formik.values.isInfo == 'yes') ?
                    <>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text custom-margin-top-div">Will you provide the accounting via a file upload, direct access via link, or both?</label>
                            <div className="radio">
                                <label className="label-text-black radio-inline"><input type="radio" name="provideAccount" onChange={e => isProvideAccount(e)} checked={formik.values.provideAccount === "upload"} value="upload" />Upload</label>
                                <label className="label-text-black radio-inline"><input type="radio" name="provideAccount" onChange={e => isProvideAccount(e)} checked={formik.values.provideAccount === "link"} value="link" />Link</label>
                                <label className="label-text-black radio-inline"><input type="radio" name="provideAccount" onChange={e => isProvideAccount(e)} checked={formik.values.provideAccount === "both"} value="both" />Both</label>
                            </div>
                            {formik.touched.provideAccount && formik.errors.provideAccount ? (
                                <div className="text text-danger" align="left">{formik.errors.provideAccount}</div>
                            ) : null}
                        </div>
                    </>
                    : ''}
                {(formik.values.provideAccount == 'upload' || formik.values.provideAccount == 'both') ?
                    <>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text label-margin-for-span-p custom-margin-top-div">Please attach document here</label>
                            <Files
                                className='files-dropzone form-control'
                                onChange={onDoxChange}
                                onError={onDoxError}
                                maxFileSize={30000000}
                                minFileSize={0}
                                clickable
                                multiple={false}
                            >
                                Drop files here or click to upload
                            </Files>
                            {formik.touched.document && formik.errors.document ? (
                                <div className="text text-danger" align="left">{formik.errors.document}</div>
                            ) : null}
                            {
                                (bondDocument)
                                    ?
                                    <>
                                        <li>
                                            <span className='files-list-item-content-item files-list-item-content-item-1'>
                                                {(bondDocument.name.length > 40) ? bondDocument.name.substring(0, 40) + "..." : bondDocument.name} ({bondDocument.sizeReadable})
                                            </span>
                                            <span id={bondDocument.id} className='files-list-item-remove fa fa-times' onClick={e => removeDox()} >
                                            </span>
                                        </li>
                                    </>
                                    :
                                    null
                            }
                            {
                                (props.formType == 'edit' && updateDox)
                                    ?
                                    <>
                                        <li>
                                            <span className='files-list-item-content-item files-list-item-content-item-1'>
                                                {
                                                    (updateDox.split('MTM')[1]) ?
                                                        'MTM' + updateDox.split('MTM')[1] :
                                                        null
                                                }
                                            </span>
                                            <span className='files-list-item-remove fa fa-times' onClick={e => removeDoxFromDB(updateDox, "document")} >
                                            </span>
                                        </li>
                                    </>
                                    :
                                    null
                            }
                        </div>
                        {(formik.values.provideAccount == 'both') ? <div className="clear-both"></div> : ''}
                    </>
                    : ''}
                {(formik.values.provideAccount == 'link' || formik.values.provideAccount == 'both') ?
                    <>
                        <div className="form-group form-group-margin col-md-4">
                            <label className="label-text label-margin-for-span-p custom-margin-top-div">Direct access via link</label>
                            <input type="text" name="accessLink" className="form-control" {...formik.getFieldProps('accessLink')} />
                            {formik.touched.accessLink && formik.errors.accessLink ? (
                                <div className="text text-danger" align="left">{formik.errors.accessLink}</div>
                            ) : null}
                        </div>
                    </>
                    : ''}
            </div>
            <div className="form-row">
                <div className="form-group form-group-margin col-md-12">
                    <label className="label-text">Transaction Summary </label>
                    {/* <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable'],
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                            ]
                        }
                    }}
                    data={(formik.values.transactionSummary)?formik.values.transactionSummary:''}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor ) => {
                        const data = editor.getData();
                        // formik.values.transactionSummary = (data)?data:'';
                        props.setCustomsFormVal('transactionSummary',(data)?data:'');
                        // console.log( { event, editor, data } );
                    }}
                /> */}

                    <textarea name='transactionSummary' rows={6} className="form-control" {...formik.getFieldProps('transactionSummary')} >
                        {(formik.values.transactionSummary) ? formik.values.transactionSummary : ''}
                    </textarea>
                </div>
            </div>
        </>
    );
};


export default Step2;