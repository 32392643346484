import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import './contact.css';
import * as ActionTypes from '@store/actions';

const ContactUs = ({ props }) => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);


    const contactUsSubmit = async (value) => {
        try {
            const { contactUs } = await AuthService.contactUs(value);

            toast.success("Your message has been successfully sent. We will contact you very soon!");
            document.getElementById('preloader').style.display = "none";
            formik.resetForm();
        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // toast.error(
            //     (error.message) ||
            //         'Failed'
            // );
        }
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .test('alphabets', 'Name must only contain alphabets', (value) => {
                    return /^[a-zA-Z\s]*$/.test(value);
                })
                .required('Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email address is required'),
            subject: Yup.string()
                .required('Subject is required'),
            message: Yup.string()
                .required('Message is required'),
        }),
        onSubmit: (values) => {
            contactUsSubmit(values);
            document.getElementById('preloader').style.display = "block";
        }
    });


    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Contact Us</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Contact Us</li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="contact-section bg-blue p-t-3">
                <div className="auto-container">

                    <div className="row">
                        <div className="col-md-6">
                            <img className="img" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Contact-Us.png"} />
                        </div>
                        <div className="col-md-6 p-t-3">
                            <div className="default-form contact-form p-t-3">
                                <form onSubmit={formik.handleSubmit} id="contact-form">
                                    <div className="row clearfix">
                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <input type="text" name="name" placeholder="Name *" {...formik.getFieldProps('name')} className='input-style' />
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className="text text-danger" align="left">{formik.errors.name}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <input type="email" name="email" placeholder="Email *" {...formik.getFieldProps('email')} className='input-style' />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text text-danger" align="left">{formik.errors.email}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                            <input type="text" name="subject" placeholder="Subject *" {...formik.getFieldProps('subject')} className='input-style' />
                                            {formik.touched.subject && formik.errors.subject ? (
                                                <div className="text text-danger" align="left">{formik.errors.subject}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                            <textarea name="message" placeholder="Type your question/comments here *" {...formik.getFieldProps('message')} className='input-style mb-0'></textarea>
                                            {formik.touched.message && formik.errors.message ? (
                                                <div className="text text-danger" align="left">{formik.errors.message}</div>
                                            ) : null}
                                        </div>


                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                            <button type="submit" className="theme-btn btn-style-one">send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="map-area">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="box m-t-2 m-b-2 bg-light-blue">
                                <div className="card two_col">
                                    <div className="card-body text-left p-3">
                                        <h3 className="p-b-1 text-center black"><strong>GET IN TOUCH WITH US</strong></h3>
                                        <p className="black">
                                            We encourage comments and questions. Please feel free to call, email, or write your questions in the form above, and one of our staff will respond as soon as possible.
                                        </p>
                                        <div>
                                            <h3 className="p-b-1 black"><strong>Contact Us</strong></h3>
                                        </div>
                                        <ul className="contact-info text-left black">
                                            <li className="p-b-1"><h4><span className="fa fa-phone"></span><span className="p-l-2">(305) 952-4184</span></h4></li>
                                            <li className="p-b-1"><h4><span className="fa fa-envelope"></span><span className="p-l-2">info@mytrustme.com</span></h4></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-7">
                            <div className=" box card two_col m-t-2 m-b-2">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3587.6863183105183!2d-80.14896198528233!3d25.945522583553725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9ac5440c1a975%3A0x102719215c896950!2s18305%20Biscayne%20Blvd%20%23210%2C%20Aventura%2C%20FL%2033160!5e0!3m2!1sen!2sus!4v1636786630376!5m2!1sen!2sus" width="100%" height="430px" style={{ border: "0", marginBottom: '-8px' }} allowFullScreen="" loading="lazy"></iframe>
                            </div>
                        </div> */}
                    </div>
                </div>

            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);