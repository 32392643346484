import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import {toast} from 'react-toastify';

const ResetCode = (props) => {
    const history = useHistory();
    /**
     * 
     * @param {*} value 
     */
    const forgotPassword = async (value) => {
        let username = value.email;
        try {
              const {userData} = await AuthService.checkEmailExist(value);
              if(userData.email){
                await Auth.forgotPassword(username);
                toast.success("Otp sent to your E-mail. please check");
                history.push('/reset-password');
              }else{
                toast.error("Entered email does not exist. Please check your email");
              }
              document.getElementById('preloader').style.display = "none";
        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // toast.error(
            //     (error.message) ||
            //         'Failed'
            // );
        }
   }

   const formik = useFormik({
       initialValues: {
           email: ''
       },
       validationSchema: Yup.object({
           email: Yup.string()
               .email('Invalid email address')
               .required('Email address is required')
       }),
       onSubmit: (values) => {
           forgotPassword(values);
           document.getElementById('preloader').style.display = "block";
       }
   });


    return (
        <>
        <div className="col-md-12 col-sm-12">
        <div className="default-form contact-form">
            <form onSubmit={formik.handleSubmit} id="contact-form">
                <div className="row clearfix">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                            <input type="text" name="email"  placeholder="Email *" {...formik.getFieldProps('email')} />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="text text-danger" align="left">{formik.errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <button type="submit" className="theme-btn btn-style-one btn-lg">Reset</button>
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <a  onClick={e => props.handleChange(1)} ><h4>Sign In</h4></a>
                    </div>
                </div>
            </form>
        </div>
        </div>
        </>
    );
};


export default ResetCode;