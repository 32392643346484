import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import "react-phone-input-2/lib/style.css";
import PhoneInput from 'react-phone-input-2';
import './quotes.css';
const Step1 = ({formik,setCustomsFormVal}) => {

    const handlePhoneNo = (key,phone,type='') => {
        // formik.values.phoneNo = "+" + phone
        setCustomsFormVal(key,phone,type);
        if(phone && phone.length < 12){
            setTimeout(async()=>{
                await formik.setFieldError(key, "Minimum 10 digits")
                await formik.setFieldTouched(key, true)
            },1000)
        }else{
            setTimeout(async()=>{
                await formik.setFieldError(key, "")
                await formik.setFieldTouched(key, false)
            },1000)
        }
    }

    return (
        <>
        <div className="form-row">
            <div className="form-group form-group-margin col-md-4">
                <label className="label-text">Institution <span className="text text-danger">*</span></label>
                <input type="text" name="institution" className="form-control" {...formik.getFieldProps('institution')}/>
                {formik.touched.institution && formik.errors.institution ? (
                    <div className="text text-danger" align="left">{formik.errors.institution}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4">
                <label className="label-text">First Name <span className="text text-danger">*</span></label>
                <input type="text" name="firstName" className="form-control" {...formik.getFieldProps('firstName')}/>
                    {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="text text-danger" align="left">{formik.errors.firstName}</div>
                    ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4">
                <label className="label-text">Last Name <span className="text text-danger">*</span></label>
                <input type="text" name="lastName" className="form-control" {...formik.getFieldProps('lastName')}/>
                    {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="text text-danger" align="left">{formik.errors.lastName}</div>
                    ) : null}
            </div>
        </div>
        <div className="clear-both"></div>
        <div className="form-row bg-white">
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">E-mail <span className="text text-danger">*</span></label>
            <input type="text" name="email" className="form-control" {...formik.getFieldProps('email')}/>
                {formik.touched.email && formik.errors.email ? (
                    <div className="text text-danger" align="left">{formik.errors.email}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">Work Tel. <span className="text text-danger">*</span></label>
            <PhoneInput
                className="phone-no-input"
                country={'us'}
                inputProps={{
                    name: 'workTel'
                }}
                countryCodeEditable={false}
                value={(formik.values.workTel.length > 2)?formik.values.workTel:'+1'}
                onChange={workTel =>  handlePhoneNo('workTel',(workTel.length >= 2)?"+"+workTel:'','required')}
                />
            {formik.touched.workTel && formik.errors.workTel ? (
                    <div className="text text-danger" align="left">{formik.errors.workTel}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">Cell No.</label>
            <PhoneInput
                className="phone-no-input"
                country={'us'}
                inputProps={{
                    name: 'cellNo'
                }}
                countryCodeEditable={false}
                value={(formik.values.cellNo.length > 2)?formik.values.cellNo:'+1'}
                onChange={cellNo => handlePhoneNo('cellNo',(cellNo.length >= 2)?"+"+cellNo:'')}
                />
            {formik.touched.cellNo && formik.errors.cellNo ? (
                    <div className="text text-danger" align="left">{formik.errors.cellNo}</div>
                ) : null}
            </div>
        </div>
        <div className="clear-both"></div>
        <div className="form-row col-md-12 p-0">
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">Additional Contact First Name</label>
            <input type="text" name="additionalFirstName" className="form-control" {...formik.getFieldProps('additionalFirstName')}/>
            {formik.touched.additionalFirstName && formik.errors.additionalFirstName ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalFirstName}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">Additional Contact Last Name</label>
            <input type="text" name="additionalLastName" className="form-control" {...formik.getFieldProps('additionalLastName')}/>
            {formik.touched.additionalLastName && formik.errors.additionalLastName ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalLastName}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">Additional Contact E-mail</label>
            <input type="text" name="additionalEmail" className="form-control" {...formik.getFieldProps('additionalEmail')}/>
            {formik.touched.additionalEmail && formik.errors.additionalEmail ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalEmail}</div>
                ) : null}
            </div>
            
            <div className="clear-both"></div>
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">Work Tel. </label>
            <PhoneInput
                className="phone-no-input"
                country={'us'}
                inputProps={{
                    name: 'additionalWorkTel'
                }}
                countryCodeEditable={false}
                value={(formik.values.additionalWorkTel.length > 2)?formik.values.additionalWorkTel:'+1'}
                onChange={additionalWorkTel => handlePhoneNo('additionalWorkTel',(additionalWorkTel.length >= 2)?"+"+additionalWorkTel:'')}
                />
            {formik.touched.additionalWorkTel && formik.errors.additionalWorkTel ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalWorkTel}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4 col-sm-4">
            <label className="label-text">Cell No.</label>
            <PhoneInput
                className="phone-no-input"
                country={'us'}
                inputProps={{
                    name: 'additionalCellNo'
                }}
                countryCodeEditable={false}
                value={(formik.values.additionalCellNo.length > 2)?formik.values.additionalCellNo:'+1'}
                onChange={additionalCellNo => handlePhoneNo('additionalCellNo',(additionalCellNo.length >= 2)?"+"+additionalCellNo:'')}
                />
            {formik.touched.additionalCellNo && formik.errors.additionalCellNo ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalCellNo}</div>
                ) : null}
            </div>
            </div>
            <div className="clear-both"></div>
        </>
    );
};


export default Step1;