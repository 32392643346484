import React, { useState, useEffect } from 'react';
import * as AppConstant from '@app/appConstants';

const Section5 = ({ data }) => {
    const cusipExchangeDesign = data.cusipExchange.length > 0
        && data.cusipExchange.map((item, i) => {
            return (
                <li>
                    <span>{(item.cusipType)?item.cusipType.replaceAll('_', " ")+':':'----'} </span>
                    <span> {(item.cusipExchange)?item.cusipExchange.replaceAll('_', " "):'----'}</span>
                </li>
            )
        });
    return (
        <>
            <div className="form-row">
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">{(data.corporateTrustService == AppConstant.globalConst.CORPCORPATETRUSTSERVICE && data.issuanceType._id == AppConstant.globalConst.CORPESCROWISSUANCEID)?"ADDITIONAL SERVICES FOR ESCROW TRANSACTIONS ONLY":(data.issuanceType._id == AppConstant.globalConst.CORPESCROWISSUANCEID)?"ADDITIONAL SERVICES FOR REFUNDING ESCROW TRANSACTIONS ONLY":"ADDITIONAL SERVICES FOR EXCHANGE TRANSACTIONS ONLY"}</h1>
                    </div>
                </div>
            </div>
            <div className="form-row col-md-12 details-row-left">
            <div className=" col-md-12">
                <label className="label-text">Please provide CUSIP/ISIN information for each note that will be exchanged</label>
                <p className="paragraph-text form-group-margin " >{(data.cusipExchange.length != 0 ? <ul >{cusipExchangeDesign}</ul> : <div>----</div>)}</p>
            </div>
            </div>
            <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 details-row-left radio-form-bg">
                <div className="col-md-12 padding-0 margin-head-info">
                    <div className=" form-group form-group-margin col-md-6">
                        <label className="label-text">If this transaction includes an Exchange of existing debt, will you require Extension of Offer services?</label>
                        <p className="paragraph-text">{(data.offerServices)?data.offerServices:'----'}</p>
                    </div>
                    <div className="form-group form-group-margin col-md-6">
                        <label className="label-text">If this transaction includes an Exchange of existing debt, will you require Midnight Expiration services?</label>
                        <p className="paragraph-text">{(data.expirationServices)?data.expirationServices:'----'}</p>
                    </div>
                    </div>
                </div>
        
        </>
    );
};


export default Section5;