import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as AuthService from '@services/auth';
import * as ArsService from '@services/ars';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
var _ = require('lodash');

const Step8 = (props) => {
    let formik = props.formik;

    const [trustee, setTrustee] = useState([]);
    const [trusteelistCount, setTrusteeListCount] = useState(true);
    const [trusteeno, setTrusteeNo] = useState(0);
    const [trusteeListData, setTrusteeListData] = useState('');
    // console.log("trusteeListData",props.trusteeListData);
    const getTrusteeProvider = async (roleid) => {
        try {
            const data = await ArsService.GetProviderList(roleid);
            console.log(data, "data");
            let typeList = (data.data.issuanceType) ? data.data.issuanceType : [];
            setTrustee(typeList);
        } catch (error) {
            //  toast.error(error);
        }
    }

    useEffect(() => {
        if (props.loggedUserDetail['custom:user_type']) {
            getTrusteeProvider(props.loggedUserDetail['custom:user_type']);
        }
        setTrusteeNo(formik.values.noOfTrustee);
        console.log(props, "listdata")
        setTrusteeListData(props.trusteeListData)
    }, [props.loggedUserDetail]);

    const handlesetTrusteeListOnchange = val => {
        setTrusteeListCount(true)
        setTrusteeListData(val)
        // formik.values.trusteeList = (val) ? val : '';
        props.setCustomsFormVal('trusteeList', (val) ? val : '', 'required');
    }

    const handleTrusteenoOnchange = val => {
        setTrusteeNo(val);
        if (!val) {
            setTimeout(async () => {
                await formik.setFieldError('noOfTrustee', "* Required")
                await formik.setFieldTouched('noOfTrustee', true)
            }, 1000)
        }
        // props.formik.values.noOfTrustee = (val) ? val : 0;
        props.setCustomsFormVal('noOfTrustee', (val) ? val : 0);
        // formik.values.trusteeList = '';
        props.setCustomsFormVal('trusteeList', '');
        setTrusteeListData('')
        setTrusteeListCount(false)
    }


    const optionsList = [];
    const trusteeNo = [];

    let countNo = 1;
    trustee.length > 0
        && trustee.map((item, i) => {
            let astrickVal = (item.userCount == 0) ? `${item.name} *` : item.name
            optionsList.push({ label: astrickVal, value: item._id });
            if (countNo > 2) {
                trusteeNo.push(<option selected={(trusteeno == countNo) ? 'selected' : ''} value={countNo}>{countNo}</option>);
            }
            countNo++;
        });

    return (
        <>
            <div className="form-row">
                <div className="col-md-12 padding-0 margin-head-info">
                    <div className="main-title-head text-center">
                        <h1 className="h1-font-size-title">{(props.formtype == 'va') ? "Verification Agent Selection" : "Arbitrage Rebate Services Provider Selection"}</h1>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group form-group-margin col-md-6">
                    <label className="label-text label-margin-for-span-p-legal-fee"><p className="span-hash color-white">.</p>Please select how many provider quotes you require <span className="text text-danger">*</span></label>
                    {(trusteeNo && trusteeNo.length > 0) ?
                        <select className="form-control" id="sel1" name="noOfTrustee" {...formik.getFieldProps('noOfTrustee')} onChange={e => handleTrusteenoOnchange(e.target.value)}>
                            <option readOnly selected value="">Select number of servicers</option>
                            {trusteeNo}
                        </select>
                        : ''}
                    {formik.touched.noOfTrustee && formik.errors.noOfTrustee ? (
                        <div className="text text-danger" align="left">{formik.errors.noOfTrustee}</div>
                    ) : null}
                </div>
                <div className="form-group form-group-margin col-md-6">
                    <label className="label-text">Please select preferred service providers. <span className="text text-danger">*</span>
                        <p className="span-hash">(unless specifically requested, we will only reach out to providers that are subscribed to MyTrustMe) (select multiple) </p></label>
                    {(optionsList && optionsList.length > 0) ?
                        <MultiSelect
                            onChange={handlesetTrusteeListOnchange}
                            options={optionsList}
                            name="trusteeList"
                            className="multi-drop trustListCls"
                            limit={trusteeno}
                            defaultValue={(trusteelistCount) ? trusteeListData : []}
                        />
                        : ''}
                    <label className="label-text"><p className="span-hash">Servicers with '<span>&#10033;</span>' are currently not registered. Once registered, they will get RFP Request.</p></label>
                    {formik.touched.trusteeList && formik.errors.trusteeList ? (
                        <div className="text text-danger" align="left">{formik.errors.trusteeList}</div>
                    ) : null}
                </div>
            </div>
        </>
    );
};


export default Step8;