import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const FAQ = () => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Frequently Asked Questions </h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Frequently Asked Questions </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 text-left">
                            {/* <!-- start container --> */}
                            <div class="container">
                                <div class="panel-group" id="accordion">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                    <span className='font-weight-700'>What is MyTrustMe.com?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne" class="panel-collapse collapse">
                                            <div class="panel-body">MyTrustMe.com is secure, cloud-based platform that allows subscribers to submit Requests for Proposals (RFPs) from service providers for a variety of required services related to Bond administration.</div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                                    <span className='font-weight-700'>How do I get started on MyTrustMe.com?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo" class="panel-collapse collapse">
                                            <div class="panel-body">To access the platform, simply select the Register option at the top right of the homepage.  Follow the instructions regarding registration.  MyTrustMe.com utilizes Dual-Factor Verification and will text you a code to your mobile number to complete registration.</div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                                    <span className='font-weight-700'>Does MyTrustMe.com offer any training on the system?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree" class="panel-collapse collapse">
                                            <div class="panel-body">While we don’t offer any formal training, we have tutorial videos to help you navigate the easy-to-use platform.  You can access those videos by selecting the “How it Works” tab at the top of our homepage.  Scroll down to the bottom and you will see the videos we currently offer.  You can also access these videos on our YouTube channel.</div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseFourth">
                                                    <span className='font-weight-700'>Is there a limit to the number of transactions I can submit? </span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFourth" class="panel-collapse collapse">
                                            <div class="panel-body">No.  You can submit an unlimited number of transactions via the platform.  If your subscription type includes transaction fees, you will only be charged a transaction fee if you click on the “Accept” button with the Previous RFP module.</div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseFifth">
                                                    <span className='font-weight-700'>Why would I use MyTrustMe.com instead of just calling or e-mailing my service provider? </span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFifth" class="panel-collapse collapse">
                                            <div class="panel-body">MyTrustMe.com reduces the amount of time you would need to obtain the information you need from those providers.  It can also provide cost savings based on the comparison of rates provided. In addition, MyTrustMe.com prepares a proprietary analysis of all the responses and organizes and stores that information for you.  The system provides as fair process for all transactors responding to RFPs and provides transparency for auditing. </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseSixth">
                                                    <span className='font-weight-700'>Do I have to sign a long-term contract to use MyTrustMe.com?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseSixth" class="panel-collapse collapse">
                                            <div class="panel-body">No.  There are no contracts you have to enter into to use our system.  You can cancel your subscription at any time. </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventh">
                                                    <span className='font-weight-700'>Do I have to be based in the United States to use the system?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseSeventh" class="panel-collapse collapse">
                                            <div class="panel-body">No.  Our system can be utilized by anyone from anywhere.  So, if you are a requestor based in Europe, Asia, or Latin America, you can still use the system to request services from providers for transactions in any jurisdiction providers operate in.</div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseEight">
                                                    <span className='font-weight-700'>Can I switch my subscription selection?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseEight" class="panel-collapse collapse">
                                            <div class="panel-body">Yes.  Select the   icon at the top right of the screen once you are logged in to the system.  Select Manage Subscription from the drop-down menu and select “Change Plan” on the left of the screen.</div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseNinth">
                                                    <span className='font-weight-700'>Does my subscription renew automatically?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseNinth" class="panel-collapse collapse">
                                            <div class="panel-body">Yes.  On the anniversary date of each month (for month-to-month subscribers) or each year (for annual subscribers), we will charge the credit card with which you entered upon subscribing an amount equal to the rate for your subscription type.</div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseTenth">
                                                    <span className='font-weight-700'>How does MyTrustMe.com secure the information I enter and who has access to that information?</span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTenth" class="panel-collapse collapse">
                                            <div class="panel-body">All data entered by users into the system are automatically encrypted and stored on our secured database on the Amazon Web Services (AWS) platform.  Only the user inputting the information (on both the requestor and responder side) have access to the information (unless you or the responder allow access to a co-worker to assist on that particular transaction).  No employee at MyTrustMe.com has access to the information transmitted through our website.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end container --> */}
                        </div>
                    </div>

                </div>
            </section>

        </>
    );
};

export default FAQ;