import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import * as ActionTypes from '@store/actions';
import { connect } from 'react-redux';
import store from '@store/index';
import { appTimeZone } from '@app/appConstants';
import moment from 'moment-timezone';
// import ResetModal from "@pages/forgot-password/ResetModal";

const Login = (props) => {
    const [resetModal, setResetModal] = useState(false);
    const history = useHistory();

    /**
     * 
     * @param {*} value 
     */
    const userLogin = async (value) => {
        let username = value.email;
        let password = value.password;
        let todayDate = new Date(moment().tz(appTimeZone)).toISOString();
        try {
            const user = await Auth.signIn(username, password);
            if (user.attributes['custom:id']) {
                const { userData } = await AuthService.GetProfile(user.attributes['custom:id']);
                const { roleData } = await AuthService.GetRoleById(user.attributes['custom:user_type']);
                if (userData && userData.isSubscribed && userData.expiryDate > todayDate) {
                    // if(roleData && roleData.slug == 'capital_markets_banker' || roleData.slug == 'law_firms'){
                    //     history.push("/trustee");
                    // }else if(roleData && roleData.slug == 'corporate_trust_provider') {
                    //     history.push("/corporate");
                    // }
                    if (roleData && roleData.slug == 'corporate_trust_provider') {
                        history.push("/corporate");
                    } else {
                        history.push("/trustee");
                    }
                } else {
                    // alert("logedd");
                    // history.push('/subscription/'+window.btoa(userData._id));
                    history.push('/');
                }

                await Auth.updateUserAttributes(user, {
                    'custom:userrole': roleData.slug,
                    'custom:isSubscribed': (userData.isSubscribed) ? '1' : '0',
                    'custom:expiryDate': userData.expiryDate,
                });
                const userDetail = user.attributes;
                props.onUserLogin(user);
                props.onUserDetail(userDetail);
                toast.success("You are Logged In successfully. Please wait...");
            } else {
                await Auth.signOut();
                store.dispatch({ type: ActionTypes.LOGOUT_USER });
                history.push("/");
                toast.success("You are Logout Successfully");
            }
            document.getElementById('preloader').style.display = "none";
        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            // toast.error(
            //     (error.message) ||
            //         'Failed'
            // );
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email address is required'),
            password: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .required('Password is required')
        }),
        onSubmit: (values) => {
            userLogin(values);
            document.getElementById('preloader').style.display = "block";
        }
    });


    const openForgotModal = () => {
        setResetModal(true);
    }




    return (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="default-form contact-form">
                    <form onSubmit={formik.handleSubmit} id="contact-form">
                        <div className="row clearfix">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <input type="text" name="email" placeholder="Email *" {...formik.getFieldProps('email')} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="text text-danger" align="left">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input type="password" name="password" placeholder="Password *" {...formik.getFieldProps('password')} />
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className="text text-danger" align="left">{formik.errors.password}</div>
                                    ) : null}
                                </div>
                                <div className="checkboxes col-md-6 font-18">
                                    <label>
                                        <input type="checkbox" name="remember" className="remember-box" value="" />
                                        <span>Remember Me</span></label>
                                </div>
                                <div className="col-md-6 font-18">
                                    <a onClick={e => props.openForgot(3)}><i className="fa fa-key" aria-hidden="true"></i> <label>Forgot Password</label></a>
                                </div>

                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <button type="submit" className="theme-btn btn-style-one btn-lg">Login</button>
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <a onClick={e => props.handleChange(2)} ><h4>Not Enrolled ? Sign Up now</h4></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);