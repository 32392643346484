import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const AcceptableUserAgreement = () => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Acceptable Use Agreement and External Facing Services Policy</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Acceptable Use Agreement and External Facing Services Policy</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="default-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 text-left">
                            <p>MyTrustMe.com LLC recognizes the value of computer and other electronic resources to improve transactions and enhance the obtaining and analysis of information. To this end, MyTrustMe.com LLC encourages the responsible use of computers; computer networks, including the Internet; and other electronic resources in support of the mission and goals of the MyTrustMe.com platform.</p>

                            <p>Because the Internet is an unregulated, worldwide vehicle for communication, information available to staff and customers is impossible to control. Therefore, MyTrustMe.com LLC adopts this policy governing the voluntary use of electronic resources and the Internet in order to provide guidance to individuals and groups obtaining access to these resources on MyTrustMe.com LLC’s owned domains.</p>
                            <b>User Agreement</b>
                            <p>By registering to access MyTrustMe.com, users agree that they have read, understand, and will abide by the above Acceptable Use Policy when using resources owned, leased, or operated by MyTrustMe.com LLC. They further understand that any violation of the regulations above is unethical and may constitute a criminal offense. Should they commit any violation, access privileges may be revoked, and disciplinary action may be taken, and/or appropriate legal action may be initiated. </p>

                            <b>MyTrustMe.com LLC Rights and Responsibilities</b>
                            <p>It is the policy of the MyTrustMe.com LLC to maintain an environment that promotes ethical and responsible conduct in all online network activities by staff and customers. It shall be a violation of this policy for any employee, customer, or other individual to engage in any activity that does not conform to the established purpose and general rules and policies of the network. Within this general policy, MyTrustMe.com LLC recognizes its legal and ethical obligation to protect the well-being of customers and any data. To this end, the MyTrustMe.com LLC retains the following rights and recognizes the following obligations:</p>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>To log network use and to monitor fileserver space utilization by users, and assume no responsibility or liability for files deleted due to violation of fileserver space allotments.</li>
                                <li style={{ listStyle: 'auto' }}>To remove a user account on the network.</li>
                                <li style={{ listStyle: 'auto' }}>To monitor the use of online activities. This may include real-time monitoring of network activity and/or maintaining a log of Internet activity for later review.</li>
                                <li style={{ listStyle: 'auto' }}>To provide internal and external controls as appropriate and feasible. Such controls shall include the right to determine who will have access to MyTrustMe.com LLC owned domains and, specifically, to exclude those who do not abide by the MyTrustMe.com LLC's acceptable use policy or other policies governing the use of our facilities, equipment, and materials. MyTrustMe.com LLC reserves the right to restrict online destinations through software or other means.</li>
                                <li style={{ listStyle: 'auto' }}>To provide guidelines and make reasonable efforts to train staff and customers in acceptable use and policies governing online communications.</li>
                            </ol>
                            <b>Staff Responsibilities</b>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>Staff members who supervise staff or customer accounts, control electronic equipment, or otherwise have occasion to observe users use of said equipment online shall make reasonable efforts to monitor the use of this equipment to assure that it conforms to the mission and goals of MyTrustMe.com LLC.</li>
                                <li style={{ listStyle: 'auto' }}>Staff should make reasonable efforts to become familiar with the Internet and its use so that effective monitoring, instruction, and assistance may be achieved.</li>
                            </ol>
                            <b>User Responsibilities</b>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>Use of the electronic media provided by the MyTrustMe.com LLC is a privilege that offers a wealth of information and resources for research and transacting business. In order to maintain the privilege, users agree to learn and comply with all of the provisions of this policy.</li>
                            </ol>
                            <b>Acceptable Use</b>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>All use of the Internet must be in support of the intended use of the system and consistent with the mission and objectives of MyTrustMe.com LLC.</li>
                                <li style={{ listStyle: 'auto' }}>Proper codes of conduct in electronic communication must be used.</li>
                                <li style={{ listStyle: 'auto' }}>Accounts are to be used only by the authorized owner of the account for the authorized purpose.</li>
                                <li style={{ listStyle: 'auto' }}>All communications and information accessible via the network should be assumed to be private property.</li>
                                <li style={{ listStyle: 'auto' }}>From time to time, MyTrustMe.com LLC will make determinations on whether specific uses of the network are consistent with the acceptable use practice.</li>
                            </ol>
                            <b>Unacceptable Use</b>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>Giving out personal information about another person, including home address and phone number, is strictly prohibited.</li>
                                <li style={{ listStyle: 'auto' }}>Excessive use of the network for personal business shall be cause for disciplinary action.</li>
                                <li style={{ listStyle: 'auto' }}>Any use of the network for product advertisement or political lobbying is prohibited.</li>
                                <li style={{ listStyle: 'auto' }}>Users shall not intentionally seek information on, obtain copies of, or modify files, other data, or passwords belonging to other users, or misrepresent other users on the network.</li>
                                <li style={{ listStyle: 'auto' }}>No use of the network shall serve to disrupt the use of the network by others. Hardware and/or software shall not be destroyed, modified, or abused in any way.</li>
                                <li style={{ listStyle: 'auto' }}>Malicious use of the network to develop programs that harass other users or infiltrate a computer or computing system and/or damage the software components of a computer or computing system is prohibited.</li>
                                <li style={{ listStyle: 'auto' }}>Hate mail, harassment, discriminatory remarks, and other antisocial behaviors are prohibited on the network.</li>
                                <li style={{ listStyle: 'auto' }}>Use of the network to access or process pornographic material, inappropriate text files (as determined by the system administrator or building administrator), or files dangerous to the integrity of the local area network is prohibited.</li>
                                <li style={{ listStyle: 'auto' }}>MyTrustMe.com LLC’s platform may not be used for transmitting entertainment software or other files not related to the mission and objectives of the platform, including copyrighted commercial and non-commercial material, and all other files not directly related to the instructional and administrative purposes of the MyTrustMe.com LLC platform.</li>
                                <li style={{ listStyle: 'auto' }}>Downloading, copying, otherwise duplicating, and/or distributing copyrighted materials without the specific written permission of the copyright owner is prohibited, except that duplication and/or distribution of materials for educational purposes is permitted when such duplication and/or distribution would fall within the Fair Use Doctrine of the United States Copyright Law (Title 17, USC).</li>
                                <li style={{ listStyle: 'auto' }}>Use of the network for any unlawful purpose is prohibited.</li>
                                <li style={{ listStyle: 'auto' }}>Use of profanity, obscenity, racist terms, or other language that may be offensive to another user is prohibited.</li>
                            </ol>
                            <b>Disclaimer</b>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>MyTrustMe.com LLC cannot be held accountable for the information that is retrieved via the network.</li>
                                <li style={{ listStyle: 'auto' }}>Pursuant to the Electronic Communications Privacy Act of 1986 (18 USC 2510 et seq.), notice is hereby given that there are no facilities provided by this system for sending or receiving private or confidential electronic communications. </li>
                                <li style={{ listStyle: 'auto' }}>The MyTrustMe.com LLC will not be responsible for any damages you may suffer, including loss of data resulting from delays, non-deliveries, or service interruptions caused by our own negligence or your errors or omissions. Use of any information obtained is at your own risk.</li>
                                <li style={{ listStyle: 'auto' }}>MyTrustMe.com LLC makes no warranties (expressed or implied) with respect to:</li>
                                <ol style={{ paddingLeft: '35px' }}>
                                    <li style={{ listStyle: 'circle' }}>the content of any advice or information received by a user, or any costs or charges incurred as a result of seeing or accepting any information; and </li>
                                    <li style={{ listStyle: 'circle' }}>any costs, liability, or damages caused by the way the user chooses to use his or her access to the network.</li>
                                </ol>
                                <li style={{ listStyle: 'auto' }}>MyTrustMe.com LLC reserves the right to change its policies and rules at any time.</li>
                            </ol>
                            <b>Password Policy</b><br />
                            <b>1. Overview</b>
                            <p>Passwords are an important aspect of computer security. They are the front line of protection for user accounts. A poorly chosen password may result in the compromise of MyTrustMe.com LLC’s entire network. As such, all employees and users (including contractors and vendors with access to MyTrustMe.com LLC’s systems) are responsible for taking the appropriate steps, as outlined below, to select and secure their passwords.</p>
                            <b>2. Purpose</b>
                            <p>The purpose of this policy is to establish a standard for the creation of strong passwords, the protection of those passwords, and the frequency of change.</p>
                            <b>3. Scope</b>
                            <p>The scope of this policy includes all personnel or customers who have or are responsible for an account (or any form of access that supports or requires a password) on any system that resides at any MyTrustMe.com LLC facility, has access to the MyTrustMe.com LLC network, or stores any non-public MyTrustMe.com LLC information.</p>
                            <b>4. Policy</b>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>All system-level passwords (e.g., root, enable, NT admin, application administration accounts, etc.) must be changed on at least a quarterly basis.</li>
                                <li style={{ listStyle: 'auto' }}>All user-level passwords (e.g., e-mail, web, desktop computer, etc.) should be changed at least every six months. The recommended change interval is every four months. </li>
                                <li style={{ listStyle: 'auto' }}>Each successive password must be unique. Re-use of the same password will not be allowed.</li>
                                <li style={{ listStyle: 'auto' }}>Passwords must be a minimum of eight (8) characters long.</li>
                                <li style={{ listStyle: 'auto' }}>User accounts that have system-level privileges granted through group memberships or programs such as "sudo" must have a unique password from all other accounts held by that user.</li>
                                <li style={{ listStyle: 'auto' }}>Passwords must not be inserted into e-mail messages or other forms of electronic communication.</li>
                                <li style={{ listStyle: 'auto' }}>Where Simple Network Management Protocol (SNMP) is used, the community strings must be defined as something other than the standard defaults of "public," "private," and "system," and must be different from the passwords used to log in interactively. A keyed hash must be used where available (e.g., SNMPv2).</li>
                                <li style={{ listStyle: 'auto' }}>All user-level and system-level passwords must conform to the guidelines described below.</li>
                                <li style={{ listStyle: 'auto' }}>Passwords should never be written down or stored online.</li>
                            </ol>
                            <b>4.1 Password Protection Standards</b>
                            <ol style={{ paddingLeft: '35px' }}>
                                <li style={{ listStyle: 'auto' }}>Do not share agency passwords with anyone, including administrative assistants or secretaries. All passwords are to be treated as sensitive, confidential information.</li>
                                <li style={{ listStyle: 'auto' }}>If someone demands a password, refer them to this document.</li>
                                <li style={{ listStyle: 'auto' }}>Do not use the "Remember Password" feature of applications.</li>
                                <li style={{ listStyle: 'auto' }}>Do not write passwords down and store them anywhere in your office. Do not store passwords in a file on ANY computer system without encryption.</li>
                                <li style={{ listStyle: 'auto' }}>Change passwords at least once every six months (except system-level passwords which must be changed quarterly). The recommended change interval is every four months.</li>
                                <li style={{ listStyle: 'auto' }}>If an account or password is suspected to have been compromised, report the incident to MyTrustMe.com LLC.</li>
                            </ol>
                            <b>5. Enforcement</b>
                            <p>Any employee or user found to have violated this policy may be subject to disciplinary action and loss of network privileges.</p>
                        </div>
                    </div>

                </div>
            </section>

        </>
    );
};


export default AcceptableUserAgreement;