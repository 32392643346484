import React,{useState,useEffect} from 'react';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as CrfpService from '@services/crfp';
import * as Yup from 'yup';
import { useParams,useHistory } from 'react-router-dom';
import dateFormat from "dateformat";
import * as RfpService from '@services/rfp';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';


const EditCorporate = ({currentUser}) => {
    const [isSubmit,setIsSubmit] = useState(false);
    let { uniqueNo,filterType,rfpunique } = useParams();
    const history = useHistory();
    const [data,setData] = useState({})
    const [services,setServices] = useState([]);
    const [checkData,setCheckData] = useState(false);
    const [allRfp,setAllRfp] = useState([]);
    const [finishStatus, setfinishStatus] = useState(false);

        // File Uploads
        const fileUploading = async (request,response,reqType) => {
            const checkSheetFile = (request.document)?request.document:"";
    
            let fileData = new FormData();
            fileData.append('document', request.document);
            const checkAdditionalDoc = (request.additionalDocuments)?request.additionalDocuments:[];
            for (const key of Object.keys(request.additionalDocuments)) {
                fileData.append('additionalDocuments', request.additionalDocuments[key])
            }
            fileData.append("id",response._id);
            fileData.append("uniqueNo",response.uniqueNo);
            fileData.append("userId",response.userId);
            try {
                const data = await CrfpService.uploadDocuments(fileData);
                toast.success("Your request has been "+reqType);
            } catch (error) {
                // toast.error(
                //     (error.response &&
                //         error.response.data &&
                //         error.response.data.message) ||
                //         'Something went wrong'
                // );
            }
        }

    // get cfr detail
    const geCfrDetails = async () => {
        try{
            const { cfrDetail,rfpDetail }  = await CrfpService.GetCFRDetailsForm(uniqueNo);
            setData(cfrDetail)
            setCheckData(true);
            if(filterType == "import"){
                getRfpDetails(rfpunique)
                console.log('uniqqq',rfpunique)
            }else{  
               getRfpDetails(rfpDetail.uniqueNo);
            }
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    
    const getRfpDetails = async (rfpId) => {
        try{
            const { rfpDetail }  = await RfpService.GetRFPDetails(rfpId);
            await setServices(rfpDetail.additionalServices);
            await setAllRfp(rfpDetail);
        } catch (error) {
            // toast.error(
            //     (error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         'Something went wrong'
            // );
        }
    }

    useEffect(() => {
        geCfrDetails();
    },[])


    const onBackButtonEvent = (e) => {
        setIsSubmit(true)
        e.preventDefault();
        if (!finishStatus) {
            // if (window.confirm("Are you sure you want to exit without saving?")) {
            //     setfinishStatus(true)
            //     history.push('/saved-quote');
            // } else {
            //     // window.history.pushState(null, null, window.location.pathname);
            //     setfinishStatus(false)
            // }


           Swal.fire({
                    title: 'Are you sure ?',
                    text: "You want to exit without saving ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                  }).then((result) => {
                    if (result.isConfirmed) {
                        setIsSubmit(false)
                        setfinishStatus(true)
                        history.push('/saved-quote');
                    }else{
                        setIsSubmit(false)
                        window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'saved-quote');
                        setfinishStatus(false)
                    }
                  });
        }
    }


    useEffect(() => {
        // window.history.pushState(null, null, window.location.pathname);
        window.history.pushState(null, null, (window.location.pathname) ? window.location.pathname : 'saved-quote');
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
          window.removeEventListener('popstate', onBackButtonEvent);  
        };
    }, []);


    /**
     * New Rfp Api
     */
    const addNewCrfp = async (request) =>{
        setIsSubmit(true)
        try {
                if(filterType == "import"){
                    request.uniqueId = '';
                }
                const {data} = await CrfpService.NewCrfp(request);
                if(data && data.saveCfrData){
                fileUploading(request,data.saveCfrData,'submitted');
                }else{
                    toast.success('Your requested has been submitted.');
                }
                setIsSubmit(false)
                history.push('/previous-quote');
        } catch (error) {
                setIsSubmit(false)
                // toast.error(
                //     (error.response &&
                //         error.response.data &&
                //         error.response.data.message) ||
                //         'Something went wrong'
                // );
        }
    }
    const formik = useFormik({
        initialValues: {
            userId: data.userId,
            rfpId: (filterType == "import")?allRfp._id:data.rfpId ?? '',
            uniqueId: uniqueNo,
            institution: data.institution ?? '',
            formType: 'complete',
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email ?? '',
            workTel: data.workTel ?? '',
            cellNo: data.cellNo ?? '',
            additionalFirstName: data.additionalFirstName ?? '',
            additionalLastName: data.additionalLastName ?? '',
            additionalEmail: data.additionalEmail ?? '',
            additionalWorkTel: data.additionalWorkTel ?? '',
            additionalCellNo: data.additionalCellNo ?? '',
            transactionSummary: data.transactionSummary ?? '',
            document:  '',
            additionalDocuments: [],
            typeOfServices: data.typeOfServices ?? '',
            legalFeeQuote: data.legalFeeQuote ?? '',
            additionalComments: data.additionalComments ?? '',
            additionalServices: (data.additionalServices)?data.additionalServices:'',
            additionalAcceptanceFee: (data.additionalAcceptanceFee)?data.additionalAcceptanceFee:'',
            additionalAnnualFee: (data.additionalAnnualFee)?data.additionalAnnualFee:'',
            corporateService: (data.corporateService)?data.corporateService:'',
            corporateFees: (data.corporateFees)?data.corporateFees:[],
            legalType: data.legalType ?? '',
            legalAmount: data.legalAmount ?? '',
            legalCurrencyType: data.legalCurrencyType ?? '',
        },
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: Yup.object({
            institution: Yup.string()
                .required('* Required'),
            firstName: Yup.string()
                .required('* Required'),
            lastName: Yup.string()
                .required('* Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('* Required'),
            workTel: Yup.string()
                .required('* Required')
                .min(12,'Minimum 10 digits'),
            cellNo: Yup.string()
            .min(12,'Minimum 10 digits'),
            additionalWorkTel: Yup.string()
            .min(12,'Minimum 10 digits'),
            additionalCellNo: Yup.string()
            .min(12,'Minimum 10 digits'),
            // typeOfServices: Yup.array()
            //     .required('* Required'),
            additionalEmail: Yup.string()
                .email('Invalid additional email address'),
        }),
        onSubmit: (values) => {
            setIsSubmit(true)
            values.formType='complete'
            addNewCrfp(values);
        }
    });

    const focusValidations = (errors) => {
        for(var i in errors){
            document.getElementsByName(i)[0].focus()
            break;
        }
    }


    useEffect(() => {
        formik.setErrors({
            "institution": "",
            "firstName": "",
            "lastName": "",
            "email": "",
            "workTel": "",
            "additionalEmail": ""
        })
    },[])


    const onDraft = async () => {
        setIsSubmit(true)
        formik.values.formType='draft'
        let draftData = formik.values;
        if(filterType == "import"){
            draftData.uniqueId = '';
        }
        console.log(draftData,"draft");
            try {
                const {data} = await CrfpService.NewCrfp(formik.values);
                if(data && data.saveCfrData){
                    fileUploading(draftData,data.saveCfrData,'drafted')
                }else{
                    toast.success('Your requested has been drafted.');
                }
                setIsSubmit(false)
                history.push('/saved-quote');
            } catch (error) {
                setIsSubmit(false)
                // if(error.message){
                //     toast.error(error.message);
                // }else{
                //    toast.error("Something went wrong");
                // }
            }
    }


    const setCustomsFormVal = (key,value) => {
        formik.setFieldValue(key, value)
        if(value){
            setTimeout(()=>{
                formik.setFieldError(key, '')
            },1000)
        }
    }


    return (
    <>
    <section className="contact-section" id="TrusteeDashboard" className="contact-section"  style={{'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundPosition: 'top center' }}>
        <div className="auto-container">
            <div className="row clearfix">
                <div className="form-column col-md-12 col-sm-12 col-xs-12">
                    <h1 className="h1-text text-black">Corporate Trust Services Form</h1>
                </div>
                <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5">
                    <div className="col-md-12 main-form-outside bg-white">
                        <div className="main-title-head">
                            <h1 className="h1-font-size-title">Your information</h1>
                        </div>
                        <form onSubmit={formik.handleSubmit} align="left">
                            {(checkData)?
                            <>
                            <Step1 formik={formik} setCustomsFormVal={setCustomsFormVal}/>
                            <Step2 formik={formik} issuanceAmount={allRfp.issuanceAmount} services={services} rfpDetails={allRfp} formType={'edit'} filterType={filterType} setCustomsFormVal={setCustomsFormVal}/>
                            {(filterType == "import")?<Step3 formik={formik} />:<Step3 formik={formik} formType={'edit'} docs={data.document ?? ''} addiDoc={data.additionalDocuments ?? []} setCustomsFormVal={setCustomsFormVal}/>}
                            {(data.legalFeeQuote == 'yes')?<Step4 formik={formik} setCustomsFormVal={setCustomsFormVal}/>:''}
                            </>
                            :
                            ''
                            }
                            <div className="form-row" align="right">
                                <div className="col-md-12 btn-margin-top-bottom p-12">
                                <button type="button" onClick={(e) => onBackButtonEvent(e)} className="btn btn-primary btn-style-submit btn-back-style res-btn "><i className="fa fa-arrow-left"></i> Back</button>
                                <button type="button" onClick={e=>onDraft()} className="btn btn-success btn-style-submit rfp-button res-btn m-0">Save</button>
                                <button type="submit" onClick={e=>
                                        focusValidations(formik.errors)
                                    } className="btn btn-success btn-style-submit res-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <section>
        <div className="auto-container">
            <div className="col-md-12" style={{zIndex: '999999'}}>
            <div className="col-md-12 p-0" align="left"><button onClick={() => history.goBack()} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</button></div>
            </div>
            <h1 className="text-black">Corporate Trust Services Form</h1>
        </div>
    </section>
    <section className="contact-section">
    	<div className="auto-container">
        	<div className="row clearfix">
                <div className="form-column col-md-12 col-sm-12 col-xs-12">
                <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Your information</h2></div></div>
                    <div className="default-form contact-form">
                        <form onSubmit={formik.handleSubmit} align="left">
                           {(checkData)?
                            <>
                            <Step1 formik={formik} />
                            <Step2 formik={formik} issuanceAmount={allRfp.issuanceAmount} rfpDetails={allRfp} services={services} formType={'edit'} />
                            <Step3 formik={formik} formType={'edit'} docs={data.document ?? ''} addiDoc={data.additionalDocuments ?? []}/>
                            {(data.legalFeeQuote == 'yes')?<Step4 formik={formik} />:''}
                            </>
                            :
                            ''
                            }
                            <div className="form-row" align="right">
                                <div className="col-md-12">
                                <button type="button" onClick={e=>onDraft()}  className="btn btn-primary rfp-button">Save Progress</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>            
                </div>
            </div>
        </div>
    </section> */}
    </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
  });
  
  
export default connect(mapStateToProps)(EditCorporate);