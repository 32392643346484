import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import postscribe from 'postscribe';
import { appTimeZone } from '@app/appConstants';
import moment from 'moment-timezone';

const Footer = () => {
    const currentYear = new Date(moment().tz(appTimeZone)).getFullYear();
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    useEffect(() => {
        postscribe('#scriptLoad', '<script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=t5HGikMU2L4CfRyxvmBMTSIARXYUrFRDMmfRkTlbOMzAduyXR0Tv2BmZFW6E"></script>')
    }, [])

    return (
        <footer className="main-footer">

            <div className="footer-upper" style={{ paddingBottom: "0px" }}>
                <div className="auto-container">
                    <div className="row clearfix">

                        <div className="col-lg-4 col-sm-4 col-xs-12 column" align="left">
                            <div className="footer-widget about-widget">
                                {/* <img src="/frontend/assets/images/home/logo.jpg" alt="" /> */}
                                <div className="text margin-bott-30">
                                    <h3>The company was founded by experienced bankers and advisors.</h3>
                                </div>

                                <div className="social-links">
                                    <a href="https://www.facebook.com/Mytrustmecom-LLC-102000225675933" target="_blank" className="facebook-fb"><span className="fa fa-facebook-f"></span></a>
                                    <a href="https://twitter.com/MyTrustMeLLC" target="_blank" className="twitter-tw"><span className="fa fa-twitter"></span></a>
                                    {/* <a href="#" className="googleplus-gp"><span className="fa fa-google-plus"></span></a>
                                    <a href="#" className="skype-skp"><span className="fa fa-skype"></span></a> */}
                                    <a href="https://www.youtube.com/channel/UCsxgo6kPDSnU9ijQDNiZxUw" target="_blank" className="youtube"><span className="fa fa-youtube"></span></a>
                                    <a href="https://www.linkedin.com/company/mytrustme-com/" target="_blank" className="linked-in"><span className="fa fa-linkedin" ></span></a>

                                </div>

                            </div>
                        </div>


                        <div className="col-lg-4 col-sm-4 col-xs-12 column footer-pl" align="left">
                            <div className="footer-widget links-widget">
                                <h3>Quick Links</h3>
                                <div className="row clearfix">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <ul>
                                            <li><Link to={'/'} onClick={e => scrollToTop()}>Home</Link>
                                            </li>
                                            <li><Link to={'/events'} onClick={e => scrollToTop()}>Events</Link>
                                            </li>
                                            <li><Link to={'/blogs'} onClick={e => scrollToTop()}>Blogs</Link>
                                            </li>
                                            <li><Link to={'/faq'} onClick={e => scrollToTop()}>FAQ</Link></li>
                                            <li><Link to={'/privacy-policy'} onClick={e => scrollToTop()}>Privacy Policy</Link></li>
                                            <li><Link to={'/terms'} onClick={e => scrollToTop()}>Terms and Conditions</Link></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-xs-12 column footer-pl" align="left">
                            <div className="footer-widget newsletter-widget">
                                <h3>Contact Us</h3>

                                <div className="newsletter-form">
                                    <div className="text padd-bott-20">
                                        <ul className="contact-info">
                                            <li><div ><h5><span className="fa fa-phone"></span> (305) 952-4184</h5></div></li>
                                            <li><div ><h5><span className="fa fa-envelope"></span> info@mytrustme.com</h5></div></li>
                                        </ul>
                                        <span id="siteseal"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12 col-sm-12 col-xs-12" align="center"><div className="copyright-text">MYTRUSTME<sup>TM</sup> © {currentYear}  ALL RIGHTS RESERVED. Patent Pending. </div></div>
                    </div>
                </div>
            </div>


        </footer>
    )
}
export default Footer;