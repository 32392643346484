import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
import './events.css'
import { map, sortedLastIndexOf } from 'lodash';
import API from '@app/utils/axios';
import { ConsoleLogger } from '@aws-amplify/core';
import dateFormat from "dateformat";
import Pagination from "react-js-pagination";

const Events = ({ props }) => {
    const [data, setData] = useState([]);
    const [eventsData, setEventsData] = useState([{}]);
    const [pastEventData, setpastEventData] = useState([]);
    const [sideBarData, setSideBarData] = useState([])
    const [handleUpEvent, setUpEventState] = useState(false);
    const [handlePastEvent, setPastEventState] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [totalEventData, setTotalEventsData] = useState(0);
    const [postActivePage, setPostActivePage] = useState(1)
    const [totalPastData, setTotalPastData] = useState(0);




    useEffect(async () => {
        if (handleUpEvent) {
            let eventsData = await getEventsData("upcomingEvents", 1)
            setEventsData(eventsData.data.data.eventData)
            setUpEventState(true)
            setTotalEventsData(eventsData.data.data.eventsTotalLength)
        }
    }, [])



    useEffect(async () => {
        if (activePage) {
            let eventsData = await getEventsData("upcomingEvents", activePage)
            setEventsData(eventsData.data.data.eventData)
            setUpEventState(true)
            setTotalEventsData(eventsData.data.data.eventsTotalLength)
        }
    }, [activePage])

    useEffect(async () => {
        if (postActivePage) {
            let postData = await getEventsData("pastEvents", postActivePage)
            setpastEventData(postData.data.data.eventData)
            setTotalPastData(postData.data.data.pastTotalLength)
        }
    }, [postActivePage])

    const getEventsData = async (EventsType, page) => {
        const data = {
            "eventType": EventsType,
            "page": String(page),
        }
        const eventsData = await API.post('user/get-events', data)
        console.log('eventsData', eventsData)
        return eventsData
    }
    const renderHTML = (rawHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const upcomingEventDesign = (handleUpEvent) ? eventsData.map((key, i) => {
        let blogImg = (key.image) ? process.env.REACT_APP_AWS_OBJECT_URL + key.image.replaceAll("\\", "/") : "";

        let eventStartDate = (key.startEventDate) ? dateFormat(key.startEventDate, "mmm dd,yyyy") : 'N/A'
        let eventEndDate = (key.endEventDate) ? dateFormat(key.endEventDate, "mmm dd,yyyy") : 'N/A'
        let startAndEndDate = `${eventStartDate + " - " + eventEndDate}`

        return (
            <div className='col-md-12 col-sm-12 col-xs-12 event-block text-left'>
                <div className='row'>
                    <div className='col-md-2 col-sm-12 col-xs-12 event-no-padding'>
                        <div class="event-image event-no-padding"
                            style={{
                                fontSize: "30px",
                                backgroundColor: "white",
                                backgroundImage: `url(${blogImg})`,
                                backgroundSize: '100px',
                                border: '5px solid #e8e8e882'
                            }}>
                        </div>
                    </div>
                    <div className='col-md-8  col-sm-12 col-xs-12  event-no-padding'>
                        <div className='event-date' style={{ paddingTop: '6px' }}>

                            {
                                (eventStartDate == eventEndDate) ? eventStartDate : startAndEndDate
                            }
                        </div>
                        <div className='event-sub-data'>
                            <div class="event-text" style={{ paddingTop: "21px", fontSize: "20px" }}>

                                {
                                    (key.url) ?
                                        <a href={`${key.url}`} target="_blank">
                                            <b className='ft-30 text-black'>{key.title}</b>
                                        </a> : <b className='ft-30 text-black'>{key.title}</b>
                                }
                            </div>

                            <p style={{ paddingTop: "9px", fontSize: "17px" }}>{key.description.length > 90 ? key.description.substring(0, 90) + "..." : renderHTML(key.description)}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }) : ""




    const pastEventDesign = (handlePastEvent) ? pastEventData.map((key, i) => {
        let blogImg = (key.image) ? process.env.REACT_APP_AWS_OBJECT_URL + key.image.replaceAll("\\", "/") : "";
        let startdate = (key.startEventDate) ? dateFormat(key.startEventDate, "UTC:mmm dd,yyyy ") : 'N/A'
        let eventStartDate = (key.startEventDate) ? dateFormat(key.startEventDate, "UTC:mmm dd,yyyy") : 'N/A'
        let eventEndDate = (key.endEventDate) ? dateFormat(key.endEventDate, "UTC:mmm dd,yyyy") : 'N/A'
        let startAndEndDate = `${eventStartDate + " - " + eventEndDate}`
        let pstDesc = key.description.length > 90 ? key.description.substring(0, 90) + "..." : key.description

        return (
            <div className='col-md-12 col-sm-12 col-xs-12 event-block text-left'>
                <div className='row'>
                    <div className='col-md-2 col-sm-12 col-xs-12 event-no-padding'>
                        <div class="event-image event-no-padding"
                            style={{
                                fontSize: "30px",
                                backgroundColor: "white",
                                backgroundImage: `url(${blogImg})`,
                                backgroundSize: '100px',
                                border: '5px solid #e8e8e882'
                                // width:"187px",
                                // height:"183px"
                            }}>
                        </div>
                    </div>
                    <div className='col-md-8  col-sm-12 col-xs-12  event-no-padding'>
                        <div className='event-date' style={{ paddingTop: '6px' }}>

                            {
                                (eventStartDate == eventEndDate) ? startdate : startAndEndDate
                            }
                        </div>
                        <div className='event-sub-data'>
                            <div class="event-text" style={{ paddingTop: "21px", fontSize: "20px" }}>

                                {
                                    (key.url) ?
                                        <a href={`${key.url}`} target="_blank">
                                            <b className='ft-30 text-black'>{key.title}</b>
                                        </a> : <b className='ft-30 text-black'>{key.title}</b>
                                }
                            </div>
                            <p style={{ paddingTop: "9px", fontSize: "17px" }}>{renderHTML(pstDesc)}</p>
                        </div>
                    </div>
                </div>
            </div>

        )
    }) : ""

    const changeTabStatus = async (type) => {
        if (type == 'upcomingEvents') {
            let eventsData = await getEventsData("upcomingEvents")
            setEventsData(eventsData.data.data.eventData)
            setUpEventState(true)
            setPastEventState(false);

        } else if (type == 'pastEvents') {
            let postData = await getEventsData("pastEvents")
            setpastEventData(postData.data.data.eventData)
            setUpEventState(false)
            setPastEventState(true);

        }
    }



    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }


    return (
        <>

            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Events</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Events</li>
                        </ul>
                    </div>
                </div>
            </section>
            <div class="sidebar-page-container with-right-sidebar event-bg-blue bg-blue">
                <div class="auto-container">
                    <div class="row clearfix ">

                        <div class="content-side col-lg-12 col-md- col-sm-12 col-xs-12  ">
                            <ul class="post-filter list-inline ">
                                <li class={(handleUpEvent) ? "active" : ""} data-filter=".default-portfolio-item" onClick={e => changeTabStatus('upcomingEvents')}>
                                    <span>Upcoming Events </span>
                                </li>
                                <li class={(handlePastEvent) ? "active" : ""} data-filter=".Consulting" onClick={e => changeTabStatus('pastEvents')}>
                                    <span>Past Events</span>
                                </li>
                            </ul>
                            {(handleUpEvent) ?
                                (upcomingEventDesign && upcomingEventDesign.length > 0) ?
                                    <section class="blog">
                                        <div class="news-style-two">
                                            {upcomingEventDesign}
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={totalEventData}
                                                pageRangeDisplayed={5}
                                                onChange={
                                                    (activePage) => setActivePage(activePage)
                                                }
                                            />

                                        </div>
                                    </section>
                                    : <div align="center"><h2>No Event Found</h2></div>
                                : null}
                            {(handlePastEvent) ?
                                (pastEventDesign && pastEventDesign.length > 0) ?
                                    <section class="blog">
                                        <div class="news-style-two">
                                            {/* <div class="row-15 grid clearfix masonary-layout filter-layout"> */}
                                            {pastEventDesign}
                                            <Pagination
                                                activePage={postActivePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={totalPastData}
                                                pageRangeDisplayed={5}
                                                onChange={
                                                    (postActivePage) => setPostActivePage(postActivePage)
                                                }
                                            />
                                            {/* </div> */}
                                        </div>
                                    </section>
                                    : <div align="center"><h2>No Event Found</h2></div>
                                : null}
                        </div>
                    </div>
                </div>
            </div >


        </>
    );

}


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);




