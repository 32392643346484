const globalConst = {
    ISSUANCETYPE_ID: "6152ddf179fe4614405b054e",
    SERVICE_ID: "61922b42fec64d3d5c52c948",
    MUNICORPORATETRUSTSERVICE: "Defeasance Escrow Agent",
    SHOWTRANSACTIONSECTION: ["Defeasance Escrow Agent", "Bond Defeasance / Bond Refunding", "Refunding Escrow Agent", "Tender Agent / Exchange Agent", "Successor Trustee"],
    SHOWMUNIDOCDOWNLOAD: ["Defeasance Escrow Agent", "Escrow Agent", "Refunding Escrow Agent", "Bond Defeasance / Bond Refunding"],
    CORPCORPATETRUSTSERVICE: "Escrow Agent",
    CORPESCROWISSUANCEID: "61c9a79c73c7ab5ffcb57f90",
    ARSFORMHANDLE: ["621f77dd88254232e8e18e03", "621f779a8d947c1d54cf83bd", "61c570ed37cb6e99c9849900", "6184e132d5d3843ca8c0700a", "616ab33063c7f01cfc0a2f5e"]
}

const selectDateFormat = (date) => {
    let parts = date.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    let finalDate = new Date(parts[0], parts[1] - 1, parts[2]);
    return finalDate;
}

const env = process.env.REACT_APP_MODE;
if (env == 'production') {
    globalConst.ARSFORMHANDLE = ["6184e132d5d3843ca8c0700a", "61c570ed37cb6e99c9849900", "62286507017ed62de4f3d5a1", "62286532017ed62de4f3d5a6", "616ab33063c7f01cfc0a2f5e"];
}

const datatableDataLimit = 10;

const appTimeZone = "America/New_York";

let removeHttpFromUrl = (url) => {
    return url.replace(/(^\w+:|^)\/\//, '')
}

export {
    globalConst,
    selectDateFormat,
    datatableDataLimit,
    appTimeZone,
    removeHttpFromUrl
}