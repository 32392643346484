import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as ArsrService from '@services/arsr';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import dateFormat from "dateformat";
import Swal from 'sweetalert2';
import AssignUserModal from '../../../components/global/modal/AssignUserModal';
import { assignUserToForm } from '../../../services/auth';
import { getUsersByArsr } from '../../../services/arsr';

const SavedQuote = ({ currentUser }) => {
  const { formtype } = useParams();
  const [arsrUserModalOpen, setArsrUserModalOpen] = useState(false);
  const [arsrUserData, setArsrUserData] = useState([]);
  const [selectedRowArs, setSelectedRowArs] = useState([]);

  const [savedData, setSavedData] = useState([]);
  const getSavedList = async () => {
    try {
      if (currentUser['custom:id']) {
        const requestData = {
          userId: currentUser['custom:id'],
          type: "draft",
          reqType: formtype
        };

        const data = await ArsrService.GetSavedLists(requestData);
        let draftList = (data.data.arsrData) ? data.data.arsrData : [];
        console.log('ress', data);
        setSavedData(draftList);
      }
    } catch (error) {
      // toast.error(error);
    }
    document.getElementById('preloader').style.display = "none";
  }

  // deleteCFRData
  const removeARSRFromDB = async (formId) => {
    Swal.fire({
      title: 'Are you sure ?',
      text: "You want to delete this Quote ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const removeParam = {
          id: formId,
        }
        try {
          const { data } = await ArsrService.removeARSR(removeParam);
          toast.success('Quote has been deleted.');
          document.getElementById('preloader').style.display = "block";
          await getSavedList();
        } catch (error) {
          // if (error.message) {
          //   toast.error(error.message);
          // } else {
          //   toast.error("Something went wrong");
          // }
        }
      }
    });
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    getSavedList();
    document.getElementById('preloader').style.display = "block";
  }, [currentUser['custom:id']]);


  const arsrUserColumns = [
    {
      name: 'Name',
      cell: (row) => (row.firstName + " " + row.lastName)
    },
    {
      name: 'Email',
      cell: (row) => <p style={{
        textTransform: 'none'
      }}>
        {(row.email) ? row.email : "N/A"}
      </p>
    },
    {
      name: 'Permissions',
      cell: (row) => <>
        <label>
          <input type="checkbox" name="remember" onClick={
            () => handlePermissions(row, 'view')
          } defaultChecked={(row.assignIsView) ? row.assignIsView : false} className="remember-box" value="" id={'permission-view-' + row._id} />
          <span> View</span>
        </label>

        <label style={{
          marginLeft: "1em"
        }}>
          <input type="checkbox" name="remember" onClick={
            () => handlePermissions(row, 'edit')
          } defaultChecked={(row.assignIsEdit) ? row.assignIsEdit : false} className="remember-box" value="" id={'permission-edit-' + row._id} />
          <span> Edit</span>
        </label>
      </>
    },
  ];


  const showArsUserList = async (row) => {
    document.getElementById('preloader').style.display = "block";
    const {
      arsrData
    } = await getUsersByArsr({ arsrUserid: currentUser['custom:id'], arsId: row.arsId._id })
    setArsrUserData(arsrData);
    setArsrUserModalOpen(true)
    setSelectedRowArs(row.arsId);
    document.getElementById('preloader').style.display = "none";
  }

  const columns = [
    {
      name: 'Transaction Id',
      sortable: true,
      width: "15%",
      cell: (row) => <>
        {
          (row.isView) ?
            <Link to={'/ars/view-rfp/' + row.arsId.uniqueNo + '/' + row.arsId.reqType}>{row.uniqueNo}</Link>
            : row.uniqueNo
        }
      </>
    },
    {
      name: 'Saved Date',
      sortable: true,
      cell: (row) => (row.arsId) ? dateFormat(row.arsId.createdAt, "UTC:mmm d, yyyy") : ""
    },
    {
      name: 'Issuer Name',
      selector: row => (row.arsId) ? row.arsId.issuerName : '...',
      sortable: true,
    },
    {
      name: 'Requester Name',
      selector: row => ((row.arsId) ? row.arsId.firstName : '') + ' ' + ((row.arsId) ? row.arsId.lastName : ''),
      sortable: true,
    },
    {
      name: 'Transaction/Project Name',
      selector: row => (row.arsId) ? row.arsId.projectName : '...',
      sortable: true,
    },
    {
      name: 'Action',
      sortable: true,
      cell: (row) => <>
        {
          (row.isEdit) &&
          <>
            <button onClick={e => showArsUserList(row)} className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            <div style={{ paddingLeft: '5px' }}>
              <Link to={'/edit-response/' + row.uniqueNo + '/' + row.arsId.reqType} className="btn btn-primary btn-sm">
                <i className="fa fa-pencil-square-o" title="Edit Response" />
              </Link>
            </div>
            <div style={{ paddingLeft: '5px' }}>
              <button onClick={e => removeARSRFromDB(row._id)} className="btn btn-danger btn-sm">
                <i className="fa fa-trash" title="Delete Response" />
              </button>
            </div>
          </>
        }
        {
          (row.isView && !row.isEdit) &&
          <>
            <button disabled className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            <div style={{ paddingLeft: '5px' }}>
              <button disabled className="btn btn-primary btn-sm">
                <i className="fa fa-pencil-square-o" title="Edit Response" />
              </button>
            </div>
            <div style={{ paddingLeft: '5px' }}>
              <button disabled className="btn btn-danger btn-sm">
                <i className="fa fa-trash" title="Delete Response" />
              </button>
            </div>
          </>
        }
        {
          (!row.isView && !row.isEdit) &&
          <>
            <button disabled className="btn btn-success btn-sm"><i className="fa fa-user-plus" title="Assign User" /></button>
            <div style={{ paddingLeft: '5px' }}>
              <button disabled className="btn btn-primary btn-sm">
                <i className="fa fa-pencil-square-o" title="Edit Response" />
              </button>
            </div>
            <div style={{ paddingLeft: '5px' }}>
              <button disabled className="btn btn-danger btn-sm">
                <i className="fa fa-trash" title="Delete Response" />
              </button>
            </div>
          </>
        }
      </>
    },
  ];


  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
        fontSize: '15px',
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '16px',
        minHeight: '50px'
      },
    },
    //heading row style
    headRow: {
      style: {
        backgroundColor: '#f5f6fa',

      }
    },

    cells: {
      style: {
        padding: '10px', // override the cell padding for data cells
        paddingRight: '8px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      },
    },
  };



  const handlePermissions = async (row, type) => {
    console.log(selectedRowArs, "rfpIdf")
    const view = document.getElementById('permission-view-' + row._id).checked
    const edit = document.getElementById('permission-edit-' + row._id).checked

    let updateCriteria = {
      userId: row._id,
      formType: "arsr",
      isEdit: edit,
      isView: view,
      formId: selectedRowArs._id
    }

    if (type == "edit") {
      document.getElementById('permission-view-' + row._id).checked = true
      document.getElementById('permission-edit-' + row._id).checked = (!edit) ? false : true
      updateCriteria.isView = true
      updateCriteria.isEdit = (!edit) ? false : true
    } else if (type == "view") {
      document.getElementById('permission-view-' + row._id).checked = (!view) ? false : true
      document.getElementById('permission-edit-' + row._id).checked = false
      updateCriteria.isView = (!view) ? false : true
      updateCriteria.isEdit = false
    }

    await assignUserToForm(updateCriteria)
    // setTimeout(() => {
    //   setArsrUserModalOpen(false)
    // },1000)
  }


  return (
    <>
      <section>
        <div className="auto-container">
          <h1 className="h1-text text-black">Saved Quote List</h1>
        </div>
      </section>
      <section id="TrusteeDashboard" className="contact-section" style={{ 'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top' }}>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="form-column col-md-12 col-sm-12 col-xs-12">
              {
                (arsrUserModalOpen) &&
                <AssignUserModal
                  title={"Assign Permission To Users"}
                  columns={arsrUserColumns}
                  data={arsrUserData}
                  handleConfirmClose={() => setArsrUserModalOpen(!arsrUserModalOpen)}
                />
              }
              <DataTable
                columns={columns}
                data={savedData}
                pagination
                customStyles={customStyles}
                dense
              />
            </div>
            <div className="col-md-12 clr-both">
              <div className="col-md-12 p-0" align="left"><Link to={'/trustee'} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</Link></div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser
});


export default connect(mapStateToProps)(SavedQuote);
