import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';

const Step7 = (props) => {
    let formik = props.formik;

    return (
        <>
        <div className="form-row">
            <div className="col-md-12 padding-0 margin-head-info">
                <div className="main-title-head text-center">
                    <h1 className="h1-font-size-title">Trustee / Escrow Legal Fees</h1>
                </div>
            </div>
        </div>
        {/* <div className="col-md-12"><div className="segment_header"><h2 className="steps-title">Trustee Legal Fees</h2></div></div> */}
        <div className="form-row">
            <div className="form-group form-group-margin col-md-4">
            <label className="label-text label-margin-for-span-p-legal-fee"><p className="span-hash color-white">.</p>Legal Fee Quote <span className="text text-danger">*</span></label>
            <div className="radio">
                <label className="label-text-black radio-inline"><input type="radio" name="legalFee" onChange={formik.handleChange} checked={formik.values.legalFee === "yes"} value="yes" />Yes</label>
                <label className="label-text-black radio-inline"><input type="radio" name="legalFee" onChange={formik.handleChange} checked={formik.values.legalFee === "no"} value="no"/>No</label>
                </div>
                {formik.touched.legalFee && formik.errors.legalFee ? (
                    <div className="text text-danger" align="left">{formik.errors.legalFee}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4">
            <label className="label-text label-margin-for-span-p-legal-fee"><p className="span-hash color-white">.</p>Legal Fee Type {(formik.values.legalFee === "yes")?<span className="text text-danger">*</span>:''}</label>
            <select className="form-control"  name="legalType" {...formik.getFieldProps('legalType')}>
                <option readOnly selected value="">Select Legal Fee Type</option>
                <option value="estimate">Estimate</option>
                <option value="capped">Capped</option>
            </select>
            {formik.touched.legalType && formik.errors.legalType ? (
                    <div className="text text-danger" align="left">{formik.errors.legalType}</div>
                ) : null}
            </div>
            <div className="form-group form-group-margin col-md-4">
            <label className="label-text">Additional comments regarding legal expenses 
            <p className="span-hash">(cap on fees, preferred law firm, etc.)</p>
            </label>
            <input type="text" name="additionalLegalFee" className="form-control" {...formik.getFieldProps('additionalLegalFee')}/>
            {formik.touched.additionalLegalFee && formik.errors.additionalLegalFee ? (
                    <div className="text text-danger" align="left">{formik.errors.additionalLegalFee}</div>
                ) : null}
            </div>
        </div>
        </>
    );
};


export default Step7;