import React,{useState,useEffect} from 'react';
import {toast} from 'react-toastify';
import * as RfpService from '@services/rfp';
import * as AuthService from '@services/auth';
import DataTable from 'react-data-table-component';
import {connect} from 'react-redux';
import { useParams,useHistory,Link  } from 'react-router-dom';
import dateFormat from "dateformat";
import Swal from 'sweetalert2';

const  Response= ({currentUser}) => {
   let history = useHistory();
    const [response, setResponse] = useState([]);
    const [profile, setProfile] = useState();
    let { uniqueNo } = useParams();
    const getResponseList = async (requestData) =>{
        try {
             const {cfrData}= await RfpService.getAllResponse(requestData);
             let historyList = (cfrData)?cfrData:[];
             setResponse(historyList);
         } catch (error) {
            //  toast.error(error);
         }
         document.getElementById('preloader').style.display = "none";
      }

      const getProfile = async () => {
        if (currentUser['custom:id']) {
            try {
                const { userData } = await AuthService.GetProfile(currentUser['custom:id']);
                setProfile(userData);
                document.getElementById('preloader').style.display = "none";
            } catch (error) {
                document.getElementById('preloader').style.display = "none";
                // toast.error(
                //     (error.message) ||
                //     'Failed'
                // );
            }
        }
    }


      const acceptedCfr = async (crfId,rfpId,userID) =>{
        try {
             let statusChange = {
              rfpId: rfpId,
              cfrId: crfId
             }
             const {message}= await RfpService.changeResponseStatus(statusChange);
             toast.success("Your Proposal has been accepted.");
             const query = {
              userId:userID,
              uniqueNo:uniqueNo
             };
             getResponseList(query);
         } catch (error) {
            //  toast.error(error);
         }
         document.getElementById('preloader').style.display = "none";
      }

    
      useEffect(() => {
        window.scrollTo(0, 0)
        const query = {
            userId:currentUser['custom:id'],
            uniqueNo:uniqueNo
        };
        
        if(currentUser['custom:id']){
          getResponseList(query);
          getProfile();
        } 
        document.getElementById('preloader').style.display = "block";
      }, [currentUser['custom:id']]);


      const acceptResponse = (crfId,rfpId,userID) => {
        let message = "You want to accept this response ?";
        // if (process.env.REACT_APP_SECOND_PLAN == "profile.planId" || process.env.REACT_APP_THIRD_PLAN == "profile.planId") {
        //    message = "The amount $25 will be deducted from your account.";
        // }
        Swal.fire({
          title: 'Are you sure ?',
          text: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Accept'
        }).then((result) => {
          if (result.isConfirmed) {
            acceptedCfr(crfId,rfpId,userID);
            document.getElementById('preloader').style.display = "block";
          }
        });
      }

      
      const columns = [
        {
            name: 'Transaction Id',
            sortable: true,
            cell : (row) => row.uniqueNo ?? '...',
            width:'15%'
        },
        {
            name: 'Response Date',
            sortable: true,
            cell: (row) => (row.createdAt)?dateFormat(row.createdAt, "UTC:mmm d, yyyy"):""
        },
        {
            name: 'Respondent Name',
            selector: row => ((row.firstName)?row.firstName:'')+" "+((row.lastName)?row.lastName:''),
            sortable: true,
        },
        {
            name: 'Organization Name',
            selector: row => (row.userId) ? (row.userId.organization)?row.userId.organization.name:'N/A': 'N/A',
            sortable: true,
        },
        {
          name: 'Status',
          selector: row => (!row.status) ? <p>Awaiting Decision</p>: <p style={{textTransform: 'capitalize'}}>{row.status.replace('_', ' ')}</p>,
          sortable: true,
          width: '16rem'
        },
        {
          name: 'Action',
          sortable: true,
          cell : (row) => <><div style={{paddingLeft: '5px'}}><Link to={'/cfr-detail/' + row.uniqueNo} className="btn btn-primary btn-sm"><i className="fa fa-th-list" title="View Responses Detail"/></Link></div> {(!row.status)?<div style={{ paddingLeft: '5px' }}><button onClick={e => acceptResponse(row._id,(row.rfpId)?row.rfpId._id:'',row.requesterId)} className="btn btn-success btn-sm"><i className="fa fa-check-square-o" title="Accept"/></button></div>:(row.status == 'awaiting_decision')?<div style={{ paddingLeft: '5px' }}><button onClick={e => acceptResponse(row._id,(row.rfpId)?row.rfpId._id:'',row.requesterId)} className="btn btn-success btn-sm"><i className="fa fa-check-square-o" title="Accept"/></button></div>:''}</>
      },
    ];
    

    const customStyles = {
        rows: {
          style: {
            minHeight: '72px', // override the row height
          }
        },
        headCells: {
          style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            fontSize: '16px',
            minHeight: '50px'
          },
        },
        headRow: {
          style: {
            backgroundColor: '#f5f6fa',
           
          }
        },
        cells: {
          style: {
            padding: '10px', // override the cell padding for data cells
            paddingRight: '8px',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
          },
        },
      };



    return (
    <>
    <section>
        <div className="auto-container">
            <h1 className="h1-text text-black">RFP's Response List</h1>
        </div>
    </section>
    <section id="TrusteeDashboard" className="contact-section"  style={{'padding': '45px 0px 70px', backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat',backgroundSize:'cover', backgroundPosition: 'top' }}>
    	<div className="auto-container">
        	<div className="row clearfix">
                <div className="form-column col-md-12 col-sm-12 col-xs-12">
                   <DataTable
                        
                        columns={columns} 
                        data={response}
                         pagination
                        customStyles={customStyles}
                        dense
                    />
                </div>
                <div className="col-md-12 clr-both">
                    <div className="col-md-12 p-0" align="left"><button onClick={() => history.goBack()} className="btn btn-primary"><i className="fa fa-arrow-left"></i> Back</button></div>
                </div>
            </div>
        </div>
    </section>
    </>
    );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser
});


export default connect(mapStateToProps)(Response);
