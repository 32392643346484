import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as AuthService from '@services/auth';
import { useFormik } from 'formik';
import { Auth } from "aws-amplify";
import * as Yup from 'yup';
import './../user-detail/trustee-style.css';
import { Link, useHistory } from 'react-router-dom';

const PasswordReset = (location) => {
    const history = useHistory(location);
    const [mailmsg, setMailMsgValue] = useState( false)

    useEffect(() => {
        window.scrollTo(0, 0)
        if(history.location.state){
            setMailMsgValue(true)
            // setTimeout(() => setMailMsgValue(false), 3000);
        }

    }, []);


    const forgotUserPassword = async (reset) => {
        try {
            const { userData } = await AuthService.checkEmailExist({ email: reset.email });
            if (userData.email) {
                let username = reset.email;
                let code = reset.otp;
                let new_password = reset.newPassword;
                const resetPass = await Auth.forgotPasswordSubmit(username, code, new_password);
                const updateParam = {
                    userId: userData._id,
                    newPassword: new_password
                }
                const { updatedPassword } = await AuthService.updateResetPassword(updateParam);
                history.push('/');
                toast.success("Your password is successfully changed.")
                document.getElementById('preloader').style.display = "none";
            } else {
                toast.error("Entered email does not exist. Please check your email");
            }
        } catch (error) {
            // console.log(error,"pass")
            document.getElementById('preloader').style.display = "none";
            // toast.error((error.response &&
            //     error.response.data &&
            //     error.response.data.message) || (error.message) || 'Failed');
        }
    }


    const formik = useFormik({
        initialValues: {
            email: '',
            newPassword: '',
            otp: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email address is required'),
            newPassword: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Please enter a new password'),
            otp: Yup.string()
                .required('Please enter a verification code'),
        }),
        onSubmit: (values) => {
            forgotUserPassword(values);
            document.getElementById('preloader').style.display = "block";
        }
    });


    return (
        <>
            <section id="TrusteeDashboard" className="contact-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                <div className="auto-container">
                    <div className="row clearfix row-container-margin-width">
                        <h1 className="h1-text text-black">Reset Password</h1>
                        < div className="col-md-12 bg-white margin-top-5 form-edit-col-main">
                            <div className="col-md-6 edit-profile-image">
                                <img className="edit-profile-image" style={{ width: '92%' }} src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Reset-Passwrd.png"} />
                            </div>
                            <div className="col-md-6 form-edit-col">
                                <div className="col-md-12 unset-padding padding-edit-profile">
                                    <div className="Inside">
                                        <h1 className="text-black h1-edit-profile">Reset Password</h1>
                                    </div>


                                    <form className="default-form contact-form form-margin text-left change-password-form-padding" onSubmit={formik.handleSubmit} >
                                        {(mailmsg) ?
                                            <div className="alert alert-success">
                                                <span>
                                                    Verification Code has been sent to your email . Please check spam/junk folder also.
                                                </span>
                                            </div>
                                            : null}

                                        <div className="form-group col-md-12">
                                            <label className="label-color">Email <span className="text text-danger">*</span></label>
                                            <input type="email" className="form-control" name="email" {...formik.getFieldProps('email')} />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text text-danger">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label className="label-color">New Password<span className="text text-danger">*</span></label>
                                            <input type="password" className="form-control" name="newPassword" {...formik.getFieldProps('newPassword')} />
                                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                                <div className="text text-danger">{formik.errors.newPassword}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label className="label-color">Verification Code<span className="text text-danger">*</span></label>
                                            <input type="text" className="form-control" name="otp" {...formik.getFieldProps('otp')} />
                                            {formik.touched.otp && formik.errors.otp ? (
                                                <div className="text text-danger">{formik.errors.otp}</div>
                                            ) : null}

                                        </div>
                                        <div className="form-group col-md-12 text-center">
                                            <button type="submit" className="btn btn-primary" style={{ width: '150px' }}>Submit</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};



export default PasswordReset;
