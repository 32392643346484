import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import './resources.css';
import * as ActionTypes from '@store/actions';

const Resource = ({ props }) => {

    const loader = () => {
        document.getElementById('preloader').style.display = "block";
        setTimeout(() => document.getElementById('preloader').style.display = "none", 2000);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loader()
    }, []);

    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/about/AboutUs.jpg)" }}>
                <div className="auto-container">
                    <h1>Resources</h1>
                    <div className="bread-crumb-outer">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/'}>Home</Link></li>
                            <li className="active">Resources</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="services-section bg-blue">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="service-block-three col-md-4 col-sm-6 col-xs-12 text-color-black-resource">
                                <h2>Corporate Trust Banks</h2>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        {/* <div className='custom-round-image'> */}
                                            <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Ankura.png"} />
                                        {/* </div> */}
                                    </div>
                                    <a href="https://ankuratrust.com/" target="_blank"><h3 className='text-color-black-resource' >Ankura Trust Company</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/TMI.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="icon-graphic"></span></div> */}
                                    <a href="https://www.tmico.com/" target="_blank"><h3 className='text-color-black-resource' >TMI Trust Company</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Wilmington-trust.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-vector"></span></div> */}
                                    <a href="https://www3.wilmingtontrust.com/corporate-institutional/capital-markets-agency-services.html" target="_blank"><h3 className='text-color-black-resource' >Wilmington Trust</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/UMB.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-headphones"></span></div> */}
                                    <a href="https://www.umb.com/institutional-banking/corporate-trust" target="_blank"><h3 className='text-color-black-resource' >UMB</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Truist.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-bar-chart-1"></span></div> */}
                                    <a href="https://www.bbt.com/commercial/commercial-solutions/investment-trust-services/corporate-trust.html" target="_blank"><h3 className='text-color-black-resource' >Truist Bank – Corporate Trust Services</h3></a>
                                </div>
                            </div>



                            <div className="service-block-three col-md-4 col-sm-6 col-xs-12 text-color-black-resource">
                                <h2>Bond Resources</h2>

                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Cusip.png"} />
                                    </div>
                                    
                                    <a  href="https://www.cusip.com/index.html" target="_blank"><h3 className='text-color-black-resource' >CUSIP Global Services </h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Isin.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-archives"></span></div> */}
                                    <a href="https://www.isin.org/isin-database/" target="_blank"><h3 className='text-color-black-resource' >ISIN</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Bond-Buyer.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-archives"></span></div> */}
                                    <a href="https://www.bondbuyer.com/" target="_blank"><h3 className='text-color-black-resource' >Bond Buyer</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Bond-link.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-users"></span></div> */}
                                    <a href="https://www.bondlink.com/" target="_blank"><h3 className='text-color-black-resource' >BondLink</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Bonds-loans.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-archives"></span></div> */}
                                    <a href="https://bondsloans.com" target="_blank"><h3 className='text-color-black-resource' >Bonds & Loans</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/MSRB.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-headphones"></span></div> */}
                                    <a href="https://msrb.org" target="_blank"><h3 className='text-color-black-resource' >MSRB</h3></a>
                                </div>
                            </div>

                            <div className="service-block-three col-md-4 col-sm-6 col-xs-12 text-color-black-resource">
                                <h2>Finance</h2>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Bloomberg.png"} />
                                    </div>
                                    <a href="https://www.bloomberg.com/" target="_blank"><h3 className='text-color-black-resource' >Bloomberg</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Morningstar.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-money"></span></div> */}
                                    <a href="https://www.morningstar.com/" target="_blank"><h3 className='text-color-black-resource' >Morningstar</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Yahoo.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-users"></span></div> */}
                                    <a href="https://www.yahoo.com/finance" target="_blank"><h3 className='text-color-black-resource' >Yahoo! Finance</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/SEC.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="flaticon-stats"></span></div> */}
                                    <a href="https://www.sec.gov/" target="_blank"><h3 className='text-color-black-resource' >SEC</h3></a>
                                </div>
                                <div className="inner-box">
                                    <div className="m-auto p-2">
                                        <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/Resource-Page/Thomson-reuters.png"} />
                                    </div>
                                    {/* <div className="icon-box m-auto img-circle bg-white p-2"><span className="icon-graphic"></span></div> */}
                                    <a href="https://www.thomsonreuters.com/en.html" target="_blank"><h3 className='text-color-black-resource' >Thomson Reuters</h3></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Resource);