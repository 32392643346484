import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import {toast} from 'react-toastify';
import "react-phone-input-2/lib/style.css";
import PhoneInput from 'react-phone-input-2';
import './Custom.css';

const Register = (props) => {
    const history = useHistory();

    const [roleopt,setRoleOpt] = useState([]);
    const [orgopt,setOrgOpt] = useState([]);
    const [otherRole,setOtherRole] = useState(false);
    const [otherOrg,setOtherOrg] = useState(false);
    const [roleValue, setRoleValue] = useState("");
    const [orgValue, setOrgValue] = useState("");

       const getRole = async () => {
            try {
                const role = await AuthService.GetRole();
                setRoleOpt(role.roleData ?? []);
            } catch (error) {
                // if(error.message){
                //     toast.error(error.message);
                // }else{
                //     toast.error("Something went wrong");
                // }
            }
       }

       const getOrganization = async (val) => {
        try {
            const organization = await AuthService.GetOrganization(val);
            setOrgOpt(organization.organizationData ?? []);
        } catch (error) {
            // if(error.message){
            //     toast.error(error.message);
            // }else{
            //     toast.error("Something went wrong");
            // }
        }
   }


       useEffect(() => {
         getRole();
       },[])


        const userDetail = async (value) => {
            let username = value.email;
            let password = value.password;
               try {
                   const {userData} = await AuthService.registerUser(value);
                   if(userData){
                        await Auth.signUp({
                            username,
                            password,
                            attributes: {
                            email: username,
                            'custom:user_type': userData.role,
                            'custom:organization': userData.organization,
                            name: value.firstName,
                            family_name : value.lastName,
                            phone_number: (value.phoneNo)?value.phoneNo:'',
                            'custom:id': userData._id
                            }
                        });
                        toast.success("You have registered succesfully. Please check your email for verification");
                        setRoleValue('');
                        setOrgValue('');
                        formik.resetForm();
                        history.push('/');
                        
                   }else{
                        toast.error("Something went wrong");
                   }
                   document.getElementById('preloader').style.display = "none";

               } catch (error) {
                   document.getElementById('preloader').style.display = "none";
                //    console.log(error);
                //    if(error.response){
                //     toast.error(error.response.data.message);
                //    }else{
                //     toast.error('Something went wrong');
                //    }
               }
       }
   
       const formik = useFormik({
           initialValues: {
               firstName: '',
               lastName: '',
               email: '',
               phoneNo: '',
               password: '',
               role: '',
               organization: '',
               roleName: '',
               organizationName: '',
               isSubscribed: true,
               expiryDate: '2022-01-10'
           },
           enableReinitialize: true,
           validationSchema: Yup.object({
                firstName: Yup.string()
                   .test('alphabets', 'First Name must only contain alphabets', (value) => {
                       return /^[a-zA-Z\s]*$/.test(value);
                   })
                   .required('First Name is required'),
                lastName: Yup.string()
                   .test('alphabets', 'Last Name must only contain alphabets', (value) => {
                       return /^[a-zA-Z\s]*$/.test(value);
                   })
                   .required('Last Name is required'),
                email: Yup.string()
                   .email('Invalid email address')
                   .required('Email address is required'),
                phoneNo: Yup.string(),
                password: Yup.string()
                   .min(8, 'Must be 8 characters or more')
                   .required('Password is required'),
                role: Yup.string()
                   .required('Please select role.'),
                organization: Yup.string()
                   .required('Please select organization.')
           }),
           onSubmit: (values) => {
                let checkError = false;
                if(otherRole && roleValue == ''){
                   toast.error("Role name is required.");
                   checkError = false;
                }else{
                   values.roleName =  roleValue;
                   checkError = true;
                }
                if(otherOrg && orgValue == ''){
                    toast.error("Organization name is required.");
                    checkError = false;
                }else{
                    values.organizationName =  orgValue;
                    checkError = true;
                }
                if(checkError){
                    userDetail(values);
                    document.getElementById('preloader').style.display = "block";
                }
           }
       });

    
    const selectedRole = (selRole) => {
        if(selRole == 'other'){
          setOtherRole(true);
        }else{
          if(selRole){
            getOrganization(selRole);
          }else{
            setOrgOpt([]);
          }
          setOtherRole(false);
        }
        formik.values.role = (selRole)?selRole:'';
    }

    const selectedOrganization = (selOrg) => {
        if(selOrg == 'other'){
          setOtherOrg(true);
        }else{
          setOtherOrg(false);
        }
        formik.values.organization = (selOrg)?selOrg:'';
    }
    
    const roleDesign = roleopt.length > 0
    && roleopt.map((item, i) => {
        return(
            <option value={(item)?item._id:''}>{(item)?item.name:''}</option>
        );
    });

    const orgDesign = orgopt.length > 0
    && orgopt.map((item, i) => {
        return(
            <option value={(item)?item._id:''}>{(item)?item.name:''}</option>
        );
    });

    return (
        <>
        <div className="col-md-12 col-sm-12">
        <div className="default-form contact-form">
            <form onSubmit={formik.handleSubmit} id="contact-form">
                <div className="row clearfix">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                            <input type="text" name="firstName"  placeholder="First Name *" {...formik.getFieldProps('firstName')} />
                            {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="text text-danger"  align="left">{formik.errors.firstName}</div>
                        ) : null}
                        </div>
                        <div className="form-group">
                            <input type="text" name="lastName"  placeholder="Last Name *" {...formik.getFieldProps('lastName')} />
                            {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text text-danger"  align="left">{formik.errors.lastName}</div>
                        ) : null}
                        </div>
                        <div className="form-group">
                            <input type="email" name="email"  placeholder="Email *" {...formik.getFieldProps('email')} />
                            {formik.touched.email && formik.errors.email ? (
                        <div className="text text-danger" align="left">{formik.errors.email}</div>
                    ) : null}
                        </div>
                        <div className="form-group">
                            <PhoneInput
                            className="phone-no-input"
                            country={'us'}
                            placeholder="Phone No (optional)"
                            onChange={phone => formik.values.phoneNo = (phone)?"+"+phone:''}
                            value={formik.values.phoneNo}
                            />
                            {formik.touched.phoneNo && formik.errors.phoneNo ? (
                                <div className="text text-danger" align="left">{formik.errors.phoneNo}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <input type="password" name="password"  placeholder="Password *" {...formik.getFieldProps('password')} />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="text text-danger" align="left">{formik.errors.password}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                        <select className="form-control" id="sel" name="role" defaultValue={formik.values.role} onChange={e => selectedRole(e.target.value)}>
                            <option selected value="">Select Role</option>
                            {roleDesign?
                            roleDesign
                            :
                            ''
                            }
                            {
                                (roleDesign)?
                                <option value="other">Other</option>:
                                ""
                            }
                        </select>
                        {formik.touched.role && formik.errors.role ? (
                            <div className="text text-danger" align="left">{formik.errors.role}</div>
                        ) : null}
                        </div>
                        {(otherRole)?
                        <div className="form-group">
                          <input type="text" name="otherrole"  placeholder="Role Name *" value={roleValue} onChange={e => setRoleValue(e.target.value)}/>
                        </div>
                        :''}
                        <div className="form-group">
                        <select className="form-control" id="sel" name="organization" defaultValue={formik.values.organization} onChange={e => selectedOrganization(e.target.value)}>
                            <option  selected value="">Select Organization</option>
                            {(orgDesign)?orgDesign:''}
                            <option value="other">Other</option>
                        </select>
                        {formik.touched.organization && formik.errors.organization ? (
                            <div className="text text-danger" align="left">{formik.errors.organization}</div>
                        ) : null}
                        </div>
                        {(otherOrg)?
                        <div className="form-group">
                          <input type="text" name="otherorg"  placeholder="Organization Name *" value={orgValue} onChange={e => setOrgValue(e.target.value)}/>
                        </div>
                        :''}
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <button type="submit" className="theme-btn btn-style-one btn-lg">Sign Up</button>
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <a onClick={e => props.handleChange(1)}><h4>Already have account ? Sign In</h4></a>
                    </div>
                </div>
            </form>
        </div>
        </div>
        </>
    );
};


export default Register;