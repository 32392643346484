import React from 'react';
import { connect } from 'react-redux';
import * as ActionTypes from '@store/actions';
// import RevSlider, { Slide, Caption } from 'react-rev-slider';
import RevSlider, { Slide, Caption } from 'react-rev-slider';
// import Carousel from 'nuka-carousel';
// import './slider.css'

const BannerSection = ({ onUserLogin, isLoggedIn }) => {
    const config = {
        delay: 9000,
        startwidth: 1170,
        startheight: 500,
        hideThumbs: 10,
        fullWidth: "on",
        forceFullWidth: "off"
    };

    return (
        <>
            <section className="main-slider" data-start-height="680" data-slide-overlay="yes">
                <div className="container-fluid">
                    <div className="tp-banner">
                        {/* <Carousel
                            cellAlign="center"
                            easing="easeInQuad"
                            speed={200}
                        >
                            <div className="slider-bg" style={{
                                background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),'+"url(" + process.env.REACT_APP_IMAGE_TAG+ "/assets/images/main-slider/1.jpg)"
                            }}>
                                <div className="slider-text">
                                    <h2>More time for more Business. Guaranteed.</h2>
                                    <div className="text" align="left">
                                        <b>MyTrustMe</b> takes the tedium out of capital debt market issuances. Our proprietary tech automates the current manual process, letting you spend more time doing<br /> what you do best: transacting new business.
                                    </div>
                                </div>
                            </div>
                            
                            <div className="slider-bg" style={{
                                background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),'+"url(" + process.env.REACT_APP_IMAGE_TAG+ "/assets/images/main-slider/3.jpg)"
                            }}>
                                
                                <div className="slider-text">
                                    <h2>More time for more Business. Guaranteed.</h2>
                                    <div className="text" align="left">
                                        <b>MyTrustMe</b> takes the tedium out of capital debt market issuances. Our proprietary tech automates the current manual process, letting you spend more time doing<br /> what you do best: transacting new business.
                                    </div>
                                </div>
                            </div>
                            
                            <div className="slider-bg" style={{
                                background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),'+"url(" + process.env.REACT_APP_IMAGE_TAG+ "/assets/images/main-slider/2.jpg)"
                            }}>
                                
                                <div className="slider-text">
                                    <h2>More time for more Business. Guaranteed.</h2>
                                    <div className="text" align="left">
                                        <b>MyTrustMe</b> takes the tedium out of capital debt market issuances. Our proprietary tech automates the current manual process, letting you spend more time doing<br /> what you do best: transacting new business.
                                    </div>
                                </div>
                            </div>
                        </Carousel> */}
                        <RevSlider config={config}>
                            <Slide
                                src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/1.jpg"}
                                alt="slidebg1"
                                data-bgfit="cover"
                                data-bgposition="left top"
                                data-bgrepeat="no-repeat"
                                slideProperties={{
                                    'data-transition': "fade",
                                    'data-slotamount': "1",
                                    'data-masterspeed': "1000",
                                    'data-saveperformance': "off",
                                    'data-title': "Awesome Title Here",
                                    'data-thumb': process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/1.jpg",
                                }}
                            >
                                <div className="slide-overlay"></div>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="-70"
                                    data-speed="1500"
                                    data-start="0"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <h2>More time for more Business. Guaranteed.</h2>
                                </Caption>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="5"
                                    data-speed="1500"
                                    data-start="500"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <div className="text" align="left">
                                        <b>MyTrustMe</b> takes the tedium out of capital debt market issuances. Our proprietary tech automates the current manual process, letting you spend more time doing<br /> what you do best: transacting new business.
                                    </div>
                                </Caption>
                                <Caption
                                    class="tp-caption sfl sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="15"
                                    data-y="center"
                                    data-voffset="110"
                                    data-speed="1500"
                                    data-start="1000"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                </Caption>
                            </Slide>
                            <Slide
                                src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/3.jpg"}
                                alt="slidebg1"
                                data-bgfit="cover"
                                data-bgposition="center top"
                                data-bgrepeat="no-repeat"
                                slideProperties={{
                                    'data-transition': "fade",
                                    'data-slotamount': "1",
                                    'data-masterspeed': "1000",
                                    'data-saveperformance': "off",
                                    'data-title': "Awesome Title Here",
                                    'data-thumb': process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/3.jpg",
                                }}
                            >
                                <div className="slide-overlay"></div>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="-70"
                                    data-speed="1500"
                                    data-start="0"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <div align="left"><h2>Where Trust <span className="theme_color">& Innovation Merge</span></h2></div>
                                </Caption>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="5"
                                    data-speed="1500"
                                    data-start="500"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <div className="text pt-100 pt-3" align="left">MyTrustMe… </div>
                                    <div className="text pt-20" align="left">
                                        <ul className="disc-style pl-15">
                                            <li>Increases the efficiency of administering debt issuances.</li>
                                            <li>Makes pricing more transparent while reducing short- and long-term costs.</li>
                                            <li>Provides easier access to capital, giving issuers and investors more time for more business.</li>
                                        </ul>
                                    </div>
                                    <div className="text pt-20 pt-3" align="left">…Guaranteed.</div>
                                </Caption>
                                <Caption
                                    class="tp-caption sfb sfb tp-resizeme"
                                    data-x="center"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="90"
                                    data-speed="1500"
                                    data-start="1000"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                </Caption>
                            </Slide>
                            <Slide
                                src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/2.jpg"}
                                alt="slidebg1"
                                data-bgfit="cover"
                                data-bgposition="center top"
                                data-bgrepeat="no-repeat"
                                slideProperties={{
                                    'data-transition': "fade",
                                    'data-slotamount': "1",
                                    'data-masterspeed': "1000",
                                    'data-saveperformance': "off",
                                    'data-title': "Awesome Title Here",
                                    'data-thumb': process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/2.jpg",
                                }}
                            >
                                <div className="slide-overlay"></div>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="-60"
                                    data-speed="1500"
                                    data-start="0"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <h2>You Control the Process</h2>
                                </Caption>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="1"
                                    data-speed="1500"
                                    data-start="500"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <div className="text" align="left">Our proprietary tech eliminates manual labor from the debt issuance process. That way, you can do business at your speed.</div>
                                </Caption>
                                <Caption
                                    class="tp-caption sfb sfb tp-resizeme"
                                    data-x="center"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="90"
                                    data-speed="1500"
                                    data-start="1000"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                </Caption>
                            </Slide>
                            <Slide
                                src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/4.jpg"}
                                alt="slidebg1"
                                data-bgfit="cover"
                                data-bgposition="center top"
                                data-bgrepeat="no-repeat"
                                slideProperties={{
                                    'data-transition': "fade",
                                    'data-slotamount': "1",
                                    'data-masterspeed': "1000",
                                    'data-saveperformance': "off",
                                    'data-title': "Awesome Title Here",
                                    'data-thumb': process.env.REACT_APP_IMAGE_TAG + "/assets/images/main-slider/3.jpg",
                                }}
                            >
                                <div className="slide-overlay"></div>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="-70"
                                    data-speed="1500"
                                    data-start="0"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <div align="left"><h2>Save Time & Money.  Fairness.  Transparency.</h2></div>
                                </Caption>
                                <Caption
                                    class="tp-caption sft sfb tp-resizeme"
                                    data-x="left"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="5"
                                    data-speed="1500"
                                    data-start="500"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                    <div className="text pt-12" align="left">MyTrustMe.com is the world's only platform for DCM Bankers, and other transactors, such as; Issuers (Corporate, Municipal, and Sovereign), Lawyers, Financial Advisors,<br /> and others, that helps them secure critical tertiary services for their clients to successfully place debt into the market.</div>
                                    <div className="text pt-10" align="left">
                                        Our proprietary technology allows transactors to secure the most competitive fees for the Issuance over the life of the outstanding debt, typically saving thousands of<br /> dollars per issuance.
                                    </div>
                                    <div className="text pt-10" align="left">
                                        We also provide an exportable analysis for each transaction that structures and highlights critical information, making it easier for decision makers to select the best-suited<br /> provider for their transaction.
                                    </div>
                                </Caption>
                                <Caption
                                    class="tp-caption sfb sfb tp-resizeme"
                                    data-x="center"
                                    data-hoffset="0"
                                    data-y="center"
                                    data-voffset="90"
                                    data-speed="1500"
                                    data-start="1000"
                                    data-easing="easeOutExpo"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-elementdelay="0.01"
                                    data-endelementdelay="0.3"
                                    data-endspeed="1200"
                                    data-endeasing="Power4.easeIn"
                                >
                                </Caption>
                            </Slide>
                        </RevSlider>
                    </div>
                </div>
            </section>
        </>
    );
};


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerSection);