import * as ActionTypes from '../actions';

const initialState = {
    isLoggedIn: false,
    token: '',
    currentUser: {
        "custom:id": "",
        "name": "",
        "email": "",
        "phone_number":"",
        "custom:user_type": "",
        "userrole": "",
      }
};

const reducer = (state = initialState, action) => {
    // console.log(action);
    // console.log(ActionTypes.LOGIN_USER);
    if (action.type === ActionTypes.LOGIN_USER) {
        return {
            ...state,
            isLoggedIn: true,
            token: action.token
        };
    }

    if (action.type === ActionTypes.LOGOUT_USER) {
        return {
            ...state,
            isLoggedIn: false,
            token: null,
            currentUser: {
                "custom:id": "",
                "name": "",
                "email": "",
                "phone_number":"",
                "custom:user_type": "",
                "userrole": "",
            }
        };
    }
    if (action.type === ActionTypes.LOAD_USER) {
        return {
            ...state,
            currentUser: action.currentUser
        };
    }

    return {...state};
};

export default reducer;
