import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import * as AuthService from '@services/auth';
import './trustee-style.css';
require("dotenv").config();

const CorporateDashboard = ({currentUser}) => {
    const [org,setOrg] = useState({});
    const getOrganization = async () =>{
        if(currentUser['custom:organization']){
            try {
                const {orgData} = await AuthService.GetOrganizationById(currentUser['custom:organization']);
                setOrg(orgData);
                document.getElementById('preloader').style.display = "none";
            } catch (error) {
                document.getElementById('preloader').style.display = "none";
                // toast.error(
                //     (error.message) ||
                //         'Failed'
                // );
            }
        }
      }
    
      useEffect(() => {
        window.scrollTo(0, 0)
        getOrganization();
        document.getElementById('preloader').style.display = "block";
      }, [currentUser]);

    return (
    <>
    <section id="TrusteeDashboard" className="contact-section" style={{backgroundImage: "url("+process.env.REACT_APP_IMAGE_TAG+"/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat',backgroundSize: 'cover',backgroundPosition: 'bottom'}}>
    	<div className="auto-container">
        	<div className="row clearfix">
                <h1 className="h1-text text-black">WELCOME {(currentUser.name)?currentUser.name:''} {(currentUser.family_name)?currentUser.family_name:''}</h1>
                {(org)?(org.logo)?<img src={process.env.REACT_APP_AWS_OBJECT_URL+org.logo} className="w-12" />: <img src={process.env.REACT_APP_IMAGE_TAG+"/assets/no-org.png"} className="w-12"/>:<h1 className="text-black">Dashboard</h1>}
                <div className="col-md-12 grid3">
                    <div className="col-md-4 col-xs-12 margin-bottom-5">
                        <Link to={'/new-rfp-list'}>
                            <div className="col-md-12 box-radius-style">
                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/new.png"} alt="" />
                                <h3 className="h3-font-style text-black">NEW RFP</h3>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4 col-xs-12 margin-bottom-5">
                        <Link to={'/saved-quote'}>
                            <div className="col-md-12 box-radius-style">
                                <img  className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/proposal.png"} alt="" />
                                <h3 className="h3-font-style text-black">SAVED RFP / RFP IN PROGRESS</h3>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4 col-xs-12 margin-bottom-5">
                        <Link to={'/previous-quote'}>
                            <div className="col-md-12 box-radius-style">
                                <img className="icon-sz-sm-20 image-margin" src={process.env.REACT_APP_IMAGE_TAG + "/assets/images/icons/history.png"} alt="" />
                                <h3 className="h3-font-style text-black">PREVIOUS / SUBMITTED RFPs</h3>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});
  
  
export default connect(mapStateToProps,null)(CorporateDashboard);
