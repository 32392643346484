import React from 'react';
require("dotenv").config();

const Section3 = ({ data }) => {



  const openFile = (url) => {
    window.open(process.env.REACT_APP_AWS_OBJECT_URL + url, "_blank");
  }
  return (
        <>
      <div className="form-row">
        <div className="form-column col-md-12 col-sm-12 col-xs-12 margin-top-5 padding-0 ">
          <div className="main-title-head text-center">
            <h1 className="h1-font-size-title">ATTACHMENTS</h1>
          </div>
        </div>
      </div>
      <div className="form-row col-md-12 details-row-left">
        <div className="col-md-6">
          <label className="form-group-margin label-text">Term Sheet</label>
          {
            (data && data.termSheet)
              ?
              <>
                <li>
                  <span className='files-list-item-content-item files-list-item-content-item-1 cursor-pointer paragraph-text' onClick={e => openFile(data.termSheet)}>
                    {(data.termSheet.substring(data.termSheet.lastIndexOf("/") + 1).length > 60) ? data.termSheet.substring(data.termSheet.lastIndexOf('/') + 1).substring(0, 40) + "..." : data.termSheet.substring(data.termSheet.lastIndexOf('/') + 1)}
                  </span>
                </li>
              </>
              :
              <div className="col-md-12 paragraph-text padding-0">----</div>
          }
        </div>
        <div className="col-md-6 ">
          <label className="form-group-margin label-text">Additional documents </label>
          {
            data.additionalDocuments && data.additionalDocuments.length > 0
              ?
              <>
                <div>
                  <div>
                    <ul>
                      {data.additionalDocuments.map((file) =>
                        <li>
                          <span className='files-list-item-content-item files-list-item-content-item-1 cursor-pointer paragraph-text' onClick={e => openFile(file)}>
                            {(file.substring(file.lastIndexOf("/") + 1).length > 60) ? file.substring(file.lastIndexOf("/") + 1).substring(0, 40) + "..." : file.substring(file.lastIndexOf("/") + 1)}
                          </span></li>
                      )}
                    </ul>
                  </div>
                </div>
              </>
              : <div className="col-md-12 paragraph-text padding-0">----</div>
          }

        </div>


      </div>
      </>
      );
};


      export default Section3;