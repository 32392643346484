import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Login.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import * as ActionTypes from '@store/actions';
import { connect } from 'react-redux';
import store from '@store/index';
import { appTimeZone } from '@app/appConstants';
import moment from 'moment-timezone';

const LoginModal = (props) => {

    const [resetModal, setResetModal] = useState(false);
    const history = useHistory();
    const [passwordShown, setPasswordShown] = useState(false);
    /**
     * 
     * @param {*} value 
     */
    const userLogin = async (value) => {
        let username = value.email;
        let password = value.password;
        let todayDate = new Date(moment().tz(appTimeZone)).toISOString();
        try {
            AuthService.checkUserAccount(username).then(async ({ userData }) => {
                console.log(props, "user")
                if (props.useridSet?.length > 0) {
                    props.useridSet((userData) ? userData._id : '')
                }
                Auth.signIn(username, password).then(async (user) => {
                    if (user.attributes['custom:id']) {
                        const { userData } = await AuthService.GetProfile(user.attributes['custom:id']);
                        const { roleData } = await AuthService.GetRoleById(user.attributes['custom:user_type']);
                        await Auth.updateUserAttributes(user, {
                            'custom:userrole': roleData.slug,
                            'custom:isSubscribed': (userData.isSubscribed) ? '1' : '0',
                            'custom:expiryDate': (userData.expiryDate) ? userData.expiryDate : '',
                        });
                        if (userData && userData.isSubscribed && userData.expiryDate > todayDate) {
                            // if(roleData && roleData.slug == 'capital_markets_banker' || roleData.slug == 'law_firms'){
                            //     history.push("/trustee");
                            // }else if(roleData && roleData.slug == 'corporate_trust_provider') {
                            //     history.push("/corporate");
                            // }
                            if (roleData && roleData.slug == 'corporate_trust_provider') {
                                history.push("/corporate");
                            } else {
                                history.push("/trustee");
                            }
                        } else {
                            history.push('/subscription/' + window.btoa(userData._id));
                        }

                        let usez = await Auth.currentAuthenticatedUser();
                        const userDetail = usez.attributes;
                        props.handleLoginModal(false)
                        props.onUserLogin(usez.signInUserSession.idToken.jwtToken);
                        props.onUserDetail(userDetail);
                        // toast.success("You are Logged In successfully. Please wait...");
                    } else {
                        await Auth.signOut();
                        store.dispatch({ type: ActionTypes.LOGOUT_USER });
                        history.push("/");
                        // toast.success("You are Logout Successfully");
                    }
                }).catch(async (error) => {
                    console.log(error, "err")
                    document.getElementById('preloader').style.display = "none";
                    if (error && error.message == 'User is not confirmed.') {
                        props.usernameSet(username);
                        props.handleVerifyModal(true);
                        props.customMessage("Account not verified. Please check your email for verification code. Check spam/junk folder also.");
                        await Auth.resendSignUp(username);
                        // toast.error("Account not verified. Please check your email for verification code. Check spam/junk folder also.");
                    } else {
                        toast.error(
                            (error.message) ||
                            'Failed'
                        );
                    }
                });
            }).catch((err) => {
                console.log(err)
                // if(err && err.response && err.response.data.responseType == "EMAIL_ALREADY_EXISTS" || err.response.data.responseType == "EMAIL_NOT_FOUND"){
                //     toast.error(err.response.data.message);
                //     console.log("email")
                // }
                document.getElementById('preloader').style.display = "none";
            });

            document.getElementById('preloader').style.display = "none";
        } catch (error) {
            document.getElementById('preloader').style.display = "none";
            if (error && error.message == 'User is not confirmed.') {
                props.usernameSet(username);
                props.handleVerifyModal(true);
                props.customMessage("Account not verified. Please check your email for verification code. Check spam/junk folder also.");
                await Auth.resendSignUp(username);
                // toast.error("Account not verified. Please check your email for verification code. Check spam/junk folder also.");
            } else {
                toast.error(
                    (error.message) ||
                    'Failed'
                );
            }
        }
    }

    const setCustomsFormVal = (key, value, type) => {
        formik.setFieldValue(key, value)
        if (value) {
            setTimeout(() => {
                formik.setFieldError(key, '')
            }, 1000)
        }
        if (!value && type == 'required') {
            setTimeout(async () => {
                await formik.setFieldError(key, "* Required")
                await formik.setFieldTouched(key, true)
            }, 1000)
        }
        if (value && key == 'password' && value.length < 8) {
            setTimeout(async () => {
                await formik.setFieldError(key, "Must be 8 characters or more")
                await formik.setFieldTouched(key, true)
            }, 1000)
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email address is required'),
            password: Yup.string()
                .min(8, 'Must be 8 characters or more')
                .required('Password is required')
        }),
        onSubmit: (values) => {
            userLogin(values);
            document.getElementById('preloader').style.display = "block";
        }
    });


    const togglePasswordVisiblity = (typeOf) => {
        if (typeOf == 'password') {
            setPasswordShown(passwordShown ? false : true);
        }
    };

    return (
        <>
            <div className="modal fade in d-block bd-example-modal-lg auth-modal" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content br-0" align="left">
                        <div className="modal-header login-modal-header" align="center">
                            <button type="button" onClick={e => props.handleLoginModal(false)} className="close mdl-close login-mdl-close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body" style={{ padding: '40px' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={process.env.REACT_APP_IMAGE_TAG + "/assets/Login.png"} />
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-12 col-sm-12">
                                        <div align="center" className="pt-2">
                                            <h2 className="main-title">Secure Login</h2>
                                            <p>Welcome back! Please log-in to your account.</p>
                                        </div>
                                        <div className="default-form contact-form">
                                            <form onSubmit={formik.handleSubmit} id="contact-form">
                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        <div className="form-group">
                                                            <input type="text" name="email" placeholder="Email *" {...formik.getFieldProps('email')} />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text text-danger" align="left">{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-group">
                                                            <input type={passwordShown ? "text" : "password"} name="password" placeholder="Password *" onChange={e => setCustomsFormVal('password', e.target.value, 'required')} />
                                                            <span className="p-viewer" onClick={e => togglePasswordVisiblity('password')}><i class={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></span>
                                                            {formik.touched.password && formik.errors.password ? (
                                                                <div className="text text-danger" align="left">{formik.errors.password}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="row">
                                                            <div className="checkboxes col-md-6 font-18 text-align-right">
                                                                <label>
                                                                    <input type="checkbox" name="remember" className="remember-box" value="" />
                                                                    <span>Remember Me</span></label>
                                                            </div>
                                                            <div className="col-md-6 font-18 forgot-btn text-align-left">
                                                                <a onClick={() => props.handleForgetModal(true)} ><label style={{ color: '#306ac6', cursor: 'pointer' }}>Forgot Password</label></a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: "center" }} >
                                                        <button type="submit" className="theme-btn btn-style-one btn-lg active-btn">Log in</button>
                                                    </div>
                                                    {/* <div className="col-md-6 col-sm-6 col-xs-6 text-align-left">
                                        <button type="button" className="theme-btn btn-style-one btn-lg in-active-btn" onClick={() => props.handleRegisterModal(true)}>Sign Up</button>
                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);