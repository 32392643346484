import React, { useState, useEffect } from 'react';
// import {
//     ChartComponent,
//     SeriesCollectionDirective,
//     SeriesDirective,
//     Inject,
//     Legend,
//     Category,
//     Tooltip,
//     ColumnSeries,
//     DataLabel,
//   } from '@syncfusion/ej2-react-charts';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid,
    Legend, Tooltip
} from 'recharts';
import PropTypes from 'prop-types';

const Graph = ({
    data,
    handleGraphModal
}) => {

    const [graphData, setGraphData] = useState([])
    const [secondGraphData, setSecondGraphData] = useState([])
    useEffect(() => {
        let tempGraphData = [];
        let tempSecondGraphData = [];
        for (let i = 0; i < data.length; i++) {
            console.log(data[i]);
            tempGraphData.push({
                name: (data[i]) ? data[i].organizations.name : (i + 1).toString(),
                LegalFees: (data[i].legalAmount) ? parseInt(data[i].legalAmount) : 0,
                TotalWithoutLegalFees: (data[i].withoutLegalFee) ? parseInt(data[i].withoutLegalFee) : 0
            })
            // tempGraphData.push({
            //     x: (data[i]) ? data[i].organizations.name : (i + 1).toString(),
            //     y: parseInt(data[i].withLegalFee)
            // })
            // tempSecondGraphData.push({
            //     x: (data[i]) ? data[i].organizations.name : (i + 1).toString(),
            //     y: parseInt(data[i].withoutLegalFee)
            // })
        }

        setGraphData(tempGraphData)
        setSecondGraphData(tempSecondGraphData)
    }, [data])
    // console.log(graphData)
    return (
        <>
            <div className="modal fade in d-block bd-example-modal-lg auth-modal" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg" style={{ width: 'auto' }}>
                    <div className="modal-content" style={{
                        borderRadius: "20px"
                    }} align="left">
                        {/* <div className="modal-header login-modal-header" align="center">
                    </div> */}
                        <button type="button" onClick={e => handleGraphModal(false)} className="close mdl-close login-mdl-close" data-dismiss="modal">&times;</button>

                        <div className="modal-body" style={{ padding: '50px' }}>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        (graphData.length != 0 || secondGraphData.length != 0) ?
                                            <BarChart width={(graphData.length > 4) ? 900 : 160 * graphData.length} height={500} data={graphData} >
                                                <CartesianGrid />
                                                <XAxis dataKey="name" />
                                                <YAxis tickFormatter={(value) => new Intl.NumberFormat('en').format(value)} allowDataOverflow={true} />
                                                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                                <Legend />
                                                <Bar dataKey="TotalWithoutLegalFees" stackId="a" fill="#3169c6" />
                                                <Bar dataKey="LegalFees" stackId="a" fill="#99aff8" />
                                            </BarChart>
                                            // <ChartComponent
                                            //     id="charts"
                                            //     style={{ textAlign: 'left'}}
                                            //     primaryXAxis={{
                                            //         valueType: 'Category',
                                            //         interval: 1,
                                            //         majorGridLines: { width: 0 },
                                            //     }}
                                            //     primaryYAxis={{
                                            //         majorGridLines: { width: 1 },
                                            //         majorTickLines: { width: 0 },
                                            //         lineStyle: { width: 0 },
                                            //         labelStyle: { color: 'black' },
                                            //     }}
                                            //     chartArea={{ border: { width: 0 } }}
                                            //     tooltip={{ enable: true }}
                                            //     width={'100%'}
                                            //     title="Total with Legal Fees"
                                            // >
                                            //     <Inject
                                            //         services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}
                                            //     />
                                            //     <SeriesCollectionDirective>
                                            //         {
                                            //             graphData.length > 0
                                            //                 && graphData.map((item, i) => {
                                            //                 return(
                                            //                     <SeriesDirective
                                            //                         position="left"
                                            //                         dataSource={[item]}
                                            //                         xName="x"
                                            //                         yName="y"
                                            //                         // name={`Legal Fees`}
                                            //                         type="Column"
                                            //                         groupName={item.x}
                                            //                         columnWidth={0.1*graphData.length}
                                            //                         columnSpacing={0.1}
                                            //                         fill={"#99aff8"}
                                            //                         marker={{
                                            //                             dataLabel: {
                                            //                                 visible: false,
                                            //                                 position: 'Top',
                                            //                                 font: { fontWeight: '600', color: '#ffffff' },
                                            //                             },
                                            //                         }}
                                            //                     ></SeriesDirective>
                                            //                 )  
                                            //                 })
                                            //         }
                                            //         {
                                            //             secondGraphData.length > 0
                                            //                 && secondGraphData.map((item, i) => {
                                            //                 return(
                                            //                     <SeriesDirective
                                            //                         dataSource={[item]}
                                            //                         xName="x"
                                            //                         yName="y"
                                            //                         // name={`Cumulative cost over the life of the issuance`}
                                            //                         type="Column"
                                            //                         groupName={item.x}
                                            //                         columnWidth={0.1*graphData.length}
                                            //                         fill={"#3169c6"}
                                            //                         columnSpacing={0.1}
                                            //                         marker={{
                                            //                             dataLabel: {
                                            //                                 visible: false,
                                            //                                 position: 'Top',
                                            //                                 font: { fontWeight: '600', color: '#ffffff' },
                                            //                             },
                                            //                         }}
                                            //                     ></SeriesDirective>
                                            //                 )  
                                            //                 })
                                            //         }
                                            //     </SeriesCollectionDirective>
                                            // </ChartComponent>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='col-md-6'><p style={{
                                    float: 'right'
                                }}><span class='graph-clr-box graph-clr-box-green'></span> <span style={{ marginLeft: '5px' }}>Legal Fees</span></p></div>
                                <div className='col-md-6'><p style={{
                                    float: 'left'
                                }}><span class='graph-clr-box graph-clr-box-blue'></span> <span style={{ marginLeft: '5px' }}>Total Without Legal Fees</span></p></div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};
Graph.propTypes = {
    data: PropTypes.array.isRequired
};
export default Graph;