import React, { useState, useEffect } from 'react';
import Section1 from '@components/arsresponse/view/Section1'
import Section2 from '@components/arsresponse/view/Section2'
import Section3 from '@components/arsresponse/view/Section3'
import Section4 from '@components/arsresponse/view/Section4'
import { toast } from 'react-toastify';
import * as ArsrService from '@services/arsr';
import '../../sender/rfp/rfp.css'
import { useParams, useHistory } from 'react-router-dom';

const ArsrDetail = () => {
    const [data, setData] = useState({})
    const [isFetching, setIsFetching] = useState(false)
    let { uniqueNo } = useParams();
    const history = useHistory();

    const getArsrDetails = async () => {
        try {
            const { arsrDetail } = await ArsrService.GetArsrDetails(uniqueNo);
            await setData(arsrDetail)

            setIsFetching(true);
        } catch (error) {
            // if (error.message) {
            //     toast.error(error.message);
            // } else {
            //     toast.error("Something went wrong");
            // }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getArsrDetails();
    }, [])

    return (
        <>
            <section className="contact-section">
                <div className="row clearfix">
                    <div className="auto-container">
                        <h1 className="h1-text text-black">{(data.reqFormType == 'ars') ? "Arbitrage Rebate Services" : "Verification Agent"} Form</h1>
                    </div>
                </div>
            </section>

            <section id="TrusteeDashboard" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMAGE_TAG + "/assets/background-trustee.jpg)", backgroundRepeat: 'no-repeat', backgroundPosition: 'top center', textAlign: 'left', textTransform: 'capitalize', paddingTop: '0px !important', }}>
                <div className="row clearfix" >
                    <div className="auto-container" style={{ borderRadius: " 8px", boxShadow: "0px 0px 10px 0px #d2c9c9" }}>
                        {
                            (isFetching) ?
                                <div className="row clearfix ">
                                    <Section1 data={data} />
                                    <Section2 data={data} />
                                    <Section3 data={data} />
                                </div>
                                : null
                        }
                        <div className="col-md-12 btn-margin-top-bottom" style={{ zIndex: '999999' }}>
                            <div className="col-md-12 p-0" ><button onClick={() => history.goBack()} className="btn btn-primary res-btn m-b"><i className="fa fa-arrow-left"></i> Back</button></div>
                        </div>
                    </div>

                </div>

            </section>


        </>
    );
};

export default ArsrDetail;
