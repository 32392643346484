import React,{useState,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './Login.css';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import * as AuthService from '@services/auth';
import { Auth } from "aws-amplify";
import {toast} from 'react-toastify';
import * as ActionTypes from '@store/actions';
import {connect} from 'react-redux';
import store from '@store/index';
import './verifyOtpModal.css'
import OtpInput from 'react-otp-input';

const VerifyOtpModal = ({username,handleVerifyModal,customMessage,userId}) => {

    const [resetModal,setResetModal] = useState(false);
    const history = useHistory();
    const [passwordShown, setPasswordShown] = useState(false);
    const [otp,setOtp] = useState('');
    const [mailmsg, setMailMsgValue] = useState();

    
    useEffect(() => {
            setMailMsgValue((customMessage)?customMessage:"Please check your email for verification code. Check spam/junk folder also.");
    }, []);


    /**
     * 
     * @param {*} value 
     */
    const userVerifyOtp = async () => {
        if(otp){
            if(otp.length == 6){
                Auth.confirmSignUp(username, otp, {
                    forceAliasCreation: true,
                }).then(data => {
                    toast.success('Your account is verified successfully');
                    handleVerifyModal(false)
                    history.push('/subscription/'+window.btoa(userId));
                }).catch(error => {
                    // toast.error(error.message);
                    setMailMsgValue(error.message);
                })
            }else{
                // toast.error("Verify Code must be of 6 digit number");
                setMailMsgValue("Verify Code must be of 6 digit number");
            }
        }else{
            // toast.error("Please enter verification code");
            setMailMsgValue("Please enter verification code");
        }
    }

    const resendOtp = async () => {
        await Auth.resendSignUp(username);
        // toast.success("Please check your email for verification code. Check spam/junk folder also.");
        setMailMsgValue("Please check your email for verification code. Check spam/junk folder also.");
    }


    const handleChange = (otp) => setOtp( otp );

    return (
        <>
         <div className="modal fade in d-block bd-example-modal-lg auth-modal" id="myModal" role="dialog">
            <div className="modal-dialog modal-lg">
            <div className="modal-content br-0" align="left">
                <div className="modal-header login-modal-header" align="center">
                <button type="button" onClick={e => handleVerifyModal(false)} className="close mdl-close login-mdl-close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body" style={{padding:'40px'}}>
                  <div className="row">
                     <div className="col-md-6">
                     <img src={process.env.REACT_APP_IMAGE_TAG+"/assets/Login.png"} />
                     </div>
                     <div className="col-md-6">
                     <div className="col-md-12 col-sm-12 verify-form">
                         <div align="center" className="pt-2">
                            <h2 className="main-title">Verify Code</h2>
                            <p>Welcome back! Please Verify your account.</p>
                         </div>
                        <div className="default-form contact-form">
                            <form id="contact-form">
                                <div className="row clearfix">
                                    <div className="col-md-12 col-sm-12 col-xs-12">  
                                        <form action="" className="mt-5">
                                            <OtpInput
                                                value={otp}
                                                onChange={handleChange}
                                                numInputs={6}
                                                separator={<span>-</span>}
                                            />
                                        </form>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 text-align-right" style={{textAlign:"center",marginTop: '5%'}} >
                                        <button type="button" onClick={() => resendOtp()} className="theme-btn btn-style-one btn-lg active-btn" style={{
                                            marginRight: '10px'
                                        }}>Resend Code</button>
                                        <button type="button" onClick={() => userVerifyOtp()} className="theme-btn btn-style-one btn-lg active-btn">Verify</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                       </div>
                       {(mailmsg)?
                        <div className="col-md-12 alert alert-success" style={{marginTop: '10px'}}>
                            <span>
                                {mailmsg}
                            </span>
                        </div>
                        :null }
                     </div>
                  </div>
                </div>
            </div>
            
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser
  });
  
  const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (token) => dispatch({
        type: ActionTypes.LOGIN_USER, token
    }),
    onUserDetail: (currentUser) => dispatch({
        type: ActionTypes.LOAD_USER, currentUser
    })
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtpModal);